import produce from "immer";
import * as merchantActions from "service/actions/merchant";

let initialState = {
    privilege: null,
    isAdmin: null,
    isImpersonate: false,
};

export default produce((draft, action) => {
    switch (action.type) {
        case merchantActions.setProfile().type:
            const keys = Object.keys(action.data);
            keys.forEach((key) => {
                draft[key] = action.data[key];
            });
            break;
        default:
    }
}, initialState);

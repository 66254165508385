import { styled } from "@material-ui/core";
import { memo } from "react";

const Column = styled("div")({
    display: "flex",
    flexDirection: "column",
});
export default (data) => {
    return (
        <Column>
            <span>{data}</span>
        </Column>
    );
};

import { styled } from "@material-ui/core";
import { YHLogo, YohoButtonIcon } from "components/Global";
import { FormText } from "components/Global/Form";
import useMenu from "hooks/useMenu";
import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { setLogout, stopImpersonation } from "service/actions/authentication";
import { setRedirect } from "service/actions/site";
import ProfileMenu from "./Menu/ProfileMenu";
import { useDispatch, useSelector } from "react-redux";
import ImpersonationBox from "./ImpersonationBox";

const Header = styled("div")(({ theme }) => ({
    display: "flex",
    padding: "8px 20px",
    height: 49,
    boxShadow: `inset 0 -1px 0 0 ${theme.yoho.md_color.grey[300]}`,
    justifyContent: "space-between",
    zIndex: 100000,
}));

const YHtitle = styled("h5")(({ theme }) => ({
    ...theme.yoho.typography.h5,
    margin: 0,
}));

const YHBrand = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
});

const RightPanel = styled("div")({
    marginTop: 5,
    gap: 28,
    "& > div": {
        alignItems: "center",
    },
});

export default () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { menu } = useMenu();
    const profileMenuRef = useRef();
    const { isAdmin, isImpersonate } = useSelector((state) => state.profile);
    const [showImpersonationBox, setShowImpersonationBox] = useState(false);

    return (
        <>
            <Header className="header">
                <YHBrand>
                    <YHLogo />
                    <YHtitle>YOHO Merchant Centre</YHtitle>
                    {isAdmin && (
                        <FormText color="grey" code="400">
                            <h5>Administration Console</h5>
                        </FormText>
                    )}
                    {isImpersonate && (
                        <FormText color="red" code="400">
                            <h5>Impersonation Mode</h5>
                        </FormText>
                    )}
                </YHBrand>
                <RightPanel>
                    <div
                        ref={profileMenuRef}
                        onClick={() => {
                            menu(ProfileMenu, {
                                refs: profileMenuRef,
                                logoutAction: () => dispatch(setLogout(pathname)),
                                profileAction: () =>
                                    dispatch(
                                        setRedirect({
                                            pathname: isAdmin ? "/admin/personal" : "/personal",
                                        }),
                                    ),
                                impersonateAction: () => setShowImpersonationBox(true),
                                stopImpersonateAction: () => dispatch(stopImpersonation()),
                            });
                        }}
                    >
                        <YohoButtonIcon width={40} height={40} src="/assets/icon/account-outline.svg" />
                    </div>
                </RightPanel>
            </Header>
            {isAdmin && <ImpersonationBox open={showImpersonationBox} setOpen={setShowImpersonationBox} />}
        </>
    );
};

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ButtonLink } from "components/Global/Form";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    error: {
        color: "red",
    },
    title: {
        fontSize: 17,
        marginTop: 0,
    },
}));

const FormTitle = styled("p")(({ theme }) => ({
    "&::before": {
        content: "''",
        position: "absolute",
        left: 0,
        top: "36px",
        width: "10px",
        height: "26px",
        backgroundColor: theme.palette.primary.main,
    },
    fontSize: 28,
    fontWeight: 500,
    lineHeight: "40.5px",
    marginTop: 0,
    color: theme.palette.primary.main,
}));

const FormMainContainer = styled(Grid)({
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
});

const BackContainer = styled("div")(({ theme }) => ({
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.main,
    "& > p": {
        margin: 0,
        cursor: "pointer",
    },
}));

const FormContainer = styled(Grid)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "#424242",
    "& *": {
        margin: 0,
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
    },
    "& p": {
        marginTop: 6,
    },
    "& img": {
        width: "160px",
    },
    "& p.text-2": {
        color: "#b2b2b2",
    },
}));

const ActionContainer = styled(Grid)(({ theme }) => ({}));

export default () => {
    const propsST = useLocation();
    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        if (!propsST.state) {
            history.push("/auth/forgotpass");
        }
    }, [propsST.state]);

    return (
        <div className={classes.root}>
            <FormTitle className={classes.title}>Check Your Mailbox</FormTitle>

            <div style={{ height: "100%" }}>
                <FormMainContainer container spacing={2} justify="center">
                    <FormContainer item sm={12} xs={12}>
                        <img src="/assets/icon/email.svg" />
                        <p className="text-1">We have sent a password reset email to</p>
                        <p className="text-2">{propsST.state?.data || "d*****@yoh********.com"}</p>
                        <p className="text-3">One step left!</p>
                    </FormContainer>
                    <ActionContainer item sm={12} xs={12}>
                        <BackContainer>
                            <ButtonLink to="/auth/login" cvariant="text" ccolor="blue">
                                OK
                            </ButtonLink>
                        </BackContainer>
                    </ActionContainer>
                </FormMainContainer>
            </div>
        </div>
    );
};

import { styled } from "@material-ui/core";
import { Link } from "react-router-dom";
import { YohoIcon } from "components/Global";

const YHMenu = styled("span")(({ theme, collapse }) => ({
    display: "flex",
    alignItems: "center",
    gap: "20px",
    margin: "16px",
    textDecoration: "none",
    color: theme.yoho.typography.color,
    borderRadius: 10,
    cursor: "pointer",
    "&:hover": {
        color: theme.yoho.md_color.blue.important[500],
        backgroundColor: collapse === "false" ? theme.yoho.md_color.grey[200] : "unset",
        "& > div": {
            backgroundColor: collapse === "true" ? theme.yoho.md_color.grey[200] : "unset",
            "& img": {
                filter: "invert(48%) sepia(70%) saturate(1018%) hue-rotate(160deg) brightness(93%) contrast(103%)",
            },
        },
    },
    "&:visited": {
        color: "unset",
    },
}));

const YHLinkMenu = styled(Link)(({ theme, collapse }) => ({
    display: "flex",
    alignItems: "center",
    gap: "20px",
    margin: "16px",
    textDecoration: "none",
    color: theme.yoho.typography.color,
    borderRadius: 10,
    "&:hover": {
        color: theme.yoho.md_color.blue.important[500],
        backgroundColor: collapse === "false" ? theme.yoho.md_color.grey[200] : "unset",
        "& > div": {
            backgroundColor: collapse === "true" ? theme.yoho.md_color.grey[200] : "unset",
            "& img": {
                filter: "invert(48%) sepia(70%) saturate(1018%) hue-rotate(160deg) brightness(93%) contrast(103%)",
            },
        },
    },
    "&:visited": {
        color: "unset",
    },
}));

const YHSubmenu = styled(Link)(({ theme }) => ({
    display: "flex",
    margin: "20px 0 20px 60px;",
    textDecoration: "none",
    color: theme.yoho.typography.color,
    "&:hover": {
        color: theme.palette.primary.main,
    },
}));

const YHNavIcon = styled("div")(({ theme }) => ({
    borderRadius: "50%",
    width: "56px",
    minWidth: "56px",
    height: "56px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));

const YHLabel = styled("span")(({ theme }) => ({}));

export default ({ menu, collapse, openDrawer, index, active, setActive }) => {
    // For non link menu use span to not trigger leave page warning
    return (
        <>
            {menu.sub ? (
                <YHMenu
                    collapse={collapse.toString()}
                    key={index}
                    onClick={() => {
                        if (collapse) {
                            openDrawer(true);
                            setActive(index);
                        } else {
                            setActive(index);
                        }
                    }}
                >
                    <YHNavIcon>
                        <YohoIcon size={10} {...menu?.icon?.props} />
                    </YHNavIcon>{" "}
                    {!collapse && <YHLabel>{menu?.name}</YHLabel>}
                </YHMenu>
            ) : (
                <YHLinkMenu collapse={collapse.toString()} to={menu.path} key={index} onClick={() => openDrawer(false)}>
                    <YHNavIcon>
                        <YohoIcon size={10} {...menu?.icon?.props} />
                    </YHNavIcon>{" "}
                    {!collapse && <YHLabel>{menu?.name}</YHLabel>}
                </YHLinkMenu>
            )}

            {active === index &&
                menu.sub?.map((submenu, idx) => (
                    <YHSubmenu
                        to={submenu.path}
                        key={idx}
                        onClick={() => {
                            setActive(null);
                            openDrawer(false);
                        }}
                    >
                        {submenu?.name}
                    </YHSubmenu>
                ))}
        </>
    );
};

import api from "service/apis/config";
import { store } from "service/store";
import * as siteAction from "service/actions/site";

const editorImageUpload = async (imageFile) => {
    try {
        const getLinkResponse = await api.get("/images/editor-upload-link");
        const { uploadURL } = getLinkResponse.data.result;

        const form = new FormData();
        form.append("image", imageFile);
        const uploadResponse = await api.post(uploadURL, form);
        const { publicUrl } = uploadResponse.data.result;
        return publicUrl;
    } catch {
        return "";
    }
};

export const pickEditorImage = (callback, alt) => {
    const inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.accept = "image/*";
    inputElement.style.display = "none";
    document.body.appendChild(inputElement);

    inputElement.addEventListener("change", async (event) => {
        store.dispatch(
            siteAction.setSnackbar({
                open: true,
                message: "Please wait while we upload your image...",
                severity: "info",
            }),
        );

        const file = event.target.files[0];
        if (file && file.size < 8 * 1024 * 1024) {
            const publicUrl = await editorImageUpload(file);
            if (publicUrl) {
                callback(publicUrl, { alt });
                store.dispatch(
                    siteAction.setSnackbar({
                        open: true,
                        message: "Image upload sucessful",
                        severity: "success",
                    }),
                );
            } else {
                callback("", {});
                store.dispatch(
                    siteAction.setSnackbar({
                        open: true,
                        message: "An error occur during image upload please contact us",
                        severity: "error",
                    }),
                );
            }
        } else {
            callback("", {});
            if (file) {
                store.dispatch(
                    siteAction.setSnackbar({
                        open: true,
                        message: "Image must not be larger then 8MB",
                        severity: "error",
                    }),
                );
            }
        }
    });

    inputElement.click();
};

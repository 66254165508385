import { FormText } from "components/Global/Form";

export default (e) => {
    return (
        <div>
            <FormText>
                <p className="subtitle2">{e.reduce((a, b) => a + b.listing, 0)}</p>
            </FormText>
        </div>
    );
};

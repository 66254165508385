export const convertOrderStatus = (status) => {
    const RefundRequests = status?.RefundRequests?.map((item) => item.status);
    if (status.order_status === 3) return "Order Complete";
    if ((!status.order_status || status.order_status === 1) && !status.pay_status) return "Unpaid";
    if (status.order_status === 2) return "Cancelled";
    if (status.order_status >= 1) {
        if (status.pay_status === 2) {
            if (status.shipping_status) return "Shipped";
            if (RefundRequests?.find((item) => item === 1) || status?.refund_status === 1) return "Refund Case Open";
            return "To Ship";
        }
        return "Order Complete";
    }
    return "-";
};

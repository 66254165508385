import Skeleton from "@material-ui/lab/Skeleton";
import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import Header from "components/MerchantCentre/Header";
import { useState } from "react";
import { styled } from "@material-ui/core";

const Title = styled("h1")(({ theme }) => ({
    ...theme.yoho.typography.h2,
    color: theme.yoho.md_color.grey[800],
    margin: 0,
    marginBottom: "14px",
}));

const MenuContainer = styled("div")({
    display: "flex",
    gap: 50,
});

const Menu = styled("h4")(({ active, theme }) => ({
    ...theme.yoho.typography.button,
    color: active === "active" ? theme.yoho.md_color.blue[500] : theme.yoho.md_color.grey[800],
    display: "flex",
    gap: "10px",
    textTransform: "capitalize",
    cursor: "pointer",
    margin: "13px 0",
    "& .bubble": {
        color: theme.yoho.md_color.red[500],
    },
}));

const Divider = styled("div")(({ theme }) => ({
    width: "100%",
    borderBottom: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

export default () => {
    const [active, setActive] = useState("general");
    return (
        <Container>
            <div className="main">
                <div>
                    <Title>
                        <Skeleton />
                    </Title>
                    <MenuContainer>
                        {[70.31, 162.16, 102.22, 216.86].map((item) => (
                            <Menu key={`${JSON.stringify(item)}`}>
                                <Skeleton width={item} />
                            </Menu>
                        ))}
                    </MenuContainer>
                    <Divider />
                </div>
                <Content>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Content>
            </div>
            {{
                // "general": <GeneralNote />,
                // "shipping methods": <ShippingMethodsNote />,
                // "table rates": <TableRatesNotes />,
                // "delivery & return policy": <DeliveryNotes />
            }[active] || <></>}
        </Container>
    );
};

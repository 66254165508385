import { styled, Dialog, DialogTitle } from "@material-ui/core";
import { FormButton, FormText } from "components/Global/Form";
import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import { useState } from "react";
import Search from "components/MerchantCentre/MerchantConsole/Products/Add/Search/Search";
import { useDispatch, useSelector } from "react-redux";
import {
    findQuery,
    selectResellProduct,
    confirmResellSelection,
    setProductSearch,
    setNewProductName,
} from "service/actions/merchant";
import { setRedirect } from "service/actions/site";

const Wrap = styled("div")(({ theme, bottomline, align = "center" }) => ({
    padding: "20px 20px 0px",
    display: "flex",
    flexDirection: "column",
    gap: 12,
    alignItems: align,
}));

const ProductView = styled("div")(({ theme }) => ({
    display: "flex",
    flex: 1,
    width: 680,
    padding: "5px 25px 25px",
    gap: 10,
    "& img": {
        width: 180,
        height: 180,
        objectFit: "cover",
        objectPosition: "left",
        borderRadius: 8,
    },
    "& > div": {
        paddingLeft: 20,
    },
    "& > div.good_details": {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        "& > div.goods_desc": {
            width: "75%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            "& button": {
                display: "inline-block",
                width: 150,
                marginRight: 10,
            },
        },
    },
    "& .variation-items": {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        flexWrap: "wrap",
        gap: 5,
    },
}));

const CustomButton = styled(FormButton)({
    height: 36,
    textTransform: "capitalize",
});

const ResultContainer = styled("div")(({ theme }) => ({
    textAlign: "left",
    width: "calc(70% + 40px)",
    padding: "9px 0px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 5px #A8A8A8",
    borderRadius: 10,
}));

const ResultWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    gap: 8,
    overflowY: "auto",
    flexDirection: "column",
    width: "100%",
    borderBottom: "1px solid #C8C8C8",
    paddingBottom: 9,
    maxHeight: "calc(100vh - 420px)",
    "& div": {
        padding: "0 20px",
    },
    "& > div:hover": {
        backgroundColor: theme.yoho.md_color.grey[100],
        cursor: "pointer",
    },
}));

const SkeletonImage = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    width: "176px",
    height: "176px",
    minWidth: 176,
    position: "relative",
    boxSizing: "border-box",
    borderRadius: 8,
}));

const NoMatchMessage = styled("p")(({ theme }) => ({
    width: "fit-content",
    lineHeight: "48px",
    height: 48,
    margin: "auto",
    color: theme.yoho.md_color.grey[500],
    fontSize: 18,
}));

export default () => {
    const dispatch = useDispatch();
    const query = useSelector((state) => state.merchant.product.search.query);
    const selected = useSelector((state) => state.merchant.product.search.data);
    const [searchString, setSearchString] = useState("");

    const performSearch = (searchText = "") => {
        setSearchString(searchText);
        if (searchText) dispatch(findQuery(searchText));
    };

    return (
        <Container>
            <div className="main">
                <Content>
                    <Wrap bottomline={1} align="center">
                        <FormText>
                            <h4>Before Start</h4>
                        </FormText>
                        <FormText>
                            <h3>
                                <b>Tell us what you are going to sell</b>
                            </h3>
                        </FormText>
                        <Search
                            placeholder="Product Name / Serial Number (GTIN)"
                            clickaction={performSearch}
                            searchaction={(e) => performSearch(e.target.value)}
                            value={searchString}
                        />

                        {(query.result.length > 0 || searchString) && searchString && (
                            <ResultContainer>
                                <ResultWrapper>
                                    {query.result.map((item, key) => (
                                        <div key={key} onClick={() => dispatch(selectResellProduct(item))}>
                                            <FormText>
                                                <p className="button">{item.goods_name}</p>
                                            </FormText>
                                            <FormText>
                                                <p className="body1">{item.category.replaceAll("/", " / ")}</p>
                                            </FormText>
                                        </div>
                                    ))}
                                    {query.result.length === 0 && (
                                        <NoMatchMessage>Searching... Results will be shown here if any</NoMatchMessage>
                                    )}
                                </ResultWrapper>
                                <div style={{ textAlign: "right", padding: "9px 10px 0px" }}>
                                    <span style={{ margin: "0px 15px", fontSize: 17 }}>
                                        <b>Not seeing your product?</b>
                                    </span>
                                    <FormButton
                                        cvariant="contained"
                                        onClick={() => {
                                            dispatch(setNewProductName(searchString));
                                            dispatch(setRedirect("/products/add/selected"));
                                        }}
                                    >
                                        Create a new product
                                    </FormButton>
                                </div>
                            </ResultContainer>
                        )}
                    </Wrap>
                    {selected.length > 0 && (
                        <Dialog open maxWidth={800}>
                            <DialogTitle>Confirm selling product</DialogTitle>
                            <ProductView>
                                {selected[0].original_img ? (
                                    <img src={selected[0].original_img} alt="product image" />
                                ) : (
                                    <SkeletonImage />
                                )}
                                <div className="good_details">
                                    <div className="goods_desc">
                                        <FormText>
                                            <p className="subtitle1">{selected[0].goods_name}</p>
                                            <p className="body2" style={{ margin: "9px 0" }}>
                                                Category: {selected[0].type}
                                            </p>
                                            <p className="body2" style={{ margin: "9px 0" }}>
                                                Serial Number: {selected[0].goods_sn}
                                            </p>
                                            <p className="body2" style={{ margin: "9px 0" }}>
                                                {"(For reference only your serial number may be different)"}
                                            </p>
                                        </FormText>
                                        <div>
                                            <CustomButton
                                                cvariant="outlined"
                                                onClick={() => dispatch(setProductSearch([]))}
                                            >
                                                Cancel
                                            </CustomButton>
                                            <CustomButton
                                                onClick={() => {
                                                    dispatch(confirmResellSelection(selected[0].goods_id));
                                                    dispatch(setProductSearch([]));
                                                }}
                                            >
                                                Confirm
                                            </CustomButton>
                                        </div>
                                    </div>
                                    <div>
                                        <a href={selected[0].hyperlink} target="_blank" rel="noopener noreferrer">
                                            View on YOHO
                                        </a>
                                    </div>
                                </div>
                            </ProductView>
                        </Dialog>
                    )}
                </Content>
            </div>
        </Container>
    );
};

import { Editor } from "@tinymce/tinymce-react";

export default ({ name, action, draft, product_id }) => {
    return (
        <Editor
            apiKey={process?.env.REACT_APP_TINY_MCE}
            onFocusOut={(e) => action({ [name]: e.target.innerHTML }, product_id)}
            initialValue={draft?.spu?.[name]}
            init={{
                resize: "height",
                min_height: 200,
                menubar: false,
                statusbar: true,
                media_live_embeds: false,
                toolbar_mode: "wrap",
                plugins: [
                    "advlist autolink lists link image media charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime table paste code help wordcount",
                ],
                toolbar:
                    "formatselect | image preview media link |" +
                    " bullist numlist | preview code |" +
                    " bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | removeformat | help",
                content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
        />
    );
};

import { useState, useEffect } from "react";
import { FormButton } from "components/Global";
import { styled } from "@material-ui/core/styles";
import { Table } from "components/General/Table";
import { useApiWithErrorHandle } from "service/apis/merchant/utilHook";
import * as settingAPI from "service/apis/merchant/setting";
import { useHistory, Link } from "react-router-dom";

const PageContainer = styled("div")({
    margin: "0px 35px",
    position: "relative",
});
const TableHead = styled("div")({
    display: "flex",
    margin: "16px 0px",
    justifyContent: "flex-end",
});
const AddButton = styled(FormButton)({ width: 135, height: 38 });

const InfoText = styled("p")({
    color: "#888888",
});

const rowPerPage = 10;

const lv1CategoryColumns = [
    {
        label: "Sort Order",
        name: "sortOrder",
        size: 0.5,
    },
    {
        label: "Chinese Name",
        name: "chineseName",
        size: 1.6,
    },
    {
        label: "English Name",
        name: "englishName",
        size: 1.6,
    },
    {
        label: "Hidden",
        name: "isHidden",
        size: 0.8,
    },
    {
        label: "Number of Goods",
        name: "numGoods",
        size: 1.2,
    },
];

export default function StorePageCategoryManagement() {
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [pagination, setPagination] = useState({ count: 1, page: 1 });
    const history = useHistory();
    const getStorePageCategoryList = useApiWithErrorHandle(settingAPI.getStorePageCategoryList);
    const deleteStorePageCategory = useApiWithErrorHandle(settingAPI.deleteStorePageCategory);

    const loadCategoryList = async () => {
        const result = await getStorePageCategoryList({
            limit: rowPerPage,
            offset: (pagination.page - 1) * rowPerPage,
        });

        if (result) {
            setPagination({ ...pagination, count: Math.ceil(result.count / rowPerPage) });
            setRows(
                result.rows.map((row) => ({
                    catId: row.catId,
                    sortOrder: row.sortOrder,
                    chineseName: row.chineseName,
                    englishName: row.englishName,
                    isHidden: row.isHidden ? "Yes" : "No",
                    numGoods: row.numGoods,
                })),
            );
            setLoading(false);
        }
    };

    const handleStorePageCategoryDelete = async (row) => {
        const confirmDelete = window.confirm(`Are you sure to delete "${row.chineseName}"?`);
        if (confirmDelete) {
            setLoading(true);
            await deleteStorePageCategory({ catId: row.catId });
            await loadCategoryList();
        }
    };

    useEffect(() => {
        loadCategoryList();
    }, [pagination.page]);

    return (
        <PageContainer>
            <h1>Store Categories</h1>
            <InfoText>
                Store categories enable merchants to define custom categories for showing there products on the store
                page in a more organized way. Take a look at this{" "}
                <a
                    href="https://drive.google.com/file/d/148Jm3SeffT-mIdHaMabdQKbdh9Av2qro/view?usp=sharing"
                    target="_blank"
                >
                    video
                </a>{" "}
                to know more.
            </InfoText>
            <TableHead>
                <Link to="/settings/store-page-category/add">
                    <AddButton>Add New</AddButton>
                </Link>
            </TableHead>

            <Table
                loading={loading}
                columns={lv1CategoryColumns}
                rows={rows}
                emptyMessage="No Store Categories"
                rowEdit={({ catId }) => history.push(`/settings/store-page-category/edit/${catId}`)}
                rowDelete={(row) => handleStorePageCategoryDelete(row)}
                pagination={{
                    ...pagination,
                    onChange: (_, value) => setPagination({ ...pagination, page: value }),
                }}
            />
        </PageContainer>
    );
}

import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import Header from "components/MerchantCentre/Header";
import { useState } from "react";
import Profile from "./Profile";
import Security from "./Security";

export default () => {
    const [active, setActive] = useState("profile");
    return (
        <Container>
            <div className="main">
                <Header menu={["profile", "security"]} active={active} setActive={setActive} title="Personal Setting" />
                <Content>
                    {{
                        profile: <Profile key="profile" />,
                        security: <Security key="security" />,
                    }[active] || <div>Page is still under construction</div>}
                </Content>
            </div>
            {active === "security" && (
                <div className="sub">
                    <h5>Password policy</h5>
                    <p>
                        By default, all YOHO merchant centre account passwords must be a minimum of eight characters.
                        There are no other requirements around resets or expiration.
                    </p>
                </div>
            )}
        </Container>
    );
};

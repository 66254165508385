import { styled } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import InputText from "components/Global/Form/InputText";
import { TextSelection } from "components/Global/Form/NormalSelect";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateShippingShippingMethod } from "service/actions/merchant";

const Row = styled("div")({
    display: "flex",
    alignItems: "center",
    margin: "15px 0",
    alignItems: "flex-start",
    "& .operating_days": {
        padding: "9px 0",
    },
    "& div:nth-child(1)": {
        width: 235,
    },
});

export default () => {
    const { register, handleSubmit, control, reset } = useForm();
    const shipping = useSelector((state) => state.merchant.setting.shipping);
    const [inputField, setInputField] = useState({
        local_delivery_by: shipping?.shipping_methods?.local_delivery_by,
        international_delivery_by: shipping?.shipping_methods?.international_delivery_by,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        reset(shipping?.shipping_methods);
    }, []);

    const onSubmit = (data) => {
        dispatch(updateShippingShippingMethod(data));
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <FormCategory>
                        <Row className="full">
                            <FormText className="w-2">
                                <p className="subtitle1">Local delivery by</p>
                            </FormText>
                            <TextSelection
                                inputRef={register()}
                                name="local_delivery_by"
                                data={shipping?.init_data.logistics_company}
                                keyValue="logistics_id"
                                keyDisplay="logistics_name"
                                onChange={(e) => setInputField({ ...inputField, [e.target.name]: e.target.value })}
                            />
                            {inputField?.local_delivery_by == "0" && (
                                <InputText
                                    style={{ margin: "0 10px" }}
                                    label="Name of Shipping Carrier"
                                    control={control}
                                    name="other_local_delivery_by"
                                    variant="outlined"
                                />
                            )}
                        </Row>
                        <FormButton ccolor="blue" cvariant="contained" type="submit" fullWidth>
                            Save changes
                        </FormButton>
                    </FormCategory>
                </div>
            </form>
        </div>
    );
};

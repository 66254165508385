import OrdersDetails from "components/MerchantCentre/MerchantConsole/Orders/Details";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShippingSetting } from "service/actions/merchant";

export default () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getShippingSetting());
    }, []);

    return <OrdersDetails />;
};

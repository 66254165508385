import {
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    styled,
    TextField,
} from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import Popup from "components/Global/Layout/popup";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar, unsetPopup } from "service/actions/site";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomDenyDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

export default ({ action, close, title = "" }) => {
    const methods = useForm({
        defaultValues: {
            selection: "refund",
        },
        mode: "onChange",
    });

    const dispatch = useDispatch();
    const shipping = useSelector((state) => state.merchant.setting.shipping);
    const onSubmit = (data) => {
        console.log("data", data);
    };

    const selection = methods.watch("selection");

    return (
        <Popup size="md" fullWidth>
            <CustomDialogTitle>{title}</CustomDialogTitle>
            <FormProvider {...methods}>
                <CustomDenyDialogContent>
                    <Controller
                        name="selection"
                        control={methods.control}
                        render={({ field, ...rest }) => {
                            return (
                                <FormControl>
                                    <RadioGroup
                                        {...rest}
                                        row
                                        value={rest?.value}
                                        onChange={(_, value) => rest.onChange(value)}
                                    >
                                        <FormControlLabel
                                            value="refund"
                                            control={<Radio color="primary" />}
                                            label="Refund this order"
                                        />
                                        <FormControlLabel
                                            value="exchange"
                                            control={<Radio color="primary" />}
                                            label="Exchange an item"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            );
                        }}
                    />
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <FormCategory justifycontent="center">
                            <TextField
                                select
                                label="Shipping Carrier"
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                            >
                                {shipping?.init_data.logistics_company.map((option) => (
                                    <option key={option?.logistics_name} value={option?.logistics_id}>
                                        {option?.logistics_name}
                                    </option>
                                ))}
                            </TextField>
                            <TextField label="Tracking no." variant="outlined" />
                        </FormCategory>
                        <Divider variant="fullWidth" orientation="horizontal" />
                        <FormCategory className="full" justifycontent="center">
                            <TextField className="full" label="Remark" variant="outlined" />
                            <CustomFormButton
                                className="half"
                                ccolor="blue"
                                cvariant="contained"
                                type="submit"
                                fullWidth
                            >
                                Submit
                            </CustomFormButton>
                            <CustomFormButton className="half" ccolor="blue" cvariant="text" fullWidth onClick={close}>
                                later
                            </CustomFormButton>
                        </FormCategory>
                    </form>
                </CustomDenyDialogContent>
            </FormProvider>
        </Popup>
    );
};

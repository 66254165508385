import MomentUtils from "@date-io/moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { Fragment } from "react";

export default ({ label, className, name, value, onChange, disabled = false, size = "medium" }) => {
    const dateFormatter = (str) => {
        return str;
    };

    const disablePrev = (date) => moment(date) <= moment(value[name[0]]);

    return (
        <Fragment>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                    size={size}
                    style={{ width: 170 }}
                    name={name[0]}
                    disabled={disabled}
                    variant="inline"
                    inputVariant="outlined"
                    InputProps={{
                        className: className,
                    }}
                    autoOk={true}
                    value={moment(value[name[0]]).format("YYYY-MM-DD")}
                    format="YYYY-MM-DD"
                    label={label}
                    inputValue={moment(value[name[0]]).format("YYYY-MM-DD")}
                    onChange={(e) => onChange({ [name[0]]: e })}
                    rifmFormatter={dateFormatter}
                />
            </MuiPickersUtilsProvider>
            To
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                    size={size}
                    style={{ width: 170 }}
                    name={name[1]}
                    shouldDisableDate={disablePrev}
                    variant="inline"
                    inputVariant="outlined"
                    disabled={disabled}
                    InputProps={{
                        className: className,
                    }}
                    autoOk={true}
                    value={moment(value[name[1]]).format("YYYY-MM-DD")}
                    format="YYYY-MM-DD"
                    label={label}
                    inputValue={moment(value[name[1]]).format("YYYY-MM-DD")}
                    onChange={(e) => onChange({ [name[1]]: e })}
                    rifmFormatter={dateFormatter}
                />
            </MuiPickersUtilsProvider>
        </Fragment>
    );
};

import { styled } from "@material-ui/styles";
import { FormButton } from "components/Global";
import { FormText } from "components/Global/Form";
import { TextSelection } from "components/Global/Form/NormalSelect";
import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import Header from "components/MerchantCentre/Header";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmResellProduct, setProductResellPublishStatus } from "service/actions/merchant";
import { setRedirect } from "service/actions/site";
import Description from "./Description";
import Shipment from "./Shipment";
import Variations from "./Variations";
import VitalInfo from "./VitalInfo";
import WarrantyTerms from "./WarrantyTerms";
import { Prompt } from "react-router-dom";

const SideMenu = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    minWidth: "380px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "20px",
    height: "fit-content",
    borderRadius: 16,
    "& > *": {
        width: "100% !important",
    },
}));

const LinkText = styled("a")(({ theme }) => ({
    color: theme.yoho.md_color.blue[500],
}));

const productOptions = [
    { name: "List item(s) now", value: "listed" },
    { name: "Do NOT list item(s), I'll update later", value: "unlisted" },
];

export default () => {
    const [activeTab, setActiveTab] = useState("vital info");

    const dispatch = useDispatch();
    const listingStatus = useSelector((state) => state.merchant.product.selected.data.sku[0].status);
    const submitLoading = useSelector((state) => state.merchant.product.selected.data.loading);
    const spu = useSelector((state) => state.merchant.product.selected.data.spu);

    return (
        <Container>
            <div className="main">
                <Header
                    menu={["vital info", "variations", "description", "shipment", "warranty terms"]}
                    active={activeTab}
                    setActive={setActiveTab}
                    title={`Add Product - ${spu.vital_info_product_name_chinese}`}
                />
                <Content>
                    {
                        {
                            "vital info": <VitalInfo />,
                            variations: <Variations />,
                            shipment: <Shipment />,
                            description: <Description />,
                            "warranty terms": <WarrantyTerms />,
                        }[activeTab]
                    }
                </Content>
            </div>
            <div className="sub-product">
                <SideMenu>
                    <FormText>
                        <h5>Unsaved Product</h5>
                    </FormText>
                    <TextSelection
                        data={productOptions}
                        value={listingStatus}
                        onChange={(event) => dispatch(setProductResellPublishStatus(event.target.value))}
                    />
                    <FormButton
                        cvariant="contained"
                        ccolor="blue"
                        disabled={submitLoading}
                        onClick={() => dispatch(confirmResellProduct())}
                    >
                        {submitLoading ? "Loading . . . " : "Submit and Finish"}
                    </FormButton>
                    <FormButton cvariant="text" ccolor="blue" onClick={() => dispatch(setRedirect("/products/list"))}>
                        Discard
                    </FormButton>
                </SideMenu>
                <div className="info">
                    <h5>Important Note</h5>
                    <p>
                        To know more about selling on YOHO{" "}
                        <LinkText
                            href="https://sites.google.com/view/yoho3p"
                            target="_blank"
                            title="visit our merchant help centre"
                            rel="noopener noreferrer"
                        >
                            visit our merchant help centre
                        </LinkText>
                        .
                    </p>
                    <p>
                        For inquiry, please whatsapp us through hotline 53357570. This hotline is managed by several
                        departments in order to ensure the service quality.
                    </p>
                </div>
            </div>
            <Prompt when={!submitLoading} message="You have unsaved changes, are you sure you want to leave?" />
        </Container>
    );
};

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import BankField from "components/Global/Form/BankField";

const Bank = () => {
    const { reset, control } = useForm();
    const bankInfo = useSelector((state) => state.merchant.setting?.store?.bank_account);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (bankInfo) {
            reset(bankInfo);
            setReady(true);
        }
    }, [bankInfo]);

    return (
        ready && (
            <form>
                <BankField readOnly control={control} />
            </form>
        )
    );
};

export default Bank;

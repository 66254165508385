import { MenuItem, MenuList } from "@material-ui/core";
import Menu from "components/Global/Layout/Menu";

export default ({ refs, ...props }) => {
    return (
        <Menu refs={refs}>
            <MenuList>
                <MenuItem onClick={props?.removeAction}>Remove</MenuItem>
            </MenuList>
        </Menu>
    );
};

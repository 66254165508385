import { FormButton, FormCategory } from "components/Global";
import MultiRTE from "components/Global/Form/MultiRTE";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateShippingDRPolicy } from "service/actions/merchant";

const store_lang = [
    { id: 1, name: "Traditional Chinese", code: "delivery_return_policy_chinese" },
    { id: 2, name: "English", code: "delivery_return_policy_english" },
];

export default () => {
    const methods = useForm();
    const dispatch = useDispatch();
    const delivery_return_policy = useSelector((state) => state.merchant.setting.shipping?.delivery_return_policy);

    useEffect(() => {
        methods.reset(delivery_return_policy);
    }, [delivery_return_policy]);

    const onSubmit = (data) => {
        dispatch(updateShippingDRPolicy(data));
    };

    return (
        <div>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div>
                        <FormCategory>
                            <div className="full">
                                <MultiRTE options={store_lang} defaultstate={store_lang[0].code} />
                            </div>
                            <FormButton ccolor="blue" cvariant="contained" type="submit" fullWidth>
                                Save changes
                            </FormButton>
                        </FormCategory>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

import { all, call, put, takeLatest } from "redux-saga/effects";
import * as siteAction from "service/actions/site";
import * as publicApis from "service/apis/public";

export function* initAPPSAGA() {
    const [
        { data: masterdata },
        { data: categorydata },
        { data: countriesdata },
        { data: internationalcodedata },
        { data: departmentsdata },
        { data: productdata },
    ] = yield all([
        call(publicApis.getmasterdata),
        call(publicApis.getcategories),
        call(publicApis.getcountries),
        call(publicApis.getinternationalcode),
        call(publicApis.getdepartments),
        call(publicApis.getproduct),
    ]);

    if (!masterdata.error) yield put(siteAction.setInitialForm(masterdata.result));
    if (!categorydata.error) yield put(siteAction.setCategoryData(categorydata.result));
    if (!countriesdata.error || !internationalcodedata.error || !departmentsdata.error || !productdata.error) {
        yield put(
            siteAction.setMasterDataConfig({
                countries: countriesdata?.result,
                internationalcode: internationalcodedata?.result,
                departments: departmentsdata?.result,
                product: productdata?.result,
            }),
        );
    }
}

function* mainSagaSignIn() {
    yield takeLatest(siteAction.initAPP().type, initAPPSAGA);
}

export default mainSagaSignIn;

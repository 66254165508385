import { styled } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { processEditProduct } from "service/actions/merchant";

const ProductWrapper = styled("div")({
    display: "flex",
    gap: 10,
    fontSize: 12,
    width: "100%",
    paddingRight: 15,
});

const Thumbnail = styled("div")({
    borderRadius: 8,
    border: "1px solid #bdbdbd",
    minWidth: 60,
    width: 60,
    minHeight: 60,
    height: 60,
    overflow: "hidden",
    cursor: "pointer",
    "& img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
});

const ProductDesc = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
});

const ProductInfo = styled("div")({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "5px",
    "& .sku": {
        flexGrow: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2 /* number of lines to show */,
        lineClamp: 2,
        WebkitBoxOrient: "vertical",
    },
});

const Stock = styled("span")({
    borderRadius: 4,
    backgroundColor: "#e8422e",
    color: "white",
    height: 25,
    width: 90,
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const Discount = styled("span")({
    borderRadius: 4,
    backgroundColor: "#dbb30f",
    color: "white",
    height: 25,
    width: 60,
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
});

export default (rowData) => {
    const dispatch = useDispatch();
    const onDiscountClick = () => {
        window.open(`/promotion/on-sale-promotion/edit/${rowData.discounts[0]?.discount_event_id}`);
    };

    return (
        <ProductWrapper>
            <Thumbnail onClick={() => rowData.onImageClick(rowData.goods_id, rowData.product_name)}>
                <img src={rowData.image} />
            </Thumbnail>
            <ProductDesc>
                <strong style={{ cursor: "pointer" }} onClick={() => dispatch(processEditProduct(rowData.goods_id))}>
                    {rowData.product_name}
                </strong>
                <ProductInfo>
                    <span className="sku">SKU# {rowData.sku || rowData.goods_id}</span>
                    {rowData.discounts.length > 0 && <Discount onClick={onDiscountClick}>On Sale</Discount>}
                    {rowData.stock_qty < 1 && rowData.yoho_stock_qty < 1 && <Stock>Out Of Stock</Stock>}
                </ProductInfo>
            </ProductDesc>
        </ProductWrapper>
    );
};

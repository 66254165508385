import { styled } from "@material-ui/core";
import { FormButton } from "components/Global";
import { encryptString } from "helper/transformer";
import { memo } from "react";
import { Link } from "react-router-dom";

const ActionColumn = styled("div")({
    display: "flex",
    flexDirection: "column",
});

const CustomLink = styled(Link)({
    textDecoration: "none",
});

export default memo(({ data }) => {
    return (
        <ActionColumn>
            <CustomLink to={`/admin/application/detail/${encryptString(`${data.id},${data.company_id}`, "base64")}`}>
                <FormButton height={28} width={85} ccolor="blue" cvariant="contained">
                    Review
                </FormButton>
            </CustomLink>
        </ActionColumn>
    );
});

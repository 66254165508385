import React from "react";
import { useDispatch } from "react-redux";
import { setPopup, unsetPopup } from "service/actions/site";

export default () => {
    const dispatch = useDispatch();
    return (Component, props) => {
        dispatch(unsetPopup());
        setTimeout(() => {
            dispatch(setPopup(<Component close={() => dispatch(unsetPopup())} {...props} />));
        }, 0);
    };
};

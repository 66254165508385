import { all, call, put, takeLatest } from "redux-saga/effects";
import cookie from "js-cookie";
import { DEFAULT_COOKIE_PARAMS } from "helper";
import * as merchantAction from "service/actions/merchant";
import * as siteAction from "service/actions/site";
import * as merchantSettingApis from "service/apis/merchant/setting";
import { getMerhcantProfileSAGA } from "..";

export function* getAccountSettingSAGA() {
    const { data, ...rest } = yield call(merchantSettingApis.getaccountsettings);

    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield put(merchantAction.setAccountSetting(data.result));
    } else {
        yield all([
            put(
                siteAction.setSnackbar({
                    open: true,
                    message: "Failed to get account data",
                    severity: "error",
                }),
            ),
            put(merchantAction.resetSettingLoading()),
            put(siteAction.setRedirect("forceAppBack")),
        ]);
    }
}

function* updateAccountPrivilegeSAGA(payload) {
    const { access_id, role_id, route_id } = payload.data;
    const { data, ...rest } = yield call(merchantSettingApis.updateaccountprivilege, { access_id, role_id, route_id });

    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield all([call(getAccountSettingSAGA), call(getMerhcantProfileSAGA)]);
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Privilege has been updated",
                severity: "success",
            }),
        );
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

export default [
    takeLatest(merchantAction.getAccountSetting().type, getAccountSettingSAGA),
    takeLatest(merchantAction.updateAccountPrivilege().type, updateAccountPrivilegeSAGA),
];

import { styled } from "@material-ui/core";
import { FormText } from "components/Global/Form";

const BuyerNotes = styled("div")(({ theme }) => ({
    gridColumn: "1/34",
    gridRow: "4/5",
    padding: 15,
    borderRadius: 8,
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
        display: "flex",
    },
}));

export default ({ data }) => {
    return (
        <BuyerNotes>
            <div>
                <FormText color="grey" code={500}>
                    <p className="body2">Total Weight:&nbsp;</p>
                </FormText>
                <FormText color="grey" code={800}>
                    <p className="body2">{data.weight}</p>
                </FormText>
            </div>
            <div>
                <FormText color="grey" code={500}>
                    <p className="body2">Item(s) Subtotal:&nbsp;</p>
                </FormText>
                <FormText color="grey" code={800}>
                    <p className="body2">HK$ {data.sub_total}</p>
                </FormText>
            </div>
        </BuyerNotes>
    );
};

import { InputAdornment, TextField } from "@material-ui/core";
import { FormCategory } from "components/Global";

export default ({ inputRef, marketRef, vipRef, memberRef }) => {
    return (
        <FormCategory>
            <TextField
                inputRef={vipRef}
                name="variations_sales_vip_price"
                variant="outlined"
                label="VIP Price"
                type="number"
                inputProps={{
                    step: 0.01,
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">HK$</InputAdornment>,
                }}
            />

            <TextField
                inputRef={memberRef}
                name="variations_sales_member_price"
                variant="outlined"
                label="Member Price"
                type="number"
                inputProps={{
                    step: 0.01,
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">HK$</InputAdornment>,
                }}
            />
            <TextField
                inputRef={marketRef}
                name="variations_sales_market_price"
                variant="outlined"
                label="Market Price"
                type="number"
                inputProps={{
                    step: 0.01,
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">HK$</InputAdornment>,
                }}
            />
            <TextField
                inputRef={inputRef}
                name="variations_sales_inventory"
                variant="outlined"
                label="Inventory"
                type="number"
                inputProps={{
                    step: 1,
                }}
            />
        </FormCategory>
    );
};

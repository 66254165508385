import { FormText } from "components/Global/Form";
import moment from "moment";

export default (e) => {
    return (
        <div>
            <FormText>
                <p className="body">{typeof e === "number" ? moment.unix(e).format("MMM DD, YYYY") : ""}</p>
            </FormText>
            <FormText>
                <p className="body">{typeof e === "number" ? moment.unix(e).format("HH:mm") : ""}</p>
            </FormText>
        </div>
    );
};

import { styled } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import Table from "components/Global/Table";
import Column from "components/Global/Table/Column";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { trimString } from "helper";
import { generateVariationSKU } from "service/actions/merchant";
import { orderSelectedProductVariation } from "service/actions/merchant";
import PriceColumn from "../table/Price";
import ActionColumn from "./table/ActionColumn";
import VariationItem from "./VariationItem";
import { Alert } from "@material-ui/lab";

const Variant = styled("div")({
    display: "flex",
    width: "100% !important",
    gap: 20,
    alignItems: "center",
});

const VariantCustomColumn = styled("div")({
    display: "flex",
    gap: 20,
});

const SkeletonImage = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    width: "60px",
    height: "60px",
    position: "relative",
    boxSizing: "border-box",
    borderRadius: 8,
    "&:after": {
        content: '""',
        position: "absolute",
        borderBottom: `1px solid ${theme.yoho.md_color.grey[400]}`,
        top: "2.3px",
        right: "-76.853px",
        width: "77.8px",
        transform: "rotate(135deg)",
        transformOrigin: "0% 0%",
    },
}));

const ThumbImg = styled("img")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    width: "60px",
    height: "60px",
    objectFit: "contain",
    position: "relative",
    boxSizing: "border-box",
    borderRadius: 8,
    "&:after": {
        content: '""',
        position: "absolute",
        borderBottom: `1px solid ${theme.yoho.md_color.grey[400]}`,
        top: "2.3px",
        right: "-76.853px",
        width: "77.8px",
        transform: "rotate(135deg)",
        transformOrigin: "0% 0%",
    },
}));

const DropableContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 20,
});

const InfoContainer = styled(Alert)({
    width: "100% !important",
    borderRadius: 8,
});

const safeGetImageURL = (imageSlot) => {
    try {
        const imageURL = URL.createObjectURL(imageSlot.file);
        return imageURL;
    } catch (error) {
        // Redux persist cannot save non-serializable file object
        if (imageSlot.publicURL) return imageSlot.publicURL;
        return null;
    }
};

const VariantColumn = (rowData) => {
    const mainImageSlot = rowData.image_slots.find((slot) => slot.isMain && slot.occupied);
    const imageURL = mainImageSlot && safeGetImageURL(mainImageSlot);

    return (
        <VariantCustomColumn>
            {imageURL ? <ThumbImg src={imageURL} /> : <SkeletonImage />}
            <div>
                <FormText
                    style={{
                        width: 250,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    <span className="subtitle1" data-text={trimString(rowData?.vital_info_product_name_chinese, 120)}>
                        {trimString(rowData?.vital_info_product_name_chinese, 120)}
                    </span>
                </FormText>
                <FormText>
                    <p>SKU: {rowData?.variations_general_custom_sku || rowData?.goods_id || rowData?.main_goods_id}</p>
                </FormText>
                <FormText>
                    <p>GTIN: {rowData?.variations_general_gtin}</p>
                </FormText>
            </div>
        </VariantCustomColumn>
    );
};

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const $variationAttributes = createSelector(
    (state) => state.merchant.product.config.variation_attribute,
    (state) => state.merchant.product.config.variation_attribute_item,
    (state) => state.merchant.product.selected?.data?.spu?.vital_info_category,
    (var_attr, var_attr_item, draft_category) => {
        const sub_category = draft_category || null;
        const variation_attribute = var_attr?.filter(({ sub_category_id }) => sub_category_id === sub_category);
        const variation_attribute_item = var_attr_item?.filter(({ attribute_id }) =>
            variation_attribute.some((item) => item.attribute_id === attribute_id),
        );
        return {
            variation_attribute,
            variation_attribute_item,
            sub_category,
        };
    },
);

export default () => {
    const dispatch = useDispatch();

    const skuGenerationLoading = useSelector((state) => state.merchant.product.selected.data.loading);
    const variationAttributes = useSelector($variationAttributes);
    const spu = useSelector((state) => state.merchant.product.selected.data.spu);
    const skuList = useSelector((state) => state.merchant.product.selected.data.sku);

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;
        if (destination?.droppableId === source.droppableId && destination.index === source.index) return;

        const newVariation = reorder(spu?.variations_attributes, result.source.index, result.destination.index);
        dispatch(orderSelectedProductVariation(newVariation));
    };

    const generateProductVariation = () => dispatch(generateVariationSKU({ spu, mode: "resell" }));

    return (
        <FormCategory style={{ gap: "5px" }}>
            <div className="full">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <DropableContainer {...provided.droppableProps} ref={provided.innerRef}>
                                {spu?.variations_attributes?.map((item, index) => (
                                    <Draggable
                                        key={String(item?.attribute_id)}
                                        draggableId={String(item?.attribute_id)}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps}>
                                                <VariationItem data={item} provided={provided} />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </DropableContainer>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <Variant>
                <FormText>
                    <p className="subtitle1">Variants List</p>
                </FormText>
                {variationAttributes.variation_attribute.length > 0 && (
                    <FormButton
                        cvariant="outlined"
                        ccolor="blue"
                        height={36}
                        width="fit-content"
                        onClick={generateProductVariation}
                        disabled={!!skuGenerationLoading}
                    >
                        {skuGenerationLoading ? "Loading . . ." : "Update Variations"}
                    </FormButton>
                )}
            </Variant>
            <InfoContainer severity="info" style={{ marginTop: 12 }}>
                {spu.stale
                    ? "Attributes changed! Click 'Update Variations' to get a new set of variations with the latest attributes."
                    : "For variants that you do not sell you can delete them."}
            </InfoContainer>

            {!spu.stale && (
                <Table
                    data={skuList}
                    className="full"
                    showExport={false}
                    showHistory={false}
                    showLayout={false}
                    disableCheckbox={true}
                >
                    <Column
                        field={[
                            "vital_info_product_name_chinese",
                            "variations_general_gtin",
                            "variations_general_custom_sku",
                            "vital_info_product_name_english",
                            "goods_id",
                            "main_goods_id",
                            "image_slots",
                        ]}
                        render={VariantColumn}
                        label="Variants"
                    />
                    <Column field="variations_sales_inventory" label="Inventory" />
                    <Column
                        field={[
                            "variations_sales_vip_price",
                            "variations_sales_member_price",
                            "variations_sales_market_price",
                        ]}
                        render={PriceColumn}
                        label="Selling Price"
                    />
                    <Column
                        type="custom"
                        render={(rowData) => <ActionColumn {...rowData} canDelete={skuList.length > 1} />}
                    />
                </Table>
            )}
        </FormCategory>
    );
};

import { styled } from "@material-ui/core";
import { useState } from "react";
import RichTextEditor from "./MPW_RTE";

const ComboWrapper = styled("div")({
    "& > .full": { width: "100%" },
});

const MenuContainer = styled("div")({
    display: "flex",
});

const Menu = styled("h4")(({ active, theme, type }) => ({
    ...theme.yoho.typography[type],
    color: theme.yoho.md_color.grey[800],
    display: "flex",
    position: "relative",
    "&.active": {
        color: theme.yoho.md_color.blue[500],
        "&:after": {
            content: "''",
            background: theme.yoho.md_color.blue[500],
            width: "100%",
            position: "absolute",
            height: "1px",
            left: 0,
            bottom: "-14px",
        },
    },
    textTransform: "capitalize",
    cursor: "pointer",
    padding: "0 15px",
    margin: "13px 0",
    textAlign: "center",
    "& .bubble": { color: theme.yoho.md_color.red[500] },
}));

const Divider = styled("div")(({ theme }) => ({
    width: "100%",
    borderBottom: `1px solid ${theme.yoho.md_color.grey[300]}`,
    marginBottom: "20px",
}));

export default ({
    draft,
    options,
    action = () => {},
    className,
    type = "button",
    defaultstate = "",
    termsData = {},
    product_id,
}) => {
    const [option, setOption] = useState(defaultstate);

    return (
        <ComboWrapper {...(className && { className })}>
            <MenuContainer>
                {options.map((item) => (
                    <Menu
                        key={`${JSON.stringify(item.name)}`}
                        type={type}
                        className={option === item.code ? " active" : ""}
                        onClick={() => setOption(item.code)}
                    >
                        {item.name}
                        {!!item?.count && <a className="bubble">{item?.count}</a>}
                    </Menu>
                ))}
            </MenuContainer>
            <Divider />
            {options.map((e) => (
                <div key={e.code} className="full" style={{ display: option !== e.code ? "none" : "inline-flex" }}>
                    <RichTextEditor
                        name={e.code}
                        action={action}
                        termsData={termsData}
                        draft={draft}
                        product_id={product_id}
                    />
                </div>
            ))}
        </ComboWrapper>
    );
};

import Application from "components/MerchantCentre/AdministrationConsole/Application";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApplication } from "service/actions/admin";

export default () => {
    const filter = useSelector((state) => state.admin.param);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getApplication(filter));
    }, [filter]);

    return <Application />;
};

import { DEFAULT_COOKIE_PARAMS } from "helper";
import cookie from "js-cookie";

export function* APIVerificator(data, rest, callback) {
    if (data.status === 401 || rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("admin-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-admin-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
        return;
    }
    yield callback();
}

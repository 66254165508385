import { styled } from "@material-ui/styles";
import Container from "components/MerchantCentre/Container";
import { memo, useEffect, useMemo, useState } from "react";
import alldata from "../alldata";
import BuyerNotes from "./components/BuyerNotes";
import OrderHeader from "./components/Header";
import PurchasedItem from "./components/PurchasedItem";
import Notes from "./components/Notes";
import OrderSummary from "./components/OrderSummary";
import SalesProceeds from "./components/SalesProceeds";
import ShipTo from "./components/ShipTo";
import Timeline from "./components/Timeline";
import Weight from "./components/Weight";
import { useDispatch } from "react-redux";
import { clearOrderDetail } from "service/actions/merchant";
import { useParams } from "react-router-dom";
import { convertOrderStatus } from "../helper";
import { useGetOrderDetail, useGetOrderLogs } from "../hooks";

export const OrderDetails = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 20,
    gap: 20,
    "& .head": {
        display: "flex",
        alignItems: "center",
        gap: 10,
    },
    "& .main": {
        display: "grid",
        gridTemplateColumns: "repeat(38, 1fr)",
        gap: 10,
        gridAutoRows: "minmax(20px, auto)",
    },
});

const groupOrderData = (data) => {
    return {
        orderHeader: {
            order_id: data?.order_id,
            order_sn: data?.order_sn,
            order_status: data?.order_status,
            pay_status: data?.pay_status,
            shipping_status: data?.shipping_status,
            RefundRequests: data?.RefundRequests,
        },
        orderSummary: {
            order_id: data?.order_id,
            pay_time: data?.pay_time || "",
            platform: data?.platform === "web" ? "Online" : "",
            tel: `+852-${data?.tel}` || "",
            DeliveryOrders: data?.DeliveryOrders || [],
            order_remarks: data?.order_remarks || "",
            ship_remarks: data?.serial_numbers || "",
            order_status: convertOrderStatus({
                order_status: data?.order_status,
                pay_status: data?.pay_status,
                shipping_status: data?.shipping_status,
                RefundRequests: data?.RefundRequests,
            }),
        },
        salesProceeds: {
            order_goods_subtotal: data?.order_goods_subtotal || 0,
            order_amount: data?.order_amount || 0,
            shipping_fee: data?.shipping_fee || 0,
            checking_add_charge: data?.checking_add_charge || 0,
            pay_discount: data?.pay_discount || 0,
            money_paid: data?.money_paid || 0,
            coupon_codes: data?.coupon_codes || [],
            coupon_amount: data?.coupon || 0,
            pay_name: data?.pay_name || "",
            grand_total: data?.total_fee || 0,
            client_grand_total: (
                parseFloat(data?.order_goods_subtotal) +
                parseFloat(data?.shipping_fee) -
                parseFloat(data?.coupon)
            ).toFixed(2),
        },
        buyerNotes: {
            postscript: data?.postscript || "",
        },
        shipTo: {
            consignee: data?.consignee || "",
            tel: data?.tel || "",
            address: data?.address || "",
        },
        purchasedItem: {
            orderGoods: data?.OrderGoods || [],
            isShowYohoQty: data?.isShowYohoQty || false,
        },
        weight: {
            weight: `${data?.OrderGoods?.reduce((prev, curr) => prev + parseFloat(curr?.weight), 0)?.toFixed(2) || 0} Kg`,
            sub_total: data?.order_goods_subtotal || 0,
        },
        timeline: data,
        notes: {},
    };
};

export default memo(() => {
    const [active, setActive] = useState("all");
    const { order_id } = useParams();
    const { data, isLoading, refetch } = useGetOrderDetail(order_id);
    const orderDetail = groupOrderData(data);

    const { data: orderLogs } = useGetOrderLogs(order_id);
    const memoLogs = useMemo(() => orderLogs?.reverse(), [orderLogs]);

    const dispatch = useDispatch();

    let shipcount = 0;
    const tabledata = alldata.filter(({ status }) =>
        ["refund case opened", "exchange case opened", "refund case closed", "exchange case closed"].includes(
            status.toLocaleLowerCase(),
        ),
    );
    let exchangecount = tabledata.length;
    let TOSHIP_CASE = `To Ship ${shipcount}`;
    let EXCHANGE_CASE = `Exchange/Refund ${exchangecount}`;

    useEffect(() => {
        if (active.includes("To Ship")) {
            setActive(TOSHIP_CASE);
        } else if (active.includes("Exchange/Refund")) {
            setActive(EXCHANGE_CASE);
        }

        return () => {
            dispatch(clearOrderDetail(order_id));
        };
    }, [shipcount, exchangecount]);

    if (isLoading) return null;

    return (
        <Container>
            <OrderDetails>
                <OrderHeader data={orderDetail.orderHeader} />
                <div className="main">
                    <OrderSummary data={orderDetail.orderSummary} refetch={refetch} />
                    <SalesProceeds data={orderDetail.salesProceeds} />
                    <BuyerNotes data={orderDetail.buyerNotes} />
                    <ShipTo data={orderDetail.shipTo} />
                    <PurchasedItem data={orderDetail.purchasedItem} />
                    <Weight data={orderDetail.weight} />
                    <Timeline logs={memoLogs} data={orderDetail.timeline} />
                    <Notes />
                </div>
            </OrderDetails>
        </Container>
    );
});

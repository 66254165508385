import { FormControlLabel, Radio, RadioGroup, styled } from "@material-ui/core";
import { Controller } from "react-hook-form";

const ErrorText = styled("div")({
    color: "#f44336",
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 1.66,
    width: "100%",
});

export default ({ data, name, control, disabled, errors, rules }) => {
    return (
        <>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={(props) => {
                    return (
                        <RadioGroup
                            row
                            aria-label={name}
                            value={typeof props.value === "object" ? Number(props.value.id) : Number(props.value)}
                            onChange={props.onChange}
                        >
                            {data.map((radio) => (
                                <FormControlLabel
                                    key={`personal_title-${radio.id}`}
                                    value={radio.id}
                                    control={<Radio disabled={disabled} color="primary" />}
                                    label={radio.name}
                                />
                            ))}
                        </RadioGroup>
                    );
                }}
            />
            {errors?.[name] ? <ErrorText>{errors?.[name]?.message}</ErrorText> : <></>}
        </>
    );
};

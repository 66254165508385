import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/styles";
import { ButtonLink } from "components/Global/Form";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { forgotPasswordRequest } from "service/actions/authentication";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    error: {
        color: "red",
    },
    title: {
        fontSize: 17,
        marginTop: 0,
        "&:before": {
            top: 35,
            width: 20,
            height: 20,
        },
    },
}));

const FormTitle = styled("p")(({ theme }) => ({
    "&::before": {
        content: "''",
        position: "absolute",
        left: 0,
        width: "10px",
        height: "26px",
        backgroundColor: theme.palette.primary.main,
    },
    fontSize: 28,
    fontWeight: 500,
    lineHeight: "40.5px",
    marginTop: 0,
    color: theme.palette.primary.main,
}));

const FormMainContainer = styled(Grid)({
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
});

const FormButton = styled(Button)(({ theme }) => ({
    height: "52px",
    ...theme.yoho.button.contained.blue,
    textTransform: "capitalize",
}));

const BackContainer = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px 0px",
}));

const FormContainer = styled(Grid)(({ theme }) => ({
    "& div.by-user": {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "24px",
        margin: "10px 0",
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        "& p": {
            color: theme.palette.primary.main,
            margin: 0,
            cursor: "pointer",
        },
    },
}));

const ActionContainer = styled(Grid)(({ theme }) => ({}));

export default () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();
    const onSubmit = (data) =>
        dispatch(forgotPasswordRequest({ ...data, callback_url: `${window.location.origin}/auth/forgotpass/verify` }));

    return (
        <div className={classes.root}>
            <FormTitle className={classes.title}>Reset Password</FormTitle>

            <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
                <FormMainContainer container spacing={2} justify="center">
                    <FormContainer item sm={12} xs={12} style={{ height: 179 }}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            name="email"
                            inputRef={register({ required: true })}
                        />
                    </FormContainer>
                    <ActionContainer item sm={12} xs={12}>
                        <FormButton variant="contained" type="submit" fullWidth>
                            Continue
                        </FormButton>
                        <BackContainer>
                            <ButtonLink to="/auth/login" cvariant="text" ccolor="blue">
                                Back
                            </ButtonLink>
                        </BackContainer>
                    </ActionContainer>
                </FormMainContainer>
            </form>
        </div>
    );
};

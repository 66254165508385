import { Paper, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { styled } from "@material-ui/core/styles";
import { useState } from "react";
import { useShowSnackbar } from "service/apis/merchant/utilHook";
import { CircularProgress } from "@material-ui/core";

const convertBytesToMB = (bytes) => bytes / (1024 * 1024);

const Container = styled("div")({
    position: "relative",
    color: "grey",
});

const Hint = styled("div")({
    margin: "10px 0px",
});

const ImagePaper = styled(Paper)(({ theme, height, width }) => ({
    height: height,
    width: width,
    display: "grid",
    placeItems: "center",
    cursor: "pointer",
    borderRadius: 10,
    boxShadow: theme.shadows[3],
    "&:hover": {
        boxShadow: theme.shadows[6],
    },
}));

async function getImageDimension(file) {
    if (file && file.type.startsWith("image/")) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve([img.height, img.width]);
            img.onerror = reject;
            img.src = URL.createObjectURL(file);
        }).catch(() => false);
    } else {
        return false;
    }
}

function calculateSymbolSize(imageWidth, imageHeight) {
    // Determine the short edge of the image
    const shortEdge = Math.min(imageWidth, imageHeight);

    // Calculate 30% of the short edge
    let symbolSize = Math.floor(shortEdge * 0.3);

    // Ensure the symbol size is clipped between 70px and 200px
    symbolSize = Math.max(70, symbolSize);
    symbolSize = Math.min(200, symbolSize);

    return symbolSize;
}

export default function ImageUpload({
    value,
    name,
    height,
    width,
    onChange,
    hints = null,
    imageFileMaxSize = 5, // MB
    ratioTolerance = 0.2,
    forceImageMinSize = true,
    handleImageUpload,
}) {
    const [isUploading, setIsUploading] = useState(false);
    const showSnackbar = useShowSnackbar();

    const handleDeleteImage = () => onChange("");

    const handleImageAsFile = async (event) => {
        const image = event.target.files[0];
        if (!image) return;
        if (convertBytesToMB(image.size) >= imageFileMaxSize) {
            showSnackbar(`The max size of the supported image file is ${imageFileMaxSize}MB.`);
            return;
        }
        const dimensions = await getImageDimension(image);
        if (dimensions) {
            const [imgHeight, imgWidth] = dimensions;
            if (forceImageMinSize) {
                if (imgHeight < height || imgWidth < width) {
                    showSnackbar(`Dimension of image should be at least ${width}px (width) by ${height}px (height)`);
                    return;
                }
            }

            const expectedRatio = width / height;
            const actualRatio = imgWidth / imgHeight;
            const deviation = Math.abs(actualRatio / expectedRatio - 1);
            if (deviation > ratioTolerance) {
                showSnackbar(
                    `Dimension of image should be ${width}px (width) by ${height}px (height) or similar aspect ratio.`,
                );
                return;
            }
            setIsUploading(true);
            const publicUrl = await handleImageUpload(image);
            onChange(publicUrl);
            setIsUploading(false);
        } else {
            showSnackbar("Uploaded file is not a supported image file.");
        }
    };

    const symbolSize = calculateSymbolSize(width, height);

    if (isUploading) {
        return (
            <Container>
                <ImagePaper height={height} width={width}>
                    <CircularProgress size={symbolSize} />
                </ImagePaper>
                {hints && <Hint>{hints}</Hint>}
            </Container>
        );
    }

    return (
        <Container>
            {value ? (
                <div style={{ display: "grid" }}>
                    <img src={value} alt={name} width={`${width}`} height={`${height}`} />
                    <Typography
                        style={{ cursor: "pointer", color: "red", textDecoration: "underline" }}
                        onClick={handleDeleteImage}
                    >
                        Remove
                    </Typography>
                </div>
            ) : (
                <>
                    <input
                        accept="image/*"
                        name={name}
                        type="file"
                        onChange={(e) => handleImageAsFile(e)}
                        style={{ display: "none" }}
                        id={`id_${name}`}
                    />
                    <label htmlFor={`id_${name}`}>
                        <ImagePaper height={height} width={width}>
                            <AddIcon style={{ height: symbolSize, width: symbolSize }} />
                        </ImagePaper>
                    </label>
                </>
            )}
            {hints && <Hint>{hints}</Hint>}
        </Container>
    );
}

import { DialogContent, DialogTitle, styled, CircularProgress } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import Popup from "components/Global/Layout/popup";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSnackbar, unsetPopup } from "service/actions/site";
import ImagesDropzone from "./ImagesDropzone";
import { Alert } from "@material-ui/lab";
import { getImageUploadLinks, uploadProductImages } from "service/apis/merchant/product";
import { getProductList } from "service/actions/merchant";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

const validate = ({ existingImages, imageSlots }) => {
    try {
        const allImages = [...existingImages, ...imageSlots];

        if (!allImages.reduce((prev, { occupied }) => prev || occupied, false)) {
            throw { tab: "images", message: "Product must have at least one image." };
        }

        return { error: false };
    } catch (error) {
        return {
            error: true,
            ...error,
        };
    }
};

export default ({ goodsId, goodsName }) => {
    const dispatch = useDispatch();

    const [errorMsg, setErrorMsg] = useState("");
    const [imageSlots, setImageSlots] = useState(null);
    const [thumbSlots, setThumbSlots] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const fetchUploadData = async () => {
            const response = await getImageUploadLinks(goodsId);
            const { imageSlots, thumbSlots, existingImages } = response.data.result;
            setImageSlots([...existingImages, ...imageSlots].sort((a, b) => b.occupied - a.occupied));

            setThumbSlots([...existingImages, ...thumbSlots].sort((a, b) => b.occupied - a.occupied));
        };

        fetchUploadData();
    }, []);

    const imageUploading =
        imageSlots?.reduce((prev, curr) => prev || !!curr.isUploading, false) ||
        thumbSlots?.reduce((prev, curr) => prev || !!curr.isUploading, false) ||
        submitting;

    const saveImageChanges = async () => {
        const payload = {
            goodsId,
            imageSlots: imageSlots.filter(({ id }) => !id),
            thumbSlots: thumbSlots.filter(({ id }) => !id),
            existingImages: imageSlots.filter(({ id }) => id),
        };

        const { error, message } = validate(payload);

        if (error) {
            setErrorMsg(message);
            return;
        }

        setSubmitting(true);
        const { data } = await uploadProductImages(payload);

        if (data.error) {
            dispatch(
                setSnackbar({
                    open: true,
                    message: data.message || "Unknown Error",
                    severity: "error",
                }),
            );
        } else {
            dispatch(unsetPopup());
            dispatch(getProductList());
            dispatch(
                setSnackbar({
                    open: true,
                    message: "Images updated sucessfully",
                    severity: "success",
                }),
            );
        }
    };

    if (!imageSlots) {
        return (
            <Popup size="md">
                <CustomDialogTitle>{`Photo for ${goodsName}`}</CustomDialogTitle>
                <DialogContent>
                    <div style={{ margin: "15px calc(50% - 22px) 30px" }}>
                        <CircularProgress />
                    </div>
                </DialogContent>
            </Popup>
        );
    }

    return (
        <Popup size="md" fullWidth>
            <CustomDialogTitle>{`Photo for ${goodsName}`}</CustomDialogTitle>
            <DialogContent>
                {errorMsg && (
                    <Alert severity="error" style={{ margin: "15px 20px 0px" }}>
                        {errorMsg}
                    </Alert>
                )}
                <FormCategory justifycontent="center">
                    <div style={{ textAlign: "center" }} className="full">
                        <ImagesDropzone
                            imageSlots={imageSlots}
                            setImageSlots={setImageSlots}
                            thumbSlots={thumbSlots}
                            setThumbSlots={setThumbSlots}
                        />
                    </div>
                    <CustomFormButton
                        onClick={saveImageChanges}
                        disabled={imageUploading}
                        className="half"
                        ccolor="blue"
                        cvariant="contained"
                        fullWidth
                    >
                        {imageUploading ? "Loading..." : "Save"}
                    </CustomFormButton>
                </FormCategory>
            </DialogContent>
        </Popup>
    );
};

import { styled } from "@material-ui/core/styles";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { FormButton } from "components/Global";
import { useState } from "react";
import { downloadOrderSummary } from "service/apis/merchant/order";
import { blobToText } from "helper/lib";
import * as siteAction from "service/actions/site";

const Container = styled("div")({
    padding: "0px 35px",
});

const Button = styled(FormButton)({
    width: "300px",
});

const SelectionLabel = styled("label")({
    fontSize: "21px",
    lineHeight: "52px",
    marginRight: "20px",
});

const MonthSelection = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    maxWidth: "1000px",
    padding: "15px 24px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    borderRadius: 20,
}));

const monthList = [];
const date = new Date();

for (let i = 1; i <= 6; i++) {
    date.setMonth(date.getMonth() - 1);
    monthList.push({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        display: `${date.getFullYear()} ${date.toLocaleString("default", { month: "long" })}`,
    });
}

export default function SalesSummary() {
    const [selectedMonth, setSelectedMonth] = useState({
        month: monthList[0].month,
        year: monthList[0].year,
    });

    const dispatch = useDispatch();

    const downloadReport = async () => {
        const { data, headers, status } = await downloadOrderSummary(selectedMonth);
        if (status !== 200) {
            const errorString = await blobToText(data);
            const error = JSON.parse(errorString);
            dispatch(
                siteAction.setSnackbar({
                    open: true,
                    message: `${error.message}. Please contact admin` || "Error occurs please contact admin.",
                    severity: "error",
                }),
            );
            return;
        }

        const objectURL = URL.createObjectURL(data);
        const contentDisposition = headers["content-disposition"];
        let fileName = "Sales Summary.xlsx";

        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            if (filenameMatch && filenameMatch[1]) {
                fileName = filenameMatch[1].replace(/['"]/g, ""); // Remove any surrounding quotes
            }
        }

        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = objectURL;
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Container>
            <h1>Sales Summary</h1>
            <MonthSelection>
                <div>
                    <SelectionLabel>Select a month</SelectionLabel>
                    <FormControl>
                        <Select
                            variant="outlined"
                            style={{ width: 180 }}
                            value={`${selectedMonth.year}-${selectedMonth.month}`}
                            onChange={(event) => {
                                const [year, month] = event.target.value.split("-");
                                setSelectedMonth({ year, month });
                            }}
                        >
                            {monthList.map(({ year, month, display }, index) => (
                                <MenuItem key={index} value={`${year}-${month}`}>
                                    {display}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <Button cvariant="contained" ccolor="blue" onClick={downloadReport}>
                    Download Report
                </Button>
            </MonthSelection>
        </Container>
    );
}

import { styled } from "@material-ui/core";
import { memo } from "react";

const Column = styled("div")({
    display: "flex",
    flexDirection: "column",
});

export default (entry) => {
    return (
        <Column>
            <span>
                {entry?.contact_international_code_id?.dial_code}
                {entry?.contact_number}
            </span>
        </Column>
    );
};

import { Divider } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { FormButton } from "components/Global";
import { FormText } from "components/Global/Form";
import { TextSelection } from "components/Global/Form/NormalSelect";
import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import Header from "components/MerchantCentre/Header";
import { encryptString } from "helper/transformer";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { cleanupAdminProductDetail, setAdminProductDraft } from "service/actions/admin";
import { useAdminProductActions, useGetAdminProductDetail, useGetAdminProductInit } from "../hooks";
import Description from "./Description";
import Shipment from "./Shipment";
import Variations from "./Variations";
import VitalInfo from "./VitalInfo";
import WarrantyTerms from "./WarrantyTerms";

const SideMenu = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    minWidth: "380px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "10px",
    height: "fit-content",
    borderRadius: 16,
    "& > *": {
        width: "100% !important",
    },
}));

const CurrentSKU = styled("div")(({ theme }) => ({
    height: 60,
    display: "flex",
    gap: 10,
    alignItems: "center",
    "& .thumb": {
        width: 60,
        height: 60,
        objectFit: "contain",
        background: "#FFFFFF",
        border: `1px solid ${theme.yoho.md_color.grey[400]}`,
        borderRadius: 8,
    },
    "& .info": {
        "& .subinfo": {
            display: "flex",
            alignItems: "center",
            "& hr": {
                margin: "5px 10px",
            },
        },
    },
}));

const LinkText = styled("a")(({ theme }) => ({
    color: theme.yoho.md_color.blue[500],
}));

export default () => {
    const [active, setActive] = useState("vital info");
    const { id } = useParams();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [goods_id, merchant_id] = encryptString(id, "ascii").split(",");
    const [merchantId, setMerchantId] = useState(merchant_id.toString());
    const { data: initData, isLoading: onInitialLoading } = useGetAdminProductInit({
        merchant_id: merchantId.toString(),
    });
    const { isLoading } = useGetAdminProductDetail({ goods_id, merchant_id: merchantId.toString() });
    const { submitProduct, onSubmitProduct, discardProduct, onDiscardProduct } = useAdminProductActions();
    const detailData = useSelector((state) => state.admin.product.detail?.[goods_id]?.[merchantId]);
    const mainRef = useRef();
    const subRef = useRef();

    const handleSubmitPayload = useCallback(
        (payload) => {
            if (detailData) {
                dispatch(setAdminProductDraft(payload, { goods_id, merchant_id: merchantId }, ["spu"]));
            }
        },
        [detailData],
    );

    const head_image = !isLoading ? detailData?.sku?.[0]?.exist_variations_general_image_main.original_img : null;
    let VARIANT_CASE = `variations (${detailData?.sku?.length})`;

    useEffect(() => {
        if (active.includes("variations")) {
            setActive(VARIANT_CASE);
        }
    }, [detailData]);

    useEffect(() => {
        return () => {
            dispatch(cleanupAdminProductDetail({ goods_id, merchant_id: merchantId }));
            queryClient.removeQueries([
                "admin-product-detail",
                { goods_id: goods_id.toString(), merchant_id: merchantId.toString() },
            ]);
            queryClient.removeQueries(["admin-product-category", { merchant_id: merchantId.toString() }]);
            queryClient.removeQueries(["admin-product-init", { merchant_id: merchantId.toString() }]);
        };
    }, []);

    return (
        !isLoading &&
        !onInitialLoading && (
            <>
                <Container>
                    <div className="main" ref={mainRef}>
                        <CurrentSKU>
                            {head_image ? <img src={head_image} className="thumb" /> : <div className="thumb"></div>}
                            <div className="info">
                                <FormText>
                                    <h4>{detailData?.spu?.vital_info_product_name_chinese}</h4>
                                </FormText>
                                <div className="subinfo">
                                    <FormText>
                                        <p className="body1">{detailData?.spu?.vital_info_product_name_english}</p>
                                    </FormText>
                                    <Divider orientation="vertical" flexItem />
                                    <LinkText
                                        href={`${detailData?.spu?.hyperlink}?merchantId=${merchantId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="View on YOHO"
                                    >
                                        View on YOHO
                                    </LinkText>
                                </div>
                            </div>
                        </CurrentSKU>
                        <Header
                            menu={[
                                "vital info",
                                `variations (${detailData?.sku.length})`,
                                "description",
                                "shipment",
                                "warranty terms",
                            ]}
                            active={active}
                            setActive={setActive}
                            currentWidth={mainRef?.current?.getBoundingClientRect()?.width}
                        />
                        <Content>
                            {{
                                "vital info": (
                                    <VitalInfo
                                        draft={detailData}
                                        product_id={goods_id}
                                        merchant_id={merchantId}
                                        initData={initData}
                                        handleSubmitPayload={handleSubmitPayload}
                                    />
                                ),
                                [VARIANT_CASE]: (
                                    <Variations
                                        draft={detailData}
                                        product_id={goods_id}
                                        merchant_id={merchantId}
                                        initData={initData}
                                    />
                                ),
                                description: (
                                    <Description
                                        draft={detailData}
                                        product_id={goods_id}
                                        merchant_id={merchantId}
                                        initData={initData}
                                        handleSubmitPayload={handleSubmitPayload}
                                    />
                                ),
                                shipment: (
                                    <Shipment
                                        draft={detailData}
                                        product_id={goods_id}
                                        merchant_id={merchantId}
                                        initData={initData}
                                        handleSubmitPayload={handleSubmitPayload}
                                    />
                                ),
                                "warranty terms": (
                                    <WarrantyTerms
                                        draft={detailData}
                                        product_id={goods_id}
                                        merchant_id={merchantId}
                                        initData={initData}
                                    />
                                ),
                            }[active] || (
                                <Variations
                                    draft={detailData}
                                    product_id={goods_id}
                                    merchant_id={merchantId}
                                    initData={initData}
                                />
                            )}
                        </Content>
                    </div>
                    <div className="sub-product" ref={subRef}>
                        <SideMenu>
                            <FormText>
                                <h5>Update Product</h5>
                            </FormText>

                            <FormText>
                                <p className="body2">Select seller views</p>
                            </FormText>
                            <TextSelection
                                name="merchant_lists"
                                data={detailData?.merchant_lists}
                                keyValue="merchant_id"
                                keyDisplay="company_name_english"
                                defaultValue={detailData?.merchant_selected?.id}
                                onChange={(event) => {
                                    setMerchantId(event.target.value);
                                }}
                            />
                            <FormText color="grey" code={500}>
                                <p className="body2">
                                    Last update: {moment(detailData?.spu?.updated_at).format("MMM D, YYYY hh:mm A")}
                                </p>
                            </FormText>
                            <FormButton
                                cvariant="contained"
                                ccolor="blue"
                                disabled={onSubmitProduct || onDiscardProduct}
                                onClick={() => {
                                    submitProduct({
                                        type: "spu",
                                        action: "listed",
                                        spu_id: detailData?.spu?.goods_id,
                                        merchant_id: merchantId,
                                        category_level: detailData?.spu?.category_level,
                                        vital_info_product_name_chinese:
                                            detailData?.spu?.vital_info_product_name_chinese,
                                        vital_info_product_name_english:
                                            detailData?.spu?.vital_info_product_name_english,
                                        vital_info_category: detailData?.spu?.vital_info_category,
                                        vital_info_brand: detailData?.spu?.vital_info_brand,
                                        vital_info_recognition_rewards:
                                            detailData?.spu?.vital_info_recognition_rewards !== null
                                                ? JSON.stringify(detailData?.spu?.vital_info_recognition_rewards)
                                                : JSON.stringify([]),
                                        vital_info_origin_of_goods: detailData?.spu?.vital_info_origin_of_goods,
                                        vital_info_type_of_item: detailData?.spu?.vital_info_type_of_item,
                                        vital_info_source_of_product: detailData?.spu?.vital_info_source_of_product,
                                        days_to_ship_from: detailData?.spu?.days_to_ship_from,
                                        days_to_ship_to: detailData?.spu?.days_to_ship_to,
                                        shipment_ship_to_local_only: detailData?.spu?.shipment_ship_to_local_only,
                                        shipment_return_policy_english: detailData?.spu?.shipment_return_policy_english,
                                        shipment_return_policy_chinese: detailData?.spu?.shipment_return_policy_chinese,
                                        description_product_features_chinese:
                                            detailData?.spu?.description_product_features_chinese,
                                        description_product_features_english:
                                            detailData?.spu?.description_product_features_english,
                                        description_introduction_english:
                                            detailData?.spu?.description_introduction_english,
                                        description_introduction_chinese:
                                            detailData?.spu?.description_introduction_chinese,
                                    });
                                }}
                            >
                                {" "}
                                {onSubmitProduct ? "Loading . . . " : "Save and Finish"}
                            </FormButton>
                            <FormButton
                                disabled={onDiscardProduct || onSubmitProduct}
                                cvariant="text"
                                ccolor="blue"
                                onClick={() => {
                                    discardProduct({
                                        type: "spu",
                                        action: "discard",
                                        spu_id: detailData?.spu?.goods_id,
                                        merchant_id: merchantId,
                                    });
                                }}
                            >
                                discard
                            </FormButton>
                        </SideMenu>
                        <div className="info">
                            <h5>Important Note</h5>
                            <p>
                                To know more about selling on YOHO{" "}
                                <LinkText
                                    href="https://sites.google.com/view/yoho3p"
                                    target="_blank"
                                    title="visit our merchant help centre"
                                    rel="noopener noreferrer"
                                >
                                    visit our merchant help centre
                                </LinkText>
                                .
                            </p>
                            <p>
                                For inquiry, please whatsapp us through hotline 53357570. This hotline is managed by
                                several departments in order to ensure the service quality.
                            </p>
                        </div>
                    </div>
                </Container>
            </>
        )
    );
};

import AddProductForm from "components/MerchantCentre/MerchantConsole/Products/Add/Form";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductAttributeConfig, getProductInitialConfig, getProductVariationDraft } from "service/actions/merchant";

export default () => {
    const dispatch = useDispatch();
    const merchant = useSelector((state) => state.merchant);

    useEffect(() => {
        if (
            !merchant.product.config.hasOwnProperty("brands") ||
            !merchant.product.config.hasOwnProperty("categories")
        ) {
            dispatch(getProductInitialConfig());
        }

        if (!merchant.product.config.hasOwnProperty("variation_attribute")) {
            dispatch(getProductAttributeConfig());
        }
    }, []);

    return !merchant.loading ? <AddProductForm /> : <div></div>;
};

import { ADMIN } from "service/constants/admin";

export const getAdminProductDetail = (payload) => ({ type: ADMIN.PRODUCT.DETAIL.GET, payload });

export const setAdminProductDetail = (data, args) => ({ type: ADMIN.PRODUCT.DETAIL.SET, data, args });

export const getAdminProductInit = (payload) => ({ type: ADMIN.PRODUCT.DETAIL.INIT_GET, payload });

export const setAdminProductInit = (data, args) => ({ type: ADMIN.PRODUCT.DETAIL.INIT_SET, data, args });

export const setAdminProductDraft = (data, args, property = null) => ({
    type: ADMIN.PRODUCT.DETAIL.DRAFT_SET,
    data,
    args,
    property,
});

export const cleanupAdminProductDetail = (args) => ({ type: ADMIN.PRODUCT.DETAIL.CLEANUP, args });

import localForage from "localforage";

export const indexDBInstance = localForage.createInstance({
    name: "YOHO-MKP",
});

export const flushStorage = () => {
    indexDBInstance.clear();
    return indexDBInstance.dropInstance();
};

export default indexDBInstance;

import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import Header from "components/MerchantCentre/Header";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrderList, setOrderParam } from "service/actions/merchant";
import { useGetOrderList } from "./hooks";
import List from "./List";

const orderStatusMap = {
    All: "all",
    Pending: "await_payment",
    "To Ship": "to_ship",
    Shipped: "shipped",
    "Exchange / Refund": "return_refund",
    Invalid: "invalid",
};

export default () => {
    const orderParams = useSelector((state) => state.merchant.order.params);
    const [active, setActive] = useState("All");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setOrderParam({
                status: orderStatusMap[active],
                start_position: 0,
                page: 1,
            }),
        );
    }, [active]);

    const { isLoading, data, refetch } = useGetOrderList(orderParams);
    const orderData = data?.data?.result;
    const statusSummary = orderData?.status_summary || {};

    useEffect(() => {
        if (orderData) {
            dispatch(setOrderList(orderData));
        }
    }, [orderData]);

    useEffect(
        () => () => {
            dispatch(setOrderParam({ search_string: "" }));
        },
        [],
    );

    const PENDING_CASE = { name: "Pending", count: statusSummary.await_payment || 0 };
    const TOSHIP_CASE = { name: "To Ship", count: statusSummary.to_ship || 0 };
    const SHIPPED_CASE = { name: "Shipped", count: statusSummary.shipped || 0 };
    const EXCHANGE_CASE = { name: "Exchange / Refund", count: statusSummary.return_refund || 0 };
    const INVALID_CASE = { name: "Invalid", count: statusSummary.invalid || 0 };
    const ALL_CASE = { name: "All", count: statusSummary.all || 0 };

    return (
        <Container>
            <div className="main">
                <Header
                    menu={[ALL_CASE, PENDING_CASE, TOSHIP_CASE, SHIPPED_CASE, EXCHANGE_CASE, INVALID_CASE]}
                    active={active}
                    setActive={setActive}
                />
                <Content>
                    <List data={orderData} isLoading={isLoading} orderParams={orderParams} refetch={refetch} />
                </Content>
            </div>
        </Container>
    );
};

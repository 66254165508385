import { styled } from "@material-ui/core";
import { FormText } from "components/Global/Form";
import { Link } from "react-router-dom";

const CustomLink = styled(Link)(({ theme }) => ({
    ...theme.yoho.typography.body,
    textDecoration: "none",
    color: "black",
    "&:visited": {
        textDecoration: "none",
        color: "black",
    },
    "&:hover": {
        textDecoration: "underline",
    },
}));

export default (e) => {
    return (
        <div>
            <FormText>
                <CustomLink className="body" to={`/orders/${e.order_id}`}>
                    {e.order_sn}
                </CustomLink>
            </FormText>
        </div>
    );
};

export const ACTION = {
    MODE: {
        LOGIN: {
            SET: "AUTH_ACTION_MODE_LOGIN_SET",
        },
    },
    REGISTER: {
        GET: "AUTH_ACTION_REGISTER_GET",
        SET: "AUTH_ACTION_REGISTER_SET",
        ERROR: "AUTH_ACTION_REGISTER_ERROR",
    },
    LOGIN: {
        GET: "AUTH_ACTION_LOGIN_GET",
        SET: "AUTH_ACTION_LOGIN_SET",
    },
    LOGOUT: {
        SET: "AUTH_ACTION_LOGOUT_SET",
    },
    RESET: {
        PASSWORD: {
            REQUEST: "AUTH_ACTION_RESET_PASSWORD_REQUEST",
            EMAIL: "AUTH_ACTION_RESET_PASSWORD_EMAIL_SET",
            VERIFY: {
                GET: "AUTH_ACTION_RESET_PASSWORD_VERIFY_GET",
                SET: "AUTH_ACTION_RESET_PASSWORD_VERIFY_SET",
            },
            CHANGE: "AUTH_ACTION_RESET_PASSWORD_CHANGE",
        },
    },
    IMPERSONATE: {
        START: "IMPERSONATION_START",
        STOP: "IMPERSONATION_STOP",
    },
};

export const FORM = {
    INITIAL_FORM_GET: "AUTH_FORM_INITIAL_FORM_GET",
    INITIAL_FORM_SET: "AUTH_FORM_INITIAL_FORM_SET",
    INITIAL_FORM_ERROR: "AUTH_FORM_INITIAL_FORM_ERROR",

    DATA: {
        AREA: {
            BY_COUNTRY: {
                GET: "AUTH_FORM_DATA_AREA_BY_COUNTRY_GET",
                SET: "AUTH_FORM_DATA_AREA_BY_COUNTRY_SET",
            },
        },
        SUBDISTRICT: {
            BY_AREA: {
                GET: "AUTH_FORM_DATA_SUBDISTRICT_BY_AREA_GET",
                SET: "AUTH_FORM_DATA_SUBDISTRICT_BY_AREA_SET",
            },
        },
        MERCHANT: {
            REVISE: {
                GET: "AUTH_FORM_DATA_MERCHANT_REVISE_GET",
                SET: "AUTH_FORM_DATA_MMERCHANT_REVISE_SET",
                CLEAR: "AUTH_FORM_DATA_MERCHANT_REVISE_CLEAR",
            },
        },
    },

    GET_MASTER_DATA: "AUTH_FORM_MASTER_DATA_GET",
    GET_COUNTRIES: "AUTH_FORM_COUNTRIES_GET",
    GET_AREAS: "AUTH_FORM_AREAS_GET",
    GET_DISTRICT_BY_AREA: "AUTH_FORM_DISTRICT_BY_AREA_GET",
    GET_SUB_DISTRICT_BY_DISTRICT: "AUTH_FORM_SUB_DISTRICT_BY_DISTRICT_GET",
    GET_SUB_DISTRICT_BY_AREA: "AUTH_FORM_SUB_DISTRICT_BY_AREA_GET",
    GET_CATEGORIES: "AUTH_FORM_CATEGORIES_GET",
    GET_SUB_CATEGORIES: "AUTH_FORM_SUB_CATEGORIES_GET",
    GET_DEPARTMENTS: "AUTH_FORM_DEPARTMENTS_GET",
    GET_INTERNATIONALCODE: "AUTH_FORM_INTERNATIONALCODE_GET",
};

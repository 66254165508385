import ProductList from "components/MerchantCentre/MerchantConsole/Products/List";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductAttributeConfig, getProductInitialConfig, getProductList } from "service/actions/merchant";

export default () => {
    const dispatch = useDispatch();
    const merchant = useSelector((state) => state.merchant);

    useEffect(() => {
        if (
            !merchant.product.config.hasOwnProperty("brands") ||
            !merchant.product.config.hasOwnProperty("categories")
        ) {
            dispatch(getProductInitialConfig());
        }

        if (!merchant.product.config.hasOwnProperty("variation_attribute")) {
            dispatch(getProductAttributeConfig());
        }
        if (merchant.product.list === null || merchant.product.list.current_page === undefined) {
            dispatch(getProductList());
        }
    }, []);

    return <ProductList />;
};

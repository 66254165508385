import produce, { current } from "immer";
import * as adminAction from "service/actions/admin";
import moment from "moment";

let initialState = {
    list: null,
    param: { status: "pending", submission_date: "Custom", size: 1000, page: 1 },
    selected: [],
    detail: null,
    product: {
        init: {},
        list: {},
        detail: {},
    },
};

const selectedEntry = new Set();

export default produce((draft, action) => {
    const currentDraft = current(draft);
    switch (action.type) {
        case adminAction.setApplication().type:
            draft.list = produce(currentDraft.list, (props) => {
                props = action.data;
                return props;
            });
            break;
        case adminAction.setApplicationParam().type:
            let draftData = action.data;
            if (action.data.submission_date === "Custom") {
                delete draftData.submission_date;
            } else {
                delete draftData.from;
                delete draftData.to;
            }

            const keys = Object.keys(draftData);

            draft.param = produce(currentDraft.param, (props) => {
                keys.forEach((ikey) => {
                    props[ikey] = action.data[ikey];
                });

                return props;
            });
            break;
        case adminAction.selectApplication().type:
            selectedEntry[selectedEntry.has(action.data) ? "delete" : "add"](action.data);
            const entrydata = Array.from(selectedEntry);
            if (Array.isArray(entrydata)) {
                draft.selected = entrydata;
            }
            break;
        case adminAction.setApplicationDetail().type:
            draft.detail = produce(currentDraft.detail, (props) => {
                props = action.data;
                return props;
            });
            break;

        case adminAction.setMerchantList().type:
            draft.merchant = produce(currentDraft.merchant, (props) => {
                props = action.data;
                return props;
            });
            break;
        case adminAction.setAdminProductInit().type:
            const initKeys = action.args;

            draft.product.init = produce(currentDraft.product.init, (props) => {
                props[initKeys.merchant_id] = action.data;
                return props;
            });
            break;
        case adminAction.setAdminProductDetail().type:
            const detailKeys = action.args;
            draft.product.detail = produce(currentDraft.product.detail, (props) => {
                if (!props.hasOwnProperty(detailKeys.goods_id)) {
                    Object.assign(props, { [detailKeys.goods_id]: {} });
                }
                props[detailKeys.goods_id] = produce(props[detailKeys.goods_id], (goods) => {
                    if (!goods.hasOwnProperty(detailKeys.merchant_id)) {
                        Object.assign(goods, { [detailKeys.merchant_id]: {} });
                    }
                    goods[detailKeys.merchant_id] = produce(goods[detailKeys.merchant_id], (merchant) => {
                        merchant = action.data;
                        return merchant;
                    });
                    return goods;
                });
                return props;
            });
            break;
        case adminAction.setAdminProductDraft().type:
            const draftKeys = action.args;
            const property = action.property;
            draft.product.detail = produce(currentDraft.product.detail, (props) => {
                if (!props.hasOwnProperty(draftKeys.goods_id)) {
                    Object.assign(props, { [draftKeys.goods_id]: {} });
                }
                props[draftKeys.goods_id] = produce(props[draftKeys.goods_id], (goods) => {
                    if (!goods.hasOwnProperty(draftKeys.merchant_id)) {
                        Object.assign(goods, { [draftKeys.merchant_id]: {} });
                    }
                    goods[draftKeys.merchant_id] = produce(goods[draftKeys.merchant_id], (merchant) => {
                        property.forEach((properKey) => {
                            merchant[properKey] = produce(merchant[properKey], (specialDraft) => {
                                Object.keys(action.data).forEach((key) => {
                                    specialDraft[key] = action.data[key];
                                });
                                return specialDraft;
                            });
                        });
                        return merchant;
                    });
                    return goods;
                });
                return props;
            });
            break;
        case adminAction.cleanupAdminProductDetail().type:
            draft.product.detail = produce(currentDraft.product.detail, (props) => {
                delete props[action.args.goods_id];
                return props;
            });
            break;
        default:
    }
}, initialState);

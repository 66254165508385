export const ADMIN = {
    APPLICATION: {
        LIST: {
            GET: "ADMIN_APPLICATION_LIST_GET",
            SET: "ADMIN_APPLICATION_LIST_SET",
            ERROR: "ADMIN_APPLICATION_LIST_ERROR",
        },
        PARAM: {
            SET: "ADMIN_APPLICATION_PARAM_SET",
        },
        SELECT: {
            SET: "ADMIN_APPLICATION_SELECT_SET",
        },
        EXPORT: {
            SET: "ADMIN_APPLICATION_EXPORT_SET",
        },
        DETAIL: {
            GET: "ADMIN_APPLICATION_DETAIL_GET",
            SET: "ADMIN_APPLICATION_DETAIL_SET",
            ERROR: "ADMIN_APPLICATION_DETAIL_ERROR",
        },
        APPROVE: {
            GET: "ADMIN_APPLICATION_APPROVE_GET",
            SET: "ADMIN_APPLICATION_APPROVE_SET",
            ERROR: "ADMIN_APPLICATION_APPROVE_ERROR",
        },
        DENY: {
            GET: "ADMIN_APPLICATION_DENY_GET",
            SET: "ADMIN_APPLICATION_DENY_SET",
            ERROR: "ADMIN_APPLICATION_DENY_ERROR",
        },
        STATUS: {
            GET: "ADMIN_APPLICATION_STATUS_GET",
            SET: "ADMIN_APPLICATION_STATUS_SET",
            ERROR: "ADMIN_APPLICATION_STATUS_ERROR",
        },
    },
    MERCHANT: {
        LIST: {
            GET: "ADMIN_MERCHANT_GET",
            SET: "ADMIN_MERCHANT_SET",
            ERROR: "ADMIN_MERCHANT_ERROR",
        },
        RESET: {
            PASSWORD: "ADMIN_MERCHANT_RESET_PASSWORD",
            STATUS: "ADMIN_MERCHANT_RESET_STATUS",
        },
    },
    PRODUCT: {
        DETAIL: {
            GET: "ADMIN_PRODUCT_DETAIL_GET",
            SET: "ADMIN_PRODUCT_DETAIL_SET",
            INIT_GET: "ADMIN_PRODUCT_DETAIL_INIT_GET",
            INIT_SET: "ADMIN_PRODUCT_DETAIL_INIT_SET",
            DRAFT_SET: "ADMIN_PRODUCT_DETAIL_DRAFT_SET",
            CLEANUP: "ADMIN_PRODUCT_DETAIL_CLEANUP",
        },
    },
};

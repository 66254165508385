import { styled } from "@material-ui/core";
import { useState } from "react";
import MPDI_TA from "./MPDI_TA";

const ComboWrapper = styled("div")({
    "& > .full": {
        width: "100%",
    },
});

const MenuContainer = styled("div")({
    display: "flex",
});

const Menu = styled("h4")(({ active, theme, type }) => ({
    ...theme.yoho.typography[type],
    color: theme.yoho.md_color.grey[800],
    display: "flex",
    position: "relative",
    "&.active": {
        color: theme.yoho.md_color.blue[500],
        "&:after": {
            content: "''",
            background: theme.yoho.md_color.blue[500],
            width: "100%",
            position: "absolute",
            height: "1px",
            left: 0,
            bottom: "-14px",
        },
    },
    textTransform: "capitalize",
    cursor: "pointer",
    padding: "0 15px",
    margin: "13px 0",
    textAlign: "center",
    "& .bubble": {
        color: theme.yoho.md_color.red[500],
    },
}));

const Divider = styled("div")(({ theme }) => ({
    width: "100%",
    borderBottom: `1px solid ${theme.yoho.md_color.grey[300]}`,
    marginBottom: "20px",
}));

export default ({ langs, action = () => {}, className, defaultLang, draft }) => {
    const [selectedLang, setSlelectedLang] = useState(defaultLang);

    return (
        <ComboWrapper {...(className && { className })}>
            <MenuContainer>
                {langs.map((lang) => (
                    <Menu
                        type="button"
                        key={`${JSON.stringify(lang.name)}`}
                        className={selectedLang === lang.code ? " active" : ""}
                        onClick={() => setSlelectedLang(lang.code)}
                    >
                        {lang.name}
                    </Menu>
                ))}
            </MenuContainer>
            <Divider />
            {langs.map((lang) => (
                <div
                    key={lang.code}
                    className="full"
                    style={{ display: selectedLang !== lang.code ? "none" : "inline-flex" }}
                >
                    <MPDI_TA
                        draft={draft}
                        name={lang.code}
                        action={(event) => {
                            const aiGenerationTag = lang.code.includes("chinese")
                                ? "is_ai_gen_goods_brief_cn"
                                : "is_ai_gen_goods_brief_en";
                            action({
                                [lang.code]: event.target.value || "",
                                [aiGenerationTag]: 0,
                            });
                        }}
                    />
                </div>
            ))}
        </ComboWrapper>
    );
};

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/styles";
import { PasswordField } from "components/Global/Form";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { forgotPasswordChange } from "service/actions/authentication";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0px 0px 15px",
    },
    error: {
        color: "red",
    },
    title: {
        fontSize: 17,
        marginTop: 0,
    },
}));

const FormTitle = styled("p")(({ theme }) => ({
    "&::before": {
        content: "''",
        position: "absolute",
        left: 0,
        top: "36px",
        width: "10px",
        height: "26px",
        backgroundColor: theme.palette.primary.main,
    },
    fontSize: 28,
    fontWeight: 500,
    lineHeight: "40.5px",
    marginTop: 0,
    color: theme.palette.primary.main,
}));

const FormButton = styled(Button)(({ theme }) => ({
    height: "52px",
    ...theme.yoho.button.contained.blue,
}));

export default () => {
    const { search } = useLocation();
    const dispatch = useDispatch();
    const query = {
        [search.split("=")[0].slice(1, search.split("=")[0].length)]: search.split("=")[1],
    };

    const classes = useStyles();
    const { register, handleSubmit, watch, errors } = useForm();
    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = () => {
        dispatch(forgotPasswordChange({ ...query, new_password: password.current }));
    };

    return (
        <div className={classes.root}>
            <FormTitle className={classes.title}>Reset Password</FormTitle>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} justify="center">
                    <Grid item sm={12} xs={12}>
                        <PasswordField
                            style={{ width: "100%" }}
                            name="password"
                            label="New Password"
                            errors={errors}
                            inputRef={register({
                                required: "You must specify a password",
                                minLength: {
                                    value: 8,
                                    message: "Password must have at least 8 characters",
                                },
                            })}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <PasswordField
                            style={{ width: "100%" }}
                            name="repeat"
                            label="Repeat Password"
                            errors={errors}
                            inputRef={register({
                                validate: (value) => value === password.current || "The passwords do not match",
                            })}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <FormButton variant="contained" type="submit" fullWidth>
                            Continue
                        </FormButton>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

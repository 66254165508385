import { InputAdornment, TextField } from "@material-ui/core";
import { FormCategory } from "components/Global";

export default ({ defaultData, inputRef, getValues, customRef, errors }) => {
    // console.log("defaultData general", defaultData);
    return (
        <FormCategory>
            <TextField
                inputRef={inputRef}
                name="variations_general_gtin"
                variant="outlined"
                label="GTIN (Barcode)"
                disabled={defaultData.status === "published"}
            />

            <TextField
                inputRef={inputRef}
                name="variations_general_mpn"
                variant="outlined"
                label="MPN (Part no.)"
                disabled={defaultData.status === "published"}
            />

            <TextField
                inputRef={customRef}
                name="variations_general_weight"
                variant="outlined"
                label="Weight"
                type="number"
                inputProps={{
                    step: 0.000001,
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                }}
                disabled={defaultData.status === "published"}
            />

            <TextField
                inputRef={inputRef}
                name="variations_general_item_cost"
                variant="outlined"
                label="Item Cost"
                helperText="This information will not be displayed to others"
                type="number"
                inputProps={{
                    step: 0.01,
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">HK$</InputAdornment>,
                }}
            />
            {errors?.variations_general_weight && (
                <>
                    <span style={{ color: "red" }}>{errors?.variations_general_weight.message}</span>
                    <div></div>
                </>
            )}
            <TextField inputRef={inputRef} name="variations_general_custom_sku" variant="outlined" label="Custom SKU" />
        </FormCategory>
    );
};

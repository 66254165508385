import { styled } from "@material-ui/core";
import { useEffect, useState } from "react";
import { FormButton } from "components/Global";
import { Link } from "react-router-dom";

import { Table } from "components/General/Table";
import * as promotionAPI from "service/apis/merchant/promo";
import { useHistory } from "react-router-dom";
import { useApiWithErrorHandle } from "service/apis/merchant/utilHook";

const PageContainer = styled("div")({
    margin: "0px 35px",
    overflow: "auto",
});

const InfoText = styled("p")({
    color: "#888888",
});

const AddButton = styled(FormButton)({ width: 135, height: 38 });

const TableHead = styled("div")({
    display: "flex",
    margin: "16px 0px",
    justifyContent: "flex-end",
});

const columns = [
    {
        label: "Title",
        name: "title",
        size: 2,
    },
    {
        label: "Start Date",
        name: "startDate",
        size: 1,
    },
    {
        label: "End Date",
        name: "endDate",
        size: 1,
    },
    {
        label: "Number of Products",
        name: "numProduct",
        size: 1,
    },
];

function unixTimeStampToDate(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
}

const rowPerPage = 10;

export default function PromotionInfo() {
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [pagination, setPagination] = useState({ count: 1, page: 1 });
    const history = useHistory();

    const getPromoInfoList = useApiWithErrorHandle(promotionAPI.getPromoInfoList);
    const deletePromoInfo = useApiWithErrorHandle(promotionAPI.deletePromoInfo);

    const loadPromoList = async () => {
        const result = await getPromoInfoList({ limit: rowPerPage, offset: (pagination.page - 1) * rowPerPage });
        if (result) {
            setPagination({ ...pagination, count: Math.ceil(result.count / rowPerPage) });
            setRows(
                result.rows.map((row) => ({
                    id: row.topic_id,
                    title: row.title.zh_hk,
                    startDate: unixTimeStampToDate(row.start_time),
                    endDate: unixTimeStampToDate(row.end_time),
                    numProduct: row.products.length,
                })),
            );
            setLoading(false);
        }
    };

    const handlePromoInfoDelete = async (row) => {
        const confirmDelete = window.confirm(`Are you sure to delete "${row.title}"?`);
        if (confirmDelete) {
            setLoading(true);
            await deletePromoInfo({ topic_id: row.id });
            await loadPromoList();
        }
    };

    useEffect(() => {
        loadPromoList();
    }, [pagination.page]);

    return (
        <PageContainer>
            <h1>Promotion Information</h1>
            <InfoText>
                Promotion information can be shown for a selected group of products, notifying the customer on relevant
                promotion campaign. It is for information only and does not affect the product price on YOHO. Take a
                look at this{" "}
                <a
                    href="https://drive.google.com/file/d/1BjDkYHTAI9DZzq2MCR2ma5zrjtaU-Yyc/view?usp=sharing"
                    target="_blank"
                >
                    video
                </a>{" "}
                to know more.
            </InfoText>
            <TableHead>
                <Link to="/promotion/promotion-info/add">
                    <AddButton>Add New</AddButton>
                </Link>
            </TableHead>
            <Table
                loading={loading}
                columns={columns}
                rows={rows}
                emptyMessage="No Promotion Information"
                rowEdit={(row) => history.push(`/promotion/promotion-info/edit/${row.id}`)}
                rowDelete={(row) => handlePromoInfoDelete(row)}
                pagination={{ ...pagination, onChange: (_, value) => setPagination({ ...pagination, page: value }) }}
            />
        </PageContainer>
    );
}

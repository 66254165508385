import { InputAdornment, TextField } from "@material-ui/core";
import { FormCategory } from "components/Global";

export default ({ inputRef, customRef }) => {
    return (
        <FormCategory>
            <TextField inputRef={inputRef} name="variations_general_gtin" variant="outlined" label="GTIN (Barcode)" />

            <TextField inputRef={inputRef} name="variations_general_mpn" variant="outlined" label="MPN (Part no.)" />

            <TextField
                inputRef={customRef}
                name="variations_general_weight"
                variant="outlined"
                label="Weight"
                InputProps={{ endAdornment: <InputAdornment position="start">Kg</InputAdornment> }}
                type="number"
                inputProps={{
                    step: 0.000001,
                }}
            />

            <TextField
                inputRef={inputRef}
                name="variations_general_item_cost"
                variant="outlined"
                label="Item Cost"
                type="number"
                helperText="This information will not be displayed to others"
                inputProps={{
                    step: 0.01,
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">HK$</InputAdornment>,
                }}
            />
            <TextField inputRef={inputRef} name="variations_general_custom_sku" variant="outlined" label="Custom SKU" />
        </FormCategory>
    );
};

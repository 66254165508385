import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { styled } from "@material-ui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

const StyledContainer = styled(Container)({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    flexWrap: "wrap",
});

const RootFormContainer = styled("div")(({ form }) => ({
    height: form === "register" ? "unset" : "100vh",
    display: "flex",
    alignItems: "center",
}));

const TitleContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
});

const TitleLogo = styled("img")({
    height: "40px",
});

const TitleText = styled("h4")({
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "34.75px",
    margin: "10px 0 40px 0",
});

const LoginContainer = styled(Paper)(({ theme }) => ({
    width: "378px",
    padding: "30px 30px 20px 30px",
    borderRadius: "20px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    position: "relative",
    boxSizing: "border-box",
}));

const LoadingContainer = styled("div")({
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    background: "rgba(0,0,0,0.5)",
    zIndex: "500",
});

function LoginPage({ children }) {
    const authLoading = useSelector((state) => state.site.loading.auth);

    return (
        <RootFormContainer>
            <StyledContainer maxWidth="sm">
                <TitleContainer>
                    <TitleLogo
                        src={
                            new Date().getMonth() + 1 == 12
                                ? "/assets/images/yoho_logo_christmas.png"
                                : "/assets/images/yoho_logo.png"
                        }
                    />
                    <TitleText>YOHO Merchant Centre</TitleText>
                </TitleContainer>
                <LoginContainer>{children}</LoginContainer>
                {authLoading && (
                    <LoadingContainer>
                        <CircularProgress />
                    </LoadingContainer>
                )}
            </StyledContainer>
        </RootFormContainer>
    );
}

export default LoginPage;

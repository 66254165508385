import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import Header from "components/MerchantCentre/Header";
import { useState } from "react";
import General from "./General";
import ShippingMethods from "./ShippingMethods";
import TableRates from "./TableRates";
import DeliveryReturnPolicy from "./DeliveryReturnPolicy";
import DeliveryNotes from "./note/DeliveryNotes";
import ShippingMethodsNote from "./note/ShippingMethodsNote";
import GeneralNote from "./note/GeneralNote";
import TableRatesNotes from "./note/TableRatesNotes";

export default () => {
    const [active, setActive] = useState("general");
    return (
        <Container>
            <div className="main">
                <Header
                    menu={["general", "shipping methods", "table rates", "delivery & return policy"]}
                    active={active}
                    setActive={setActive}
                    title="Shipment Management"
                />
                <Content>
                    {{
                        general: <General />,
                        "shipping methods": <ShippingMethods />,
                        "table rates": <TableRates />,
                        "delivery & return policy": <DeliveryReturnPolicy />,
                    }[active] || <div>Page is still under construction</div>}
                </Content>
            </div>
            {{
                general: <GeneralNote />,
                "shipping methods": <ShippingMethodsNote />,
                "table rates": <TableRatesNotes />,
                "delivery & return policy": <DeliveryNotes />,
            }[active] || <></>}
        </Container>
    );
};

import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    Paper,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
} from "@material-ui/core";
import { FormButton } from "components/Global";
import { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table } from "../Table";
import { debounce } from "lodash";
import * as promoAPI from "service/apis/merchant/promo";
import * as adminProductAPI from "service/apis/admin/product";
import * as merchantAPI from "service/apis/admin/merchant";
import { useApiWithErrorHandle } from "service/apis/merchant/utilHook";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        marginTop: "10px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "auto",
        gridGap: "10px",
    },
    top: {
        gridColumn: "1 / 3",
        gridRow: "1",
        marginBottom: "6px",
    },
    left: {
        gridColumn: "1",
        gridRow: "2",
    },
    right: {
        gridColumn: "2",
        gridRow: "2",
    },
    bottom: {
        gridColumn: "1 / 3",
        gridRow: "3",
    },
}));

const columnsTemplate = [
    {
        label: "Product Name",
        name: "label",
        size: 3,
    },
];

export default function ProductList({
    value,
    onItemAdd,
    onItemRemove,
    enablePriceInput = false,
    onEventPriceChange: onPriceChange,
    isAdmin,
}) {
    const classes = useStyles();
    const [options, setOptions] = useState([]);
    const [merchantOptions, setMerchantOptions] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const [searchTarget, setSearchTarget] = useState("product");
    const [searchText, setSearchText] = useState("");
    const [dialogData, setDialogData] = useState(null);

    const api = isAdmin ? adminProductAPI : promoAPI;
    const getProductSelectorOptions = useApiWithErrorHandle(api.getProductSelectorOptions, () => []);
    const getProductsFromGroup = useApiWithErrorHandle(api.getProductsFromGroup, () => []);
    const getMerchantOptions = useApiWithErrorHandle(merchantAPI.adminMerchantDropDownOptions, () => []);

    const performOptionsSearch = useCallback(
        debounce(async (target, text, productIds, merchantId) => {
            const result = await getProductSelectorOptions({
                searchTarget: target,
                searchText: text,
                selectedProductIds: productIds,
                merchantId,
            });
            setOptions(result);
        }, 300),
        [],
    );

    useEffect(() => {
        const getMerchantDropdown = async () => {
            const result = await getMerchantOptions();
            setMerchantOptions(result);
        };
        if (isAdmin) {
            getMerchantDropdown();
        }
    }, [isAdmin]);

    useEffect(() => {
        setOptions([]);
        setSearchText("");
    }, [searchTarget]);

    useEffect(() => {
        if (selectedMerchant) {
            setOptions([]);
            setSearchText("");
            onItemRemove(value);
        }
    }, [selectedMerchant]);

    useEffect(() => {
        const selectedProductIds = value.map((p) => p.id);
        if (searchText) {
            if (isAdmin) {
                const { value: merchantId } = selectedMerchant;
                performOptionsSearch(searchTarget, searchText, selectedProductIds, merchantId);
            } else {
                performOptionsSearch(searchTarget, searchText, selectedProductIds);
            }
        }
    }, [searchText, searchTarget, value]);

    useEffect(() => {
        const retrieveProducts = async () => {
            const payload = {
                groupType: dialogData.target,
                groupId: dialogData.targetId,
            };
            if (isAdmin) {
                const { value: merchantId } = selectedMerchant;
                payload.merchantId = merchantId;
            }
            const result = await getProductsFromGroup(payload);
            setDialogData({
                ...dialogData,
                needFetch: false,
                products: result.map((good) => ({
                    label: good.goods_name,
                    id: good.goods_id,
                })),
            });
        };

        if (dialogData && dialogData.needFetch) {
            retrieveProducts();
        }
    }, [dialogData]);

    const columns = [...columnsTemplate];
    if (enablePriceInput) {
        columns.push({
            label: "Discount Price",
            name: "discountPrice",
            render: (row) => (
                <TextField
                    id={row.id}
                    variant="outlined"
                    value={row.discountPrice ?? 0}
                    onChange={(e) => onPriceChange(e.target.id, e.target.value)}
                />
            ),
            size: 1,
        });
    }

    return (
        <div className={classes.container}>
            {isAdmin ? (
                <Autocomplete
                    fullWidth
                    className={classes.top}
                    options={merchantOptions}
                    value={selectedMerchant}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Merchants" variant="outlined" />}
                    PaperComponent={({ children, params }) => (
                        <Paper {...params} style={{ width: "100%" }}>
                            {children}
                        </Paper>
                    )}
                    onChange={(_, selected) => setSelectedMerchant(selected)}
                />
            ) : (
                <div className={classes.top} style={{ margin: 0 }}>
                    {/* Placeholder for grid layout */}
                </div>
            )}
            {(!isAdmin || selectedMerchant) && (
                <>
                    <FormControl>
                        <InputLabel
                            id="search-target-label"
                            variant="outlined"
                            style={{ background: "#F8FAFF", padding: "0px 6px" }}
                        >
                            Select Products By
                        </InputLabel>
                        <Select
                            labelId="search-target-label"
                            value={searchTarget}
                            onChange={(event) => setSearchTarget(event.target.value)}
                            className={classes.left}
                            variant="outlined"
                        >
                            <MenuItem value="product">Product</MenuItem>
                            <MenuItem value="brand">Brand</MenuItem>
                            <MenuItem value="category">Category</MenuItem>
                            <MenuItem value="storeCategory">Store Category</MenuItem>
                        </Select>
                    </FormControl>
                    <Autocomplete
                        options={options}
                        inputValue={searchText}
                        value={null} // For searching only never hold any actual value
                        getOptionLabel={(option) => option.label}
                        filterOptions={(opt) => opt} // No front-end filtering
                        className={classes.right}
                        renderInput={(params) => <TextField {...params} label="Search" variant="outlined" />}
                        PaperComponent={({ children, params }) => (
                            <Paper {...params} style={{ width: "100%" }}>
                                {children}
                            </Paper>
                        )}
                        onInputChange={(_, searched) => setSearchText(searched)}
                        onChange={(_, selected) => {
                            if (!selected) return;
                            if (searchTarget === "product") {
                                setSearchText("");
                                if (!value.find((v) => v.id === selected.id)) {
                                    onItemAdd([selected]);
                                }
                            } else {
                                setSearchText("");
                                setDialogData({
                                    target: searchTarget,
                                    targetName: selected.label,
                                    targetId: selected.id,
                                    needFetch: true,
                                });
                            }
                        }}
                    />
                    <div className={classes.bottom}>
                        <Table
                            columns={columns}
                            rows={value}
                            rowDelete={(row) => onItemRemove([row])}
                            emptyMessage={"No Items Added"}
                        />
                    </div>
                </>
            )}
            {dialogData && (
                <Dialog open>
                    {dialogData.needFetch ? (
                        <div style={{ padding: "80px 275px" }}>
                            <CircularProgress style={{ height: "50px", width: "50px" }} />
                        </div>
                    ) : (
                        <>
                            <DialogTitle style={{ width: "550px", padding: "25px", paddingBottom: "0px" }}>
                                Choose what you would like to do with products from {dialogData.targetName}
                            </DialogTitle>
                            <DialogContent>
                                <ul style={{ padding: "0px 25px" }}>
                                    <li>Add All - Add all products from {dialogData.targetName} to the list</li>
                                    <li>Remove All - Remove all products from {dialogData.targetName} from the list</li>
                                    <li>Cancel - Cancel the action and do nothing</li>
                                </ul>
                            </DialogContent>
                            <DialogActions style={{ width: "550px", padding: "25px", paddingTop: "8px" }}>
                                <FormButton
                                    cvariant="contained"
                                    onClick={() => {
                                        const productDelta = dialogData.products.filter(
                                            (p) => !value.map((v) => v.id).includes(p.id),
                                        );
                                        onItemAdd(productDelta);
                                        setDialogData(null);
                                    }}
                                >
                                    Add All
                                </FormButton>
                                <FormButton
                                    cvariant="contained"
                                    onClick={() => {
                                        const productDelta = dialogData.products.filter((p) =>
                                            value.map((v) => v.id).includes(p.id),
                                        );
                                        onItemRemove(productDelta);
                                        setDialogData(null);
                                    }}
                                >
                                    Remove All
                                </FormButton>
                                <FormButton cvariant="outlined" onClick={() => setDialogData(null)}>
                                    Cancel
                                </FormButton>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
            )}
        </div>
    );
}

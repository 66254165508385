import { styled } from "@material-ui/core";
import { FormText } from "components/Global/Form";
import ProductSelectedVariationTerms from "./ProductSelectedVariationTerms";

const VariationItemWrapper = styled("div")({
    display: "flex",
    gap: 20,
    alignItems: "center",
    "& .column": {
        display: "flex",
        gap: 20,
        alignItems: "center",
        width: "50%",
        "& .icon-drag": {
            display: "inline-block",
            width: "10px",
            height: "62px",
            backgroundSize: "cover",
            backgroundImage: `url("/assets/icon/drag.svg")`,
        },
        "& > div:first-child": {
            width: 200,
        },
        "& > div:last-of-type": {
            width: "100%",
        },
    },
});

export default ({ data, provided }) => {
    return (
        <VariationItemWrapper>
            <div className="column">
                <img src="/assets/icon/drag.svg" {...provided?.dragHandleProps} />
                <FormText>
                    <p className="body1">
                        {data?.attribute_name_english} {data?.attribute_name_chinese}
                    </p>
                </FormText>
            </div>
            <div className="column">
                <ProductSelectedVariationTerms label="Variation Attributes" data_variation={data} />
            </div>
        </VariationItemWrapper>
    );
};

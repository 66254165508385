import React, { useLayoutEffect, useState } from "react";

export const getDataFromNestedObject = (key, data) => {
    const key_dict = key.split(".");
    return (
        (Object.keys(data).length !== 0 &&
            ["", ...key_dict].reduce((a, b) => {
                return (a?.hasOwnProperty(b) && a[b]) || (data.hasOwnProperty(b) && data[b]);
            })) ||
        ""
    );
};

export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};

export const format2HTML = (html) => {
    var tab = "\t";
    var result = "";
    var indent = "";

    html.split(/>\s*</).forEach(function (element) {
        if (element.match(/^\/\w/)) {
            indent = indent.substring(tab.length);
        }

        result += indent + "<" + element + ">\r\n";

        if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith("input")) {
            indent += tab;
        }
    });

    return result.substring(1, result.length - 3);
};

export const encryptString = (value, code = "base64") => {
    const convertString = typeof value !== "string" ? value.toString() : value;
    return code === "base64"
        ? Buffer.from(convertString).toString("base64")
        : Buffer.from(convertString, "base64").toString("ascii");
};

import { styled } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Title = styled("h1")(({ theme }) => ({
    ...theme.yoho.typography.h2,
    color: theme.yoho.md_color.grey[800],
    margin: 0,
    marginBottom: "14px",
}));

const MenuContainer = styled("div")({
    overflowX: "auto",
    display: "flex",
    gap: 50,
});

const Menu = styled("h4")(({ active, theme }) => ({
    ...theme.yoho.typography.button,
    color: active === "active" ? theme.yoho.md_color.blue[500] : theme.yoho.md_color.grey[800],
    display: "flex",
    gap: "10px",
    textTransform: "capitalize",
    cursor: "pointer",
    margin: "12px 0",
    fontSize: 18,
    "& .bubble": {
        padding: "1px 9px",
        borderRadius: 10,
        backgroundColor: theme.palette.grey[200],
        fontSize: 17,
    },
}));

const Divider = styled("div")(({ theme }) => ({
    width: "100%",
    borderBottom: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

export default ({ title, menu, active, setActive, setStartPosition, setIsContentLoading }) => {
    const renderMenu = typeof menu[0] === "object" ? menu : menu.map((item) => ({ name: item }));
    const { pathname } = useLocation();
    const { isAdmin } = useSelector((state) => state.profile);

    const handleActive = (item) => {
        setActive(item);
        if (pathname.includes("admin/merchant") && isAdmin) {
            setStartPosition(0);
            setIsContentLoading(true);
        }
    };

    return (
        <div>
            {!!title && <Title>{title}</Title>}
            <MenuContainer>
                {renderMenu.map((item) => (
                    <Menu
                        key={`${JSON.stringify(item.name)}`}
                        active={active === item.name ? "active" : ""}
                        className={active === item.name ? "active" : ""}
                        onClick={() => handleActive(item.name)}
                    >
                        {item.name}
                        {item.count != undefined && <span className="bubble">{item.count}</span>}
                    </Menu>
                ))}
            </MenuContainer>
            <Divider />
        </div>
    );
};

import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";

function InputText({
    label,
    style,
    name,
    variant,
    className,
    disabled,
    multiline,
    rows,
    display,
    type,
    control = {},
    inputRef,
    error,
    helperText,
    InputProps,
}) {
    return (
        <Controller
            render={(props) => (
                <TextField
                    {...(display && { style: { display } })}
                    {...(label && { label })}
                    {...(rows && { rows })}
                    {...(style && { style })}
                    {...(multiline && { multiline })}
                    {...(type && { type })}
                    disabled={disabled}
                    variant={variant || "outlined"}
                    className={className}
                    {...(inputRef && { inputRef })}
                    {...(error && { error })}
                    {...(helperText && { helperText })}
                    {...(InputProps && { InputProps })}
                    {...props}
                />
            )}
            {...(name && { name })}
            control={control}
            defaultValue=""
        />
    );
}

export default InputText;

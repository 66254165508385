import { DialogContent, DialogTitle, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import Popup from "components/Global/Layout/popup";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { unsetPopup } from "service/actions/site";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomDenyDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

export default ({ action, close, title = "Edit Warranty Terms", editdata = "", attr_id, defaultData }) => {
    const { register, handleSubmit, watch, errors, setValue, control, reset } = useForm();
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        reset(editdata);
        setReady(true);
    }, []);

    const onSubmit = (data) => {
        data.value = data.attribute_item_name_english;
        data.attribute_id = attr_id;
        if (editdata) {
            action(data, editdata.template_id, editdata);
        } else {
            action(data);
        }
        dispatch(unsetPopup());
    };

    return (
        ready && (
            <Popup size="md" fullWidth>
                <CustomDialogTitle>{title}</CustomDialogTitle>
                <CustomDenyDialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormCategory justifycontent="center">
                            <TextField
                                inputRef={register()}
                                name="attribute_item_name_chinese"
                                variant="outlined"
                                label="Variation Terms (Traditional Chinese)"
                                defaultValue={defaultData?.value}
                            />
                            <TextField
                                inputRef={register()}
                                name="attribute_item_name_english"
                                variant="outlined"
                                label="Variation Terms (English)"
                                defaultValue={defaultData?.value}
                            />
                            <CustomFormButton
                                className="half"
                                ccolor="blue"
                                cvariant="contained"
                                type="submit"
                                fullWidth
                            >
                                Submit
                            </CustomFormButton>
                            <CustomFormButton className="half" ccolor="blue" cvariant="text" fullWidth onClick={close}>
                                later
                            </CustomFormButton>
                        </FormCategory>
                    </form>
                </CustomDenyDialogContent>
            </Popup>
        )
    );
};

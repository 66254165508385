import { styled } from "@material-ui/core";
import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";

import { FormButton } from "components/Global";
import { FieldType } from "components/General/Field";
import { Form } from "components/General/Form";
import * as productAPI from "service/apis/admin/product";

import { useApiWithErrorHandle, useShowSnackbar } from "service/apis/merchant/utilHook";

const PageContainer = styled("div")({
    margin: "0px 35px",
    position: "relative",
});

const InfoText = styled("p")({
    color: "#888888",
});

const Grid = styled("div")({
    display: "flex",
});

const FormSection = styled("div")({
    flexGrow: 1,
});

const PanelSection = styled("div")({
    width: 380,
    paddingLeft: "25px",
    paddingTop: "13px",
});

const RightPanel = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    minWidth: "380px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "20px",
    height: "fit-content",
    borderRadius: 16,
    position: "sticky",
    top: "35px",
    "& > *": {
        width: "100% !important",
    },
}));

const initFormSections = [
    {
        fields: [
            {
                type: FieldType.DROPDOWN,
                label: "Field to Change",
                name: "updateTarget",
                options: [
                    { value: "brand", label: "Brand" },
                    { value: "category", label: "Category" },
                ],
            },
            {
                type: FieldType.AUTO_COMPLETE,
                label: "Change to",
                name: "updateValue",
            },
        ],
    },
    {
        label: "Apply to Products",
        fields: [
            {
                type: FieldType.PRODUCT_LIST,
                label: "Search and Add Related Product",
                name: "products",
                isAdmin: true,
            },
        ],
    },
];

const validateSubmission = ({ updateValue, products }) => {
    if (!updateValue) {
        return ["Update value cannot be empty", { updateValue: "This field cannot be empty" }];
    }

    if (products.length === 0) {
        return ["Please select at least one product", {}];
    }

    return ["", {}];
};

export default function ProductAmend() {
    const [submitting, setSubmitting] = useState(false);
    const [formSections, setFormSections] = useState(initFormSections);
    const [values, setValues] = useState({
        updateTarget: "brand",
        updateValue: null,
        products: [],
    });
    const [errors, setErrors] = useState({});
    const showSnackBar = useShowSnackbar();

    const getOptions = useApiWithErrorHandle(productAPI.brandCategorySearch, () => []);
    const bulkAmendProduct = useApiWithErrorHandle(productAPI.adminBulkAmendProduct, () => []);

    const changeHandler = (name, value) => {
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        const newSections = cloneDeep(initFormSections);
        newSections[0].fields[1].getOptions = (searchText) => {
            return getOptions({ searchTarget: values.updateTarget, searchText });
        };
        newSections[1].fields[0].onItemAdd = (newItem) => {
            const newList = cloneDeep(values.products);
            newList.unshift(...newItem);
            changeHandler("products", newList);
        };
        newSections[1].fields[0].onItemRemove = (removedItem) => {
            const removedId = removedItem.map(({ id }) => id);
            const newList = values.products.filter(({ id }) => !removedId.includes(id));
            changeHandler("products", newList);
        };
        setFormSections(newSections);
    }, [values]);

    const clearValues = () => {
        setValues({
            updateTarget: "brand",
            updateValue: null,
            products: [],
        });
        setErrors({});
    };

    const handleSubmit = async () => {
        const [errMessage, errFields] = validateSubmission(values);
        if (errMessage) {
            showSnackBar(errMessage, "error");
            setErrors(errFields);
            return;
        }

        const { updateTarget, updateValue, products } = values;
        const proceed = window.confirm(
            `Proceed to set the ${updateTarget} of ${products.length} products to ${updateValue.label}?`,
        );
        if (!proceed) return;

        setSubmitting(true);
        const result = await bulkAmendProduct({
            updateTarget,
            targetId: updateValue.id,
            productIds: products.map(({ id }) => id),
        });
        setSubmitting(false);
        if (result?.success) {
            showSnackBar(`${products.length} products successfully updated`, "success");
            clearValues();
        }
    };

    return (
        <PageContainer>
            <h1>Amend Products Information</h1>
            <InfoText>
                This section is designed for admin to change product categories and brands for merchants.
            </InfoText>
            <Grid>
                <FormSection>
                    <Form
                        sections={formSections}
                        values={values}
                        errors={errors}
                        defaultChangeHandler={changeHandler}
                    />
                </FormSection>
                <PanelSection>
                    <RightPanel>
                        <FormButton cvariant="contained" ccolor="blue" disabled={submitting} onClick={handleSubmit}>
                            {submitting ? "Loading . . . " : "Submit"}
                        </FormButton>
                        <FormButton cvariant="text" ccolor="blue" onClick={clearValues}>
                            Clear All
                        </FormButton>
                    </RightPanel>
                </PanelSection>
            </Grid>
        </PageContainer>
    );
}

import { Divider, styled } from "@material-ui/core";
import { FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import { TextSelection } from "components/Global/Form/NormalSelect";
import UncontrolledToggle from "components/Global/Form/Toggle/UncontrolledToggle";
import { DEFAULT_DAYS_TO_SHIP } from "constants/shipment";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import MPS_MultiRTE from "./MPS_MultiRTE";

const Row = styled("div")({
    display: "flex",
    alignItems: "center",
    margin: "15px 0",
    alignItems: "flex-start",
    "& .operating_days": { padding: "9px 0" },
    "& div:nth-child(1)": { width: 235 },
});

const store_lang = [
    { id: 1, name: "Traditional Chinese", code: "shipment_return_policy_chinese" },
    { id: 2, name: "English", code: "shipment_return_policy_english" },
];

export default ({ draft, initData, product_id, handleSubmitPayload }) => {
    const methods = useForm();

    const handleSelection = (e) => {
        handleSubmitPayload({
            days_to_ship_from: Number(e.target.value?.split(",")?.[0]),
            days_to_ship_to: Number(e.target.value?.split(",")?.[1]),
        });
    };

    const handleToggle = (e) => {
        handleSubmitPayload({ [e.target.name]: Number(e.target.checked) });
    };

    useEffect(() => {
        handleSubmitPayload({ shipment_ship_to_local_only: 1 });
    }, []);

    return (
        <FormCategory>
            <Row className="full flex align-items-center">
                <FormText className="w-2">
                    <p className="subtitle1">Day to ship</p>
                </FormText>
                <TextSelection
                    name="shipment_day_to_ship"
                    shipment
                    data={DEFAULT_DAYS_TO_SHIP}
                    defaultValue={`${draft?.spu?.days_to_ship_from},${draft?.spu?.days_to_ship_to}`}
                    onChange={handleSelection}
                />
            </Row>
            <Row className="full flex align-items-center">
                <FormText className="w-2">
                    <p className="subtitle1">Ship to Local Only</p>
                </FormText>
                <UncontrolledToggle
                    mode={1}
                    onChange={handleToggle}
                    defaultValue={1}
                    name="shipment_ship_to_local_only"
                    value="shipment_ship_to_local_only"
                    disabled={true}
                />
            </Row>
            <Divider style={{ width: "100%" }} />
            <FormText className="w-10">
                <p className="subtitle1">Delivery & Return Policy</p>
            </FormText>
            <div className="full">
                <MPS_MultiRTE
                    draft={draft}
                    product_id={product_id}
                    options={store_lang}
                    defaultstate={store_lang[0].code}
                    action={(e) => handleSubmitPayload(e)}
                />
            </div>
        </FormCategory>
    );
};

export default () => {
    return (
        <div className="sub">
            <h5>Notes</h5>
            <p>
                This is the general delivery & return policy applying to your products. It helps you to create product
                easily by providing a default value.
            </p>
        </div>
    );
};

import { YohoIcon } from "components/Global";

export const merchantroute = (url) => {
    switch (url) {
        case "products":
            return {
                name: "Product",
                path: "/products",
                icon: <YohoIcon src="/assets/icon/drawer/product.svg" width="25.2px" height="28px" />,
                sub: [
                    { name: "All Product", path: "/products/list" },
                    { name: "Add New Product", path: "/products/add" },
                ],
            };
        case "orders":
            return {
                name: "Orders",
                path: "/orders",
                icon: (
                    <YohoIcon
                        src="/assets/icon/drawer/order.svg"
                        width="23.3px"
                        height="28px"
                        mask={{ size: "25px 28px", repeat: "no-repeat", position: "center" }}
                    />
                ),
            };
        case "settings":
            return {
                name: "Setting",
                path: "/settings",
                icon: <YohoIcon src="/assets/icon/drawer/setting.svg" width="34.66px" height="28px" />,
                sub: [
                    { name: "Store Management", path: "/settings/store" },
                    { name: "Shipping Management", path: "/settings/shipping" },
                    { name: "Account Management", path: "/settings/account" },
                    { name: "Store Page", path: "/settings/account" },
                ],
            };
        case "merchant":
            return {
                name: "Merchant",
                path: "/merchant",
                icon: <YohoIcon src="/assets/icon/drawer/fulfilment.svg" width="36.52px" height="28px" />,
            };
        default:
            return false;
    }
};

export const adminroute = (url) => {
    switch (url) {
        case "application":
            return {
                name: "Application",
                path: "/admin/application",
                icon: <YohoIcon src="/assets/icon/drawer/application.svg" width="36.52px" height="28px" />,
                state: "pending",
            };
        case "merchant":
            return {
                name: "Merchant",
                path: "/admin/merchant",
                icon: <YohoIcon src="/assets/icon/drawer/merchant.svg" width="36.52px" height="28px" />,
            };
        case "products":
            return {
                name: "Product",
                path: "/admin/products",
                icon: <YohoIcon src="/assets/icon/drawer/product.svg" width="25.2px" height="28px" />,
            };
        default:
            return false;
    }
};

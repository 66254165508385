export const DEFAULT_ADMIN_MENU = [
    {
        name: "Product",
        path: "/admin/products-amend",
        icon: {
            key: null,
            ref: null,
            props: {
                src: "/assets/icon/drawer/product.svg",
                width: "25.2px",
                height: "28px",
            },
            _owner: null,
            _store: {},
        },
    },
    {
        name: "Application",
        path: "/admin/application",
        icon: {
            key: null,
            ref: null,
            props: {
                src: "/assets/icon/drawer/application.svg",
                width: "36.52px",
                height: "28px",
            },
            _owner: null,
            _store: {},
        },
    },
    {
        name: "Merchant",
        path: "/admin/merchant",
        icon: {
            key: null,
            ref: null,
            props: {
                src: "/assets/icon/drawer/merchant.svg",
                width: "36.52px",
                height: "28px",
            },
            _owner: null,
            _store: {},
        },
    },
];

export const DEFAULT_MERCHANT_MENU = [
    {
        name: "Product",
        path: "/products",
        icon: {
            key: null,
            ref: null,
            props: {
                src: "/assets/icon/drawer/product.svg",
                width: "25.2px",
                height: "28px",
            },
            _owner: null,
            _store: {},
        },
        sub: [
            {
                name: "All Product",
                path: "/products/list",
            },
            {
                name: "Add New Product",
                path: "/products/add",
            },
        ],
    },
    {
        name: "Promotion Tools",
        path: "/promotion-tools",
        icon: {
            key: null,
            ref: null,
            props: {
                src: "/assets/icon/drawer/promotion-tool.svg",
                width: "30px",
                height: "30px",
            },
            _owner: null,
            _store: {},
        },
        sub: [
            {
                name: "Promotion Information",
                path: "/promotion/promotion-info",
            },
            {
                name: "Bulk Discount Promotion",
                path: "/promotion/bulk-discount",
            },
            {
                name: "On Sale Promotion",
                path: "/promotion/on-sale-promotion",
            },
            {
                name: "Points Reward Program",
                path: "/promotion/points-reward",
            },
        ],
    },
    {
        name: "Orders",
        path: "/orders",
        icon: {
            key: null,
            ref: null,
            props: {
                src: "/assets/icon/drawer/order.svg",
                width: "23.3px",
                height: "28px",
                mask: {
                    size: "25px 28px",
                    repeat: "no-repeat",
                    position: "center",
                },
            },
            _owner: null,
            _store: {},
        },
    },
    {
        name: "Setting",
        path: "/settings",
        icon: {
            key: null,
            ref: null,
            props: {
                src: "/assets/icon/drawer/setting.svg",
                width: "34.66px",
                height: "28px",
            },
            _owner: null,
            _store: {},
        },
        sub: [
            {
                name: "Store Page",
                path: "/settings/store-page",
            },
            {
                name: "Store Banners",
                path: "/settings/store-banner",
            },
            {
                name: "Store Category",
                path: "/settings/store-page-category",
            },
            {
                name: "Business Settings",
                path: "/settings/store",
            },
            {
                name: "Shipping Management",
                path: "/settings/shipping",
            },
            {
                name: "Account Management",
                path: "/settings/account",
            },
            {
                name: "Sales Summary",
                path: "/settings/sales-summary",
            },
        ],
    },
];

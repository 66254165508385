import { useState } from "react";
import { TextField, makeStyles, Chip } from "@material-ui/core";
import { useShowSnackbar } from "service/apis/merchant/utilHook";

const useStyles = makeStyles((theme) => ({
    chip: {
        backgroundColor: theme.yoho.md_color.blue[900],
        color: theme.yoho.color.white,
        marginRight: "8px",
        marginBottom: "4px",
        "& .MuiChip-deleteIcon": { color: "unset" },
    },
    chipSection: {
        marginTop: "10px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
}));

const separators = ["Enter", ","];

function MultipleTag({ onChange, tagList, name, label, error = "", tagLenLimit = 25 }) {
    const classes = useStyles();
    const [value, setValue] = useState("");
    const showSnackBar = useShowSnackbar();

    const handleClickEnter = (event) => {
        if (separators.includes(event.key)) {
            if (tagList.find((record) => record === value)) {
                showSnackBar("This tag already exists.", "error");
            } else if (value.length > tagLenLimit) {
                showSnackBar(`Tag length cannot be longer than ${tagLenLimit} characters.`, "error");
            } else if (value) {
                onChange({ [name]: [...tagList, value] });
            }
            event.preventDefault();
            setValue("");
        }
    };

    const deleteLabel = (option) => {
        onChange({ [name]: tagList.filter((element) => element !== option) });
    };

    return (
        <div>
            <TextField
                label={label}
                name={name}
                fullWidth
                variant="outlined"
                value={value}
                error={!!error}
                helperText={error}
                onKeyDown={(event) => handleClickEnter(event)}
                onChange={(event) => setValue(event.target.value)}
            />
            <div className={classes.chipSection}>
                {tagList?.length > 0 &&
                    tagList?.map((option) => (
                        <Chip
                            key={option}
                            className={classes.chip}
                            label={option}
                            onDelete={() => deleteLabel(option)}
                        />
                    ))}
            </div>
        </div>
    );
}

export default MultipleTag;

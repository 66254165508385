import { Divider, styled } from "@material-ui/core";
import { FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import { TextSelection } from "components/Global/Form/NormalSelect";
import UncontrolledToggle from "components/Global/Form/Toggle/UncontrolledToggle";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setResellProductDraftField } from "service/actions/merchant";
import { DEFAULT_DAYS_TO_SHIP } from "constants/shipment";
import MPS_MultiRTE from "./MPS_MultiRTE";

const Row = styled("div")({
    display: "flex",
    alignItems: "center",
    margin: "15px 0",
    alignItems: "flex-start",
    "& .operating_days": { padding: "9px 0" },
    "& div:nth-child(1)": { width: 235 },
});

const store_lang = [
    { id: 1, name: "Traditional Chinese", code: "shipment_return_policy_chinese" },
    { id: 2, name: "English", code: "shipment_return_policy_english" },
];

export default () => {
    const methods = useForm();
    const draft = useSelector((state) => state.merchant.product.selected);
    const dispatch = useDispatch();

    const handleSelection = (e) => {
        dispatch(
            setResellProductDraftField({
                days_to_ship_from: Number(e.target.value?.split(",")?.[0]),
                days_to_ship_to: Number(e.target.value?.split(",")?.[1]),
            }),
        );
    };

    const handleToggle = (e) => {
        dispatch(setResellProductDraftField({ [e.target.name]: Number(e.target.checked) }));
    };

    useEffect(() => {
        dispatch(setResellProductDraftField({ shipment_ship_to_local_only: 1 }));
    }, []);

    return (
        <div>
            <FormProvider {...methods}>
                <form>
                    <FormCategory>
                        <Row className="full flex align-items-center">
                            <FormText className="w-2">
                                <p className="subtitle1">Day to ship</p>
                            </FormText>
                            <TextSelection
                                name="shipment_day_to_ship"
                                shipment
                                data={DEFAULT_DAYS_TO_SHIP}
                                defaultValue={`${draft?.data?.spu?.days_to_ship_from || DEFAULT_DAYS_TO_SHIP?.[0]?.value?.split(",")?.[0]},${draft?.data?.spu?.days_to_ship_to || DEFAULT_DAYS_TO_SHIP?.[0]?.value?.split(",")?.[1]}`}
                                onChange={handleSelection}
                            />
                        </Row>
                        <Row className="full flex align-items-center">
                            <FormText className="w-2">
                                <p className="subtitle1">Ship to Local Only</p>
                            </FormText>
                            <UncontrolledToggle
                                mode={1}
                                onChange={handleToggle}
                                defaultValue={1}
                                name="shipment_ship_to_local_only"
                                value="shipment_ship_to_local_only"
                                disabled={true}
                            />
                        </Row>
                        <Divider style={{ width: "100%" }} />
                        <FormText className="w-10">
                            <p className="subtitle1">Delivery & Return Policy</p>
                        </FormText>
                        <div className="full">
                            <MPS_MultiRTE
                                options={store_lang}
                                defaultstate={store_lang[0].code}
                                action={(e) => dispatch(setResellProductDraftField(e))}
                            />
                        </div>
                    </FormCategory>
                </form>
            </FormProvider>
        </div>
    );
};

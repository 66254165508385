import { styled } from "@material-ui/core";
import { FormButton } from "components/Global";
import usePopup from "hooks/usePopup";
import React from "react";
import { useDispatch } from "react-redux";
import { changeStatusEditProductSKU } from "service/actions/merchant";
import VariationsEditPopup from "../popup/EditSKUPopup";

const ActionCustomColumn = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 6,
    alignItems: "center",
});

export default ({ rowData }) => {
    const dispatch = useDispatch();
    const popup = usePopup();
    const handleEdit = () => {
        popup(VariationsEditPopup, {
            title: "Edit Variation",
            id: rowData.goods_id,
        });
    };

    return (
        <ActionCustomColumn>
            <FormButton height={28} width={75} ccolor="blue" cvariant="contained" onClick={handleEdit}>
                Edit
            </FormButton>
            <FormButton
                height={28}
                width={75}
                ccolor="blue"
                cvariant="outlined"
                onClick={() =>
                    dispatch(
                        changeStatusEditProductSKU({
                            listed: !(rowData.status === "listed"),
                            sku_id: rowData.goods_id,
                        }),
                    )
                }
            >
                {rowData.status === "listed" ? "Unlist" : "List"}
            </FormButton>
        </ActionCustomColumn>
    );
};

import { styled } from "@material-ui/core";
import { Cancel, CloudUploadOutlined } from "@material-ui/icons";
import { useState } from "react";

const UploadButtonContainer = styled("div")({});

const UploadButton = styled("div")({
    display: "flex",
    gap: "10px",
});

const Button = styled("label")({
    width: "200px",
    height: "44px",
    borderRadius: "26px",
    border: "1px solid #00ACEE",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    justifyContent: "center",
    color: "#00ACEE",
    cursor: "pointer",
    transition: "all 0.3s ease",
    "&:hover": {
        backgroundColor: "#E1F5FD",
    },
    "&:active": {
        backgroundColor: "#00ACEE",
        color: "white",
    },
    "& > input": {
        display: "none",
    },
});

const UploadText = styled("p")({
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "24px",
});

const TextInfo = styled("p")(({ file }) => ({
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: file ? "700" : "400",
    color: file ? "#00ACEE" : "#bdbdbd",
    display: "flex",
    gap: "8px",
    "& svg": {
        color: "#424242",
        cursor: "pointer",
    },
}));

const ErrorText = styled("p")({
    color: "#f44336",
    fontSize: 14,
    fontWeight: 400,
});

export default ({ className, inputRef, name, setError, errors, clearErrors, maxSize }) => {
    const [file, setFile] = useState(null);
    const handleFileUpload = (event) => {
        if (maxSize && event.target.files[0]?.size >= maxSize) {
            setError(name, { message: `File size must be smaller than ${Math.floor(maxSize / 1024 / 1024)} MB.` });
            return;
        }

        if (
            event.target.files[0]?.type !== "application/pdf" &&
            event.target.files[0]?.type !== "image/jpeg" &&
            event.target.files[0]?.type !== "image/png"
        ) {
            setError(name, { message: "Attachment must be JPG, PNG or PDF format" });
            event.target.value = "";
            return;
        }

        setFile(event.target.files[0].name);
        clearErrors(name);
    };

    return (
        <UploadButtonContainer className={className}>
            <UploadButton>
                <Button htmlFor="fileupload" onChange={handleFileUpload}>
                    <CloudUploadOutlined />
                    <UploadText>File Upload</UploadText>
                    <input type="file" id="fileupload" name={name} accept=".jpg,.jpeg,.png,.pdf" ref={inputRef} />
                </Button>
                <TextInfo file={file}>
                    {file ? (
                        <>
                            {file}{" "}
                            <Cancel
                                onClick={() => {
                                    setFile(null);
                                }}
                            />
                        </>
                    ) : (
                        "No file selected"
                    )}
                </TextInfo>
            </UploadButton>
            <TextInfo>
                Please upload a scanned copy of your business registration document in one of the image formats: JPG,
                PNG or PDF.
            </TextInfo>
            {errors?.document_url && <ErrorText>{errors?.document_url?.message}</ErrorText>}
        </UploadButtonContainer>
    );
};

import { styled } from "@material-ui/core";
import { FormText } from "components/Global/Form";

const BuyerNotes = styled("div")(({ theme }) => ({
    gridColumn: "1/13",
    gridRow: "2/3",
    background: theme.yoho.md_color.yellow[50],
    border: "1px solid" + theme.yoho.md_color.yellow[100],
    padding: 15,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    gap: 10,
}));

export default ({ data }) => {
    return (
        <BuyerNotes>
            <FormText color="yellow" code={800}>
                <p className="subtitle1">Customer Remarks</p>
            </FormText>
            <FormText>
                <p className="body2">{data.postscript} </p>
            </FormText>
        </BuyerNotes>
    );
};

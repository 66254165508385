import { DialogContent, DialogTitle, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import { UncontrolledNormalSelect } from "components/Global/Form/NormalSelect";
import Popup from "components/Global/Layout/popup";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { unsetPopup } from "service/actions/site";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const PopupWrapper = styled("div")({
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    "& img": {
        paddingTop: "20px",
        alignSelf: "center",
    },
});

const CustomApproveDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "0 200px",
    padding: "30px 0 0 0",
    "& div:nth-child(1)": {
        marginBottom: "50px",
    },
    "& div:nth-child(3)": {
        marginBottom: "12px",
    },
}));

const CustomDenyDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
    margin: "30px",
    "& > .button": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));

const CustomApproveFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "50px",
});

export const ApprovedPopup = ({ action, data }) => {
    const dispatch = useDispatch();
    const {
        application_id,
        company_name_chinese,
        company_name_english,
        user: {
            company_id,
            first_name,
            last_name,
            contact_international_code_id: { dial_code },
            personal_email,
            contact_number,
            personal_title_id: { name },
        },
    } = data;
    return (
        <Popup size="md" fullWidth>
            <PopupWrapper>
                <img src="/assets/icon/SuccessOutlineGreen.svg" width="40px" />
                <CustomDialogTitle>
                    <p>Application is approved!</p>
                    <p>Merchant account has been created and is ready for use.</p>
                </CustomDialogTitle>
                <CustomApproveFormButton ccolor="blue" cvariant="contained" autoFocus onClick={action} color="primary">
                    Understood
                </CustomApproveFormButton>
                <CustomApproveDialogContent>
                    <FormText>
                        <p className="body1">
                            Application# <strong>{application_id}</strong>
                        </p>
                    </FormText>
                    <FormText>
                        <p className="body1">
                            Approval Date: <strong>{moment().format("MMM DD, YYYY hh:mm")}</strong>
                        </p>
                    </FormText>
                    <FormText>
                        <h4>Company Name</h4>
                    </FormText>
                    <FormText>
                        <h4>Contact</h4>
                    </FormText>
                    <FormText>
                        <h6>{company_name_chinese}</h6>
                        <h6>{company_name_english}</h6>
                    </FormText>
                    <FormText>
                        <h6>
                            {name}. {first_name} {last_name}
                        </h6>
                        <h6>{personal_email}</h6>
                        <h6>
                            {dial_code} {contact_number}
                        </h6>
                    </FormText>
                </CustomApproveDialogContent>
            </PopupWrapper>
        </Popup>
    );
};

const SelectItem = [
    { name: "Duplicate application", value: "Duplicate application" },
    { name: "Bad public reputation", value: "Bad public reputation" },
    { name: "Lack of proper documents", value: "Lack of proper documents" },
    { name: "Other", value: "Other" },
];

export const DenyPopup = ({ action }) => {
    const [select, setSelect] = useState(undefined);
    const remarkRef = useRef();
    const dispatch = useDispatch();

    return (
        <Popup size="md" fullWidth>
            <CustomDialogTitle>Deny application</CustomDialogTitle>
            <CustomDenyDialogContent>
                <FormCategory>
                    <UncontrolledNormalSelect
                        placeholder="Reason of decline"
                        className="half"
                        variant="outlined"
                        name="reason"
                        onChange={setSelect}
                        value={select?.name}
                        data={SelectItem}
                    />
                </FormCategory>
                <FormCategory>
                    <FormText className="w-2 self-center">
                        <p className="subtitle1">Remark to applicant</p>
                    </FormText>
                    <TextField
                        inputRef={remarkRef}
                        disabled={select?.reason !== "Other"}
                        className="w-34"
                        placeholder="Optional"
                        variant="outlined"
                    />
                </FormCategory>
                <div className="button">
                    <FormButton
                        ccolor="blue"
                        cvariant="contained"
                        onClick={() =>
                            action({
                                ...(select?.reason === "Other"
                                    ? { remarks_to_applicant: remarkRef.current.value }
                                    : { reason_of_declined: select?.reason }),
                            })
                        }
                    >
                        Submit
                    </FormButton>
                    <FormButton ccolor="blue" cvariant="text" onClick={() => dispatch(unsetPopup())}>
                        later
                    </FormButton>
                </div>
            </CustomDenyDialogContent>
        </Popup>
    );
};

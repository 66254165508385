import { styled } from "@material-ui/core";
import { FormCategory } from "components/Global";
import { UncontrolledNormalSelect } from "components/Global/Form/NormalSelect";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAccountPrivilege } from "service/actions/merchant";

const Table = styled("div")({
    display: "grid",
    borderCollapse: "collapse",
    minWidth: "100%",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "20px 0",
});

const Thead = styled("div")(({ theme }) => ({
    padding: "10px",
    fontSize: "12px",
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
    borderBottom: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

const Trow = styled("div")(({ variant, theme }) => ({
    ...(variant === "bold" && { ...theme.yoho.typography.subtitle2 }),
    alignSelf: "center",
}));

export default () => {
    const accountsetting = useSelector((state) => state.merchant.setting.account);
    const dispatch = useDispatch();
    const memoizeAccountPermission = useMemo(() => {
        let AccPermission = accountsetting.permission,
            newAccPermission,
            matrixExludeName = ["Sales Report", "Fulfilment"],
            roleExludeName = ["Administrator"];

        newAccPermission = {
            ...AccPermission,
            roles: AccPermission.roles.filter((role) => !roleExludeName.includes(role.name)),
            routes: AccPermission.routes.filter((route) => !matrixExludeName.includes(route.name)),
            matrixs: AccPermission.matrixs.filter((matrix) => !matrixExludeName.includes(matrix.route_name)),
        };
        return newAccPermission;
    }, [accountsetting]);

    const handleChange = (props) => {
        const [role, route] = Object.keys(props)[0]?.split(".");
        const exist = memoizeAccountPermission?.matrixs?.find(
            (item) =>
                item.route_name === route &&
                item.role_id === memoizeAccountPermission?.roles?.find((rls) => rls.name === role)?.id,
        );
        dispatch(
            updateAccountPrivilege({
                ...exist,
                role_id: memoizeAccountPermission?.roles.find((t) => t.name === role)?.id,
                route_id: memoizeAccountPermission?.routes.find((r) => r.name === route)?.id,
                access_id: Object.values(props)[0],
            }),
        );
    };

    return (
        <div>
            <FormCategory>
                <Table>
                    <Thead>Menu / Role</Thead>
                    {/* <Thead>Administrator</Thead> */}
                    <Thead>Sales Manager</Thead>
                    <Thead>Operation Manager</Thead>
                    <Thead>Finance Manager</Thead>
                    {memoizeAccountPermission?.routes?.map((route_name) => (
                        <React.Fragment key={route_name.id}>
                            <Trow key={route_name.name}>{route_name.name}</Trow>
                            {memoizeAccountPermission?.roles?.map((role_name) => (
                                <UncontrolledNormalSelect
                                    name={`${role_name.name}.${route_name.name}`}
                                    itemValue="id"
                                    data={memoizeAccountPermission?.access}
                                    key={`${route_name.id}_${role_name.id}`}
                                    keyz={`${route_name.id}_${role_name.id}`}
                                    onChange={handleChange}
                                    value={
                                        memoizeAccountPermission?.access?.find(
                                            (item) =>
                                                item?.id ===
                                                memoizeAccountPermission?.matrixs?.find(
                                                    (matrix) =>
                                                        matrix.route_id === route_name.id &&
                                                        matrix.role_id === role_name.id,
                                                )?.access_id,
                                        )?.name ||
                                        memoizeAccountPermission?.access?.find((item) => item?.access === "na")?.name
                                    }
                                />
                            ))}
                        </React.Fragment>
                    ))}
                </Table>
            </FormCategory>
        </div>
    );
};

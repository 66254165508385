import { styled } from "@material-ui/core";
import { FormButton } from "components/Global";

export const ListRow = styled("div")((props) => ({
    display: "flex",
    justifyContent: "flex-stat",
    "& .title": {
        display: "flex",
        gap: 50,
        alignItems: "center",
    },
    "& .filter": {
        display: "flex",
        gap: 20,
    },
    "& .show": {
        display: "flex",
        alignItems: "center",
        gap: 10,
    },
}));

export const CustomButton = styled(FormButton)({
    height: 36,
    width: "fit-content",
});

export const AdminProductContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 20,
    gap: 20,
});

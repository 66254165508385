import { styled } from "@material-ui/core";
import { FormButton } from "components/Global";
import usePopup from "hooks/usePopup";
import React from "react";
import { useDispatch } from "react-redux";
import { deleteProductVariations } from "service/actions/merchant";
import VariationsEditPopup from "../popup/VariationsEditPopup";

const ActionCustomColumn = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "5px",
});

export default ({ canDelete, ...rowData }) => {
    const dispatch = useDispatch();
    const popup = usePopup();

    return (
        <ActionCustomColumn>
            <FormButton
                height={28}
                width={75}
                ccolor="blue"
                cvariant="contained"
                onClick={() => {
                    popup(VariationsEditPopup, {
                        title: "Edit Variation",
                        id: rowData.goods_id,
                    });
                }}
            >
                Edit
            </FormButton>
            {canDelete && (
                <FormButton
                    height={28}
                    width={75}
                    ccolor="red"
                    cvariant="outlined"
                    onClick={() => dispatch(deleteProductVariations({ goodsId: rowData.goods_id, mode: "add" }))}
                >
                    Delete
                </FormButton>
            )}
        </ActionCustomColumn>
    );
};

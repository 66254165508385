import { FilterSelection } from "components/Global/Form/NormalSelect";
import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import Header from "components/MerchantCentre/Header";
import Active from "./Active";
import Inactive from "./Inactive";

const data = [
    { name: "Store Name", value: "search" },
    { name: "Contact Person", value: "search1" },
    { name: "Contact Number", value: "search2" },
];
const FilterSearch = ({ search, setSearch }) => {
    return (
        <div>
            <FilterSelection data={data} value={search} onChange={setSearch} />
        </div>
    );
};
export default ({
    active,
    setActive,
    search,
    setSearch,
    startPosition,
    setStartPosition,
    limit,
    setLimit,
    isContentLoading,
    setIsContentLoading,
}) => {
    return (
        <Container>
            <div className="main">
                <Header
                    menu={["active", "inactive"]}
                    active={active}
                    setActive={setActive}
                    setStartPosition={setStartPosition}
                    setIsContentLoading={setIsContentLoading}
                    title="Merchants"
                />
                <FilterSearch search={search} setSearch={setSearch} />
                <Content>
                    {{
                        active: (
                            <Active
                                active={active}
                                search={search}
                                startPosition={startPosition}
                                setStartPosition={setStartPosition}
                                limit={limit}
                                setLimit={setLimit}
                                isContentLoading={isContentLoading}
                                setIsContentLoading={setIsContentLoading}
                            ></Active>
                        ),
                        inactive: (
                            <Inactive
                                active={active}
                                search={search}
                                startPosition={startPosition}
                                setStartPosition={setStartPosition}
                                limit={limit}
                                setLimit={setLimit}
                                isContentLoading={isContentLoading}
                                setIsContentLoading={setIsContentLoading}
                            ></Inactive>
                        ),
                    }[active] || <div>Page is still under construction</div>}
                </Content>
            </div>
        </Container>
    );
};

export const MERCHANT = {
    INIT: {
        GET: "MERCHANT_INIT_GET",
    },
    LOADING: {
        SET: "MERCHANT_LOADING_SET",
        RESET: "MERCHANT_LOADING_RESET",
    },
    SETTING: {
        LOADING: {
            RESET: "MERCHANT_SETTING_LOADING_RESET",
        },
        ACCOUNT: {
            GET: "MERCHANT_SETTING_ACCOUNT_GET",
            SET: "MERCHANT_SETTING_ACCOUNT_SET",
            PRIVILEGE: {
                UPDATE: "MERCHANT_SETTING_ACCOUNT_PRIVILEGE_UPDATE",
            },
        },
        STORE: {
            GET: "MERCHANT_SETTING_STORE_GET",
            SET: "MERCHANT_SETTING_STORE_SET",
            GENERAL: {
                SET: "MERCHANT_SETTING_STORE_GENERAL_SET",
            },
            CONTACT: {
                SET: "MERCHANT_SETTING_STORE_CONTACT_SET",
            },
            WARRANTY: {
                SELECT: "MERCHANT_SETTING_STORE_WARRANTY_SELECT",
                POST: "MERCHANT_SETTING_STORE_WARRANTY_POST",
                SET: "MERCHANT_SETTING_STORE_WARRANTY_SET",
            },
        },
        SHIPPING: {
            GET: "MERCHANT_SETTING_SHIPPING_GET",
            SET: "MERCHANT_SETTING_SHIPPING_SET",
            GENERAL: {
                SET: "MERCHANT_SETTING_SHIPPING_GENERAL_SET",
                UPDATE: "MERCHANT_SETTING_SHIPPING_GENERAL_UPDATE",
            },
            SHIPPINGMETHOD: {
                SET: "MERCHANT_SETTING_SHIPPING_SHIPPINGMETHOD_SET",
                UPDATE: "MERCHANT_SETTING_SHIPPING_SHIPPINGMETHOD_UPDATE",
            },
            TABLERATES: {
                SET: "MERCHANT_SETTING_SHIPPING_TABLERATES_SET",
                UPDATE: "MERCHANT_SETTING_SHIPPING_TABLERATES_UPDATE",
                CREATE: "MERCHANT_SETTING_SHIPPING_TABLERATES_CREATE",
            },
            DELIVERYRETURNPOLICY: {
                SET: "MERCHANT_SETTING_SHIPPING_DELIVERYRETURNPOLICY_SET",
                UPDATE: "MERCHANT_SETTING_SHIPPING_DELIVERYRETURNPOLICY_UPDATE",
            },
        },
    },
    PROFILE: {
        SET: "MERCHANT_PROFILE_SET",
        PASSWORD: "MERCHANT_PROFILE_PASSWORD_UPDATE",
        UPDATE: "MERCHANT_PROFILE_UPDATE",
    },
    PRODUCT: {
        CREATE: {
            SELECT: "MERCHANT_PRODUCT_CREATE_SELECTED",
        },
        CHECKBOX: {
            SET: "MERCHANT_PRODUCT_CHECKBOX_SET",
        },
        PARAM: {
            SET: "MERCHANT_PRODUCT_PARAM_SET",
            CLEAR: "MERCHANT_PRODUCT_PARAM_CLEAR",
        },
        LIST: {
            SEARCH: "MERCHANT_PRODUCT_LIST_SEARCH",
            GET: "MERCHANT_PRODUCT_LIST_GET",
            SET: "MERCHANT_PRODUCT_LIST_SET",
        },
        SEARCH: {
            GET: "MERCHANT_PRODUCT_SEARCH_GET",
            SET: "MERCHANT_PRODUCT_SEARCH_SET",
            CLEAR: "MERCHANT_PRODUCT_SEARCH_CLEAR",
            FIND: {
                INIT: "MERCHANT_PRODUCT_SEARCH_FIND_INIT",
                // LOAD: "MERCHANT_PRODUCT_SEARCH_FIND_LOAD",
                SET: "MERCHANT_PRODUCT_SEARCH_FIND_SET",
                SELECTED: "MERCHANT_PRODUCT_SEARCH_FIND_SELECTED",
            },
            TEMPLATE: {
                SELECT: "MERCHANT_PRODUCT_SEARCH_SELECT_TEMPLATE",
                SET: "MERCHANT_PRODUCT_SEARCH_SET_TEMPLATE",
            },
        },
        CONFIG: {
            GET: "MERCHANT_PRODUCT_CONFIG_GET",
            SET: "MERCHANT_PRODUCT_CONFIG_SET",
            ATTRIBUTE_GET: "MERCHANT_PRODUCT_CONFIG_ATTRIBUTE_GET",
        },
        SUBCATEGORY: {
            GET: "MERCHANT_PRODUCT_SUBCATEGORY_GET",
        },
        SUBSUBCATEGORY: {
            GET: "MERCHANT_PRODUCT_SUBSUBCATEGORY_GET",
        },
        VARIATION: {
            INIT: "MERCHANT_PRODUCT_VARIATION_INIT",
            POST: "MERCHANT_PRODUCT_VARIATION_POST",
            GET: "MERCHANT_PRODUCT_VARIATION_GET",
            SET: "MERCHANT_PRODUCT_VARIATION_SET",
            TERMS: {
                GET: "MERCHANT_PRODUCT_VARIATION_TERMS_GET",
                POST: "MERCHANT_PRODUCT_VARIATION_TERMS_POST",
                SET: "MERCHANT_PRODUCT_VARIATION_TERMS_SET",
            },
        },
        DRAFT: {
            SET: "MERCHANT_PRODUCT_DRAFT_SET",
            SALE: {
                SET: "MERCHANT_PRODUCT_DRAFT_SALE_SET",
            },
            VARIATION: {
                SET: "MERCHANT_PRODUCT_DRAFT_VARIATION_SET",
                UNSET: "MERCHANT_PRODUCT_DRAFT_VARIATION_UNSET",
                ORDER: "MERCHANT_PRODUCT_DRAFT_VARIATION_ORDER",
                TERMS: {
                    GET: "MERCHANT_PRODUCT_DRAFT_VARIATION_TERMS_GET",
                    SET: "MERCHANT_PRODUCT_DRAFT_VARIATION_TERMS_SET",
                    DELETE: "MERCHANT_PRODUCT_DRAFT_VARIATION_TERMS_DELETE",
                },
                DELETE: {
                    SET: "MERCHANT_PRODUCT_DRAFT_VARIATION_DELETE_GET",
                    PUBLISH: "MERCHANT_PRODUCT_DRAFT_VARIATION_DELETE_PUBLISH",
                    PUBLISHED: "MERCHANT_PRODUCT_DRAFT_VARIATION_DELETE_PUBLISHED",
                },
            },
            BULK_ACTION: {
                SET: "MERCHANT_PRODUCT_EDIT_DRAFT_BULK_ACTION_SET",
            },
        },
        POST: "MERCHANT_PRODUCT_POST",
        POSTED: "MERCHANT_PRODUCT_POSTED",
        UPDATE: "MERCHANT_PRODUCT_UPDATE",
        UPDATED: "MERCHANT_PRODUCT_UPDATED",
        DELETE: "MERCHANT_PRODUCT_DELETE",
        DELETED: "MERCHANT_PRODUCT_DELETED",
        ONSALE: {
            DRAFT: "MERCHANT_PRODUCT_ONSALE_DRAFT",
            CLEAR_DRAFT: "MERCHANT_PRODUCT_ONSALE_CLEAR_DRAFT",
            PUBLISH: "MERCHANT_PRODUCT_ONSALE_PUBLISH",
            PUBLISHED: "MERCHANT_PRODUCT_ONSALE_PUBLISHED",
            LISTING: {
                SET: "MERCHANT_PRODUCT_ONSALE_LISTING_SET",
                PUBLISHED: "MERCHANT_PRODUCT_ONSALE_LISTING_PUBLISHED",
            },
            EDIT: {
                SET: "MERCHANT_PRODUCT_ONSALE_EDIT_SET",
                PUBLISHED: "MERCHANT_PRODUCT_ONSALE_EDIT_PUBLISHED",
            },
        },
        ADD: {
            GET: "MERCHANT_PRODUCT_ADD_GET",
            PROCESS: "MERCHANT_PRODUCT_ADD_PROCESS",
            CONFIRM: "MERCHANT_PRODUCT_ADD_CONFIRM",
            LISTING: "MERCHANT_PRODUCT_ADD_LISTING",
            DRAFT: {
                SET: "MERCHANT_PRODUCT_ADD_DRAFT_SET",
                UPDATE: "MERCHANT_PRODUCT_ADD_UPDATE_DRAFT_SET",
                UPDATED: "MERCHANT_PRODUCT_ADD_UPDATED_DRAFT_SET",
                FIELD: {
                    SET: "MERCHANT_PRODUCT_ADD_DRAFT_FIELD_SET",
                },
                SALE: {
                    SET_ID: "MERCHANT_PRODUCT_ADD_DRAFT_SALE_SET_ID",
                    SET: "MERCHANT_PRODUCT_ADD_DRAFT_SALE_SET",
                },
                BULK_ACTION: {
                    PROCESS: "MERCHANT_PRODUCT_ADD_DRAFT_BULK_ACTION_PROCESS",
                    SET: "MERCHANT_PRODUCT_ADD_DRAFT_BULK_ACTION_SET",
                },
                VARIATION: {
                    CREATE: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_CREATE",
                    SET: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_SET",
                    DELETE: "MERCHANT_PRODUCT_ADD_DRAFT_DELETE",
                    UNSET: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_UNSET",
                    ORDER: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_ORDER",
                    TERMS: {
                        CREATE: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_TERMS_CREATE",
                        CREATED: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_TERMS_CREATED",
                        GET: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_TERMS_GET",
                        CLEAR: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_TERMS_CLEAR",
                        SET: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_TERMS_SET",
                        DELETE: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_TERMS_DELETE",
                    },
                    SKU: {
                        UPDATE: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_SKU_UPDATE",
                        UPDATED: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_SKU_UPDATED",
                        DELETE: {
                            SET: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_SKU_DELETE_GET",
                            PUBLISH: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_SKU_DELETE_PUBLISH",
                            PUBLISHED: "MERCHANT_PRODUCT_ADD_DRAFT_VARIATION_SKU_DELETE_PUBLISHED",
                        },
                    },
                },
            },
        },
        SELECTED: {
            GET: "MERCHANT_PRODUCT_SELECTED_GET",
            PROCESS: "MERCHANT_PRODUCT_SELECTED_PROCESS",
            DRAFT: {
                SET: "MERCHANT_PRODUCT_SELECTED_DRAFT_SET",
                UPDATE: "MERCHANT_PRODUCT_SELECTED_UPDATE_DRAFT_SET",
                UPDATED: "MERCHANT_PRODUCT_SELECTED_UPDATED_DRAFT_SET",
                FIELD: {
                    SET: "MERCHANT_PRODUCT_SELECTED_DRAFT_FIELD_SET",
                },
                SALE: {
                    SET_ID: "MERCHANT_PRODUCT_SELECTED_DRAFT_SALE_SET_ID",
                    SET: "MERCHANT_PRODUCT_SELECTED_DRAFT_SALE_SET",
                },
                BULK_ACTION: {
                    PROCESS: "MERCHANT_PRODUCT_SELECTED_DRAFT_BULK_ACTION_PROCESS",
                    SET: "MERCHANT_PRODUCT_SELECTED_DRAFT_BULK_ACTION_SET",
                },
                VARIATION: {
                    CREATE: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_CREATE",
                    SET: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_SET",
                    UNSET: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_UNSET",
                    ORDER: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_ORDER",
                    TERMS: {
                        CREATE: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_TERMS_CREATE",
                        CREATED: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_TERMS_CREATED",
                        GET: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_TERMS_GET",
                        CLEAR: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_TERMS_CLEAR",
                        SET: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_TERMS_SET",
                        DELETE: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_TERMS_DELETE",
                    },
                    SKU: {
                        UPDATE: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_SKU_UPDATE",
                        UPDATED: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_SKU_UPDATED",
                        DELETE: {
                            SET: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_SKU_DELETE_GET",
                            PUBLISH: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_SKU_DELETE_PUBLISH",
                            PUBLISHED: "MERCHANT_PRODUCT_SELECTED_DRAFT_VARIATION_SKU_DELETE_PUBLISHED",
                        },
                    },
                },
            },
        },
        EDIT: {
            GET: "MERCHANT_PRODUCT_EDIT_GET",
            PROCESS: "MERCHANT_PRODUCT_EDIT_PROCESS",
            DRAFT: {
                SET: "MERCHANT_PRODUCT_EDIT_DRAFT_SET",
                UPDATE: "MERCHANT_PRODUCT_EDIT_UPDATE_DRAFT_SET",
                UPDATED: "MERCHANT_PRODUCT_EDIT_UPDATED_DRAFT_SET",
                FIELD: {
                    SET: "MERCHANT_PRODUCT_EDIT_DRAFT_FIELD_SET",
                },
                SALE: {
                    SET_ID: "MERCHANT_PRODUCT_EDIT_DRAFT_SALE_SET_ID",
                    SET: "MERCHANT_PRODUCT_EDIT_DRAFT_SALE_SET",
                },
                BULK_ACTION: {
                    PROCESS: "MERCHANT_PRODUCT_EDIT_DRAFT_BULK_ACTION_PROCESS",
                    SET: "MERCHANT_PRODUCT_EDIT_DRAFT_BULK_ACTION_SET",
                },
                VARIATION: {
                    CREATE: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_CREATE",
                    SET: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_SET",
                    UNSET: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_UNSET",
                    ORDER: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_ORDER",
                    STATUS: {
                        SET: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_STATUS_SET",
                        UPDATE: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_STATUS_UPDATE",
                    },
                    TERMS: {
                        CREATE: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_TERMS_CREATE",
                        CREATED: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_TERMS_CREATED",
                        GET: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_TERMS_GET",
                        CLEAR: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_TERMS_CLEAR",
                        SET: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_TERMS_SET",
                        DELETE: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_TERMS_DELETE",
                    },
                    SKU: {
                        UPDATE: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_SKU_UPDATE",
                        UPDATED: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_SKU_UPDATED",
                        STATUS: {
                            CHANGE: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_SKU_STATUS_CHANGE",
                            UPDATED: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_SKU_STATUS_UPDATED",
                        },
                        DELETE: {
                            SET: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_SKU_DELETE_GET",
                            PUBLISH: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_SKU_DELETE_PUBLISH",
                            PUBLISHED: "MERCHANT_PRODUCT_EDIT_DRAFT_VARIATION_SKU_DELETE_PUBLISHED",
                        },
                    },
                },
            },
        },
    },
    ORDER: {
        GET_LIST: "MERCHANT_ORDER_GET_LIST",
        SET_LIST: "MERCHANT_ORDER_SET_LIST",
        SET_PARAM: "MERCHANT_ORDER_SET_PARAM",
        GET_DETAIL: "MERCHANT_ORDER_GET_DETAIL",
        SHOW_DETAIL: "MERCHANT_ORDER_SHOW_DETAIL",
        SET_DETAIL: "MERCHANT_ORDER_SET_DETAIL",
        CLEAR_DETAIL: "MERCHANT_ORDER_CLEAR_DETAIL",
        SET_LOGS: "MERCHANT_ORDER_SET_LOGS",
        POST_NOTE: "MERCHANT_ORDER_POST_NOTE",
        UPDATE_SHIPMENT: "MERCHANT_ORDER_UPDATE_SHIPMENT",
        UPDATE_SHIPMENT_LIST: "MERCHANT_ORDER_UPDATE_SHIPMENT_LIST",
    },
};

import api from "../config";

export const getorders = (params) => api.get(`/merchant/orders/list`, params);
export const getInvoiceFile = (params) => api.get(`/merchant/orders/invoice`, params, { responseType: "blob" });

export const getorderdetail = (params) => api.get(`/merchant/orders/detail`, params);
export const getorderactionlogs = (params) => api.get(`/merchant/orders/action-logs`, params);
export const postordernote = (body) => api.post(`/merchant/orders/internal-note`, body);
export const updateorderhipment = (body) => api.post(`/merchant/orders/shipment`, body);
export const patchorders = () => api.patch(`/merchant/orders`);
export const deleteorders = () => api.delete(`/merchant/orders`);

export const downloadOrderSummary = (params) =>
    api.get(`/merchant/orders/summary-excel`, params, { responseType: "blob" });

import { styled } from "@material-ui/core";
import { FormButton } from "components/Global";
import { useDispatch } from "react-redux";
import { processEditProduct, setPLStatus } from "service/actions/merchant";

const ActionColumn = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 6,
});

const ProductActions = ({ goods_id, status, showing }) => {
    const dispatch = useDispatch();

    return (
        <ActionColumn>
            <FormButton
                height={25}
                width={85}
                ccolor="blue"
                cvariant="contained"
                onClick={() => dispatch(processEditProduct(goods_id))}
            >
                Edit
            </FormButton>
            {showing === "sku" && (
                <FormButton
                    height={25}
                    width={85}
                    ccolor="blue"
                    cvariant="outlined"
                    onClick={() => {
                        dispatch(
                            setPLStatus({
                                type: "sku",
                                action: status === "listed" ? "unlisted" : "listed",
                                ids: [goods_id],
                            }),
                        );
                    }}
                >
                    {status === "listed" ? "Unlist" : "List"}
                </FormButton>
            )}
        </ActionColumn>
    );
};

export default ProductActions;

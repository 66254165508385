import { styled } from "@material-ui/core";
import { FormButton } from "components/Global/Form";
import usePopup from "hooks/usePopup";
import React from "react";
import { useDispatch } from "react-redux";
import { updateStoreWarranty } from "service/actions/merchant";
import WarrantyTermPopup from "../popup/WarrantyTermPopup";

const ActionCustomColumn = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
});

const ActionColumn = ({ data }) => {
    const popup = usePopup();
    const dispatch = useDispatch();
    return (
        <ActionCustomColumn>
            <FormButton
                height={28}
                width={75}
                ccolor="blue"
                cvariant="contained"
                onClick={() => {
                    popup(WarrantyTermPopup, {
                        title: "Edit Warranty Terms",
                        mode: "edit",
                        editdata: data,
                        action: (data, args, t) => {
                            dispatch(updateStoreWarranty(data, args));
                        },
                    });
                }}
            >
                Edit
            </FormButton>
        </ActionCustomColumn>
    );
};

export default React.memo(ActionColumn);

import ResetPasswordForm from "components/AuthPage/ResetPasswordForm";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getForgotPasswordVerify } from "service/actions/authentication";
import CircularProgress from "@material-ui/core/CircularProgress";
import { styled } from "@material-ui/core";

const LoadingContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
});

export default () => {
    const { search } = useLocation();
    const dispatch = useDispatch();
    const passwordstatus = useSelector((state) => state.authentication.password);

    const query = {
        [search.split("=")[0].slice(1, search.split("=")[0].length)]: search.split("=")[1],
    };

    useEffect(() => {
        dispatch(getForgotPasswordVerify(query));
    }, []);
    return passwordstatus.verify ? (
        <ResetPasswordForm />
    ) : (
        <LoadingContainer>
            <CircularProgress size={200} />
        </LoadingContainer>
    );
};

import { useDispatch } from "react-redux";
import {
    styled,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormButton } from "components/Global";
import { useState } from "react";
import { useQuery } from "react-query";
import { adminGetImpersonationUserList } from "service/apis/admin/impersonation";
import { startImpersonation } from "service/actions/authentication";
import { setLogout } from "service/actions/authentication";

const StyledDialogTitle = styled(DialogTitle)({
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "26px",
    color: "#424242",
    textAlign: "left",
});

const StyledDialogContentText = styled(DialogContentText)({
    fontSize: "16px",
    lineHeight: "21px",
    color: "#424242",
    textAlign: "left",
    display: "block",
});

const StyledNoteTitle = styled("p")({
    fontSize: "14px",
    lineHeight: "18px",
    color: "#9E9E9E",
    textAlign: "left",
});

const StyledNoteContent = styled("p")({
    fontSize: "14px",
    lineHeight: "18px",
    color: "#9E9E9E",
    textAlign: "left",
});

const StyledDialogActions = styled(DialogActions)({
    justifyContent: "center",
    paddingBottom: "20px",
});

const ImpersonationBox = ({ open, setOpen }) => {
    const dispatch = useDispatch();
    const [mkpUserId, setMkpUserId] = useState(null);

    const { isLoading, data } = useQuery("user-list", adminGetImpersonationUserList);
    if (isLoading) return null;
    if (data.data.error) {
        dispatch(setLogout());
    }

    const mkpUserList = data.data.result.map((user) => {
        const companyName = user["company.company_name"];
        const storeName = user["company.store_name"];
        const userName = `${user.first_name} ${user.last_name}`;
        return {
            label: `${companyName} - ${storeName} (${userName})`,
            id: user.id,
        };
    });

    return (
        <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <StyledDialogTitle id="alert-dialog-title">Impersonate Merchants</StyledDialogTitle>
            <DialogContent>
                <StyledDialogContentText id="alert-dialog-description">
                    <div>Please choose the merchant you would like to impersonate.</div>
                    <div style={{ margin: "16px 0px" }}>
                        <Autocomplete
                            options={mkpUserList}
                            getOptionLabel={(option) => option.label}
                            style={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label="Search" variant="outlined" />}
                            onChange={(event, value) => setMkpUserId(value.id)}
                        />
                    </div>
                    <div>
                        <StyledNoteTitle>Note:</StyledNoteTitle>
                        <StyledNoteContent>Do not change anything without consent from Merchant</StyledNoteContent>
                    </div>
                </StyledDialogContentText>
            </DialogContent>
            <StyledDialogActions>
                {true ? (
                    <>
                        <FormButton cvariant="outlined" width={147} onClick={() => setOpen(false)}>
                            Cancel
                        </FormButton>
                        {mkpUserId && (
                            <FormButton width={147} onClick={() => dispatch(startImpersonation(mkpUserId))}>
                                Confirm
                            </FormButton>
                        )}
                    </>
                ) : (
                    <CircularProgress />
                )}
            </StyledDialogActions>
        </Dialog>
    );
};

export default ImpersonationBox;

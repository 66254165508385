import { Box, makeStyles, Popper, styled, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import MultiSelection from "components/Global/Form/AutoComplete/Uncontrolled/MultiSelection";
import { TextSelection } from "components/Global/Form/NormalSelect";
import { stringSize } from "helper";
import { useCallback, useEffect, useReducer, useState } from "react";
import {
    useGetAdminProductCategory,
    useGetAdminProductCategoryLevel,
    useGetAdminProductLastCategory,
    useGetAdminProductSubCategory,
} from "../hooks";

const Category = styled("div")({
    display: "flex",
    width: "100% !important",
    gap: 20,
    alignItems: "center",
});

const useStyles = makeStyles((theme) => ({
    input: {
        padding: "18.5px 38px 18.5px 14px",
    },
}));

const PNBox = styled("div")(({ theme, ...rest }) => {
    const counter = rest?.children?.find((item) => item.props.className === "counter");

    return {
        position: "relative",
        "& .counter": {
            position: "absolute",
            display: "flex",
            alignItems: "center",
            right: 6,
            fontSize: 12,
            top: "calc(50% - 6px)",
            fontWeight: counter.props.children < 120 ? "normal" : "bold",
            color: theme.yoho.md_color[counter.props.children < 120 ? "grey" : "red"][400],
        },
    };
});

const FormWarnMessage = styled("div")(({ theme }) => {
    return {
        borderRadius: 10,
        backgroundColor: theme.yoho.md_color.yellow[50],
        color: theme.yoho.md_color.yellow[900],
        padding: "8px 12px",
        margin: "-4px 0px 4px",
    };
});

function VitaInfo({ draft, initData, merchant_id, handleSubmitPayload }) {
    const classes = useStyles();
    const { data: categoryLevel } = useGetAdminProductCategoryLevel(draft?.spu?.vital_info_category);

    const [defaultCategory, setDefaultCategory] = useState([]);
    const [categoryId, setCategoryId] = useState(categoryLevel?.result?.[0]?.id);
    const [id, setId] = useState(categoryLevel?.result?.[1]?.id);

    const { data: categoryData, isLoading: onCategoryLoading } = useGetAdminProductCategory({
        level: "1",
        merchant_id,
    });
    const { data: subCategoryData, isLoading: onSubCategoryLoading } = useGetAdminProductSubCategory({
        level: "1",
        merchant_id,
        category_id: categoryId,
    });
    const { data: lastCategoryData, isLoading: onLastCategoryLoading } = useGetAdminProductLastCategory({
        level: "1",
        merchant_id,
        category_id: categoryId,
        id,
    });

    const [levelOne, setLevelOne] = useState(
        categoryLevel?.result?.[0] || draft?.spu?.category_level?.[0] || defaultCategory?.[0],
    );
    const [levelTwo, setLevelTwo] = useState(
        categoryLevel?.result?.[1] || draft?.spu?.category_level?.[1] || defaultCategory?.[1],
    );
    const [levelThree, setLevelThree] = useState(
        categoryLevel?.result?.[2] || draft?.spu?.category_level?.[2] || defaultCategory?.[2],
    );

    const [levelOneOnChange, setLevelOneOnChange] = useState(false);
    const [levelTwoOnChange, setLevelTwoOnChange] = useState(false);

    const setCategory = useCallback(
        (reducer) => {
            if (reducer.category_level === 99) return reducer.data;
            let data = [...defaultCategory];
            if (reducer.category_level === 0) {
                data[reducer.category_level + 1] = null;
                data[reducer.category_level + 2] = null;
            }

            if (reducer.category_level === 1) {
                data[reducer.category_level + 1] = null;
            }

            if (reducer.category_level === 2) {
                handleSubmitPayload({ vital_info_category: reducer?.data?.id });
            }
            data[reducer.category_level] = reducer.data;

            handleSubmitPayload({ category_level: reducer.data });

            setDefaultCategory(data);

            if (data?.[0]) {
                setCategoryId(data?.[0]?.id);
            }

            if (data?.[1]) {
                setId(data?.[1]?.id);
                setCategoryId(data?.[1]?.category_id);
            }

            if (data?.[0]?.category_id) {
                setCategoryId(data?.[0]?.category_id);
            }
        },
        [defaultCategory, handleSubmitPayload, lastCategoryData?.result],
    );

    useEffect(() => {
        if (categoryLevel) {
            setCategory({ data: categoryLevel?.result, category_level: 99 });
            setCategoryId(categoryLevel?.result?.[0]?.id);
            setId(categoryLevel?.result?.[1]?.id);
            handleSubmitPayload({ category_level: categoryLevel?.result });
            setLevelOne(categoryLevel?.result?.[0]);
            setLevelTwo(categoryLevel?.result?.[1]);
            setLevelThree(categoryLevel?.result?.[2]);
        }
    }, [categoryLevel]);

    useEffect(() => {
        if (draft?.spu?.category_level?.length === 1) {
            setCategoryId(draft?.spu?.category_level?.[0]?.category_id);
        }
    }, [draft?.spu?.category_level]);

    useEffect(() => {
        if (levelOneOnChange && subCategoryData?.result?.[0]) {
            setLevelTwo(subCategoryData?.result?.[0]);
            setLevelOneOnChange(false);
            setCategoryId(subCategoryData?.result?.[0]?.category_id);
            setId(subCategoryData?.result?.[0]?.id);
        }
    }, [levelOneOnChange, subCategoryData]);

    useEffect(() => {
        if (
            categoryId &&
            id &&
            lastCategoryData?.result?.[0] &&
            (defaultCategory?.[2] === "undefined" || defaultCategory?.[2] === null)
        ) {
            setLevelThree(lastCategoryData?.result?.[0]);
            handleSubmitPayload({ vital_info_category: lastCategoryData?.result?.[0]?.id });
        }
    }, [categoryId, id, lastCategoryData]);

    useEffect(() => {
        if (
            levelTwoOnChange &&
            lastCategoryData?.result?.[0] &&
            (defaultCategory?.[2] === "undefined" || defaultCategory?.[2] === null)
        ) {
            setLevelThree(lastCategoryData?.result?.[0]);
            setLevelTwoOnChange(false);
            handleSubmitPayload({ vital_info_category: lastCategoryData?.result?.[0]?.id });
        }
        if (lastCategoryData?.result?.length === 0 && levelTwoOnChange === false) {
            handleSubmitPayload({ vital_info_category: subCategoryData?.result?.[0]?.id });
        }
        if (lastCategoryData?.result?.length === 0 && levelTwoOnChange) {
            // setLevelTwo(defaultCategory?.[1])
            handleSubmitPayload({ vital_info_category: defaultCategory?.[1]?.id });
        }
    }, [levelTwoOnChange, lastCategoryData]);

    const PopperMy = function (props) {
        return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
    };

    return (
        <div>
            <FormCategory>
                <FormWarnMessage className="full">
                    Changing Brand and Category will affect all merchants including YOHO
                </FormWarnMessage>
                <Autocomplete
                    className="full"
                    name="vital_info_brand"
                    fullWidth
                    PopperComponent={PopperMy}
                    filterOptions={createFilterOptions({
                        matchFrom: "any",
                        limit: 25,
                    })}
                    options={initData?.result?.brands || []}
                    value={
                        initData?.result?.brands?.find((item) => item?.brand_id === draft?.spu?.vital_info_brand) || {}
                    }
                    getOptionLabel={(option) => option?.brand_name || ""}
                    renderOption={(option, props) => {
                        return (
                            <Box
                                component="div"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                selected={props.selected}
                                inputvalue={props.inputValue}
                            >
                                {option.brand_name}
                            </Box>
                        );
                    }}
                    sx={{ width: "100%" }}
                    onChange={(_, data) => {
                        handleSubmitPayload({ vital_info_brand: data?.brand_id || {} });
                        return data || {};
                    }}
                    renderInput={(params) => <TextField variant="outlined" {...params} label="Brand" />}
                />
                <PNBox className="full">
                    <TextField
                        onChange={(e) => {
                            handleSubmitPayload({ [e.target.name]: e.target.value });
                        }}
                        fullWidth
                        name="vital_info_product_name_chinese"
                        variant="outlined"
                        label="Product Name (Traditional Chinese)"
                        onInput={(char) => {
                            char.target.value =
                                stringSize(char.target.value) <= 120
                                    ? char.target.value
                                    : draft?.spu?.vital_info_product_name_chinese;
                        }}
                        value={draft?.spu?.vital_info_product_name_chinese}
                        inputProps={{
                            className: classes.input,
                        }}
                    />
                    <div className="counter">{stringSize(draft?.spu?.vital_info_product_name_chinese)}</div>
                </PNBox>
                <PNBox className="full">
                    <TextField
                        onChange={(e) => {
                            handleSubmitPayload({ [e.target.name]: e.target.value });
                        }}
                        fullWidth
                        name="vital_info_product_name_english"
                        variant="outlined"
                        label="Product Name (English)"
                        onInput={(char) => {
                            char.target.value =
                                stringSize(char.target.value) <= 120
                                    ? char.target.value
                                    : draft?.spu?.vital_info_product_name_english;
                        }}
                        value={draft?.spu?.vital_info_product_name_english}
                        inputProps={{
                            className: classes.input,
                        }}
                    />
                    <div className="counter">{stringSize(draft?.spu?.vital_info_product_name_english)}</div>
                </PNBox>
                <Category>
                    <FormText>
                        <h5>Category:</h5>
                    </FormText>
                    {!onCategoryLoading ? (
                        <Autocomplete
                            className="full"
                            name="category"
                            fullWidth
                            size="small"
                            disableClearable
                            PopperComponent={PopperMy}
                            options={categoryData?.result || []}
                            loading={onCategoryLoading}
                            value={levelOne}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(option, props) => {
                                return (
                                    <Box
                                        component="div"
                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                        selected={props.selected}
                                        inputvalue={props.inputValue}
                                    >
                                        {option?.name}
                                    </Box>
                                );
                            }}
                            onChange={(_, data) => {
                                setCategory({ data, category_level: 0 });
                                setLevelOne(data);
                                setLevelTwo({});
                                setLevelThree({});
                                setLevelOneOnChange(true);
                            }}
                            renderInput={(params) => <TextField variant="outlined" {...params} />}
                        />
                    ) : (
                        <TextField className="full" name="subcategory" fullWidth size="small" variant="outlined" />
                    )}
                    <FormText color="grey" code={400}>
                        <h5>/</h5>
                    </FormText>
                    {!onSubCategoryLoading ? (
                        <Autocomplete
                            className="full"
                            name="subcategory"
                            fullWidth
                            size="small"
                            disableClearable
                            PopperComponent={PopperMy}
                            options={subCategoryData?.result || []}
                            loading={onSubCategoryLoading}
                            value={levelTwo || subCategoryData?.result?.[0]}
                            getOptionLabel={(option) => option?.name || ""}
                            renderOption={(option, props) => {
                                return (
                                    <Box
                                        component="div"
                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                        selected={props.selected}
                                        inputvalue={props.inputValue}
                                    >
                                        {option?.name || ""}
                                    </Box>
                                );
                            }}
                            onChange={(_, data) => {
                                setCategory({ data, category_level: 1 });
                                setLevelTwo(data);
                                setLevelThree({});
                                setLevelTwoOnChange(true);
                            }}
                            renderInput={(params) => <TextField variant="outlined" {...params} />}
                        />
                    ) : (
                        <TextField className="full" name="subcategory" fullWidth size="small" variant="outlined" />
                    )}
                    <FormText color="grey" code={400}>
                        <h5>/</h5>
                    </FormText>
                    {!onLastCategoryLoading ? (
                        <Autocomplete
                            className="full"
                            name="lastcategory"
                            fullWidth
                            size="small"
                            disableClearable
                            PopperComponent={PopperMy}
                            options={lastCategoryData?.result || []}
                            loading={onLastCategoryLoading}
                            value={levelThree || lastCategoryData?.result?.[0]}
                            getOptionLabel={(option) => option?.name || ""}
                            renderOption={(option, props) => {
                                return (
                                    <Box
                                        component="div"
                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                        selected={props.selected}
                                        inputvalue={props.inputValue}
                                    >
                                        {option?.name || ""}
                                    </Box>
                                );
                            }}
                            onChange={(_, data) => {
                                setCategory({ data, category_level: 2 });
                                setLevelThree(data || {});
                            }}
                            renderInput={(params) => <TextField variant="outlined" {...params} />}
                        />
                    ) : (
                        <TextField className="full" name="subcategory" fullWidth size="small" variant="outlined" />
                    )}
                </Category>
                <TextSelection
                    disabled
                    label="Type of Item"
                    name="vital_info_type_of_item"
                    data={initData?.result?.type_of_item || []}
                    keyValue="id"
                    keyDisplay="name"
                    value={
                        initData?.result?.type_of_item?.find((item) => item.id == draft?.spu?.vital_info_type_of_item)
                            ?.id
                    }
                    onChange={(e) => {
                        handleSubmitPayload({ [e.target.name]: e.target.value });
                    }}
                />
                <Autocomplete
                    name="vital_info_origin_of_goods"
                    fullWidth
                    PopperComponent={PopperMy}
                    options={initData?.result?.country_of_origin_id || []}
                    value={initData?.result?.country_of_origin_id?.find(
                        (item) => item.id === draft?.spu?.vital_info_origin_of_goods,
                    )}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, props) => {
                        return (
                            <Box
                                component="div"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                selected={props.selected}
                                inputvalue={props.inputValue}
                            >
                                {option.name}
                            </Box>
                        );
                    }}
                    sx={{ width: "100%" }}
                    onChange={(_, data) => {
                        handleSubmitPayload({ vital_info_origin_of_goods: data.id });
                    }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} label="Country of manufacture (made in)" />
                    )}
                />
                <TextSelection
                    label="Source of product"
                    name="vital_info_source_of_product"
                    data={initData?.result?.source_of_product || []}
                    keyValue="id"
                    keyDisplay="name"
                    value={
                        initData?.result?.source_of_product?.find(
                            (item) => item.id == draft?.spu?.vital_info_source_of_product,
                        )?.id
                    }
                    onChange={(e) => {
                        handleSubmitPayload({ [e.target.name]: e.target.value });
                    }}
                />
                <MultiSelection
                    label="Recognition Rewards"
                    name="vital_info_recognition_rewards"
                    data={initData?.result?.recognition_reward || []}
                    defaultValue={
                        typeof draft?.spu?.vital_info_recognition_rewards === "object" ||
                        draft?.spu?.vital_info_recognition_rewards === ""
                            ? draft?.spu?.vital_info_recognition_rewards
                            : [draft?.spu?.vital_info_recognition_rewards]
                    }
                    onChange={(e) => {
                        handleSubmitPayload({ [e.target.name]: e.target.value });
                    }}
                />
            </FormCategory>
        </div>
    );
}

export default VitaInfo;

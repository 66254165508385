import { Icon, InputAdornment, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import Header from "components/MerchantCentre/Header";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setApplicationParam } from "service/actions/admin";
import { useGetApplicationList } from "./hooks";
import List from "./List";

export default () => {
    const filter = useSelector((state) => state.admin.param);
    const dispatch = useDispatch();
    const [tab, setTab] = useState("pending");
    const [value, setValue] = useState("");
    const [search, setSearch] = useState(null);

    const handleSearch = (e) => {
        setValue(e.target.value);
        setSearch(e.target.value);
    };

    const handleClear = () => {
        setValue("");
        setSearch(null);
    };

    useEffect(() => {
        dispatch(
            setApplicationParam({
                size: 30,
                status: tab,
                page: 1,
            }),
        );
    }, [tab]);

    const { isLoading, data: applicationData } = useGetApplicationList({ ...filter, search });

    return (
        <Container>
            <div className="main">
                <Header
                    menu={[
                        { name: "pending", count: applicationData?.result?.total_items?.pending },
                        { name: "approved", count: applicationData?.result?.total_items?.approved },
                        { name: "declined", count: applicationData?.result?.total_items?.declined },
                    ]}
                    active={tab}
                    setActive={setTab}
                />
                <TextField
                    size="small"
                    variant="outlined"
                    style={{ width: 470, marginTop: 20 }}
                    placeholder="Search by company name, store name or application id"
                    value={value}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon style={{ textAlign: "center" }}>
                                    <img style={{ height: "100%" }} src="/assets/icon/search-outline.svg" />
                                </Icon>
                            </InputAdornment>
                        ),
                        endAdornment: value && (
                            <InputAdornment position="end">
                                <CloseIcon fontSize="small" onClick={handleClear} style={{ cursor: "pointer" }} />
                            </InputAdornment>
                        ),
                    }}
                    onKeyUp={handleSearch}
                    onChange={handleSearch}
                />
                <Content>
                    <List data={applicationData?.result} isLoading={isLoading} currentTab={tab} />
                </Content>
            </div>
        </Container>
    );
};

import { styled } from "@material-ui/core";
import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { Prompt, useHistory, useParams } from "react-router-dom";

import { FormButton } from "components/Global";
import { FieldType } from "components/General/Field";
import { Form } from "components/General/Form";
import * as promotionAPI from "service/apis/merchant/promo";
import { useDispatch } from "react-redux";
import { setSnackbar } from "service/actions/site";
import { useApiWithErrorHandle } from "service/apis/merchant/utilHook";

import { LoadingContainer } from "components/MerchantCentre/AdministrationConsole/Application/Details/StyledComponent";
import { CircularProgress } from "@material-ui/core";

const PageContainer = styled("div")({
    margin: "0px 35px",
    position: "relative",
});

const InfoText = styled("p")({
    color: "#888888",
});

const Grid = styled("div")({
    display: "flex",
});

const FormSection = styled("div")({
    flexGrow: 1,
});

const PanelSection = styled("div")({
    width: 380,
    paddingLeft: "25px",
    paddingTop: "13px",
});

const formSections = [
    {
        fields: [
            {
                type: FieldType.TEXT,
                label: "Chinese Title",
                name: "chineseTitle",
                fullWidth: true,
            },
            {
                type: FieldType.TEXT,
                label: "English Title",
                name: "englishTitle",
                fullWidth: true,
            },
            {
                type: FieldType.DATE,
                label: "Start Date ",
                name: "startDate",
            },
            {
                type: FieldType.DATE,
                label: "End Date",
                name: "endDate",
            },
        ],
    },
    ["Chinese Introduction", "English Introduction"],
    {
        fields: [
            {
                type: FieldType.EDITOR,
                label: "Chinese Intro",
                name: "chineseIntro",
            },
        ],
    },
    {
        fields: [
            {
                type: FieldType.EDITOR,
                label: "English Intro",
                name: "englishIntro",
            },
        ],
    },
    {
        fields: [
            {
                type: FieldType.PRODUCT_LIST,
                label: "Search and Add Related Product",
                name: "products",
            },
        ],
    },
];

const RightPanel = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    minWidth: "380px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "20px",
    height: "fit-content",
    borderRadius: 16,
    position: "sticky",
    top: "35px",
    "& > *": {
        width: "100% !important",
    },
}));

function validatePromoInfo({ chineseTitle, englishTitle, chineseIntro, startDate, endDate, products }) {
    if (!chineseTitle) {
        return ["Chinese title must be filled in", { chineseTitle: "This field cannot be empty" }];
    }

    if (!englishTitle) {
        return ["English title must be filled in", { englishTitle: "This field cannot be empty" }];
    }

    if (startDate > endDate) {
        return ["End date must be after start date", { endDate: "Must be after start date" }];
    }

    if (!chineseIntro) {
        return ["Chinese introduction must be filled in", { chineseIntro: "This field cannot be empty" }];
    }

    if (products.length === 0) {
        return ["Please select at least one related product", { products: "Select at least one item" }];
    }

    return ["", {}];
}

export default function PromotionInfoEdit() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    const { id: topicId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const getPromoInfo = useApiWithErrorHandle(promotionAPI.getPromoInfo, () =>
        history.push("/promotion/promotion-info"),
    );

    const editPromoInfo = useApiWithErrorHandle(promotionAPI.editPromoInfo, () => setSubmitting(false));

    const changeHandler = (field, value) => {
        const newData = cloneDeep(data);
        newData[field] = value;
        setData(newData);
    };

    // Add handler for the product list field
    formSections[4].fields[0].onItemAdd = (newItem) => {
        const newList = cloneDeep(data.products);
        newList.unshift(...newItem);
        changeHandler("products", newList);
    };

    formSections[4].fields[0].onItemRemove = (removedItems) => {
        const removedIds = removedItems.map(({ id }) => id);
        const newList = data.products.filter(({ id }) => !removedIds.includes(id));
        changeHandler("products", newList);
    };

    const loadPromoInfo = async () => {
        const result = await getPromoInfo(topicId);
        if (result) {
            const { list: productList } = result.products;
            const formattedProductList = productList.map((good) => ({
                label: good.name,
                id: parseInt(good.id, 10),
            }));
            setData({
                chineseTitle: result.title.zh_hk,
                englishTitle: result.title.en_us,
                chineseIntro: result.intro.zh_hk,
                englishIntro: result.intro.en_us,
                startDate: new Date(result.startTime * 1000),
                endDate: new Date(result.endTime * 1000),
                products: formattedProductList,
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        loadPromoInfo();
    }, []);

    const handleSubmit = async () => {
        const [errorMsg, fieldError] = validatePromoInfo(data);
        if (errorMsg) {
            setErrors(fieldError);
            dispatch(
                setSnackbar({
                    open: true,
                    message: errorMsg,
                    severity: "error",
                }),
            );
            return;
        }

        const payload = {
            topic_id: topicId,
            title: {
                zh_hk: data.chineseTitle,
                en_us: data.englishTitle,
            },
            intro: {
                zh_hk: data.chineseIntro,
                en_us: data.englishIntro,
            },
            startTime: Math.floor(data.startDate.getTime() / 1000),
            endTime: Math.floor(data.endDate.getTime() / 1000),
            products: data.products.map(({ label, id }) => ({ name: label, id })),
        };
        setSubmitting(true);
        const result = await editPromoInfo(payload);
        if (result) {
            history.push("/promotion/promotion-info");
            dispatch(
                setSnackbar({
                    open: true,
                    message: "Promotion edit successful",
                    severity: "success",
                }),
            );
        }
    };

    if (loading)
        return (
            <LoadingContainer>
                <CircularProgress size={100} />
                Loading Promotion
            </LoadingContainer>
        );

    return (
        <PageContainer>
            <h1>Edit Promotion Information</h1>
            <InfoText>
                Promotion information can be shown for a selected group of products, notifying the customer on relevant
                promotion campaign. It is for information only and does not affect the product price on YOHO. Take a
                look at this{" "}
                <a
                    href="https://drive.google.com/file/d/1BjDkYHTAI9DZzq2MCR2ma5zrjtaU-Yyc/view?usp=sharing"
                    target="_blank"
                >
                    video
                </a>{" "}
                to know more.
            </InfoText>
            <Grid>
                <FormSection>
                    <Form sections={formSections} values={data} errors={errors} defaultChangeHandler={changeHandler} />
                </FormSection>
                <PanelSection>
                    <RightPanel>
                        <FormButton cvariant="contained" ccolor="blue" disabled={submitting} onClick={handleSubmit}>
                            {submitting ? "Loading . . . " : "Submit And Finish"}
                        </FormButton>
                        <FormButton cvariant="text" ccolor="blue" onClick={() => history.goBack()}>
                            Discard
                        </FormButton>
                    </RightPanel>
                </PanelSection>
            </Grid>
            <Prompt when={!submitting} message="You have unsaved changes, are you sure you want to leave?" />
        </PageContainer>
    );
}

import { Checkbox, Divider, Icon, styled } from "@material-ui/core";
import { getDataFromNestedObject } from "helper/transformer";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { exportTableData } from "service/actions/site";
import { YohoButtonIcon } from "..";

const Container = styled("div")({
    // overflowX: "scroll",
    width: "100%",
});
const Filter = styled("div")({});

const Wrapper = styled("div")({
    display: "grid",
    overflow: "auto",
    width: "100%",
    height: "fit-content",
    // maxHeight: "645px"
});

const Table = styled("div")({
    display: "flex",
    flexWrap: "nowrap",
});

export const StyledIcon = styled(Icon)(({ theme, size }) => ({
    width: size ? size + 16 : 40,
    height: size ? size + 16 : 40,
    padding: 8,
    "& img": {
        display: "flex",
        height: "inherit",
        width: "inherit",
    },
}));

export const YohoIcon = ({ src, ...props }) => {
    return (
        <StyledIcon {...props} color="primary">
            <img src={src} />
        </StyledIcon>
    );
};

const Options = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0",
});

const LeftMenu = styled("div")({
    display: "flex",
    gap: 20,
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-start",
});

const RightMenu = styled("div")({
    display: "flex",
    gap: 20,
    alignItems: "center",
    width: "fit-content",
    justifyContent: "flex-end",
});

const CustomDivider = styled(Divider)({
    height: "22.67px",
    top: "7.5px",
    position: "relative",
});

const Error = styled("div")(({ theme }) => ({
    color: theme.yoho.md_color.red[500],
    padding: "10px 5",
}));

export default ({
    children,
    className,
    showHistory,
    showLayout,
    showExport,
    disableCheckbox,
    loading = false,
    ...props
}) => {
    const { data } = props;

    const cell = Array.isArray(children)
        ? children.filter((col) => col.props?.__type === "Column")
        : children?.props?.__type === "Column"
          ? children?.props?.__type === "Column"
          : [];

    const top = children?.find((col) => col?.props?.__type === "top");

    const [top_left, top_right] = [
        top !== undefined
            ? Array.isArray(top.props.children)
                ? top.props.children.find((tl) => tl.props.__type === "top_left")
                : top.props.children.props.__type === "top_left"
                  ? top.props.children
                  : undefined
            : undefined,
        top !== undefined
            ? Array.isArray(top.props.children)
                ? top.props.children.find((tr) => tr.props.__type === "top_right")
                : top.props.children.props.__type === "top_right"
                  ? top.props.children
                  : undefined
            : undefined,
    ];

    const Pagination = children?.find((item) => item.props?.__type === "pagination");
    const dispatch = useDispatch();
    let newData = Array.isArray(data) ? data : [];

    return (
        <Container className={className}>
            <Filter>
                <Options>
                    {top_left && (
                        <LeftMenu>
                            {top_left
                                ? Array.isArray(top_left.props.children)
                                    ? [...top_left.props.children]
                                    : top_left.props.children
                                : undefined}
                        </LeftMenu>
                    )}
                    {top_right && (
                        <RightMenu>
                            {top_right
                                ? Array.isArray(top_right.props.children)
                                    ? [...top_right.props.children]
                                    : top_right.props.children
                                : undefined}
                            {showHistory && (
                                <p>
                                    <strong>View History</strong>
                                </p>
                            )}
                            {(showHistory || showLayout || showExport) && (
                                <CustomDivider orientation="vertical" flexItem />
                            )}
                            {showLayout && (
                                <YohoButtonIcon src="/assets/icon/table.svg" width={22.67} cursor="pointer" />
                            )}
                            {showExport && (
                                <YohoButtonIcon
                                    src="/assets/icon/export.svg"
                                    width={22.67}
                                    cursor="pointer"
                                    onClick={() => dispatch(exportTableData("selected"))}
                                />
                            )}
                        </RightMenu>
                    )}
                </Options>
            </Filter>
            {!Array.isArray(data) && <Error>{"ERROR data supplied is not array type"}</Error>}
            <Wrapper>
                <Table>
                    {cell.map((child, index) => {
                        return {
                            ...child,
                            props: {
                                loading,
                                ...child.props,
                                key: index,
                                data: {
                                    cell: newData?.map((field) =>
                                        child.props.field !== undefined
                                            ? typeof child.props.field === "object"
                                                ? child.props.field.reduce(
                                                      (acc, curr) => (
                                                          (acc[curr] = getDataFromNestedObject(curr, field)), acc
                                                      ),
                                                      {},
                                                  )
                                                : getDataFromNestedObject(child.props.field, field)
                                            : field[child.props.field],
                                    ),
                                    all: newData,
                                },
                            },
                        };
                    })}
                </Table>
                {Pagination}
            </Wrapper>
        </Container>
    );
};

import { Editor } from "@tinymce/tinymce-react";
import { useMemo } from "react";

export default ({ name, action, defaultTerms, draft }) => {
    const lang = name.split("_")[name.split("_").length - 1];
    const initialValue = useMemo(() => draft.spu[name] || defaultTerms?.[`description_${lang}`] || "", []);

    return (
        <Editor
            onEditorChange={(content) => action({ [name]: content })}
            initialValue={initialValue}
            init={{
                height: 250,
                menubar: false,
                plugins: [
                    "advlist autolink lists link charmap print preview anchor",
                    "searchreplace visualblocks",
                    "insertdatetime table paste wordcount",
                ],
                toolbar:
                    "undo redo | formatselect |" +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat",
                content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size:14px }",
            }}
        />
    );
};

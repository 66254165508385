import { DialogContent, DialogTitle, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import Popup from "components/Global/Layout/popup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { unsetPopup } from "service/actions/site";
import EditGeneral from "../tabs/EditGeneral";
import EditImagesDropzone from "../tabs/EditImagesDropzone";
import EditSales from "../tabs/EditSales";
import { Alert } from "@material-ui/lab";
import { updateEditProductDraft } from "service/actions/merchant";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

const MenuContainer = styled("div")({
    display: "flex",
});

const Menu = styled("h4")(({ active, theme, type }) => ({
    ...theme.yoho.typography[type],
    color: theme.yoho.md_color.grey[800],
    display: "flex",
    position: "relative",
    "&.active": {
        color: theme.yoho.md_color.blue[500],
        "&:after": {
            content: "''",
            background: theme.yoho.md_color.blue[500],
            width: "100%",
            position: "absolute",
            height: "1px",
            left: 0,
            bottom: "-14px",
        },
    },
    textTransform: "capitalize",
    cursor: "pointer",
    padding: "0 15px",
    margin: "13px 0",
    textAlign: "center",
    "& .bubble": {
        color: theme.yoho.md_color.red[500],
    },
}));

const Divider = styled("div")(({ theme }) => ({
    width: "100%",
    borderBottom: `1px solid ${theme.yoho.md_color.grey[300]}`,
    marginBottom: "20px",
}));

const tabs = [
    { id: 1, name: "Sales", code: "sales" },
    { id: 2, name: "General", code: "general" },
    { id: 3, name: "Images", code: "images" },
];

const SkeletonImage = styled("div")({
    width: 115,
    height: 115,
    borderRadius: 12,
    border: "1px dashed black",
});

const ImagePreview = styled("img")({
    width: 115,
    height: 115,
    borderRadius: 12,
    objectFit: "contain",
});

const safeGetImageURL = (imageSlot) => {
    try {
        const imageURL = URL.createObjectURL(imageSlot.file);
        return imageURL;
    } catch (error) {
        // Redux persist cannot save non-serializable file object
        if (imageSlot.publicURL) return imageSlot.publicURL;
        return null;
    }
};

const validateFields = (formData, currentSku) => {
    try {
        const chineseRegex = /\p{Script=Han}/u;

        if (!formData.vital_info_product_name_chinese) {
            throw { tab: "sales", message: "Product Chinese name cannot be blank." };
        }

        if (chineseRegex.test(formData.vital_info_product_name_english) || !formData.vital_info_product_name_english) {
            throw { tab: "sales", message: "Product English name cannot be blank or contain Chinese characters." };
        }

        if (!formData.variations_sales_market_price || !formData.variations_sales_member_price) {
            throw { tab: "sales", message: "Member price and market price must be filled in." };
        }

        if (
            formData.variations_sales_market_price <= 0 ||
            formData.variations_sales_member_price <= 0 ||
            formData.variations_sales_vip_price <= 0
        ) {
            throw { tab: "sales", message: "Prices must be more than zero." };
        }

        if (formData.variations_sales_member_price > formData.variations_sales_market_price) {
            throw { tab: "sales", message: "Member price cannot be more than market price." };
        }

        if (formData.variations_sales_vip_price >= formData.variations_sales_member_price) {
            throw { tab: "sales", message: "VIP price must be less than member price or left empty." };
        }

        if (formData.variations_general_weight <= 0) {
            throw { tab: "general", message: "Weight must be larger than zero for shipping fee calculation." };
        }

        const allImages = [...formData.existing_images, ...formData.image_slots];

        if (!allImages.reduce((prev, { occupied }) => prev || occupied, false)) {
            throw { tab: "images", message: "Product must have at least one image." };
        }

        let promoPrice = null;
        if (currentSku.discounts.length > 0) {
            const discount = currentSku.discounts[0];
            const shopPrice = formData.variations_sales_member_price;
            switch (discount.discount_type) {
                case "percentage_discount": {
                    promoPrice = (shopPrice * (100 - discount.discount_percentage)) / 100;
                    promoPrice = Math.ceil(promoPrice);
                    break;
                }
                case "price_discount": {
                    promoPrice = parseInt(currentSku.promote_price);
                    break;
                }
                case "amount_discount": {
                    promoPrice = shopPrice - discount.discount_amount;
                    break;
                }
            }

            if (promoPrice <= 0 || promoPrice >= formData.variations_sales_member_price) {
                throw { tab: "sales", message: "Promotion price must be non-zero and less than member price." };
            }
        }

        if (
            formData.variations_sales_member_price < formData.variations_sales_market_price * 0.2 ||
            formData.variations_sales_vip_price < formData.variations_sales_market_price * 0.2
        ) {
            throw {
                tab: "sales",
                message: "Member price or VIP price is less than 20% of market price. Confirm to proceed?",
                error: false,
                warning: true,
            };
        }

        return { error: false };
    } catch (error) {
        return {
            error: true,
            ...error,
        };
    }
};

export default ({ title, id }) => {
    const dispatch = useDispatch();

    const productData = useSelector((state) => state.merchant.product.edit.data);
    const currentSku = productData.sku.find(({ goods_id }) => goods_id === id);

    const { register, handleSubmit, watch } = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        defaultValues: currentSku,
    });

    const [errorMsg, setErrorMsg] = useState("");
    const [activeTab, setActiveTab] = useState("sales");
    const [imageSlots, setImageSlots] = useState(
        cloneDeep([...currentSku.existing_images, ...currentSku.image_slots]).sort((a, b) => b.occupied - a.occupied),
    );
    const [thumbSlots, setThumbSlots] = useState(
        cloneDeep([...currentSku.existing_images, ...currentSku.thumb_slots]).sort((a, b) => b.occupied - a.occupied),
    );

    const imageUploading =
        imageSlots.reduce((prev, curr) => prev || !!curr.isUploading, false) ||
        thumbSlots.reduce((prev, curr) => prev || !!curr.isUploading, false);

    // Handle save
    const onSubmit = (formData) => {
        delete formData.variations_general_gtin;

        // Have Id mean existing images
        const savePayload = {
            ...currentSku,
            ...formData,
            image_slots: imageSlots.filter(({ id }) => !id),
            thumb_slots: thumbSlots.filter(({ id }) => !id),
            existing_images: imageSlots.filter(({ id }) => id),
        };

        const { error, tab, message, warning } = validateFields(savePayload, currentSku);

        if (error) {
            setActiveTab(tab);
            setErrorMsg(message);
            return;
        }

        if (warning) {
            setActiveTab(tab);
            const proceed = window.confirm(message);
            if (!proceed) return;
        }

        dispatch(updateEditProductDraft(savePayload));
        dispatch(unsetPopup());
    };

    const mainImageURL = imageSlots.reduce((prev, curr) => {
        if (curr.isMain && curr.occupied) {
            return safeGetImageURL(curr);
        } else {
            return prev;
        }
    }, null);

    return (
        <Popup size="md" fullWidth>
            <CustomDialogTitle>{title}</CustomDialogTitle>
            <CustomDialogContent>
                {errorMsg && (
                    <Alert severity="error" style={{ margin: "15px 20px 0px" }}>
                        {errorMsg}
                    </Alert>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormCategory justifycontent="center">
                        <div className="full flex align-items-center gap-10">
                            <FormText>
                                <p className="body1">{currentSku.variants_mixed}</p>
                            </FormText>
                        </div>
                        <div className="full flex gap-10">
                            <div onClick={() => setActiveTab("images")} style={{ cursor: "pointer" }}>
                                {mainImageURL ? <ImagePreview src={mainImageURL} /> : <SkeletonImage />}
                            </div>
                            <div className="w-12 flex gap-10 col">
                                <TextField
                                    className="w-12"
                                    variant="outlined"
                                    label="Product Name (Traditional Chinese)"
                                    name="vital_info_product_name_chinese"
                                    inputRef={register()}
                                />
                                <TextField
                                    className="w-12"
                                    variant="outlined"
                                    label="Product Name (English)"
                                    name="vital_info_product_name_english"
                                    inputRef={register()}
                                />
                            </div>
                        </div>
                        <div className="full">
                            <MenuContainer>
                                {tabs.map((item) => (
                                    <Menu
                                        key={`${JSON.stringify(item.name)}`}
                                        type="button"
                                        className={activeTab === item.code ? "active" : ""}
                                        onClick={() => setActiveTab(item.code)}
                                    >
                                        {item.name}
                                        {!!item?.count && <a className="bubble">{item?.count}</a>}
                                    </Menu>
                                ))}
                            </MenuContainer>
                            <Divider />

                            <div
                                style={{
                                    display: activeTab !== "sales" ? "none" : "inline-flex",
                                }}
                            >
                                <EditSales
                                    skuData={currentSku}
                                    watch={watch}
                                    inventoryRef={register()}
                                    vipRef={register({ valueAsNumber: true })}
                                    memberRef={register({ valueAsNumber: true })}
                                    marketRef={register({ valueAsNumber: true })}
                                />
                            </div>

                            <div
                                style={{
                                    display: activeTab !== "general" ? "none" : "inline-flex",
                                }}
                            >
                                <EditGeneral inputRef={register()} customRef={register({ valueAsNumber: true })} />
                            </div>
                        </div>

                        <div
                            style={{ display: activeTab !== "images" ? "none" : "block", textAlign: "center" }}
                            className="full"
                        >
                            <EditImagesDropzone
                                imageSlots={imageSlots}
                                setImageSlots={setImageSlots}
                                thumbSlots={thumbSlots}
                                setThumbSlots={setThumbSlots}
                            />
                        </div>

                        <CustomFormButton
                            disabled={imageUploading}
                            className="half"
                            ccolor="blue"
                            cvariant="contained"
                            type="submit"
                            fullWidth
                        >
                            {imageUploading ? "Loading..." : "Save"}
                        </CustomFormButton>
                    </FormCategory>
                </form>
            </CustomDialogContent>
        </Popup>
    );
};

import { styled } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { FormButton } from "components/Global";

const TableContainer = styled("div")({
    margin: "15px 0px",
});

const Header = styled("div")({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderTop: "1px solid #C5C5C5",
    borderBottom: "1px solid #C5C5C5",
});

const HeaderCell = styled("div")(({ size }) => ({
    fontSize: "16px",
    margin: "8px 6px",
    fontWeight: "bold",
    flex: size,
}));

const Row = styled("div")({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderBottom: "1px solid #C5C5C5",
});

const TableMessage = styled("div")({
    textAlign: "center",
    lineHeight: "60px",
    fontSize: "17px",
    borderBottom: "1px solid #C5C5C5",
});

const Cell = styled("div")(({ size }) => ({
    fontSize: "15px",
    margin: "8px 6px",
    flex: size,
}));

const ActionButton = styled(FormButton)({
    width: 90,
    height: 26,
    "&:not(:last-child)": {
        marginBottom: "7px",
    },
});

const PaginationContainer = styled("div")({
    margin: "12px 0px",
    display: "flex",
    flexDirection: "row-reverse",
});

export function Table({ columns, rows, sortHandler, pagination, rowEdit, rowDelete, loading, emptyMessage }) {
    if (loading)
        return (
            <TableContainer>
                <Header>
                    {columns.map((col) => (
                        <HeaderCell size={col.size || 1}>{col.label}</HeaderCell>
                    ))}
                    {(rowEdit || rowDelete) && <HeaderCell size={0.8}>Actions</HeaderCell>}
                </Header>
                <TableMessage>Loading...</TableMessage>
            </TableContainer>
        );

    return (
        <TableContainer>
            <Header>
                {columns.map((col) => (
                    <HeaderCell size={col.size || 1}>{col.label}</HeaderCell>
                ))}
                {(rowEdit || rowDelete) && <HeaderCell size={0.5}>Actions</HeaderCell>}
            </Header>
            {rows.map((row) => (
                <Row>
                    {columns.map((col) => (
                        <Cell size={col.size || 1}>{col.render ? col.render(row) : row[col.name]}</Cell>
                    ))}
                    {(rowEdit || rowDelete) && (
                        <Cell size={0.5}>
                            {rowEdit && <ActionButton onClick={() => rowEdit(row)}>Edit</ActionButton>}
                            {rowDelete && (
                                <ActionButton ccolor="red" cvariant="outlined" onClick={() => rowDelete(row)}>
                                    Delete
                                </ActionButton>
                            )}
                        </Cell>
                    )}
                </Row>
            ))}
            {rows.length === 0 && <TableMessage>{emptyMessage || "No Data"}</TableMessage>}
            {pagination && (
                <PaginationContainer>
                    <Pagination {...pagination} />
                </PaginationContainer>
            )}
        </TableContainer>
    );
}

import { CircularProgress, debounce, styled } from "@material-ui/core";
import Merchant from "components/MerchantCentre/AdministrationConsole/Merchant";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApplication, getMerchantList } from "service/actions/admin";

const LoadingContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    zIndex: "500",
    fontSize: 50,
    gap: 20,
});

export default () => {
    const [active, setActive] = useState("active");
    const [search, setSearch] = useState("");
    const [startPosition, setStartPosition] = useState(0);
    const [limit, setLimit] = useState(30);
    const [isLoading, setIsLoading] = useState(true);
    const [newData, setNewData] = useState(null);
    const [isContentLoading, setIsContentLoading] = useState(true);
    const data = useSelector((state) => state.admin.merchant);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getMerchantList({ status: active, search: search || "", start_position: startPosition, limit: limit }),
        );
        // setNewData(data);
    }, [active, search, startPosition, limit]);

    useEffect(() => {
        setTimeout(function () {
            setIsLoading(false);
        }, 1000);
    }, []);

    useEffect(() => {
        setTimeout(function () {
            setIsContentLoading(false);
        }, 1500);
    }, [active, startPosition]);

    return data && isLoading === false ? (
        <Merchant
            data={data}
            active={active}
            setActive={setActive}
            search={search}
            setSearch={setSearch}
            startPosition={startPosition}
            setStartPosition={setStartPosition}
            limit={limit}
            setLimit={setLimit}
            isContentLoading={isContentLoading}
            setIsContentLoading={setIsContentLoading}
        />
    ) : (
        <LoadingContainer>
            <CircularProgress size={100} />
            Loading Merchant Data
        </LoadingContainer>
    );
};

import { useDispatch } from "react-redux";
import { setSnackbar } from "service/actions/site";
import { setLogout } from "service/actions/authentication";
import { useQuery } from "react-query";

export const useApiWithErrorHandle = (apiFunction, errorCallback) => {
    const dispatch = useDispatch();

    return async (...args) => {
        try {
            const { data } = await apiFunction(...args);
            if (data.error) {
                throw new Error(data.message);
            } else {
                return data.result;
            }
        } catch (error) {
            if (error.message.includes("jwt expired")) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Your session has expired. Please sign in again.",
                        severity: "error",
                    }),
                );
                dispatch(setLogout());
                return;
            }

            dispatch(
                setSnackbar({
                    open: true,
                    message: error.message,
                    severity: "error",
                }),
            );

            if (typeof errorCallback === "function") {
                return errorCallback(error);
            }

            return null;
        }
    };
};

export const useShowSnackbar = () => {
    const dispatch = useDispatch();
    return (message, severity = "error") => {
        dispatch(setSnackbar({ open: true, message, severity }));
    };
};

import { MenuItem, TextField } from "@material-ui/core";
import { FormCategory } from "components/Global";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEditProductDraftField } from "service/actions/merchant";
import MPW_MultiRTE from "./MPW_MultiRTE";

const warrantyLangMenu = [
    { id: 1, name: "Traditional Chinese", code: "warranty_terms_chinese" },
    { id: 2, name: "English", code: "warranty_terms_english" },
];

export default () => {
    const dispatch = useDispatch();
    const defaultWarrantyTerms = useSelector((state) => state.merchant.product.config?.warranty_terms);
    const [selectedDefaultWarranty, setSelectedDefaultWarranty] = useState(defaultWarrantyTerms?.[0] || {});

    const draft = useSelector((state) => state.merchant.product.edit.data);

    const handleSelection = (_, event) => {
        setSelectedDefaultWarranty(event.props.value);
    };

    return (
        <div>
            <FormCategory>
                {defaultWarrantyTerms.length > 0 && (
                    <TextField select value={selectedDefaultWarranty} onChange={handleSelection} variant="outlined">
                        {defaultWarrantyTerms.map((option) => (
                            <MenuItem key={option.template_id} value={option}>
                                {option.template_name}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
                <div className="full">
                    <MPW_MultiRTE
                        draft={draft}
                        options={warrantyLangMenu}
                        defaultLang={warrantyLangMenu[0].code}
                        defaultTerms={selectedDefaultWarranty}
                        action={(e) => dispatch(setEditProductDraftField(e))}
                    />
                </div>
            </FormCategory>
        </div>
    );
};

import { styled } from "@material-ui/core";
import { YohoButtonIcon, YohoIcon } from "components/Global";
import { useState, useEffect, useRef } from "react";

const SearchWrapper = styled("div")(({ theme, lock, height }) => ({
    width: "70%",
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    borderRadius: 8,
    padding: "0 20px",
    height: 62,
    display: "flex",
    alignItems: "center",
    backgroundColor: lock === "pointer" ? "rgb(245, 245, 245)" : "rgb(225, 245, 253)",
    "& input": {
        backgroundColor: lock === "default" && "rgb(225, 245, 253)",
    },
    cursor: lock,
    zIndex: 5,
}));

const SearchBar = styled("input")(({ theme }) => ({
    width: "100%",
    height: "calc(100% - 2px)",
    border: "none",
    display: "flex",
    alignItems: "center",
    padding: 0,
    fontFamily: `"Noto Sans", sans-serif`,
    ...theme.yoho.typography.h5,
    "&:focus": {
        outline: "none",
    },
    "&::placeholder": {
        alignSelf: "center",
        ...theme.yoho.typography.h5,
        padding: 0,
        fontFamily: `"Noto Sans", sans-serif`,
    },
}));

const Block = styled("div")(({ theme }) => ({
    ...theme.yoho.typography.h5,
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: "calc(100% - 2px)",
}));

export default ({ placeholder, clickaction = () => {}, searchaction = () => {}, value }) => {
    const [lock, setLock] = useState(true);
    const inputRef = useRef(null);

    useEffect(() => {
        if (!lock) inputRef.current.focus();
    }, [lock]);

    return (
        <SearchWrapper
            lock={lock ? "pointer" : "default"}
            onClick={(e) => {
                if (e.target.id === "close" || e.target.closest("#close")) return;
                if (lock) clickaction("");
                setLock(false);
            }}
        >
            <YohoIcon src="/assets/icon/search-outline.svg" />
            {!lock ? (
                <SearchBar placeholder={placeholder} onChange={searchaction} value={value} ref={inputRef} />
            ) : (
                <Block>{placeholder}</Block>
            )}
            {!lock && (
                <YohoButtonIcon
                    id="close"
                    src="/assets/icon/x.svg"
                    width={28}
                    cursor="pointer"
                    onClick={() => {
                        clickaction("");
                        setLock(true);
                    }}
                />
            )}
        </SearchWrapper>
    );
};

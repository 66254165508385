import { styled } from "@material-ui/core";
import { convertOrderStatus } from "../../helper";

const StatusListing = styled("div")(({ children, theme }) => ({
    padding: 7,
    fontSize: 12,
    width: "fit-content",
    height: 24,
    fontWeight: "bold",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    backgroundColor: ["to ship", "refund case opened", "order refund", "exchange case opened", "case opened"].includes(
        children.toLowerCase(),
    )
        ? theme.yoho.md_color.red[50]
        : children.toLowerCase() === "shipped"
          ? theme.yoho.md_color.grey[200]
          : children.toLowerCase() === "unpaid"
            ? theme.yoho.md_color.grey[400]
            : ["case closed", "release fund", "exchange case closed"].includes(children.toLowerCase())
              ? theme.yoho.md_color.green[50]
              : "transparent",
    color: ["to ship", "refund case opened", "order refund", "exchange case opened", "case opened"].includes(
        children.toLowerCase(),
    )
        ? theme.yoho.md_color.red[500]
        : children.toLowerCase() === "shipped"
          ? theme.yoho.md_color.grey[800]
          : children.toLowerCase() === "unpaid"
            ? theme.yoho.color.white
            : ["case closed", "release fund", "exchange case closed"].includes(children.toLowerCase())
              ? theme.yoho.md_color.green[500]
              : theme.yoho.md_color.grey[500],
}));

export default (e) => {
    return <StatusListing>{convertOrderStatus(e)}</StatusListing>;
};

import { Chip, makeStyles, styled, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import usePopup from "hooks/usePopup";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
    clearSelectedProductVariationTerms,
    createSelectedProductVariationTerms,
    deleteSelectedProductVariationTerms,
    setSelectedProductVariationTerms,
} from "service/actions/merchant";
import { unsetPopup } from "service/actions/site";
import ProductVariationTermsPopup from "./popup/ProductVariationTermsPopup";

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        width: ({ width }) => width.width || "100%",
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    chip: {
        backgroundColor: theme.yoho.md_color.blue[900],
        color: theme.yoho.color.white,
        opacity: 1,
    },
    chipDisabled: {
        backgroundColor: theme.yoho.md_color.grey[400],
        color: theme.yoho.color.white,
        opacity: 1,
    },
    deleteIcon: (props) => ({
        color: "unset",
    }),
    deleteIconDisabled: (props) => ({
        color: "unset",
        display: "none",
    }),
}));

const RenderContent = styled("div")(({ theme }) => ({
    width: "100%",
    display: "flex",
    gap: 7.5,
    "& .en": {
        color: theme.yoho.md_color.grey[800],
    },
    "& .zh": {
        color: theme.yoho.md_color.grey[500],
    },
}));

const filter = createFilterOptions();
const $variation_attribute_item = createSelector(
    (state) => state.merchant.product.config.variation_attribute_item,
    (_, selected_var) => selected_var,
    (var_attr_item, selected_var) => {
        const variation_attribute_item = var_attr_item?.filter(
            ({ attribute_id }) => selected_var.attribute_id == attribute_id,
        );
        return variation_attribute_item || [];
    },
);

export default ({ id, className, label, data_variation = [], data = [], variant = "outlined", disabled = false }) => {
    const ref = useRef(null);
    const variation_attribute_item = useSelector((state) => $variation_attribute_item(state, data_variation));
    const [width, setWidth] = useState(0);
    const classes = useStyles({ width: width });
    const popup = usePopup();
    const dispatch = useDispatch();
    useEffect(() => setWidth({ width: ref?.current?.offsetWidth }), []);

    const RenderItem = ({ dataitem }) => {
        return dataitem?.create || dataitem?.delete ? (
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <span>{dataitem?.value}</span>
                <span>{dataitem.delete ? "Remove" : "Create New"}</span>
            </div>
        ) : (
            <RenderContent>
                <span className="en">{dataitem?.attribute_item_name_english}</span>
                <span className="zh">{dataitem?.attribute_item_name_chinese}</span>
            </RenderContent>
        );
    };

    const filterOption = (options, params) => {
        let items_ids = data_variation?.items?.map(({ attribute_item_id }) => attribute_item_id);
        let clean_option = options?.filter((item) => !items_ids?.includes(item.attribute_item_id));
        const filtered = filter(clean_option, params);

        if (
            options.some(({ attribute_item_name_chinese }) => attribute_item_name_chinese === params.inputValue) ||
            variation_attribute_item.some(
                ({ attribute_item_name_chinese }) => attribute_item_name_chinese === params.inputValue,
            )
        ) {
            return filtered;
        }
        if (params.inputValue !== "") {
            filtered.push({
                create: true,
                value: `${params.inputValue}`,
                attribute_item_name_chinese: `${params.inputValue}`,
                attribute_item_name_english: `${params.inputValue}`,
                attribute_id: data_variation.attribute_id,
            });
        }
        return filtered;
    };
    return data ? (
        <Autocomplete
            disabled
            multiple
            filterSelectedOptions
            classes={{ option: classes.option }}
            ref={ref}
            options={variation_attribute_item}
            defaultValue={data_variation?.items}
            {...(className && { className: className })}
            {...(id && { id: id })}
            value={data_variation.items}
            onChange={(da, value, status, event) => {
                if (da.keyCode === 8) {
                    return;
                }
                if (value.length === 0 && !event?.option?.create) {
                    dispatch(clearSelectedProductVariationTerms(data_variation.attribute_id));
                    return data_variation.items;
                }
                if (status === "remove-option") {
                    let clean_remove = value.map((item) => {
                        if (item.create) {
                            return variation_attribute_item.find(
                                ({ attribute_item_name_chinese, attribute_item_name_english }) =>
                                    attribute_item_name_chinese === item.attribute_item_name_chinese &&
                                    attribute_item_name_english === item.attribute_item_name_english,
                            );
                        }
                        return item;
                    });
                    let editable_set = [...(data_variation?.items || [])];
                    let newFiltered = editable_set.filter(
                        ({ attribute_item_id }) => attribute_item_id !== event.option.attribute_item_id,
                    );

                    dispatch(deleteSelectedProductVariationTerms(newFiltered, data_variation.attribute_id));
                    return;
                }
                if (event.option) {
                    if (event?.option.hasOwnProperty("create")) {
                        popup(ProductVariationTermsPopup, {
                            mode: "create",
                            title: "Create new variation term",
                            attr_id: data_variation.attribute_id,
                            defaultData: event.option,
                            action: (data) => {
                                dispatch(createSelectedProductVariationTerms(data));
                            },
                            close: () => {
                                dispatch(unsetPopup());
                            },
                        });
                        return;
                    }
                    if (status === "select-option") {
                        let editable_set = [...(data_variation?.items || [])];
                        editable_set.push({ ...event.option, editable: true });
                        dispatch(
                            setSelectedProductVariationTerms(editable_set, data_variation.attribute_id, event.option),
                        );
                        return;
                    }

                    if (status === "remove-option") {
                        dispatch(deleteSelectedProductVariationTerms(value, data_variation.attribute_id));
                        return data_variation.items;
                    }
                }
            }}
            getOptionLabel={(option) => {
                return `${option?.attribute_item_name_english} ${option?.attribute_item_name_chinese}`;
            }}
            getOptionSelected={(option, value) => {
                return option == value;
            }}
            filterOptions={filterOption}
            renderOption={(op) => {
                return <RenderItem dataitem={op} />;
            }}
            {...(data_variation?.status === "published" && { disableClearable: true })}
            renderInput={(params) => <TextField {...params} variant={variant} label={label} disabled />}
            renderTags={(_, getTagProps) =>
                data_variation?.items?.map((option, index) => {
                    return (
                        <Chip
                            key={index}
                            classes={{
                                root: option.status === "published" ? classes.chipDisabled : classes.chip,
                                deleteIcon:
                                    option.status === "published" ? classes.deleteIconDisabled : classes.deleteIcon,
                            }}
                            deleteIcon={
                                <svg
                                    className="MuiSvgIcon-root MuiChip-deleteIcon makeStyles-deleteIcon-83"
                                    viewBox="0 0 24 24"
                                    id={option.attribute_item_id}
                                >
                                    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                                </svg>
                            }
                            variant="outlined"
                            label={
                                `${option["attribute_item_name_english"]} ${option["attribute_item_name_chinese"]}` ||
                                option
                            }
                            {...getTagProps({ index })}
                        />
                    );
                })
            }
        />
    ) : (
        <>No Data Found</>
    );
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMenu, unsetMenu } from "service/actions/site";
import useKeyAction from "./useKeyAction";

export default () => {
    const dispatch = useDispatch();
    const menustate = useSelector((state) => state.site.menu);
    useKeyAction("Escape", menustate, () => dispatch(unsetMenu()));

    const menu = (Component, { ...props }) => {
        dispatch(unsetMenu());
        setTimeout(() => {
            dispatch(setMenu(<Component close={() => dispatch(unsetMenu())} {...props} />));
        }, 100);
    };
    return { menu };
};

import { create } from "apisauce";
import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, { retries: 3 });

const apiAxiosINstance = axios.create({
    baseURL: process?.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        "x-api-key": process?.env.REACT_APP_CLIENT_SECRET,
        "x-api-id": process?.env.REACT_APP_CLIENT_ID,
    },
});

const api = create({
    axiosInstance: apiAxiosINstance,
});

export default api;

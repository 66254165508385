import Navigation from "components/Layout/Navigation";
import Header from "components/Layout/Header";
import { styled } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { unsetPopup } from "service/actions/site";
import { useEffect } from "react";

const Base = styled("div")({
    display: "grid",
    gridTemplateColumns: "86px auto",
    gridTemplateRows: "65px auto",
    gridTemplateAreas: `"head head"
        "nav main"`,
});

const GridHead = styled("div")({
    gridArea: "head",
});

const GridNav = styled("div")({
    gridArea: "nav",
});

const GridMain = styled("div")({
    gridArea: "main",
    height: "calc(100vh - 68px)",
    overflow: "hidden",
    "& > div": {
        "overflow-y": "auto",
        "overflow-x": "auto",
        height: "100%",
    },
});

const Popup = styled("div")({
    zIndex: 1001,
    position: "relative",
});

const Menu = styled("div")({
    zIndex: 1001,
});

export default ({ children }) => {
    const dispatch = useDispatch();
    const popup = useSelector((state) => state.site.popup);
    const menu = useSelector((state) => state.site.menu);
    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.key === "Escape") {
                e.preventDefault();
                if (popup) {
                    dispatch(unsetPopup());
                    return;
                }
            }
        };
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    }, [popup]);

    return (
        <Base>
            <GridHead>
                <Header />
            </GridHead>
            <GridNav>
                <Navigation />
            </GridNav>
            <GridMain>
                <div>{children}</div>
            </GridMain>
            <Popup>{popup}</Popup>
            <Menu>{menu}</Menu>
        </Base>
    );
};

import { MERCHANT } from "service/constants/merchant";

export const setMerchantLoading = (key = "default", data) => ({
    type: MERCHANT.LOADING.SET,
    key,
    data,
});
export const resetMerchantLoading = () => ({ type: MERCHANT.LOADING.RESET });

export const InitAuthData = () => ({ type: MERCHANT.INIT.GET });
export const changeUserPassword = (data) => ({
    type: MERCHANT.PROFILE.PASSWORD,
    data,
});
export const setProfile = (data) => ({ type: MERCHANT.PROFILE.SET, data });
export const updateMerchantProfile = (data) => ({
    type: MERCHANT.PROFILE.UPDATE,
    data,
});
export const selectStoreWarranty = (data, args = "add") => ({
    type: MERCHANT.SETTING.STORE.WARRANTY.SELECT,
    data,
    args,
});
export const postedMerchantProduct = (data) => ({
    type: MERCHANT.PRODUCT.POSTED,
    data,
});
export const updatedMerchantProductDraft = (data) => ({
    type: MERCHANT.PRODUCT.UPDATED,
    data,
});

// settings - global
export const resetSettingLoading = () => ({
    type: MERCHANT.SETTING.LOADING.RESET,
});

// settings - account
export const getAccountSetting = () => ({ type: MERCHANT.SETTING.ACCOUNT.GET });
export const setAccountSetting = (data) => ({
    type: MERCHANT.SETTING.ACCOUNT.SET,
    data,
});
export const updateAccountPrivilege = (data) => ({
    type: MERCHANT.SETTING.ACCOUNT.PRIVILEGE.UPDATE,
    data,
});
// settings - store
export const getStoreSetting = () => ({ type: MERCHANT.SETTING.STORE.GET });
export const setStoreSetting = (data) => ({
    type: MERCHANT.SETTING.STORE.SET,
    data,
});
export const updateStoreContact = (data) => ({
    type: MERCHANT.SETTING.STORE.CONTACT.SET,
    data,
});
export const createStoreWarranty = (data) => ({
    type: MERCHANT.SETTING.STORE.WARRANTY.POST,
    data,
});
export const updateStoreWarranty = (data, id) => ({
    type: MERCHANT.SETTING.STORE.WARRANTY.SET,
    data,
    id,
});

// setting - store category
export const getStoreCategoryDetails = (data) => ({
    type: "MERCHANT_SETTING_STORE_CATEGORY_GET",
    data,
});

export const setStoreCategoryDetails = (data) => ({
    type: "MERCHANT_SETTING_STORE_CATEGORY_SET",
    data,
});

export const resetStoreCategoryDetails = (data) => ({
    type: "MERCHANT_SETTING_STORE_CATEGORY_RESET",
    data,
});

export const clearStoreCategoryDetails = (data) => ({
    type: "MERCHANT_SETTING_STORE_CATEGORY_CLEAR",
    data,
});
export const editStoreSubcategory = (data) => ({
    type: "MERCHANT_SETTING_STORE_SUBCATEGORY_EDIT",
    data,
});
export const addStoreSubcategory = (data) => ({
    type: "MERCHANT_SETTING_STORE_SUBCATEGORY_ADD",
    data,
});
export const deleteStoreSubcategory = (data) => ({
    type: "MERCHANT_SETTING_STORE_SUBCATEGORY_DELETE",
    data,
});

// setting - shipping
export const getShippingSetting = () => ({
    type: MERCHANT.SETTING.SHIPPING.GET,
});
export const setShippingSetting = (data) => ({
    type: MERCHANT.SETTING.SHIPPING.SET,
    data,
});
export const updateShippingGeneral = (data) => ({
    type: MERCHANT.SETTING.SHIPPING.GENERAL.SET,
    data,
});
export const updatedShippingGeneral = (data) => ({
    type: MERCHANT.SETTING.SHIPPING.GENERAL.UPDATE,
    data,
});
export const updateShippingShippingMethod = (data) => ({
    type: MERCHANT.SETTING.SHIPPING.SHIPPINGMETHOD.SET,
    data,
});
export const updatedShippingShippingMethod = (data) => ({
    type: MERCHANT.SETTING.SHIPPING.SHIPPINGMETHOD.UPDATE,
    data,
});
export const createShippingTableRates = (data) => ({
    type: MERCHANT.SETTING.SHIPPING.TABLERATES.CREATE,
    data,
});
export const updateShippingTableRates = (data, args) => ({
    type: MERCHANT.SETTING.SHIPPING.TABLERATES.SET,
    data,
    args,
});
export const updatedShippingTableRates = (data) => ({
    type: MERCHANT.SETTING.SHIPPING.TABLERATES.UPDATE,
    data,
});
export const updateShippingDRPolicy = (data) => ({
    type: MERCHANT.SETTING.SHIPPING.DELIVERYRETURNPOLICY.SET,
    data,
});
export const updatedShippingDRPolicy = (data) => ({
    type: MERCHANT.SETTING.SHIPPING.DELIVERYRETURNPOLICY.UPDATE,
    data,
});

// product - init
export const getProductInitialConfig = () => ({
    type: MERCHANT.PRODUCT.CONFIG.GET,
});
export const setProductInitialConfig = (data) => ({
    type: MERCHANT.PRODUCT.CONFIG.SET,
    data,
});

export const getProductAttributeConfig = () => ({
    type: MERCHANT.PRODUCT.CONFIG.ATTRIBUTE_GET,
});

export const createProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.VARIATION.POST,
    data,
});
export const getProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.VARIATION.GET,
    data,
});
export const setProductEditVariationDraft = (data) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.SET,
    data,
});

export const createProductVariationTerms = (data, actionType = "add") => ({
    type: MERCHANT.PRODUCT.VARIATION.TERMS.POST,
    data,
    actionType,
});

export const getProductSubCategory = (id) => ({
    type: MERCHANT.PRODUCT.SUBCATEGORY.GET,
    id,
});
export const getProductSubSubCategory = (id) => ({
    type: MERCHANT.PRODUCT.SUBSUBCATEGORY.GET,
    id,
});

export const setProductDraft = (data) => ({
    type: MERCHANT.PRODUCT.DRAFT.SET,
    data,
});
export const orderProductVariationDraft = (data) => ({
    type: MERCHANT.PRODUCT.DRAFT.VARIATION.ORDER,
    data,
});
export const getProductVariationTermsDraft = (data) => ({
    type: MERCHANT.PRODUCT.DRAFT.VARIATION.TERMS.GET,
    data,
});
export const setProductVariationTermsDraft = (data) => ({
    type: MERCHANT.PRODUCT.DRAFT.VARIATION.TERMS.SET,
    data,
});

export const setProductBulkActionDraft = (data, args) => ({
    type: MERCHANT.PRODUCT.DRAFT.BULK_ACTION.SET,
    data,
    args,
});

// product - list
export const getProductList = () => ({ type: MERCHANT.PRODUCT.LIST.GET });
export const setProductList = (data) => ({
    type: MERCHANT.PRODUCT.LIST.SET,
    data,
});

// product on - sale / publish

export const setPLStatus = (data) => ({
    type: MERCHANT.PRODUCT.ONSALE.LISTING.SET,
    data,
});
export const renewPLStatus = (data) => ({
    type: MERCHANT.PRODUCT.ONSALE.LISTING.PUBLISHED,
    data,
});

export const setEditStatus = (data) => ({
    type: MERCHANT.PRODUCT.ONSALE.EDIT.SET,
    data,
});
export const renewEditStatus = (data) => ({
    type: MERCHANT.PRODUCT.ONSALE.EDIT.PUBLISHED,
    data,
});

export const setProductParam = (data) => ({
    type: MERCHANT.PRODUCT.PARAM.SET,
    data,
});

export const clearProductParam = () => ({ type: MERCHANT.PRODUCT.PARAM.CLEAR });

export const setProductChecklist = (id, status, type_action = "add") => ({
    type: MERCHANT.PRODUCT.CHECKBOX.SET,
    id,
    status,
    type_action,
});

// product - search
export const setProductSearch = (data) => ({
    type: MERCHANT.PRODUCT.SEARCH.SET,
    data,
});
export const clearQuery = (data) => ({ type: MERCHANT.PRODUCT.SEARCH.CLEAR, data });
export const findQuery = (keyword) => ({
    type: MERCHANT.PRODUCT.SEARCH.FIND.INIT,
    keyword,
});
export const setQuery = (data) => ({
    type: MERCHANT.PRODUCT.SEARCH.FIND.SET,
    data,
});
export const selectResellProduct = (data) => ({
    type: MERCHANT.PRODUCT.SEARCH.FIND.SELECTED,
    data,
});
export const confirmResellSelection = (data) => ({
    type: MERCHANT.PRODUCT.SEARCH.TEMPLATE.SELECT,
    data,
});
export const setResellSelection = (data) => ({
    type: MERCHANT.PRODUCT.SEARCH.TEMPLATE.SET,
    data,
});

// product - add
// product - add creation
export const createProduct = () => ({ type: MERCHANT.PRODUCT.CREATE.SELECT });

export const confirmCreateProduct = () => ({ type: MERCHANT.PRODUCT.ADD.CONFIRM });

export const setNewProductName = (name) => ({
    type: "SET_NEW_PRODUCT_NAME",
    name,
});

export const setAddProductDraft = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.SET,
    data,
});
export const updateAddProductDraft = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.UPDATE,
    data,
});
export const updatedAddProductDraft = (data, id) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.UPDATED,
    data,
    id,
});
export const setAddProductFieldDraft = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.FIELD.SET,
    data,
});
export const setAddProductDraftField = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.SALE.SET,
    data,
});
export const syncAddProductSpuToSku = (data) => ({
    type: "SYNC_ADD_SPU_SKU",
    data,
});
export const setAddOnsaleKey = (key, mode = "new") => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.SALE.SET_ID,
    key,
    mode,
});
export const deleteProductVariations = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.VARIATION.DELETE,
    data,
});
export const generateVariationSKU = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.VARIATION.SKU.UPDATE,
    data,
});
export const updateGeneratedSKU = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.VARIATION.SKU.UPDATED,
    data,
});
// product - add - variation
export const createAddProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.VARIATION.CREATE,
    data,
});
export const setAddProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.VARIATION.SET,
    data,
});
export const unsetAddProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.VARIATION.UNSET,
    data,
});
export const orderAddProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.VARIATION.ORDER,
    data,
});
// product - add - variation terms
export const createAddProductVariationTerms = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.VARIATION.TERMS.CREATE,
    data,
});
export const createdAddProductVariationTerms = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.VARIATION.TERMS.CREATED,
    data,
});
export const getAddProductVariationTerms = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.VARIATION.TERMS.GET,
    data,
});
export const setAddProductVariationTerms = (data) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.VARIATION.TERMS.SET,
    data,
});
export const deleteAddProductVariationTerms = (attribute_id, attribute_item_id) => ({
    type: MERCHANT.PRODUCT.ADD.DRAFT.VARIATION.TERMS.DELETE,
    data: { attribute_id, attribute_item_id },
});

export const setProductCreationPublishStatus = (status) => ({
    type: MERCHANT.PRODUCT.ADD.LISTING,
    data: status,
});

export const setCreateSubmitLoading = (isLoading = true) => ({
    type: "SET_CREATE_SUBMIT_LOADING",
    isLoading,
});

// --------------------------------------------------------------------------------------------------------- //
// product - selected

export const updateResellProductDraft = (data) => ({
    type: MERCHANT.PRODUCT.SELECTED.DRAFT.UPDATE,
    data,
});

export const updatedSelectedProductDraft = (data, id) => ({
    type: MERCHANT.PRODUCT.SELECTED.DRAFT.UPDATED,
    data,
    id,
});
export const setResellProductDraftField = (data) => ({
    type: MERCHANT.PRODUCT.SELECTED.DRAFT.SALE.SET,
    data,
});
export const setResellSubmitLoading = (isLoading = true) => ({
    type: "SET_RESELL_SUBMIT_LOADING",
    isLoading,
});
export const deleteSelectedProductSKU = (ids) => ({
    type: MERCHANT.PRODUCT.SELECTED.DRAFT.VARIATION.SKU.DELETE.PUBLISH,
    ids,
});
// product - add - variation
export const createSelectedProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.SELECTED.DRAFT.VARIATION.CREATE,
    data,
});
export const setSelectedProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.SELECTED.DRAFT.VARIATION.SET,
    data,
});
export const orderSelectedProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.SELECTED.DRAFT.VARIATION.ORDER,
    data,
});

export const setProductResellPublishStatus = (data) => ({
    type: "SET_RESELL_PUBLISH_STATUS",
    data,
});

export const confirmResellProduct = (data) => ({
    type: "CONFIRM_RESELL_PRODUCT",
    data,
});
// product - add - variation terms
export const createSelectedProductVariationTerms = (data) => ({
    type: MERCHANT.PRODUCT.SELECTED.DRAFT.VARIATION.TERMS.CREATE,
    data,
});
export const getSelectedProductVariationTerms = (data) => ({
    type: MERCHANT.PRODUCT.SELECTED.DRAFT.VARIATION.TERMS.GET,
    data,
});
export const clearSelectedProductVariationTerms = (attribute_id) => ({
    type: MERCHANT.PRODUCT.SELECTED.DRAFT.VARIATION.TERMS.CLEAR,
    attribute_id,
});
export const setSelectedProductVariationTerms = (data, attribute_id, opt_data) => ({
    type: MERCHANT.PRODUCT.SELECTED.DRAFT.VARIATION.TERMS.SET,
    data,
    attribute_id,
    opt_data,
});
export const deleteSelectedProductVariationTerms = (data, attribute_id) => ({
    type: MERCHANT.PRODUCT.SELECTED.DRAFT.VARIATION.TERMS.DELETE,
    data,
    attribute_id,
});

export const processEditProduct = (key) => ({
    type: MERCHANT.PRODUCT.EDIT.PROCESS,
    key,
});

export const confirmEditProduct = (data) => ({
    type: "EDIT_PRODUCT_CONFIRM",
    data,
});
export const setEditSubmitLoading = (isLoading = true) => ({
    type: "EDIT_SUBMIT_LOADING",
    isLoading,
});
export const setEditProductLoading = (isLoading = true) => ({
    type: "EDIT_PRODUCT_LOADING",
    isLoading,
});
export const setEditProductDraft = (data) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.SET,
    data,
});
export const updateEditProductDraft = (data, id, args) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.UPDATE,
    data,
    id,
    args,
});
export const updatedEditProductDraft = (data, id) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.UPDATED,
    data,
    id,
});
export const setEditProductDraftField = (data, args = 0) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.SALE.SET,
    data,
    args,
});
export const deleteEditProductSKU = (ids) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.SKU.DELETE.PUBLISH,
    ids,
});
export const changeStatusEditProductSKU = (data) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.SKU.STATUS.CHANGE,
    data,
});
export const updatedStatusEditProductSKU = (data) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.SKU.STATUS.UPDATED,
    data,
});

// product - add - variation
export const createEditProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.CREATE,
    data,
});
export const setEditProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.SET,
    data,
});
export const unsetEditProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.UNSET,
    data,
});
export const orderEditProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.ORDER,
    data,
});
export const setStatusEditProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.STATUS.SET,
    data,
});
export const updateStatusEditProductVariation = (data) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.STATUS.UPDATE,
    data,
});
// product - add - variation terms
export const createEditProductVariationTerms = (data) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.TERMS.CREATE,
    data,
});

export const clearEditProductVariationTerms = (attribute_id) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.TERMS.CLEAR,
    attribute_id,
});
export const setEditProductVariationTerms = (data, attribute_id, opt_data = {}) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.TERMS.SET,
    data,
    attribute_id,
    opt_data,
});
export const deleteEditProductVariationTerms = (data, attribute_id) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.VARIATION.TERMS.DELETE,
    data,
    attribute_id,
});

// product - edit - bulk action
export const bulkUpdateEditProductDraft = (data) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.BULK_ACTION.PROCESS,
    data,
});
export const setBulkAction = (data) => ({
    type: MERCHANT.PRODUCT.EDIT.DRAFT.BULK_ACTION.SET,
    data,
});

// product - order
export const showOrderDetail = (params) => ({
    type: MERCHANT.ORDER.SHOW_DETAIL,
    params,
});

export const setOrderDetail = (data, args = 0) => ({
    type: MERCHANT.ORDER.SET_DETAIL,
    data,
    args,
});

export const setOrderLogs = (data, args = 0) => ({
    type: MERCHANT.ORDER.SET_LOGS,
    data,
    args,
});

export const clearOrderDetail = (args = 0) => ({
    type: MERCHANT.ORDER.CLEAR_DETAIL,
    args,
});

export const setOrderList = (data) => ({
    type: MERCHANT.ORDER.SET_LIST,
    data,
});

export const setOrderParam = (data) => ({
    type: MERCHANT.ORDER.SET_PARAM,
    data,
});

export const postOrderNote = (data) => ({
    type: MERCHANT.ORDER.POST_NOTE,
    data,
});

export const confirmOrder = (data) => ({
    type: MERCHANT.ORDER.UPDATE_SHIPMENT,
    data,
});

export const refundOrder = (data) => ({});
export const confirmRefundOrder = (data) => ({});
export const exchangeOrder = (data) => ({});
export const confirmExchangeOrder = (data) => ({});
export const submitOrderNote = (data) => ({});

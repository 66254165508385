import { Badge, Icon, IconButton, styled } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

export const YHIcon = styled("span")(({ size, width, height, color, src, cursor, mask }) => ({
    display: "block",
    width: size ? size : width ? width : "24px",
    height: size ? size : height ? height : "24px",
    background: color ? color : "#424242",
    backgroundSize: "cover",
    maskSize: "cover !important",
    mask: `url(${src})`,
    WebkitMaskSize: mask?.size ? mask?.size : "25px 25px !important",
    WebkitMaskRepeat: mask?.repeat ? mask?.repeat : "no-repeat",
    WebkitMaskPosition: mask?.position ? mask?.position : "center",
    cursor: cursor ? cursor : "context-menu",
}));

export const StyledButtonIcon = styled(IconButton)(({ theme, width, height }) => ({
    width: width ? width + 16 : 40,
    height: height ? height : width ? width + 16 : 40,
    borderRadius: height ? height / 2 : width ? width / 2 : 20,
    padding: "8px 12px",
    "&:hover": {
        backgroundColor: theme.yoho.md_color.blue[50],
    },
}));

export const StyledIcon = styled(Icon)(({ theme, size, cursor }) => ({
    width: size ? size + 16 : 40,
    height: size ? size + 16 : 40,
    padding: "6px 12px",
    cursor: cursor || "default",
    "& img": {
        display: "flex",
        height: "inherit",
        width: "inherit",
    },
}));

export const YohoIcon = ({ src, ...props }) => {
    return (
        <StyledIcon {...props} color="primary">
            <img src={src} />
        </StyledIcon>
    );
};

const StyledBadge = styled(Badge)(({ theme }) => ({
    width: "inherit",
    "& .MuiBadge-colorPrimary": {
        backgroundColor: theme.yoho.md_color.red[500],
        color: theme.yoho.color.white,
    },
    "&.MuiBadge-root": {
        verticalAlign: "unset !important",
    },
}));

const ButtonWord = styled("div")(({ theme }) => ({
    display: "inline-block",
    width: "90%",
    color: theme.palette.grey[700],
    marginRight: 8,
    fontSize: 16,
}));

export const YohoButtonIcon = ({ src, text, ...props }) => {
    const imageStyle = {
        height: props.height ? props.height - 10 : "inherit",
        width: props.height ? props.height - 10 : "inherit",
    };

    return (
        <StyledButtonIcon color="primary" {...props}>
            {!!text && <ButtonWord>{text}</ButtonWord>}
            <img src={src} style={imageStyle} />
        </StyledButtonIcon>
    );
};

export const YohoBadgeIcon = ({ src, badgeContent, ...props }) => {
    return (
        <StyledIcon color="primary" {...props}>
            <StyledBadge color="primary" badgeContent={badgeContent}>
                <img src={src} />
            </StyledBadge>
        </StyledIcon>
    );
};

export const YHPagination = ({ size, action = () => {}, page }) => {
    return (
        <div>
            <Pagination page={page} count={size || 1} onChange={(_, data) => action(data)} />
        </div>
    );
};

export const YohoLabel = styled("div")(({ theme, backgroundcolor, textcolor }) => ({
    ...theme.yoho.typography.subtitle3,
    height: "24px",
    padding: "4px 7px",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor:
        typeof backgroundcolor === "object"
            ? theme.yoho.md_color[backgroundcolor.color][backgroundcolor.coded || 50]
            : backgroundcolor || "#ffffff",
    color:
        typeof textcolor === "object"
            ? theme.yoho.md_color[textcolor.color][textcolor.code || 500]
            : textcolor || "#000000",
}));
export const YHLogo = () => (
    <img
        src={`/assets/images/yoho_logo${new Date().getMonth() + 1 == 12 ? "_christmas" : ""}.png`}
        className="main-logo"
    ></img>
);

export {
    FormCategory,
    FormTitle,
    FormButton,
    YohoSelect,
    YohoCheckbox,
    YohoRadio,
    YohoDatepicker,
    YohoFileupload,
    YohoBrandFileUpload,
    YohoProductFileUpload,
} from "./Form";

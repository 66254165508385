import produce, { current } from "immer";
import * as siteAction from "service/actions/site";
import { merchantroute, adminroute } from "helper/nav-route";

let initialState = {
    loading: {},
    isloading: false,
    menu: undefined,
    popup: undefined,
    popupLoading: {
        loading: false,
        message: "",
    },
    backdrop: false,
    snackbar: { open: false, message: "", severity: "info" },
    drawer: true,
    redirect: null,
    master: {},
    config_privilege: null,
    config_countries: null,
    config_internationalcode: null,
    config_departments: null,
    config_product: null,
    config_navbar: [
        adminroute("application"),
        adminroute("merchant"),
        adminroute("products"),
        adminroute("administration"),
    ],
    route_permission: null,
};
const selectedEntry = new Set();
export default produce((draft, action) => {
    const currentDraft = current(draft);
    switch (action.type) {
        case siteAction.setInitialForm().type:
            draft.master = produce(currentDraft.master, (props) => {
                props = { ...props, ...action.data };
                return props;
            });
            break;
        case siteAction.setLanguage().type:
            draft.language = action.data;
            break;
        case siteAction.setLoading().type:
            draft.loading = produce(currentDraft.loading, (props) => {
                props[action.data.key] = action.data.loading;
            });
            break;
        case siteAction.setDrawer().type:
            draft.drawer = action.data;
            break;
        case siteAction.setSnackbar().type:
            draft.snackbar = action.data;
            break;
        case siteAction.setPopup().type:
            draft.popup = action.data;
            break;
        case siteAction.unsetPopup().type:
            draft.popup = false;
            break;
        case siteAction.setPopupLoading().type:
            draft.popupLoading = action.payload;
            break;
        case siteAction.setMenu().type:
            draft.menu = action.data;
            break;
        case siteAction.unsetMenu().type:
            draft.menu = false;
            break;
        case siteAction.setRedirect().type:
            draft.popup = false;
            draft.menu = false;
            draft.redirect = action.data;
            break;
        case siteAction.setCategoryData().type:
            draft.master = produce(currentDraft.master, (props) => {
                props.category = action.data;
            });
            break;
        case siteAction.setSubDistrictData().type:
            draft.master = produce(currentDraft.master, (props) => {
                props.subdistrict = action.data;
            });
            break;

        case siteAction.getAreaByCountry().type:
            draft.isloading = true;
            break;
        case siteAction.setAreaByCountry().type:
            draft.master = produce(currentDraft.master, (props) => {
                props.areas = action.data;
            });
            draft.isloading = false;
            break;
        case siteAction.setMasterDataConfig().type:
            const keys = Object.keys(action.data);
            keys.forEach((key) => {
                draft[`config_${key}`] = action.data[key];
            });
            break;
        case siteAction.setPermissionRoleField().type:
            draft.config_privilege = produce(currentDraft.config_privilege, (props) => {
                return (props = action.data);
            });
            break;
        case siteAction.setNavbarRoute().type:
            const { matrixs, routes, access } = action.data;
            const na_access = access.find((acc) => acc.access === "na").id;
            const [dd] = [
                routes
                    .filter((route) => matrixs.map((mat) => mat.route_id).includes(route.id))
                    .map((rt) => rt.route_url),
            ];
            const [routeaccess] = [matrixs.filter((matr) => matr.access_id !== na_access)];
            draft.config_navbar = produce(currentDraft.config_navbar, (props) => {
                props = routeaccess.map((item) => merchantroute(item.route_url));
                return props;
            });
            break;
        case siteAction.setPermission().type:
            draft.route_permission = action.data;
            break;
        case siteAction.selectTableData().type:
            selectedEntry[selectedEntry.has(action.data) ? "delete" : "add"](action.data);
            const entrydata = Array.from(selectedEntry);
            if (Array.isArray(entrydata)) {
                draft.selected = entrydata;
            }
            break;
        default:
    }
}, initialState);

import Autocomplete from "@material-ui/lab/Autocomplete";
import { useCallback, useEffect, useState } from "react";
import { TextField, Paper } from "@material-ui/core";
import { debounce } from "lodash";

export default function FormAutocomplete({
    name,
    value,
    options,
    onChange,
    getOptions,
    optionsFilter,
    helperText,
    style,
    label,
    error,
}) {
    const [searchText, setSearchText] = useState("");
    const [localOptions, setLocalOptions] = useState([]);

    const getOptionsDebounce = useCallback(
        debounce(async (searchText) => {
            if (!getOptions) return;
            const result = await getOptions(searchText);
            setLocalOptions(result);
        }, 300),
        [getOptions],
    );

    useEffect(() => {
        getOptionsDebounce(searchText);
    }, [searchText]);

    return (
        <Autocomplete
            style={style}
            options={options || localOptions}
            inputValue={searchText}
            value={value}
            getOptionLabel={(option) => option.label}
            filterOptions={optionsFilter || (getOptions ? (opt) => opt : undefined)}
            renderInput={(params) => (
                <TextField {...params} label={label} error={error} helperText={helperText} variant="outlined" />
            )}
            PaperComponent={({ children, params }) => (
                <Paper {...params} style={{ width: "100%" }}>
                    {children}
                </Paper>
            )}
            onInputChange={(_, searchValue) => setSearchText(searchValue)}
            onChange={(_, selectedValue) => {
                setSearchText("");
                onChange(name, selectedValue);
            }}
        />
    );
}

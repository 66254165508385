import { styled } from "@material-ui/core";
import { FormButton } from "components/Global";
import React from "react";
import { useDispatch } from "react-redux";
import { resetPasswordMerchant, resetStatusMerchant } from "service/actions/admin";

const ActionCustomColumn = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 7,
    alignItems: "center",
});

export default ({ data, active, search }) => {
    const dispatch = useDispatch();

    const resetStatusAccount = (data, args) => {
        dispatch(resetStatusMerchant(data, args));
        return;
    };

    const resetPasswordAccount = (data, args) => {
        dispatch(resetPasswordMerchant(data, args));
        return;
    };

    return (
        <ActionCustomColumn>
            <FormButton
                height={25}
                width={180}
                ccolor="red"
                cvariant="contained"
                onClick={() =>
                    resetStatusAccount(
                        {
                            id: data.id,
                            status: active === "active" ? "inactive" : "active",
                        },
                        { status: active, search: search },
                    )
                }
            >
                {active === "active" ? "Disable Account" : "Enable Account"}
            </FormButton>
            <FormButton
                height={25}
                width={180}
                ccolor="red"
                cvariant="outlined"
                onClick={() =>
                    resetPasswordAccount(
                        {
                            email: data.personal_email,
                            callback_url: `${window.location.origin}/auth/forgotpass/verify`,
                        },
                        { status: active, search: search },
                    )
                }
            >
                Reset Password
            </FormButton>
        </ActionCustomColumn>
    );
};

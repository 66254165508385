import { Box, makeStyles, Popper, styled, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import MultiSelection from "components/Global/Form/AutoComplete/Uncontrolled/MultiSelection";
import MultipleTag from "components/Global/Form/MultipleTag";
import { TextSelection } from "components/Global/Form/NormalSelect";
import { stringSize } from "helper";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setResellProductDraftField } from "service/actions/merchant";

const Category = styled("div")({
    display: "flex",
    width: "100% !important",
    gap: 20,
});

const useStyles = makeStyles((theme) => ({
    input: {
        padding: "18.5px 38px 18.5px 14px",
    },
}));

const PNBox = styled("div")(({ theme, ...rest }) => {
    const counter = rest?.children?.find((item) => item.props.className === "counter");

    return {
        position: "relative",
        "& .counter": {
            position: "absolute",
            display: "flex",
            alignItems: "center",
            right: 6,
            fontSize: 12,
            top: "calc(50% - 6px)",
            fontWeight: counter.props.children < 120 ? "normal" : "bold",
            color: theme.yoho.md_color[counter.props.children < 120 ? "grey" : "red"][400],
        },
    };
});

export default () => {
    const dispatch = useDispatch();
    const vitaldata = useSelector((state) => state.merchant.product.config);
    const draft = useSelector((state) => state.merchant.product.selected);
    const classes = useStyles();

    const debounceFn = debounce((inputValue) => {
        dispatch(setResellProductDraftField(inputValue));
    }, 200);

    const handleInput = (e) => {
        debounceFn({ [e.target.name]: e.target.value });
    };

    const handleKeywordChange = (value) => {
        dispatch(setResellProductDraftField(value));
    };

    const handleFieldChange = (e) => {
        dispatch(setResellProductDraftField({ [e.target.name]: e.target.value }));
    };

    const handleAutoComplete = (param, selection, action, test) => {
        if (action === "clear") {
            dispatch(setResellProductDraftField({ [param.name]: null }));
            return;
        }
        dispatch(setResellProductDraftField({ [param.name]: selection[param.key] }));
    };

    const filterOptions = createFilterOptions({
        matchFrom: "any",
        stringify: (option) => option?.brand_name,
    });

    return (
        <div>
            <FormCategory>
                <Autocomplete
                    disabled={true}
                    filterOptions={filterOptions}
                    className="full"
                    name="vital_info_brand"
                    options={vitaldata.brands}
                    defaultValue={vitaldata.brands?.find((item) => item.brand_id == draft?.data?.spu?.vital_info_brand)}
                    getOptionLabel={(option) => option.brand_name}
                    renderInput={(params) => <TextField variant="outlined" {...params} label="Brand" />}
                />
                <PNBox className="full">
                    <TextField
                        fullWidth
                        disabled={true}
                        name="vital_info_product_name_chinese"
                        variant="outlined"
                        label="Product Name (Traditional Chinese)"
                        onInput={(char) => {
                            char.target.value =
                                stringSize(char.target.value) <= 120
                                    ? char.target.value
                                    : draft?.data?.spu?.vital_info_product_name_chinese;
                        }}
                        defaultValue={draft?.data?.spu?.vital_info_product_name_chinese}
                        onChange={handleInput}
                        inputProps={{
                            className: classes.input,
                        }}
                    />
                    <div className="counter">{stringSize(draft?.data?.spu?.vital_info_product_name_chinese)}</div>
                </PNBox>
                <PNBox className="full">
                    <TextField
                        fullWidth
                        disabled={true}
                        name="vital_info_product_name_english"
                        variant="outlined"
                        label="Product Name (English)"
                        onInput={(char) => {
                            char.target.value =
                                stringSize(char.target.value) <= 120
                                    ? char.target.value
                                    : draft?.data?.spu?.vital_info_product_name_english;
                        }}
                        defaultValue={draft?.data?.spu?.vital_info_product_name_english}
                        onChange={handleInput}
                        inputProps={{
                            className: classes.input,
                        }}
                    />
                    <div className="counter">{stringSize(draft?.data?.spu?.vital_info_product_name_english)}</div>
                </PNBox>
                <Category>
                    <FormText>
                        <h5>Category:</h5>
                    </FormText>
                    <FormText>
                        <h5>{draft?.data?.spu?.category_levels}</h5>
                    </FormText>
                </Category>

                <TextSelection
                    disabled
                    label="Type of Item"
                    name="vital_info_type_of_item"
                    data={vitaldata.type_of_item}
                    keyValue="id"
                    keyDisplay="name"
                    defaultValue={
                        vitaldata.type_of_item?.find((item) => item.id == draft?.data?.spu?.vital_info_type_of_item)?.id
                    }
                    onChange={handleFieldChange}
                />
                <Autocomplete
                    fullWidth
                    name="vital_info_origin_of_goods"
                    options={vitaldata.country_of_origin_id}
                    defaultValue={vitaldata.country_of_origin_id?.find(
                        (item) => item.id == draft?.data?.spu?.vital_info_origin_of_goods,
                    )}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, props) => {
                        return (
                            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.name}
                            </Box>
                        );
                    }}
                    onChange={(_, e, event) =>
                        handleAutoComplete({ name: "vital_info_origin_of_goods", key: "id" }, e, event)
                    }
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} label="Country of manufacture (made in)" />
                    )}
                    PopperComponent={(props) => (
                        <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />
                    )}
                />
                <TextSelection
                    label="Source of product"
                    name="vital_info_source_of_product"
                    data={vitaldata.source_of_product}
                    keyValue="id"
                    keyDisplay="name"
                    defaultValue={
                        vitaldata.source_of_product?.find(
                            (item) => item.id == draft?.data?.spu?.vital_info_source_of_product,
                        )?.id
                    }
                    onChange={handleFieldChange}
                />
                <MultiSelection
                    label="Recognition Rewards"
                    name="vital_info_recognition_rewards"
                    data={vitaldata.recognition_reward}
                    defaultValue={draft?.data?.spu?.vital_info_recognition_rewards}
                    onChange={handleFieldChange}
                />
                <MultipleTag
                    label="Chinese Keywords (Press enter to confirm)"
                    name="vital_info_keyword_chinese"
                    tagList={draft?.data?.spu?.vital_info_keyword_chinese || []}
                    onChange={handleKeywordChange}
                    tagLenLimit={8}
                />
                <MultipleTag
                    label="English Keywords (Press enter to confirm)"
                    name="vital_info_keyword_english"
                    tagList={draft?.data?.spu?.vital_info_keyword_english || []}
                    onChange={handleKeywordChange}
                    tagLenLimit={25}
                />
            </FormCategory>
        </div>
    );
};

import Topleft from "./TopLeft";
import TopRight from "./TopRight";

const Topbar = ({ children }) => {
    return children;
};

Topbar.defaultProps = {
    __type: "top",
};

export { Topbar, Topleft, TopRight };

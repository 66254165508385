import { YohoIcon } from "components/Global";
import { useState } from "react";
import { useAdminProductActions } from "../../../hooks";
// import { useAdminProductActions } from "../../hooks";

export default (props) => {
    const [image, setImage] = useState(false);
    const { setDefaultProduct } = useAdminProductActions();

    return props.is_primary ? (
        <YohoIcon src="/assets/icon/check.svg" size="unset" />
    ) : (
        <div
            onMouseOver={() => setImage(true)}
            onMouseLeave={() => setImage(false)}
            style={{
                width: 24,
                height: "100%",
                display: "flex",
                alignItems: "center",
            }}
        >
            {image ? (
                <YohoIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setDefaultProduct({ goods_id: props.goods_id })}
                    onMouseLeave={() => setImage(false)}
                    src="/assets/icon/grey-check.svg"
                    size="unset"
                />
            ) : null}
        </div>
    );
};

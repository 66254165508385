import { styled } from "@material-ui/core";
import { FormText } from "components/Global/Form";
import useMenu from "hooks/useMenu";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { unsetAddProductVariation, unsetEditProductVariation } from "service/actions/merchant";
import { unsetMenu } from "service/actions/site";
import ProductEditVariationTerms from "./ProductEditVariationTerms";
import VariationActionMenu from "./menu/VariationActionMenu";

const VariationItemWrapper = styled("div")({
    display: "flex",
    gap: 20,
    alignItems: "center",
    "& .column": {
        display: "flex",
        gap: 20,
        alignItems: "center",
        width: "50%",
        "& .icon-drag": {
            display: "inline-block",
            width: "10px",
            height: "62px",
            backgroundSize: "cover",
            backgroundImage: `url("/assets/icon/drag.svg")`,
        },
        "& img": {
            cursor: "pointer",
            "&:hover": {
                filter: "invert(58%) sepia(48%) saturate(4773%) hue-rotate(162deg) brightness(98%) contrast(103%)",
            },
        },
        "& > div:first-child": {
            width: 200,
        },
        "& > div:last-of-type": {
            width: "100%",
        },
    },
});

export default ({ data }) => {
    const dispatch = useDispatch();
    const variationRef = useRef();
    const { menu } = useMenu();

    return (
        <VariationItemWrapper>
            <div className="column">
                <img src="/assets/icon/drag.svg" />
                <FormText>
                    <p className="body1">
                        {data?.attribute_name_chinese} {data?.attribute_name_english}
                    </p>
                </FormText>
                {data.status !== "published" && (
                    <img
                        ref={variationRef}
                        src="/assets/icon/more.svg"
                        onClick={() => {
                            menu(VariationActionMenu, {
                                refs: variationRef,
                                removeAction: () => {
                                    dispatch(unsetEditProductVariation(data));
                                    dispatch(unsetMenu());
                                },
                            });
                        }}
                    />
                )}
            </div>
            <div className="column">
                <ProductEditVariationTerms label="Variation Terms" name="test" data_variation={data} disabled />
            </div>
        </VariationItemWrapper>
    );
};

import { FORM, ACTION } from "service/constants/authentication";

export const setLoginMode = (data) => ({
    type: ACTION.MODE.LOGIN.SET,
    data,
});

// New Merchant Form Generator
export const getInitialForm = () => ({
    type: FORM.INITIAL_FORM_GET,
});

export const setInitialForm = (data) => ({
    type: FORM.INITIAL_FORM_SET,
    data,
});

export const errorInitialForm = () => ({
    type: FORM.INITIAL_FORM_ERROR,
});

export const getAreaByCountry = (data) => ({
    type: FORM.DATA.AREA.BY_COUNTRY.GET,
    data,
});

export const setAreaByCountry = (data) => ({
    type: FORM.DATA.AREA.BY_COUNTRY.SET,
    data,
});

export const getSubDistrict = (data) => ({
    type: FORM.DATA.SUBDISTRICT.BY_AREA.GET,
    data,
});

export const setSubDistrict = (data) => ({
    type: FORM.DATA.SUBDISTRICT.BY_AREA.SET,
    data,
});

// Register New Merchant with data Form
export const getRegisterMerchantData = (data) => ({
    type: ACTION.REGISTER.GET,
    data,
});

export const setRegisterMerchantData = (data) => ({
    type: ACTION.REGISTER.SET,
    data,
});

export const errorRegisterMerchantData = (data) => ({
    type: ACTION.REGISTER.ERROR,
    data,
});

export const getLoginData = (data) => ({
    type: ACTION.LOGIN.GET,
    data,
});

export const setLoginData = (data) => ({
    type: ACTION.LOGIN.SET,
    data,
});

export const setLogout = (data) => ({
    type: ACTION.LOGOUT.SET,
    data,
});
// Forgot Password
export const forgotPasswordRequest = (data) => ({
    type: ACTION.RESET.PASSWORD.REQUEST,
    data,
});

export const setForgotPasswordEmail = (data) => ({
    type: ACTION.RESET.PASSWORD.EMAIL,
    data,
});

export const getForgotPasswordVerify = (data) => ({
    type: ACTION.RESET.PASSWORD.VERIFY.GET,
    data,
});

export const setForgotPasswordVerify = (data) => ({
    type: ACTION.RESET.PASSWORD.VERIFY.SET,
    data,
});

export const forgotPasswordChange = (data) => ({
    type: ACTION.RESET.PASSWORD.CHANGE,
    data,
});

export const getMerchantReviseData = (data) => ({
    type: FORM.DATA.MERCHANT.REVISE.GET,
    data,
});

export const setMerchantReviseData = (data) => ({
    type: FORM.DATA.MERCHANT.REVISE.SET,
    data,
});

export const clearMerchantReviseData = (data) => ({
    type: FORM.DATA.MERCHANT.REVISE.CLEAR,
    data,
});

export const startImpersonation = (data) => ({
    type: ACTION.IMPERSONATE.START,
    data,
});

export const stopImpersonation = () => ({
    type: ACTION.IMPERSONATE.STOP,
});

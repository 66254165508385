import { styled, TextField } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
const charLimt = {
    description_introduction_english: 255,
    description_introduction_chinese: 85,
    base: 255,
};
const TextArea = styled("div")({
    width: "100%",
    position: "relative",
});

const Counter = styled("span")(({ children, theme }) => ({
    position: "absolute",
    bottom: "8px",
    right: "10px",
    color: children >= charLimt.base ? theme.yoho.md_color.red[500] : "black",
}));

const len = (str) => {
    let size = new Blob([str]).size;
    return size;
};

export default ({ name, action, draft }) => {
    const counter = React.useMemo(() => {
        return len(draft?.spu?.[name]);
    }, [draft?.spu?.[name]]);

    return (
        <TextArea>
            <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={6}
                defaultValue={draft?.spu?.[name]}
                name={name}
                onChange={action}
                onInput={(char) => {
                    char.target.value = len(char.target.value) <= 255 ? char.target.value : char.target.defaultValue;
                }}
            />
            <Counter>{counter}</Counter>
        </TextArea>
    );
};

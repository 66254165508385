export const DEFAULT_DAYS_TO_SHIP = [
    {
        value: "1,2",
    },
    {
        value: "3,5",
    },
    {
        value: "6,10",
    },
];

import AddProduct from "components/MerchantCentre/MerchantConsole/Products/Add";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProductInitialConfig } from "service/actions/merchant";

export default () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProductInitialConfig());
    }, []);

    return <AddProduct />;
};

import { styled } from "@material-ui/core";

const MenuContainer = styled("div")({
    overflowX: "auto",
    display: "flex",
    gap: 50,
});

const Menu = styled("h4")(({ active, theme }) => ({
    ...theme.yoho.typography.button,
    color: active === "active" ? theme.yoho.md_color.blue[500] : theme.yoho.md_color.grey[800],
    display: "flex",
    gap: "10px",
    textTransform: "capitalize",
    cursor: "pointer",
    margin: "12px 0",
    fontSize: 18,
    "& .bubble": {
        padding: "1px 9px",
        borderRadius: 10,
        backgroundColor: theme.palette.grey[200],
        fontSize: 17,
    },
}));

const Divider = styled("div")(({ theme }) => ({
    width: "100%",
    borderBottom: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

export default function Tab({ menu, active, setActive }) {
    const renderMenu = typeof menu[0] === "object" ? menu : menu.map((item) => ({ name: item }));

    return (
        <div>
            <MenuContainer>
                {renderMenu.map((item) => (
                    <Menu
                        key={`${JSON.stringify(item.name)}`}
                        active={active === item.name ? "active" : ""}
                        className={active === item.name ? "active" : ""}
                        onClick={() => setActive(item.name)}
                    >
                        {item.name}
                        {item.count != undefined && <span className="bubble">{item.count}</span>}
                    </Menu>
                ))}
            </MenuContainer>
            <Divider />
        </div>
    );
}

import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    styled,
} from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const alignItems = {
    "& .align-items-center": {
        alignItems: "center",
    },
};

const flexGap = {
    "& .gap-5": { gap: 5 },
    "& .gap-10": { gap: 10 },
    "& .gap-15": { gap: 15 },
    "& .gap-20": { gap: 20 },
    "& .gap-25": { gap: 25 },
    "& .gap-30": { gap: 30 },
    "& .gap-35": { gap: 35 },
    "& .gap-40": { gap: 40 },
    "& .gap-45": { gap: 45 },
    "& .gap-50": { gap: 50 },
};

const init = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
};

const contentSize = {
    "& .w-1": { width: "calc(1 / 12 * 100%)" },
    "& .w-2": { width: "calc(2 / 12 * 100%)" },
    "& .w-3": { width: "calc(3 / 12 * 100%)" },
    "& .w-4": { width: "calc(4 / 12 * 100%)" },
    "& .w-5": { width: "calc(5 / 12 * 100%)" },
    "& .w-6": { width: "calc(6 / 12 * 100%)" },
    "& .w-7": { width: "calc(7 / 12 * 100%)" },
    "& .w-8": { width: "calc(8 / 12 * 100%)" },
    "& .w-9": { width: "calc(9 / 12 * 100%)" },
    "& .w-10": { width: "calc(10 / 12 * 100%)" },
    "& .w-11": { width: "calc(11 / 12 * 100%)" },
    "& .w-12": { width: "calc(12 / 12 * 100%)" },
};

export const FormCategory = styled("div")(({ theme, justifycontent = "unset" }) => ({
    ...init,
    justifyContent: justifycontent,
    gap: "16px",
    padding: "22px",
    "& .flex": { display: "flex" },
    "& .col": { flexDirection: "column" },
    "& > div": {
        width: "calc(50% - 8px)",
    },
    "& .half": {
        width: "calc(50% - 8px)",
    },
    "& .full": {
        width: "100%",
    },
    "& div.w-3": {
        width: "calc(33.3% - 11px)",
    },
    "& div.w-2": {
        width: "calc(25% - 12px)",
    },
    "& fieldset.MuiFormControl-root": {
        marginBottom: "25px",
        "& > legend": {
            ...theme.yoho.typography.subtitle1,
            color: "#424242",
        },
        "& > div": {
            padding: "0 14px",
            "& > label": {
                width: "calc(25% - 5px)",
            },
        },
    },
    "& div.MuiFormControl-root.Yohoradio": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        "& > legend": {
            ...theme.yoho.typography.subtitle1,
            margin: 0,
        },
        "& > div": {
            padding: "0 14px",
            width: "80%",
            "& > label": {
                width: "calc(25% - 5px)",
            },
        },
    },
    ...alignItems,
    ...flexGap,
    ...contentSize,
}));

export const FormTitle = styled("h4")(({ theme }) => ({
    ...theme.yoho.typography.h4,
    width: "100%",
    margin: 0,
}));

export const FormText = styled("div")(({ theme, color, code }) => ({
    color: color && code ? theme.yoho.md_color[color][code] : theme.yoho.md_color.grey[800],
    "& p": {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "20px",
        margin: 0,
        position: "relative",
    },
    "& span": {
        ...theme.yoho.typography.subtitle1,
        margin: 0,
        "&:before": {
            content: "attr(data-text)",
            position: "absolute",
            transform: "translateY(-100%)",
            zIndex: 500,
            padding: "5px",
            borderRadius: "10px",
            background: "rgb(88,88,88)",
            color: "#fff",
            textAlign: "center",
            display: "none",
            fontSize: "11px",
            fontWeight: "normal",
        },
        "&:hover": {
            "&:before": {
                display: "block",
            },
        },
    },
    "& p.subtitle1": {
        ...theme.yoho.typography.subtitle1,
        margin: 0,
    },
    "& p.subtitle2": {
        ...theme.yoho.typography.subtitle2,
        margin: 0,
    },
    "& p.subtitle3": {
        ...theme.yoho.typography.subtitle3,
        margin: 0,
    },
    "& p.body1": {
        ...theme.yoho.typography.body1,
        margin: 0,
    },
    "& p.body2": {
        ...theme.yoho.typography.body2,
        margin: 0,
    },
    "& h1": {
        ...theme.yoho.typography.h1,
        margin: 0,
    },
    "& h2": {
        ...theme.yoho.typography.h2,
        margin: 0,
    },
    "& h3": {
        ...theme.yoho.typography.h3,
        margin: 0,
    },
    "& h4": {
        ...theme.yoho.typography.h4,
        margin: 0,
    },
    "& h5": {
        ...theme.yoho.typography.h5,
        margin: 0,
    },
    "& h6": {
        ...theme.yoho.typography.h6,
        margin: 0,
    },
    "& p.text": {
        ...theme.yoho.typography.body2,
        margin: 0,
        color: theme.yoho.md_color.grey[400],
    },
    "& p.button": {
        ...theme.yoho.typography.button,
        margin: 0,
    },
}));

export const StyledFormButton = styled(Button)(
    ({ theme, cvariant = "contained", ccolor = "blue", height = 52, width = "calc(50% - 20px)" }) => ({
        ...theme.yoho.button[cvariant][ccolor],
        height: height,
        width: width,
        textTransform: "capitalize",
        fontSize: 14,
        padding: "0 20px",
    }),
);

export const FormButton = (props) => {
    const permission = useSelector((state) => state.site.route_permission);
    return <StyledFormButton {...props} {...(permission === "read" && { disabled: true })} />;
};

export const FormStackVertical = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
}));

export const FormStackHorizontal = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
}));

export const ButtonLink = styled(Link)(({ theme, cvariant, ccolor }) => ({
    ...theme.yoho.button[cvariant][ccolor],
    textDecoration: "none",
}));

export const PasswordField = ({ errors, inputRef, name, label, className, style }) => {
    const [show, setShow] = useState(false);
    return (
        <FormControl className={className} variant="outlined" style={style}>
            <InputLabel error={name in errors} htmlFor={name}>
                {label || name || ""}
            </InputLabel>
            <OutlinedInput
                id={name}
                type={show ? "text" : "password"}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShow(!show)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                        >
                            <img src={show ? "/assets/icon/MaskOn.svg" : "/assets/icon/MaskOff.svg"} />
                        </IconButton>
                    </InputAdornment>
                }
                name={name}
                label={label || name || ""}
                inputRef={inputRef}
                error={name in errors}
            />
            {errors[name] && <FormHelperText error={name in errors}>{errors[name].message}</FormHelperText>}
        </FormControl>
    );
};

export { default as YohoSelect } from "./Select";
export { default as YohoDatepicker } from "./DatePicker";
export { default as YohoCheckbox } from "./Checkbox";
export { default as YohoRadio } from "./Radio";
export { default as YohoFileupload } from "./FileUpload";
export { default as YohoBrandFileUpload } from "./BrandFileUpload";
export { default as YohoProductFileUpload } from "./ProductFileUpload";

import { styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory, YohoSelect } from "components/Global";
import { FormText } from "components/Global/Form";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateMerchantProfile } from "service/actions/merchant";

const Container = styled("div")(({ theme }) => ({}));

const Content = styled("div")(({ theme }) => ({}));

export default () => {
    const { register, handleSubmit, watch, errors, setValue, control, reset } = useForm();
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile);
    const international_codes = useSelector((state) => state.site.config_internationalcode);
    const [ready, setReady] = useState(false);
    useEffect(() => {
        reset(profile);
    }, [profile]);

    useEffect(() => {
        if (international_codes) {
            setReady(true);
        }
    }, [international_codes]);

    const onSubmit = (props) => {
        dispatch(updateMerchantProfile(props));
    };
    return (
        ready && (
            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Content>
                        <FormCategory>
                            <TextField inputRef={register()} name="last_name" variant="outlined" label="Last Name" />
                            <TextField inputRef={register()} name="first_name" variant="outlined" label="First Name" />
                            <FormText className="w-2">
                                <p className="subtitle1">Email Address (for login use)</p>
                            </FormText>
                            <FormText className="w-34">
                                <p className="subtitle2">{profile.personal_email}</p>
                                <p className="text">
                                    The address used to identify your YOHO Merchant Account to you. You can’t change
                                    this address.
                                </p>
                            </FormText>
                            <YohoSelect
                                inputRef={register()}
                                control={control}
                                name="contact_international_code_id"
                                className="w-3"
                                data={international_codes}
                                show={["dial_code", "name"]}
                                label="International Code"
                            />

                            <TextField
                                inputRef={register()}
                                name="contact_number"
                                className="w-3"
                                variant="outlined"
                                label="Contact Number"
                            />
                            <TextField
                                inputRef={register()}
                                name="contact_ext"
                                className="w-2"
                                variant="outlined"
                                label="Ext"
                            />
                            <TextField
                                inputRef={register()}
                                name="personal_position"
                                variant="outlined"
                                label="Position"
                                className="half"
                            />
                            <FormButton ccolor="blue" cvariant="contained" type="submit" fullWidth>
                                Save changes
                            </FormButton>
                        </FormCategory>
                    </Content>
                </form>
            </Container>
        )
    );
};

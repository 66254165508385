import { ClickAwayListener, Grow, Paper, Popper } from "@material-ui/core";
import React, { cloneElement, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unsetMenu } from "service/actions/site";

export default ({ refs, children, ...props }) => {
    const menustate = useSelector((state) => state.site.menu);
    const prevOpen = useRef(menustate);
    const dispatch = useDispatch();

    const closeAction = (event) => {
        if (refs?.current && refs?.current.contains(event.target)) return;
        dispatch(unsetMenu());
    };

    const handleListKeyDown = (event) => {
        if (event.key === "Tab") {
            event.preventDefault();
            dispatch(unsetMenu());
        }
    };

    useEffect(() => {
        if (prevOpen.current === true && menustate === false) refs?.current.focus();
        prevOpen.current = menustate;
    }, [menustate]);

    return (
        <Popper open={!!menustate} anchorEl={refs?.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) =>
                children && (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={closeAction}>
                                {cloneElement(children, {
                                    autoFocusItem: !!menustate,
                                    id: "menu",
                                    onKeyDown: handleListKeyDown,
                                })}
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )
            }
        </Popper>
    );
};

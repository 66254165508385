import { extractYouTubeID } from "helper/validate";
import { call, debounce, put, select, takeLatest } from "redux-saga/effects";
import * as merchantAction from "service/actions/merchant";
import * as siteAction from "service/actions/site";
import * as merchantProductApis from "service/apis/merchant/product";

function* findQuerySAGA(payload) {
    const { data } = yield call(merchantProductApis.searchproducts, payload.keyword);

    if (!data.error) {
        yield put(merchantAction.setQuery(data.result));
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* selectResellProductSAGA() {
    const selected = yield select((state) => state.merchant.product.search.query.selected);
    const { data } = yield call(merchantProductApis.selectProduct, selected.goods_id);

    if (!data.error) {
        yield put(merchantAction.setProductSearch([data.result]));
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* confirmResellSelectionSAGA(payload) {
    const { data } = yield call(merchantProductApis.getResellProductDraft, payload.data);

    if (!data.error) {
        yield put(merchantAction.setResellSubmitLoading(false));
        yield put(merchantAction.setResellSelection(data.result));
        yield put(siteAction.setRedirect(`/products/resell/form/${data.result.spu.spu_id}`));
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* getCreateProductDraftSAGA() {
    const product = yield select((state) => state.merchant.product);
    const payload = {
        vital_info_product_name: product.add.data.spu.vital_info_product_name_chinese,
        vital_info_category: product.draft.category[3].id,
    };
    const { data } = yield call(merchantProductApis.generateNewProductDraft, payload);

    yield put(merchantAction.setAddProductDraft(data.result));
    yield put(siteAction.setRedirect(`/products/add/form/${data.result.spu.spu_id}`));
}

function* generateVariationSAGA(payload) {
    if (payload.data.mode === "resell") {
        yield put(merchantAction.setResellSubmitLoading(true));
    } else {
        yield put(merchantAction.setCreateSubmitLoading(true));
    }

    const { data } = yield call(merchantProductApis.generateProductVariation, payload.data);

    if (!data.error) {
        yield put(
            merchantAction.updateGeneratedSKU({
                skuList: data.result,
                mode: payload.data.mode,
            }),
        );
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: `${data.result.length} products has been generated`,
                severity: "info",
            }),
        );
    } else {
        yield put(merchantAction.setResellSubmitLoading(false));
        yield put(merchantAction.setCreateSubmitLoading(false));
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data.message || "Unknown Error",
                severity: "error",
            }),
        );
    }
}

function* confirmCreateProductSAGA() {
    const productData = yield select((state) => state.merchant.product.add.data);
    const { spu, sku } = productData;

    const allSkuComplete = sku.reduce((prev, curr) => {
        return prev && !!parseInt(curr.variations_sales_member_price);
    }, true);

    const chineseRegex = /\p{Script=Han}/u;

    let errorMessage = "";
    // Missing brand
    if (spu.vital_info_brand === 0) {
        errorMessage = "Please choose the brand of your product on Vital Info.";
    }
    // Missing product name
    else if (!spu.vital_info_product_name_chinese || !spu.vital_info_product_name_english) {
        errorMessage = "Please fill in both English and Chinese product name on Vital Info.";
    }
    // English name contain Chinese characters
    else if (chineseRegex.test(spu.vital_info_product_name_english)) {
        errorMessage = "Product English name cannot contain Chinese characters.";
    }
    // Attribute updated but not variations
    else if (spu.stale) {
        errorMessage = "Please update product variations base on the latest attributes.";
    }
    // Some SKU have incomplete data
    else if (!allSkuComplete) {
        errorMessage = "One or more variations have incomplete data.";
    }
    // Invalid YouTube link
    else if (spu.youtube_link && extractYouTubeID(spu.youtube_link) === "") {
        errorMessage = "Invalid YouTube video link for product description";
    }

    if (errorMessage) {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: errorMessage,
                severity: "error",
            }),
        );
        return;
    }

    yield put(merchantAction.setCreateSubmitLoading(true));

    const { data } = yield call(merchantProductApis.confirmCreateProduct, productData);

    if (!data.error) {
        yield put(siteAction.setRedirect("/products/list"));
        yield put(merchantAction.setCreateSubmitLoading(false));
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Your product has been created",
                severity: "success",
            }),
        );
    } else {
        yield put(merchantAction.setCreateSubmitLoading(false));
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data.message || "Unknown Error",
                severity: "error",
            }),
        );
    }
}

function* confirmResellProductSAGA() {
    const productData = yield select((state) => state.merchant.product.selected.data);
    const { sku, spu } = productData;

    const allSkuComplete = sku.reduce((prev, curr) => {
        return prev && !!parseInt(curr.variations_sales_member_price);
    }, true);

    let errorMessage = "";
    // Attribute updated but not variations
    if (spu.stale) {
        errorMessage = "Please update product variations base on the latest attributes.";
    }
    // Some SKU have incomplete data
    else if (!allSkuComplete) {
        errorMessage = "One or more variations have incomplete data.";
    }
    // Invalid YouTube link
    else if (spu.youtube_link && extractYouTubeID(spu.youtube_link) === "") {
        errorMessage = "Invalid YouTube video link for product description";
    }

    if (errorMessage) {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: errorMessage,
                severity: "error",
            }),
        );
        return;
    }

    yield put(merchantAction.setResellSubmitLoading(true));

    const { data } = yield call(merchantProductApis.confirmResellProduct, productData);

    if (!data.error) {
        yield put(siteAction.setRedirect("/products/list"));
        yield put(merchantAction.setResellSubmitLoading(false));
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Your product has been created",
                severity: "success",
            }),
        );
    } else {
        yield put(merchantAction.setResellSubmitLoading(false));
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data.message || "Unknown Error",
                severity: "error",
            }),
        );
    }
}

export default [
    debounce(300, merchantAction.findQuery().type, findQuerySAGA),

    takeLatest(merchantAction.createProduct().type, getCreateProductDraftSAGA),
    takeLatest(merchantAction.generateVariationSKU().type, generateVariationSAGA),
    takeLatest(merchantAction.confirmCreateProduct().type, confirmCreateProductSAGA),

    takeLatest(merchantAction.selectResellProduct().type, selectResellProductSAGA),
    takeLatest(merchantAction.confirmResellSelection().type, confirmResellSelectionSAGA),
    takeLatest(merchantAction.confirmResellProduct().type, confirmResellProductSAGA),
];

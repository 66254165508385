import { Divider, styled } from "@material-ui/core";
import { FormText } from "components/Global/Form";
import OrderCase from "./OrderCase";
import OrderTimeline from "./OrderTimeline";

const Timeline = styled("div")(({ theme }) => ({
    gridColumn: "1/26",
    gridRow: "5/6",
    padding: 15,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    gap: 20,
}));

export default ({ data, logs }) => {
    return (
        <Timeline>
            {data?.RefundRequests?.length !== 0 && (
                <>
                    <FormText>
                        <p className="subtitle1">Cases</p>
                    </FormText>
                    <Divider />
                    <OrderCase data={data} logs={logs} />
                </>
            )}
            <FormText>
                <p className="subtitle1">Your Actions</p>
            </FormText>
            <Divider />
            <OrderTimeline data={data} logs={logs} />
        </Timeline>
    );
};

import { styled } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { showOrderDetail } from "service/actions/merchant";

const OrderWrapper = styled("div")({
    display: "flex",
    gap: 10,
    fontSize: 12,
    width: "100%",
});

const Thumbnail = styled("div")({
    borderRadius: 8,
    border: "1px solid #bdbdbd",
    minWidth: 60,
    width: 60,
    minHeight: 60,
    height: 60,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    background: "white",
    zIndex: 5,
    "& img": {
        height: "100%",
        objectFit: "contain",
    },
});

// Temporary keeping due to possible future use as shadow for multiple products
const ThumbnailShadow = styled("div")(({ theme, adjust_position }) => ({
    borderRadius: 8,
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    background: theme.yoho.md_color.grey[300],
    minWidth: 60,
    width: 60,
    minHeight: 60,
    height: 60,
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    right: -adjust_position,
    top: -adjust_position,
}));

const RelativeBox = styled("div")({
    position: "relative",
});

const OrderDesc = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
});

const OrderInfo = styled("div")({
    justifyContent: "space-between",
    "& .sku": {
        minWidth: 150,
        maxWidth: 150,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        lineClamp: 2,
        WebkitBoxOrient: "vertical",
    },
});

const CustomLink = styled(Link)(({ theme }) => ({
    ...theme.yoho.typography.subtitle3,
    textDecoration: "none",
    color: "black",
    "&:visited": {
        textDecoration: "none",
        color: "black",
    },
    "&:hover": {
        textDecoration: "underline",
    },
}));

export default (e) => {
    const OrderGoods = e.OrderGoods;
    const goods = OrderGoods?.[0];
    const goodsQty = OrderGoods?.reduce((acc, curr) => acc + curr.goods_number, 0);

    return (
        <OrderWrapper>
            <RelativeBox>
                <Thumbnail>
                    <img src={goods?.img_full_url} />
                </Thumbnail>
            </RelativeBox>
            <OrderDesc>
                <CustomLink to={`orders/${e.order_id}`}>
                    {" "}
                    {goods?.goods_name}
                    {OrderGoods?.length - 1 > 0 && <div> + {OrderGoods?.length - 1} Items</div>}
                </CustomLink>
                <OrderInfo>
                    <div>
                        <span>Quantity: {goodsQty}</span>
                    </div>
                </OrderInfo>
            </OrderDesc>
        </OrderWrapper>
    );
};

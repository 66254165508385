import StoreManagement from "components/MerchantCentre/MerchantConsole/Settings/StoreManagement";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStoreSetting } from "service/actions/merchant";

export default () => {
    const dispatch = useDispatch();
    const isloading = useSelector((state) => state.merchant.loading);

    useEffect(() => {
        dispatch(getStoreSetting());
    }, []);

    return isloading ? <div></div> : <StoreManagement />;
};

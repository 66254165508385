import { call, put, select, takeLatest } from "redux-saga/effects";
import cookie from "js-cookie";
import { DEFAULT_COOKIE_PARAMS } from "helper";
import * as merchantAction from "service/actions/merchant";
import * as siteAction from "service/actions/site";
import * as merchantProductApis from "service/apis/merchant/product";

function* getProductListSAGA() {
    const productParam = yield select((state) => state.merchant.product.params);

    const { data, ...rest } = yield call(merchantProductApis.getproducts, productParam);

    if (data.status === 401) {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield put(merchantAction.setProductList(data.result));
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* statusListProductSAGA(payload) {
    const { data, problem } = yield call(merchantProductApis.publishproduct, payload.data);

    if (problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield call(getProductListSAGA);
        yield put(siteAction.setSnackbar({ open: true, message: "Product list updated" }));
    }
}

export default [
    takeLatest(merchantAction.getProductList().type, getProductListSAGA),
    takeLatest(merchantAction.setProductParam().type, getProductListSAGA),
    takeLatest(merchantAction.setPLStatus().type, statusListProductSAGA),
];

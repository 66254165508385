import { Dialog } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setPopup, unsetPopup } from "service/actions/site";
import { Collapse, Slide, Grow, Zoom } from "@material-ui/core";
import React, { memo } from "react";

const SlideTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default memo(({ children, size = "xl", isLoading = false, ...props }) => {
    const popupstate = useSelector((state) => state.site.popup);
    const dispatch = useDispatch();

    const closeAction = () => {
        !isLoading && dispatch(unsetPopup());
    };

    return (
        <Dialog
            maxWidth={size}
            TransitionComponent={SlideTransition}
            open={popupstate !== undefined}
            onClose={closeAction}
            {...props}
        >
            {children}
        </Dialog>
    );
});

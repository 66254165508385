import { styled } from "@material-ui/core";
import { memo } from "react";

const Column = styled("div")({
    display: "flex",
    flexDirection: "column",
});
export default (entry) => {
    return (
        <Column>
            <span>
                <strong>
                    {entry?.first_name} {entry?.last_name}
                </strong>
            </span>
            <span>{entry?.personal_position}</span>
        </Column>
    );
};

import api from "../config";

export const getReleatedProductOptions = (body) => api.post("/merchant/promotion/related-product-options", body);
export const getPromoInfoList = (params) => api.get("/merchant/promotion/info/list", params);
export const getPromoInfo = (id) => api.get(`/merchant/promotion/info/${id}`);
export const getProductSelectorOptions = (body) => api.post("/merchant/products/product-search-options", body);
export const getProductsFromGroup = (params) => api.get("/merchant/products/products-in-group", params);

export const createPromoInfo = (body) => api.post("/merchant/promotion/info/create", body);
export const editPromoInfo = (body) => api.put("/merchant/promotion/info/edit", body);
export const deletePromoInfo = (params) => api.delete("/merchant/promotion/info/delete", params);

export const getDiscountEventList = (params) => api.get("/merchant/discount-event/list", params);
export const getDiscountEventInfo = (id) => api.get(`/merchant/discount-event/${id}`);
export const editDiscountEvent = (params) => api.put("/merchant/discount-event", params);
export const createDiscountEvent = (params) => api.post("/merchant/discount-event", params);
export const deleteDiscountEvent = (params) => api.delete("/merchant/discount-event", params);

export const getPointRewardStatus = (params) => api.get("/merchant/promotion/points-reward");

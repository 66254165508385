import api from "./config";

export const getRegisterMasterData = () => api.get(`/auth/register/get-master-data`);

export const AuthRegister = (body) =>
    api.post(`/auth/register`, body, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
export const AuthLogin = (body) => api.post(`/auth/login`, body);
export const AuthResetPassword = (body) => api.post(`/auth/reset-password-request`, body);
export const AuthVerify = (body) => api.post(`/auth/reset-password-verify`, body);
export const AuthChangePassword = (body) => api.post(`/auth/reset-password`, body);

export const getCategories = () => api.get(`/categories`);
export const getSubCategories = (category_id) => api.get(`/sub-categories/${category_id}`);
export const getDepartments = () => api.get(`/departments`);
export const getInternationalCode = () => api.get(`/international-codes`);

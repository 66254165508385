import { styled } from "@material-ui/core";
import { FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import moment from "moment";
import { useSelector } from "react-redux";

const Section = styled("div")(({ theme }) => ({
    width: "100%",
    "& div": {
        display: "grid",
        gridTemplateColumns: "repeat(2, max-content)",
        gap: "25px 50px",
        // "& p": {
        //     gridArea: "p"
        // },
        "& h6.link": {
            color: theme.yoho.md_color.blue[500],
        },
    },
}));

const Heading = styled("h4")(({ theme }) => ({
    ...theme.yoho.typography.h4,
    margin: 0,
    margin: "10px 0 20px 0",
    color: theme.yoho.md_color.grey[800],
}));

const Divider = styled("div")(({ theme }) => ({
    borderBottom: `1px solid ${theme.yoho.md_color.grey[300]}`,
    margin: "30px 0 10px 0",
    width: "100% !important",
}));

export default () => {
    const accountsetting = useSelector((state) => state.merchant.setting.account);

    return (
        <div>
            <FormCategory>
                <Section className="full">
                    <Heading>Company Info</Heading>
                    <FormText>
                        <p className="subtitle1">Company Name (English)</p>
                        <h6>{accountsetting?.business?.company_name_english}</h6>

                        <p className="subtitle1">Company Name (Chinese)</p>
                        <h6>{accountsetting?.business?.company_name_chinese}</h6>

                        <p className="subtitle1">Company Name Email Address</p>
                        <h6>{accountsetting?.business?.company_email_address}</h6>
                    </FormText>
                </Section>
                <Divider />
                <Section>
                    <Heading>Business Registration Address</Heading>
                    <FormText>
                        <p className="subtitle1">Floor & Room</p>
                        <h6>{accountsetting?.business?.floor_room}</h6>

                        <p className="subtitle1">Street & Building</p>
                        <h6>{accountsetting?.business?.street_building}</h6>

                        <p className="subtitle1">District</p>
                        <h6>{accountsetting?.business?.sub_district_id?.name}</h6>

                        <p className="subtitle1">Area</p>
                        <h6>{accountsetting?.business?.area_id?.name}</h6>

                        <p className="subtitle1">Country or City</p>
                        <h6>{accountsetting?.business?.country_id?.name}</h6>

                        <p className="subtitle1">Business Registration Certificate No?</p>
                        <h6>{accountsetting?.business?.certificate_number}</h6>

                        <p className="subtitle1">BR Expiry Date</p>
                        <h6>{moment(accountsetting?.business?.expired_date)?.format("MM/DD/YYYY")}</h6>

                        <p className="subtitle1">BR Supporting Document</p>
                        <h6 className="link">
                            <a href={accountsetting?.business?.document_url?.url} target="_blank">
                                {accountsetting?.business?.document_url?.file}
                            </a>
                        </h6>
                    </FormText>
                </Section>
            </FormCategory>
        </div>
    );
};

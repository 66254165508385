export default [
    {
        order_time: "Dec 14, 2021 14:30",
        order: "2021121422699",
        product_img: "/assets/images/primary-iron-fuchsia.png",
        product_name: "Dyson Supersonic HD08 Hair Dryer Fuchsia Nickel",
        product_extra: 2,
        product_quantity: 3,
        payment_time: "Dec 14, 2021 14:30",
        status: "To Ship",
    },
    {
        order_time: "Dec 14, 2021 14:30",
        order: "2021121422699",
        product_img: "/assets/images/primary-iron-fuchsia.png",
        product_name: "Dyson Supersonic HD08 Hair Dryer Fuchsia Nickel",
        product_extra: 2,
        product_quantity: 3,
        payment_time: "Dec 14, 2021 14:30",
        status: "Shipped",
    },
    {
        order_time: "Dec 14, 2021 14:30",
        order: "2021121422699",
        product_img: "/assets/images/primary-iron-fuchsia.png",
        product_name: "Dyson Supersonic HD08 Hair Dryer Fuchsia Nickel",
        product_extra: 2,
        product_quantity: 3,
        payment_time: "",
        status: "Unpaid",
    },
    {
        order_time: "Dec 14, 2021 14:30",
        order: "2021121422699",
        product_img: "/assets/images/primary-iron-fuchsia.png",
        product_name: "Dyson Supersonic HD08 Hair Dryer Fuchsia Nickel",
        product_extra: 2,
        product_quantity: 3,
        payment_time: "",
        status: "Cancelled",
    },
    {
        order_time: "Dec 14, 2021 14:30",
        order: "2021121422699",
        product_img: "/assets/images/primary-iron-fuchsia.png",
        product_name: "Dyson Supersonic HD08 Hair Dryer Fuchsia Nickel",
        product_extra: 2,
        product_quantity: 3,
        payment_time: "",
        status: "Refund case opened",
    },
    {
        order_time: "Dec 14, 2021 14:30",
        order: "2021121422699",
        product_img: "/assets/images/primary-iron-fuchsia.png",
        product_name: "Dyson Supersonic HD08 Hair Dryer Fuchsia Nickel",
        product_extra: 2,
        product_quantity: 3,
        payment_time: "",
        status: "Exchange case opened",
    },
    {
        order_time: "Dec 14, 2021 14:30",
        order: "2021121422699",
        product_img: "/assets/images/primary-iron-fuchsia.png",
        product_name: "Dyson Supersonic HD08 Hair Dryer Fuchsia Nickel",
        product_extra: 2,
        product_quantity: 3,
        payment_time: "",
        status: "Refund case closed",
    },
    {
        order_time: "Dec 14, 2021 14:30",
        order: "2021121422699",
        product_img: "/assets/images/primary-iron-fuchsia.png",
        product_name: "Dyson Supersonic HD08 Hair Dryer Fuchsia Nickel",
        product_extra: 2,
        product_quantity: 3,
        payment_time: "",
        status: "Exchange case closed",
    },
];

import { FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import MPDF_MultiRTE from "./MPDF_MultiRTE";
import MPDI_MultiRTE from "./MPDI_MultiRTE";

const description_lang = [
    { id: 1, name: "Traditional Chinese", code: "description_product_features_chinese" },
    { id: 2, name: "English", code: "description_product_features_english" },
];

const introduction_lang = [
    { id: 1, name: "Traditional Chinese", code: "description_introduction_chinese" },
    { id: 2, name: "English", code: "description_introduction_english" },
];

export default ({ draft, product_id, handleSubmitPayload }) => {
    return (
        <div>
            <FormCategory>
                <FormText>
                    <p className="subtitle1">Introduction</p>
                </FormText>
                <div className="full">
                    <MPDI_MultiRTE
                        product_id={product_id}
                        draft={draft}
                        options={introduction_lang}
                        defaultstate={introduction_lang[0].code}
                        action={(e) => handleSubmitPayload(e)}
                    />
                </div>
                <FormText>
                    <p className="subtitle1">Product Features</p>
                </FormText>
                <div className="full">
                    <MPDF_MultiRTE
                        product_id={product_id}
                        draft={draft}
                        options={description_lang}
                        defaultstate={description_lang[0].code}
                        action={(e) => handleSubmitPayload(e)}
                    />
                </div>
            </FormCategory>
        </div>
    );
};

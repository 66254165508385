export const showOptions = [
    { id: 1, name: "30", value: 30 },
    { id: 2, name: "50", value: 50 },
    { id: 3, name: "100", value: 100 },
    { id: 4, name: "200", value: 200 },
];

export const dateOptions = [
    { id: 1, name: "Last 30 Days", value: 30 },
    { id: 2, name: "Last 60 Days", value: 60 },
    { id: 3, name: "Last 90 Days", value: 90 },
    { id: 3, name: "Custom", value: "Custom" },
];

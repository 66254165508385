import { all, call, put, select, takeLatest } from "redux-saga/effects";
import * as merchantAction from "service/actions/merchant";
import * as siteAction from "service/actions/site";
import * as merchantOrderApis from "service/apis/merchant/order";
import { APIVerificator } from "service/sagas/middleware";

function* showOrderDetailSAGA(payload) {
    const [{ data: detailData, ...detailRest }, { data: logsData }] = yield all([
        call(merchantOrderApis.getorderdetail, payload.params),
        call(merchantOrderApis.getorderactionlogs, payload.params),
    ]);

    yield APIVerificator(detailData, detailRest, function* () {
        if (!detailData.error) {
            yield all([
                put(merchantAction.setOrderDetail(detailData.result, payload.params.order_id)),
                put(merchantAction.setOrderLogs(logsData.result, payload.params.order_id)),
                put(
                    siteAction.setRedirect({
                        pathname: `/orders/${payload.params.order_id}`,
                        state: payload.params.order_id,
                    }),
                ),
            ]);
        } else {
            yield put(
                siteAction.setSnackbar({
                    open: true,
                    message: detailData?.message || "Network connection error",
                    severity: "error",
                }),
            );
        }
    });
}

function* submitInternalNoteSAGA(payload) {
    const { data, ...rest } = yield call(merchantOrderApis.postordernote, payload.data);

    yield APIVerificator(data, rest, function* () {
        if (!data.error) {
            yield all([
                call(showOrderDetailSAGA, { params: { order_id: payload.data.order_id } }),
                put(
                    siteAction.setSnackbar({
                        open: true,
                        message: data?.message || "Note has been posted",
                        severity: "info",
                    }),
                ),
            ]);
        } else {
            yield put(
                siteAction.setSnackbar({
                    open: true,
                    message: data?.message || "Your session has expired. Please sign in again",
                    severity: "error",
                }),
            );
        }
    });
}

function* confirmOrderShipmentSAGA(payload) {
    const { data, ...rest } = yield call(merchantOrderApis.updateorderhipment, payload.data);

    yield APIVerificator(data, rest, function* () {
        if (!data.error) {
            yield all([
                put(siteAction.unsetPopup()),
                call(showOrderDetailSAGA, { params: { order_id: payload.data.order_id } }),
                put(
                    siteAction.setSnackbar({
                        open: true,
                        message: data?.message || "Order is being confirmed",
                        severity: "info",
                    }),
                ),
            ]);
        } else {
            yield put(
                siteAction.setSnackbar({
                    open: true,
                    message: data?.message || "Your session has expired. Please sign in again",
                    severity: "error",
                }),
            );
        }
    });
}

export default [
    takeLatest(merchantAction.showOrderDetail().type, showOrderDetailSAGA),
    takeLatest(merchantAction.postOrderNote().type, submitInternalNoteSAGA),
    takeLatest(merchantAction.confirmOrder().type, confirmOrderShipmentSAGA),
];

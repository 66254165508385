import { CircularProgress, styled } from "@material-ui/core";
import { LoadingContainer } from "components/MerchantCentre/AdministrationConsole/Application/Details/StyledComponent";
import { useEffect, useState } from "react";
import * as promotionAPI from "service/apis/merchant/promo";
import { useApiWithErrorHandle } from "service/apis/merchant/utilHook";

const PageContainer = styled("div")({
    margin: "0px 35px",
    maxWidth: "1060px",
    overflow: "auto",
});
const InfoText = styled("p")({
    color: "#888888",
    marginTop: "20px",
});
const ColoredInfoText = styled("p")(({ theme }) => ({
    width: "fit-content",
    padding: "12px 16px",
    borderRadius: "8px",
    marginTop: "28px",
    background: theme.yoho.md_color.blue[50],
    color: theme.yoho.md_color.blue[900],
}));

const enrolMailLink =
    "mailto:merchants@yohohongkong.com?subject=YOHO%20Points%20Program%20Enrolment&body=Here%20I%20confirm%20my%20enrolment%20in%20the%20Yoho%20Points%20Reward%20Program.%20I%20also%20confirm%20that%20I%20have%20read%20the%20Yoho%20Points%20Reward%20Program%20terms%20and%20conditions,%20and%20I%20understand%20that%20an%20additional%200.5%%20commission%20fee%20will%20be%20applied%20to%20all%20orders%20under%20this%20program,%20effective%20from%20the%20first%20day%20of%20the%20next%20calendar%20month.";

const enrolWhatsappLink =
    "https://wa.me/85253357570?text=Here%20I%20confirm%20my%20enrolment%20in%20the%20Yoho%20Points%20Reward%20Program.%20I%20also%20confirm%20that%20I%20have%20read%20the%20Yoho%20Points%20Reward%20Program%20terms%20and%20conditions,%20and%20I%20understand%20that%20an%20additional%200.5%%20commission%20fee%20will%20be%20applied%20to%20all%20orders%20under%20this%20program,%20effective%20from%20the%20first%20day%20of%20the%20next%20calendar%20month.";

const withdawMailLink = "mailto:merchants@yohohongkong.com?subject=YOHO%20Points%20Program%20Withdrawal";

const withdawWhatsappLink = "https://wa.me/85253357570";

export default function PointReward() {
    const [inProgram, setInProgram] = useState(null);

    const getPointRewardStatus = useApiWithErrorHandle(promotionAPI.getPointRewardStatus);

    const loadPointRewardStatus = async () => {
        const result = await getPointRewardStatus();
        if (result) {
            setInProgram(result.canMerchantsUsePoint);
        }
    };

    useEffect(() => {
        loadPointRewardStatus();
    }, []);

    if (inProgram === null) {
        return (
            <LoadingContainer>
                <CircularProgress size={100} />
            </LoadingContainer>
        );
    }

    return (
        <PageContainer>
            <h1>Points Reward Program</h1>
            <InfoText>
                (1) Program details - Customers can choose to deduct Yoho points from their orders when they check out
                the cart and settle the payment. The deduction rate is 200 Yoho points = 1HKD.
            </InfoText>
            <InfoText>
                (2) Commission and Charges - A commission fee of 0.5% of the <b>Product Sales Amount</b> will be charged
                additionally for all the orders that customers choose to settle with Yoho points and the charged amount
                will be reflected in the monthly commission column.
            </InfoText>
            <InfoText>
                (3) How to apply - Yoho Points Reward Program is defaulted as <b>DISABLED</b> at the setting. If you
                would like opt in the program, please contact Yoho Marketplace Team by sending the opt-in message to
                WhatsApp:{" "}
                <a href={enrolWhatsappLink} target="_blank">
                    +852 53357570
                </a>{" "}
                / Email: <a href={enrolMailLink}>merchants@yohohongkong.com</a>. It will be effective at the{" "}
                <b>1st day of next calendar month</b> after the message is received. Please use the template below for
                your message:
            </InfoText>
            <li style={{ color: "#888888" }}>
                Here I confirm my enrolment in the Yoho Points Reward Program. I also confirm that I have read the Yoho
                Points Reward Program terms and conditions, and I understand that an additional 0.5% commission fee will
                be applied to all orders under this program, effective from the first day of the next calendar month.
            </li>
            <InfoText>
                (4) Withdrawal - If you would like to withdraw from the program, please contact Yoho Marketplace Team at
                two months' notice by sending the messages to WhatsApp:{" "}
                <a href={withdawWhatsappLink} target="_blank">
                    +852 53357570
                </a>{" "}
                / Email: <a href={withdawMailLink}>merchants@yohohongkong.com</a>. Yoho Marketplace Team will confirm
                the withdrawal effective date to you within 14-20 working days.
            </InfoText>
            <ColoredInfoText>
                Points spending and reward is currently <b>{inProgram ? "ENABLED" : "DISABLED"}</b> on your products
            </ColoredInfoText>
        </PageContainer>
    );
}

import Details from "components/MerchantCentre/AdministrationConsole/Application/Details";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getInitialForm } from "service/actions/authentication";

export default () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getInitialForm());
    }, []);

    return <Details />;
};

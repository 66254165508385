import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import Header from "components/MerchantCentre/Header";
import { useState } from "react";
import Business from "./Business";
import RolePermission from "./RolePermission";

export default () => {
    const [active, setActive] = useState("business");
    return (
        <Container>
            <div className="main">
                <Header
                    menu={["business", "role permissions"]}
                    active={active}
                    setActive={setActive}
                    title="Account Management"
                />
                <Content>
                    {{
                        business: <Business key="business" />,
                        "role permissions": <RolePermission key="permissions" />,
                    }[active] || <div>Page is still under construction</div>}
                </Content>
            </div>
        </Container>
    );
};

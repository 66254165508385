import { styled } from "@material-ui/core";
import { FormText } from "components/Global/Form";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";

const getColor = (props) => {
    if (props.isDragAccept) {
        return "#00e676";
    }
    if (props.isDragReject) {
        return "#ff1744";
    }
    if (props.isFocused) {
        return "#2196f3";
    }
    return "#eeeeee";
};

const Container = styled("div")((props) => ({
    maxWidth: props.width,
    minWidth: props.width,
    width: props.width,
    height: props.height,
    borderRadius: 12,
    flex: "1",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: "2px",
    borderColor: getColor(props),
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease -in -out",
    cursor: "pointer",
    "& p": {
        fontWeight: "bold",
        fontSize: 12,
        lineHeight: "16px",
        color: "#00ACEE",
        textAlign: "center",
    },
}));

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    position: "relative",
    gap: 10,
};

const thumb = ({ width, height }) => {
    return {
        display: "inline-flex",
        borderRadius: "12px",
        border: "1px solid #eaeaea",
        width: width,
        height: height,
        boxSizing: "border-box",
        position: "relative",
    };
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
    borderRadius: "12px",
    position: "relative",
    width: "100%",
};

const img = {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "contain",
};

const mainimage = {
    position: "absolute",
    bottom: "0",
    width: "150px",
    display: "flex",
    background: "#00ACEE",
    margin: "0",
    padding: "10px 0",
    borderRadius: "0 0 8px 8px",
    justifyContent: "center",
    color: "white",
};

const CloseButton = styled("div")({
    position: "absolute",
    right: 10,
    top: 10,
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "rgb(0, 172, 238) !important",
        borderRadius: "50%",
    },
});

const Aside = styled("div")(({ width, height }) => ({
    width: width,
    height: height,
}));

export default ({
    generalInputRef,
    files,
    setFiles,
    mainInputRef,
    generalName,
    mainName,
    setValue,
    className,
    width = 150,
    height = 150,
}) => {
    let filtergeneralfile = files.variations_general_images?.filter(
        (item) => item.path !== undefined || item.preview !== undefined,
    );
    let localImage = [...(filtergeneralfile ? filtergeneralfile : [])];
    let serverImage = [
        ...(files.exist_variations_general_images.length > 0 ? files.exist_variations_general_images : []),
    ];

    let combined = [...localImage, ...serverImage];

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.readAsArrayBuffer(file);
        });

        const combinedImage = [...combined, ...acceptedFiles];
        let generalFiles;

        if (combinedImage.length > 5) {
            const filtered = acceptedFiles.slice(0, 5 - combined.length);
            generalFiles = [...filtered, ...files.variations_general_images];
        } else {
            generalFiles = [...acceptedFiles, ...files.variations_general_images];
        }

        let main_img = files?.exist_variations_general_image_main?.hasOwnProperty("id_img")
            ? files?.exist_variations_general_image_main.thumb_img
            : files?.variations_general_image_main?.hasOwnProperty("preview")
              ? files?.variations_general_image_main.preview
              : "";
        setValue(generalName, generalFiles);
        if (main_img) {
            setFiles({
                ...files,
                variations_general_images: generalFiles,
            });
            return;
        }
        let firstImage = generalFiles[0];
        setFiles({
            ...files,
            variations_general_image_main: firstImage,
            variations_general_images: generalFiles,
        });
    });

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: "image/jpeg,image/png,image/jpg",
        multiple: true,
        onDrop,
    });
    const Thumbs = ({ inputRef, file }) => {
        const activeImage = (e) => {
            e.stopPropagation();
            if (file.id_img) {
                let localCacheImage = [...(filtergeneralfile ? filtergeneralfile : [])];

                let serverCacheImage = [
                    ...(files.exist_variations_general_images.length > 0 ? files.exist_variations_general_images : []),
                ];

                setFiles({
                    exist_variations_general_image_main: file,
                    exist_variations_general_images: serverCacheImage,
                    variations_general_image_main: [],
                    variations_general_images: localCacheImage,
                });
                return;
            }
            let localCacheImage = [...(filtergeneralfile ? filtergeneralfile : [])];
            let serverCacheImage = [
                ...(files.exist_variations_general_images.length > 0 ? files.exist_variations_general_images : []),
            ];
            setFiles({
                exist_variations_general_image_main: {},
                exist_variations_general_images: serverCacheImage,
                variations_general_image_main: file,
                variations_general_images: localCacheImage,
            });
            return;
        };

        const deleteImg = (e) => {
            e.stopPropagation();
            if (file.id_img) {
                let localCacheImage = [...(filtergeneralfile ? filtergeneralfile : [])];

                let serverCacheImage = [
                    ...(files.exist_variations_general_images.length > 0 ? files.exist_variations_general_images : []),
                ];

                let deletedServerCachedImage = serverCacheImage.filter(({ id_img }) => id_img !== file.id_img);
                let deletedMainServerCachedImage =
                    files.exist_variations_general_image_main.id_img === file.id_img
                        ? {}
                        : files.exist_variations_general_image_main;

                setFiles({
                    exist_variations_general_image_main: deletedMainServerCachedImage,
                    exist_variations_general_images: deletedServerCachedImage,
                    variations_general_image_main: files.variations_general_image_main,
                    variations_general_images: localCacheImage,
                });
                return;
            }
            let localCacheImage = [...(filtergeneralfile ? filtergeneralfile : [])];
            let deletedLocalCachedImage = localCacheImage.filter(({ preview }) => preview !== file.preview);
            let deletedMainLocalCachedImage =
                files.variations_general_image_main.preview === file.preview ? {} : files.variations_general_image_main;
            let serverCacheImage = [
                ...(files.exist_variations_general_images.length > 0 ? files.exist_variations_general_images : []),
            ];
            setFiles({
                exist_variations_general_image_main: files.exist_variations_general_image_main,
                exist_variations_general_images: serverCacheImage,
                variations_general_image_main: deletedMainLocalCachedImage,
                variations_general_images: deletedLocalCachedImage,
            });
            return;
        };

        let mainImage = false;
        if (file.id_img) {
            mainImage = files.exist_variations_general_image_main.id_img === file.id_img;
        } else {
            mainImage = files.variations_general_image_main.preview === file.preview;
        }

        return (
            <div style={thumb({ width, height })} key={file.id_img || file.preview}>
                <div
                    style={thumbInner}
                    onClick={(e) => {
                        activeImage(e);
                    }}
                >
                    <img
                        ref={inputRef}
                        src={
                            file.thumb_img ||
                            Object.assign(file, {
                                preview: URL.createObjectURL(file),
                            }).preview
                        }
                        style={img}
                    />
                    <CloseButton
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteImg(e);
                        }}
                    >
                        <img src="/assets/icon/x.svg" />
                    </CloseButton>
                </div>
                {mainImage && (
                    <FormText style={mainimage}>
                        <p className="subtitle3">Main Image</p>
                    </FormText>
                )}
            </div>
        );
    };

    return (
        <div style={{ display: "flex", gap: 10 }} className={className}>
            <Aside style={thumbsContainer}>
                {combined.length !== 0 &&
                    combined.map((file, key) => (
                        <Thumbs
                            width={width}
                            height={height}
                            inputRef={generalInputRef}
                            name={mainName}
                            file={file}
                            key={key}
                        />
                    ))}
                {combined.length < 5 && (
                    <Container
                        width={width}
                        height={height}
                        {...getRootProps({
                            isfocused: isFocused.toString(),
                            isdragaccept: isDragAccept.toString(),
                            isdragreject: isDragReject.toString(),
                        })}
                    >
                        <input {...getInputProps()} name={generalName} />
                        <img src="/assets/icon/imgicon.svg" />
                        <p>Drag and drop an image file here or click</p>
                    </Container>
                )}
            </Aside>
        </div>
    );
};

import api from "../config";

export const adminproductlist = (params) => api.get(`/admin/product`, params);
export const adminproductdetail = (productId, merchantId) => api.get(`/admin/product/${productId}/${merchantId}`);

export const adminproductcateogry = (merchantId) => api.get(`/admin/product/categories/${merchantId}`);
export const adminproductsubcateogry = (merchantId, categoryId) =>
    api.get(`/admin/product/sub-categories/${merchantId}/${categoryId}`);
export const adminproductcategorylevel = (categoryId) => api.get(`/admin/product/category-level/${categoryId}`);

export const adminproductinit = (merchantId) => api.get(`/admin/product/get-init/${merchantId}`);
export const adminproductupdate = (productId, merchantId, body) =>
    api.put(`/admin/product/${productId}/${merchantId}`, body);
export const adminproductsubmit = (body) => api.post(`/admin/product/submit`, body);
export const adminproductupdatevariation = (body) => api.post(`/admin/product/update-variation`, body);
export const adminproductdefault = (body) => api.post(`/admin/product/set-default`, body);

export const adminproductexcel = () => api.get(`/admin/product/generate-excel`);

export const brandCategorySearch = (params) => api.get("/admin/product/brand-category-search", params);
export const getProductSelectorOptions = (body) => api.post("/admin/product/product-search-options", body);
export const getProductsFromGroup = (params) => api.get("/admin/product/products-in-group", params);
export const adminBulkAmendProduct = (body) => api.put("/admin/product/amend-products", body);

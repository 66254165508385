import React, { useState, useEffect } from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { FormCategory } from "components/Global";

export default ({ defaultData, inputRef, marketRef, errors }) => {
    return (
        <FormCategory style={{ width: "100%" }}>
            <TextField
                inputRef={inputRef}
                name="variations_sales_vip_price"
                variant="outlined"
                label="VIP Price"
                disabled={true}
                type="number"
                inputProps={{
                    step: 0.01,
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">HK$</InputAdornment>,
                }}
            />
            <TextField
                inputRef={inputRef}
                name="variations_sales_member_price"
                variant="outlined"
                label="Member Price"
                disabled={true}
                type="number"
                inputProps={{
                    step: 0.01,
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">HK$</InputAdornment>,
                }}
            />
            <TextField
                inputRef={marketRef}
                name="variations_sales_market_price"
                variant="outlined"
                label="Market Price"
                type="number"
                inputProps={{
                    step: 0.01,
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">HK$</InputAdornment>,
                }}
                disabled={defaultData.status === "published"}
            />
            <div></div>
            <div>
                {errors?.variations_sales_market_price && (
                    <>
                        <div></div>
                        <div style={{ color: "red", paddingBottom: 5 }}>
                            {errors?.variations_sales_market_price?.message}
                        </div>
                    </>
                )}
            </div>
        </FormCategory>
    );
};

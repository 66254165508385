import { styled } from "@material-ui/styles";
import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import Header from "components/MerchantCentre/Header";
import { useState } from "react";
import Categories from "./Categories";
import Contacts from "./Contacts";
import WarrantyTerms from "./WarrantyTerms";
import Bank from "./Bank";

const LinkText = styled("a")(({ theme }) => ({
    color: theme.yoho.md_color.blue[500],
}));

export default () => {
    const [active, setActive] = useState("categories");
    return (
        <Container>
            <div className="main">
                <Header
                    menu={["categories", "contacts", "warranty", "bank account"]}
                    active={active}
                    setActive={setActive}
                    title="Business Settings"
                />
                <Content>
                    {(() => {
                        switch (active) {
                            case "categories":
                                return <Categories />;
                            case "contacts":
                                return <Contacts />;
                            case "warranty":
                                return <WarrantyTerms />;
                            case "bank account":
                                return <Bank />;
                            default:
                                return null;
                        }
                    })()}
                </Content>
            </div>
            <div className="info">
                <h5>Important Note</h5>
                <p>
                    To know more about selling on YOHO{" "}
                    <LinkText
                        href="https://sites.google.com/view/yoho3p"
                        target="_blank"
                        title="visit our merchant help centre"
                        rel="noopener noreferrer"
                    >
                        visit our merchant help centre
                    </LinkText>
                    .
                </p>
                <p>
                    For inquiry, please whatsapp us through hotline 53357570. This hotline is managed by several
                    departments in order to ensure the service quality.
                </p>
            </div>
        </Container>
    );
};

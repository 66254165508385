import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "service/actions/authentication";
import { setPopupLoading, setSnackbar, unsetPopup } from "service/actions/site";
import {
    getInvoiceFile,
    getorderactionlogs,
    getorderdetail,
    getorders,
    postordernote,
    updateorderhipment,
} from "service/apis/merchant/order";
import { saveAs } from "file-saver";
import { useState } from "react";

export const useGetOrderList = (params) => {
    const dispatch = useDispatch();
    return useQuery(["order-list", params], () => getorders(params), {
        enabled: true,
        onSuccess: (success) => {
            if (success?.data?.message?.includes("jwt expired")) {
                dispatch(setLogout());
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Your session has expired. Please sign in again.",
                        severity: "error",
                    }),
                );
                return;
            }
            return success;
        },
        onError: (err) => {},
    });
};

export const useGetOrderDetail = (order_id) => {
    const dispatch = useDispatch();
    return useQuery(["order", "detail", order_id], () => getorderdetail({ order_id }), {
        enabled: true,
        onSuccess: (success) => {
            if (success?.data?.message?.includes("jwt expired")) {
                dispatch(setLogout());
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Your session has expired. Please sign in again.",
                        severity: "error",
                    }),
                );
                return;
            }
            return success;
        },
        select: (data) => data?.data?.result,
        onError: (err) => {
            console.log("ORDERLIST ERR", err);
        },
    });
};

export const useGetOrderLogs = (order_id) => {
    const dispatch = useDispatch();
    return useQuery(["order", "logs", order_id], () => getorderactionlogs({ order_id }), {
        enabled: true,
        onSuccess: (success) => {
            if (success?.data?.message?.includes("jwt expired")) {
                dispatch(setLogout());
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Your session has expired. Please sign in again.",
                        severity: "error",
                    }),
                );
                return;
            }
            return success;
        },
        select: (data) => data?.data?.result,
        onError: (err) => {
            console.log("ORDERLIST ERR", err);
        },
    });
};

export const useGetInvoiceFile = (params) => {
    return useQuery(["invoice-file", params.order_id], () => getInvoiceFile(params), {
        enabled: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        onError: (err) => {
            console.log(err);
        },
    });
};

export const useOrderActions = (refetch) => {
    const queryClient = useQueryClient();
    const orderParams = useSelector((state) => state.merchant.order.params);
    const dispatch = useDispatch();
    const [shipmentData, setShipmentData] = useState(null);

    const {
        mutate: confirmShipment,
        isLoading: onConfirmShipment,
        data: confirmShipmentData,
    } = useMutation(
        (payload) => {
            const dataPayload = {
                ...payload,
                logistics_id: parseInt(payload.logistics_id),
                shipping_origin: parseInt(payload.shipping_origin, 10),
            };
            return updateorderhipment(dataPayload);
        },
        {
            onMutate: () => {
                dispatch(setPopupLoading({ loading: true, message: "Submitting..." }));
                setShipmentData(null);
            },
            onSuccess: async (res, payload) => {
                if (res.status === 500) {
                    dispatch(setPopupLoading({ loading: false, message: "" }));
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: "Error while updating shipping status, please contact admin.",
                            severity: "error",
                        }),
                    );
                } else if (res.data.message?.includes("jwt expired")) {
                    dispatch(setLogout());
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: "Your session has expired. Please sign in again.",
                            severity: "error",
                        }),
                    );
                } else if (res.data.code === 14028 || res.data.code === 14030) {
                    dispatch(setPopupLoading({ loading: false, message: "" }));
                    setShipmentData(res.data);
                } else {
                    await queryClient.invalidateQueries(["order", "logs", payload.order_id]);
                    await queryClient.invalidateQueries(["order-list", orderParams.status]);
                    dispatch(setSnackbar({ open: true, message: "Shipping status updated", severity: "success" }));
                    dispatch(setPopupLoading({ loading: false, message: "" }));
                    dispatch(unsetPopup());
                    if (refetch) {
                        refetch();
                    }
                }
            },
            onError: () => {
                dispatch(setPopupLoading({ loading: false, message: "" }));
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Error while updating shipping status, please contact admin.",
                        severity: "error",
                    }),
                );
            },
        },
    );

    const { mutate: downloadOrderInvoice, isLoading: onDownloadOrderInvoice } = useMutation(
        (payload) => getInvoiceFile({ order_id: payload.order_id }),
        {
            onSuccess: (res, payload) => {
                if (res.data?.message?.includes("jwt expired")) {
                    dispatch(setLogout());
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: "Your session has expired. Please sign in again.",
                            severity: "error",
                        }),
                    );
                    return;
                }
                if (res.ok) {
                    saveAs(res.data, `invoice-${payload.order_sn}.pdf`);
                    dispatch(setSnackbar({ open: true, message: "Download Invoice Success", severity: "info" }));
                    return;
                }
                dispatch(setSnackbar({ open: true, message: res?.originalError?.message, severity: "error" }));
            },
            onError: (err) => {
                console.log("ERROR", err);
            },
        },
    );

    const { mutate: submitInternalNote, isLoading: onSubmitInternalNote } = useMutation(
        (payload) => postordernote(payload),
        {
            onSuccess: (res, payload) => {
                if (res.data?.message?.includes("jwt expired")) {
                    dispatch(setLogout());
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: "Your session has expired. Please sign in again.",
                            severity: "error",
                        }),
                    );
                    return;
                }
                queryClient.invalidateQueries(["order", "logs", payload.order_id]);
                queryClient.invalidateQueries(["order", "detail", payload.order_id]).then(() => {
                    dispatch(setSnackbar({ open: true, message: "Internal note submitted ", severity: "success" }));
                });
            },
            onError: (err) => {
                console.log("ERROR", err);
            },
        },
    );

    return {
        downloadOrderInvoice,
        onDownloadOrderInvoice,
        confirmShipment,
        onConfirmShipment,
        confirmShipmentData,
        shipmentData,
        submitInternalNote,
        onSubmitInternalNote,
    };
};

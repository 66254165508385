import { styled } from "@material-ui/core";

const Pagination = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 0",
    "& > div:nth-child(2)": {
        display: "flex",
        gap: "10px",
    },
});

const PaginationTable = ({ children }) => {
    return <Pagination>{children}</Pagination>;
};

PaginationTable.displayName = "pagination";

PaginationTable.defaultProps = {
    __type: "pagination",
};

export default PaginationTable;

import MerchantCentre from "pages/admin-module";
import Application from "pages/admin-module/Application";
import ApplicationDetail from "pages/admin-module/ApplicationDetail";
import AdminProductList from "pages/admin-module/Product/ProductList";
import AdminProductDetail from "pages/admin-module/Product/ProductDetail";
import PersonalSetting from "pages/site-module/PersonalSetting";
import AddProduct from "pages/merchant-module/Product/ProductAdd";
import ProductList from "pages/merchant-module/Product/ProductList";
import AddProductForm from "pages/merchant-module/Product/ProductAddForm";
import EditProductForm from "pages/merchant-module/Product/ProductEditForm";
import AddSelectedCategory from "pages/merchant-module/Product/ProductAddCategory";
import AdminMerchant from "pages/admin-module/MerchantList";
import AccountManagement from "pages/merchant-module/Setting/AccountManagement";
import StoreManagement from "pages/merchant-module/Setting/StoreManagement";
import StorePageCategoryManagement from "pages/merchant-module/Setting/StorePageCategoryManagement";
import StorePageCategoryLv1AddEdit from "pages/merchant-module/Setting/StorePageCategoryManagement/StorePageCategoryLv1AddEdit";
import ShipmentManagement from "pages/merchant-module/Setting/ShippingManagement";
import ProductAddSelected from "pages/merchant-module/Product/ProductAddSelected";
import Order from "pages/merchant-module/Order";
import OrderDetails from "pages/merchant-module/Order/details";
import SalesSummary from "pages/merchant-module/Setting/SalesSummary";
import StorePageManagement from "pages/merchant-module/Setting/StorePageManagement";
import PromotionInfo from "pages/merchant-module/PromotionTools/PromotionInfo/PromotionInfo";
import PromotionInfoAdd from "pages/merchant-module/PromotionTools/PromotionInfo/PromotionInfoAdd";
import PromotionInfoEdit from "pages/merchant-module/PromotionTools/PromotionInfo/PromotionInfoEdit";
import OnSalePromotionList from "pages/merchant-module/PromotionTools/OnSalePromotion";
import OnSalePromotionAddEdit from "pages/merchant-module/PromotionTools/OnSalePromotion/OnSalePromotionAddEdit";
import BulkDiscountList from "pages/merchant-module/PromotionTools/BulkDiscount";
import BulkDiscountAddEdit from "pages/merchant-module/PromotionTools/BulkDiscount/BulkDiscountAddEdit";
import BannerList from "pages/merchant-module/Setting/StoreBannerManagement/BannerList";
import BannerAddEdit from "pages/merchant-module/Setting/StoreBannerManagement/BannerAddEdit";
import ProductAmend from "pages/admin-module/ProductAmend/ProductAmend";
import PointReward from "pages/merchant-module/PromotionTools/PointReward/PointReward";

export default [
    { exact: true, path: "/", component: MerchantCentre, allow: true },
    { exact: true, path: "/products/list", component: ProductList, allow: true },
    { exact: true, path: "/products/add", component: AddProduct, allow: true },
    { exact: true, path: "/products/add/form/:product_id", component: AddProductForm, allow: true },
    { exact: true, path: "/products/resell/form/:product_id", component: ProductAddSelected, allow: true },
    { exact: true, path: "/products/edit/form/:product_id", component: EditProductForm, allow: true },
    { exact: true, path: "/products/add/selected", component: AddSelectedCategory, allow: true },
    { exact: true, path: "/promotion/promotion-info", component: PromotionInfo, allow: true },
    { exact: true, path: "/promotion/promotion-info/add", component: PromotionInfoAdd, allow: true },
    { exact: true, path: "/promotion/promotion-info/edit/:id", component: PromotionInfoEdit, allow: true },
    { exact: true, path: "/promotion/on-sale-promotion", component: OnSalePromotionList, allow: true },
    { exact: true, path: "/promotion/on-sale-promotion/add", component: OnSalePromotionAddEdit, allow: true },
    { exact: true, path: "/promotion/on-sale-promotion/edit/:id", component: OnSalePromotionAddEdit, allow: true },
    { exact: true, path: "/promotion/bulk-discount", component: BulkDiscountList, allow: true },
    { exact: true, path: "/promotion/bulk-discount/add", component: BulkDiscountAddEdit, allow: true },
    { exact: true, path: "/promotion/bulk-discount/edit/:id", component: BulkDiscountAddEdit, allow: true },
    { exact: true, path: "/promotion/points-reward", component: PointReward, allow: true },

    { exact: true, path: "/orders", component: Order, allow: true },
    { exact: true, path: "/orders/:order_id", component: OrderDetails, allow: true },
    { exact: true, path: "/settings/account", component: AccountManagement, allow: true },
    { exact: true, path: "/settings/store", component: StoreManagement, allow: true },
    { exact: true, path: "/settings/store-page-category", component: StorePageCategoryManagement, allow: true },
    {
        exact: true,
        path: "/settings/store-page-category/edit/:lv1CatId",
        component: StorePageCategoryLv1AddEdit,
        allow: true,
    },
    {
        exact: true,
        path: "/settings/store-page-category/add",
        component: StorePageCategoryLv1AddEdit,
        allow: true,
    },
    { exact: true, path: "/settings/store-banner", component: BannerList, allow: true },
    {
        exact: true,
        path: "/settings/store-banner/add",
        component: BannerAddEdit,
        allow: true,
    },
    {
        exact: true,
        path: "/settings/store-banner/edit/:bannerId",
        component: BannerAddEdit,
        allow: true,
    },
    { exact: true, path: "/settings/shipping", component: ShipmentManagement, allow: true },
    { exact: true, path: "/settings/sales-summary", component: SalesSummary, allow: true },
    { exact: true, path: "/settings/store-page", component: StorePageManagement, allow: true },
    { exact: true, path: "/merchant", component: MerchantCentre, allow: true },
    { exact: true, path: "/personal", component: PersonalSetting, allow: true },

    { exact: true, path: "/admin", component: Application, allow: true },
    { exact: true, path: "/admin/application", component: Application, allow: true },
    { exact: true, path: "/admin/application/detail/:id", component: ApplicationDetail, allow: true },
    { exact: true, path: "/admin/merchant", component: AdminMerchant, allow: true },
    { exact: true, path: "/admin/products", component: AdminProductList, allow: true },
    { exact: true, path: "/admin/products/:id", component: AdminProductDetail, allow: true },
    { exact: true, path: "/admin/products-amend", component: ProductAmend, allow: true },
    { exact: true, path: "/admin/personal", component: PersonalSetting, allow: true },
];

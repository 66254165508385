import { Button, styled, TextareaAutosize, TextField } from "@material-ui/core";
import { FormText } from "components/Global/Form";
import { memo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { postOrderNote } from "service/actions/merchant";
import { useOrderActions } from "../../hooks";

const BuyerNotes = styled("div")(({ theme }) => ({
    gridColumn: "26/39",
    gridRow: "5/6",
    "& > *": {
        background: theme.yoho.md_color.blue[50],
        border: "1px solid " + theme.yoho.md_color.blue[100],
        padding: 15,
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        gap: 10,
        maxHeight: 220,
    },
}));

export default memo(() => {
    const methods = useForm({
        defaultValues: {
            content: "",
        },
        mode: "onChange",
    });

    const { order_id } = useParams();
    const dispatch = useDispatch();
    const shipping = useSelector((state) => state.merchant.setting.shipping);
    const { submitInternalNote, onSubmitInternalNote } = useOrderActions();
    const onSubmit = (data) => {
        submitInternalNote({
            order_id,
            content: data.content,
        });
        methods.setValue("content", "");
    };

    const content = methods.watch("content");

    return (
        <FormProvider {...methods}>
            <BuyerNotes>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <FormText color="blue" code={800}>
                        <p className="subtitle1">Internal Notes</p>
                    </FormText>

                    <Controller
                        name="content"
                        control={methods.control}
                        render={({ onChange, value }) => (
                            <TextField
                                multiline
                                rows={6}
                                name="content"
                                variant="outlined"
                                placeholder="Leave comment..."
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    <Button variant="outlined" type="submit" disabled={onSubmitInternalNote}>
                        {onSubmitInternalNote ? "Submitting..." : "Submit"}
                    </Button>
                </form>
            </BuyerNotes>
        </FormProvider>
    );
});

import { DialogContent, DialogTitle, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import Popup from "components/Global/Layout/popup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { unsetPopup, setSnackbar } from "service/actions/site";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomDenyDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

export default ({ action, title, sub_category, editdata = "" }) => {
    const { register, handleSubmit, reset } = useForm();
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);
    const variation = useSelector((state) => state.merchant.product.config.variation_attribute);

    useEffect(() => {
        reset(editdata);
        setReady(true);
    }, []);

    const onSubmit = (data) => {
        data.sub_category_id = sub_category;

        const exist = variation.some(
            (item) =>
                item.sub_category_id == data.sub_category_id &&
                item.attribute_name_chinese === data.attribute_name_chinese &&
                item.attribute_name_english === data.attribute_name_english,
        );

        if (exist) {
            dispatch(
                setSnackbar({
                    open: true,
                    message: "Current attribute name is already exist",
                    severity: "error",
                }),
            );
            return;
        }

        if (editdata) {
            action(data, editdata.template_id, editdata);
        } else {
            action(data);
        }
        dispatch(unsetPopup());
    };

    return (
        ready && (
            <Popup size="md" fullWidth>
                <CustomDialogTitle>{title}</CustomDialogTitle>
                <CustomDenyDialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormCategory justifycontent="center">
                            <TextField
                                inputRef={register()}
                                name="attribute_name_chinese"
                                variant="outlined"
                                label="Attribute Name (Traditional Chinese)"
                                className="half"
                            />
                            <TextField
                                inputRef={register()}
                                name="attribute_name_english"
                                variant="outlined"
                                label="Attribute Name (English)"
                                className="half"
                            />
                            <CustomFormButton
                                className="half"
                                ccolor="blue"
                                cvariant="text"
                                fullWidth
                                onClick={() => dispatch(unsetPopup())}
                            >
                                Cancel
                            </CustomFormButton>
                            <CustomFormButton
                                className="half"
                                ccolor="blue"
                                cvariant="contained"
                                type="submit"
                                fullWidth
                            >
                                Submit
                            </CustomFormButton>
                        </FormCategory>
                    </form>
                </CustomDenyDialogContent>
            </Popup>
        )
    );
};

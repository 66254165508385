import { Button, styled } from "@material-ui/core";
import { FormText } from "components/Global/Form";
import usePopup from "hooks/usePopup";
import moment from "moment";
import ConfirmShippment from "../../../popup/ConfirmShippment";

const OrderSummary = styled("div")(({ theme }) => ({
    gridColumn: "1/26",
    gridRow: "1",
    borderRadius: 8,
    border: "1px solid" + theme.yoho.md_color.grey[200],
    padding: 15,
    display: "flex",
    flexDirection: "column",
    gap: 10,
}));

const OrderSummaryContent = styled("div")(({ theme }) => ({
    display: "flex",
    fontSize: 14,
    lineHeight: "20px",
    gap: 20,
    "& > div": {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        "& p": {
            margin: 0,
        },
    },
    "& label": {
        color: theme.yoho.md_color.grey[500],
    },
    "& span": {
        color: theme.yoho.md_color.grey[800],
    },
    "& .button": {
        placeSelf: "flex-end",
    },
}));

export default ({ data, refetch }) => {
    const popup = usePopup();

    return (
        <OrderSummary>
            <FormText>
                <p className="subtitle1">Order Summary</p>
            </FormText>
            <OrderSummaryContent>
                <div>
                    <p>
                        <label>Purchase Time: </label>
                        <span>
                            {data.pay_time ? moment.unix(data.pay_time).format("ddd, MMM DD, YYYY HH:mm:ss") : "--"}
                        </span>
                    </p>
                    <p>
                        <label>Purchase by: </label> <span>{data.tel}</span>
                    </p>
                    <p>
                        <label>Ordered from: </label> <span>{data.platform}</span>
                    </p>
                </div>
                <div>
                    <p>
                        <label>Shipping Service: </label>
                        <span>Standard Delivery</span>
                    </p>
                    <p>
                        <label>Send With: </label>
                        {data.order_status === "To Ship" && (
                            <Button
                                onClick={() =>
                                    popup(ConfirmShippment, {
                                        orderId: data.order_id,
                                        refetch,
                                    })
                                }
                                variant="contained"
                                color="primary"
                                style={{ height: 30 }}
                            >
                                Confirm Shipment
                            </Button>
                        )}
                        {data?.DeliveryOrders.length > 0 && (
                            <span>
                                {data?.DeliveryOrders?.[0]?.Logistic?.logistics_name || "Other"} #
                                {data?.DeliveryOrders?.[0]?.invoice_no}
                            </span>
                        )}
                    </p>
                    {data?.DeliveryOrders.length > 0 && (
                        <p>
                            <label>Remark: </label> <span dangerouslySetInnerHTML={{ __html: data?.ship_remarks }} />
                        </p>
                    )}
                </div>
            </OrderSummaryContent>
        </OrderSummary>
    );
};

import { useState, useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import { Form } from "components/General/Form";
import { FieldType } from "components/General/Field";
import { FormButton } from "components/Global";
import { cloneDeep } from "lodash";
import { useParams, useLocation, Prompt, useHistory } from "react-router-dom";
import { Table } from "components/General/Table";
import { useApiWithErrorHandle } from "service/apis/merchant/utilHook";
import { useDispatch, useSelector } from "react-redux";
import * as settingAPI from "service/apis/merchant/setting";
import * as promotionAPI from "service/apis/merchant/promo";
import { LoadingContainer } from "components/MerchantCentre/AdministrationConsole/Application/Details/StyledComponent";
import { CircularProgress } from "@material-ui/core";
import {
    getStoreCategoryDetails,
    setStoreCategoryDetails,
    resetStoreCategoryDetails,
    clearStoreCategoryDetails,
    deleteStoreSubcategory,
} from "service/actions/merchant";
import { setSnackbar } from "service/actions/site";
import StorePageCategoryLv2AddEdit from "./StorePageCategoryLv2AddEdit";

const PageContainer = styled("div")({
    margin: "0px 35px",
    position: "relative",
});
const FormSection = styled("div")({
    flexGrow: 1,
});
const PanelSection = styled("div")({
    width: 380,
    paddingLeft: "25px",
    paddingTop: "13px",
});
const Grid = styled("div")({
    display: "flex",
});
const RightPanel = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    minWidth: "380px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "20px",
    height: "fit-content",
    borderRadius: 16,
    position: "sticky",
    top: "35px",
    "& > *": {
        width: "100% !important",
    },
}));
const TableContainer = styled("div")({
    marginTop: 10,
});
const TableHead = styled("div")({
    display: "flex",
    margin: "16px 0px",
    justifyContent: "flex-end",
});
const AddButton = styled(FormButton)({ width: 135, height: 38 });
const MainContainer = styled("div")({
    width: "100%",
});

const columns = [
    {
        label: "Sort Order",
        name: "sortOrder",
        size: 0.8,
    },
    {
        label: "Chinese Name",
        name: "chineseName",
        size: 1.5,
    },
    {
        label: "English Name",
        name: "englishName",
        size: 1.5,
    },
    {
        label: "Hidden",
        name: "isHidden",
        size: 1,
    },
];

const initFormSections = [
    {
        fields: [
            {
                label: "Chinese Name",
                name: "chineseName",
                type: FieldType.TEXT,
            },
            {
                label: "English Name",
                name: "englishName",
                type: FieldType.TEXT,
            },
            {
                label: "Sort Order",
                name: "sortOrder",
                type: FieldType.TEXT,
            },
        ],
    },
    {
        fields: [
            {
                label: "Hide Category",
                name: "isHidden",
                type: FieldType.SWITCH,
            },
        ],
    },
    {
        fields: [
            {
                label: "Enable Sub-Category",
                name: "haveSubCat",
                type: FieldType.SWITCH,
            },
        ],
    },
];

function validateFormData({ chineseName, englishName, sortOrder, haveSubCat, subCategories, goods }) {
    if (!chineseName) {
        return ["Chinese name must be filled in", { chineseName: "This field cannot be empty" }];
    }
    if (!englishName) {
        return ["English name must be filled in", { englishName: "This field cannot be empty" }];
    }
    if (typeof sortOrder !== "number" && Number.isNaN(Number(sortOrder))) {
        return [
            "Sort order must be filled in and should be number only",
            { sortOrder: "This field cannot be empty and should be number only" },
        ];
    }
    if (haveSubCat) {
        if (subCategories.length === 0) return ["Please add at least one sub-category.", {}];
    } else {
        if (goods.length === 0) return ["Please add at least one goods to the category.", {}];
    }
    return ["", {}];
}

export default function StorePageCategoryLv1AddEdit() {
    const { lv1CatId } = useParams();
    const { pathname } = useLocation();
    const action = pathname.includes("edit") ? "edit" : "add";
    const dispatch = useDispatch();
    const { category: lv1CategoryInfo = {} } = useSelector((state) => state.merchant.setting);

    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [editStatus, setEditStatus] = useState({ action, level: 1, catId: lv1CatId });
    const createStorePageCategory = useApiWithErrorHandle(settingAPI.createStorePageCategory);
    const editStorePageCategory = useApiWithErrorHandle(settingAPI.editStorePageCategory);
    const history = useHistory();

    const { loading = true, haveSubCat } = lv1CategoryInfo;

    const subCatRows = lv1CategoryInfo.subCategories?.map((row) => ({
        catId: row.catId,
        sortOrder: row.sortOrder,
        chineseName: row.chineseName,
        englishName: row.englishName,
        isHidden: row.isHidden ? "Yes" : "No",
    }));

    const formValues = {
        ...lv1CategoryInfo,
        goods: lv1CategoryInfo.goods?.map(({ goodId, chineseName }) => ({
            label: chineseName,
            id: goodId,
        })),
    };

    const changeHandler = (field, value) => {
        // Handle format conversion
        if (field === "goods") {
            value = value.map(({ id, label }) => ({
                goodId: id,
                chineseName: label,
            }));
        }
        dispatch(setStoreCategoryDetails({ [field]: value }));
    };

    const handleSubmit = async () => {
        const payload = cloneDeep(lv1CategoryInfo);
        if (haveSubCat) {
            delete payload.goods;
        } else {
            delete payload.subCategories;
        }

        const [errorMsg, fieldError] = validateFormData(payload);
        if (errorMsg) {
            setErrors(fieldError);
            dispatch(
                setSnackbar({
                    open: true,
                    message: errorMsg,
                    severity: "error",
                }),
            );
            return;
        }

        setSubmitting(true);
        let result;
        if (action === "add") {
            result = await createStorePageCategory(payload);
        } else {
            result = await editStorePageCategory(payload);
        }

        if (result) {
            dispatch(setSnackbar({ open: true, message: "Saved successfully!", severity: "success" }));
            history.push("/settings/store-page-category");
        }
    };

    const handleStorePageCategoryDelete = async (row) => {
        const confirmDelete = window.confirm(`Are you sure to delete "${row.chineseName}"?`);
        if (confirmDelete) {
            dispatch(deleteStoreSubcategory(row.catId));
        }
    };

    useEffect(() => {
        if (action === "add") {
            dispatch(resetStoreCategoryDetails());
        } else {
            dispatch(getStoreCategoryDetails(lv1CatId));
        }
        return () => dispatch(clearStoreCategoryDetails());
    }, []);

    if (loading)
        return (
            <LoadingContainer>
                <CircularProgress size={100} />
                Loading Category Information
            </LoadingContainer>
        );

    if (editStatus.level === 2)
        return (
            <StorePageCategoryLv2AddEdit
                action={editStatus.action}
                catId={editStatus.catId}
                back={() => setEditStatus({ action, level: 1, catId: lv1CatId })}
            />
        );

    return (
        <PageContainer>
            <h1>Store Page Category</h1>
            <Grid>
                <MainContainer>
                    <FormSection>
                        <Form
                            sections={[
                                ...initFormSections,
                                {
                                    hidden: !!haveSubCat,
                                    fields: [
                                        {
                                            type: FieldType.PRODUCT_LIST,
                                            label: "Add Product",
                                            name: "goods",
                                            onItemAdd: (newItems) => {
                                                const newList = cloneDeep(formValues.goods);
                                                newList.unshift(...newItems);
                                                changeHandler("goods", newList);
                                            },
                                            onItemRemove: (removedItems) => {
                                                const removedIds = removedItems.map(({ id }) => id);
                                                const newList = formValues.goods.filter(
                                                    ({ id }) => !removedIds.includes(id),
                                                );
                                                changeHandler("goods", newList);
                                            },
                                        },
                                    ],
                                },
                            ]}
                            values={formValues}
                            errors={errors}
                            defaultChangeHandler={changeHandler}
                        />
                    </FormSection>
                    {haveSubCat && (
                        <TableContainer>
                            <TableHead>
                                <AddButton onClick={() => setEditStatus({ action: "add", level: 2 })}>
                                    Add New
                                </AddButton>
                            </TableHead>
                            <Table
                                columns={columns}
                                rows={subCatRows.sort((a, b) => a.sortOrder - b.sortOrder)}
                                emptyMessage="No Sub-category Added"
                                rowEdit={(row) => setEditStatus({ action: "edit", level: 2, catId: row.catId })}
                                rowDelete={(row) => handleStorePageCategoryDelete(row)}
                            />
                        </TableContainer>
                    )}
                </MainContainer>

                <PanelSection>
                    <RightPanel>
                        <FormButton cvariant="contained" ccolor="blue" disabled={submitting} onClick={handleSubmit}>
                            {submitting ? "Loading . . . " : "Submit And Save"}
                        </FormButton>
                        <FormButton
                            cvariant="text"
                            ccolor="blue"
                            onClick={() => history.push("/settings/store-page-category")}
                        >
                            Discard Changes
                        </FormButton>
                    </RightPanel>
                </PanelSection>
            </Grid>
            <Prompt when={!submitting} message="You have unsaved changes, are you sure you want to leave?" />
        </PageContainer>
    );
}

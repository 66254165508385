import { MenuItem, TextField } from "@material-ui/core";
import { FormCategory } from "components/Global";
import { useSelector } from "react-redux";
import MPW_MultiRTE from "./MPW_MultiRTE";

const description_lang = [
    { id: 1, name: "Traditional Chinese", code: "warranty_terms_chinese" },
    { id: 2, name: "English", code: "warranty_terms_english" },
];

export default ({ draft, product_id, initData }) => {
    const warrantyTerms = useSelector((state) => state.merchant.product.config?.warranty_terms);
    const active_warranty = useSelector((state) => state.merchant.product.warranty_terms.edit);

    return (
        <div>
            <FormCategory>
                {initData?.result?.warranty_terms?.length ? (
                    <TextField
                        disabled
                        select
                        defaultValue={initData?.result?.warranty_terms[0]}
                        value={initData?.result?.warranty_terms[0]}
                        variant="outlined"
                    >
                        {initData?.result?.warranty_terms?.map((option) => (
                            <MenuItem key={option.template_id} value={option}>
                                {option.template_name}
                            </MenuItem>
                        ))}
                    </TextField>
                ) : (
                    <div></div>
                )}
                <div className="full">
                    <MPW_MultiRTE
                        draft={draft}
                        product_id={product_id}
                        options={description_lang}
                        defaultstate={description_lang[0].code}
                        termsData={warrantyTerms?.find((item) => item.template_id == active_warranty)}
                    />
                </div>
            </FormCategory>
        </div>
    );
};

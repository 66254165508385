export default () => {
    return (
        <div className="sub">
            <h5>Tips</h5>
            <p>
                Your service affects your sales directly.We recommend you to handle order within 8 hours and operate
                everyday.
            </p>
        </div>
    );
};

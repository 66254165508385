import { useEffect, useState } from "react";
import { styled, Switch } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import { Prompt, useHistory } from "react-router-dom";
import { FormButton } from "components/Global";
import { FieldType } from "components/General/Field";
import { Form } from "components/General/Form";
import * as promotionAPI from "service/apis/merchant/promo";
import { useDispatch } from "react-redux";
import { setSnackbar } from "service/actions/site";
import { useApiWithErrorHandle } from "service/apis/merchant/utilHook";

const PageContainer = styled("div")({
    margin: "0px 35px",
    position: "relative",
});

const InfoText = styled("p")({
    color: "#888888",
});

const Grid = styled("div")({
    display: "flex",
});

const FormSection = styled("div")({
    flexGrow: 1,
});

const PanelSection = styled("div")({
    width: 380,
    paddingLeft: "25px",
    paddingTop: "13px",
    position: "sticky",
    top: "35px",
});

const SwitchContainer = styled("div")(({ theme }) => ({
    color: "#181818",
    width: "100%",
    textAlign: "center",
    padding: "8px 0px",
    borderRadius: 12,
    marginTop: 15,
    background: theme.yoho.md_color.grey[100],
    fontSize: 18,
    position: "sticky",
    top: "230px",
}));

const AutoDescContainer = styled("div")(({ theme }) => ({
    color: "#181818",
    width: "calc(100% - 36px)",
    padding: "16px 18px",
    borderRadius: 12,
    marginTop: 15,
    background: theme.yoho.md_color.grey[100],
    fontSize: 16,
    position: "sticky",
    top: "300px",
}));

const RightPanel = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    minWidth: "380px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "20px",
    height: "fit-content",
    borderRadius: 16,
    position: "sticky",
    top: "30px",
    "& > *": {
        width: "100% !important",
    },
}));

const formSections = [
    {
        fields: [
            {
                type: FieldType.TEXT,
                label: "Chinese Title",
                name: "title",
            },
            {
                type: FieldType.TEXT,
                label: "English Title",
                name: "titleEnUs",
            },
            {
                type: FieldType.TEXT,
                label: "Chinese Description",
                name: "description",
                fullWidth: true,
            },
            {
                type: FieldType.TEXT,
                label: "English Descrtiption",
                name: "descriptionEnUs",
                fullWidth: true,
            },
            {
                type: FieldType.DATE,
                label: "Start Date ",
                name: "startDate",
            },
            {
                type: FieldType.DATE,
                label: "End Date",
                name: "endDate",
            },
        ],
    },
    {
        fields: [
            {
                name: "thresholdType",
                label: "Discount Threshold",
                type: FieldType.RADIO,
                options: [
                    { label: "By Quantity (Buy N items or more for discount)", value: "over_qty" },
                    { label: "By Dollar Value (Buy Y dollars or more for discount)", value: "over_price" },
                ],
                fullWidth: true,
            },
            {
                name: "discountType",
                label: "Discount Method",
                type: FieldType.RADIO,
                options: [
                    { label: "Percentage Discount", value: "percentage_discount" },
                    { label: "Save X Dollars", value: "price_discount" },
                ],
                fullWidth: true,
            },
            {
                name: "quantityThreshold",
                label: "Quantity (Buy more than N items)",
                type: FieldType.TEXT,
                inputType: "number",
                hidden: true,
            },
            {
                name: "dollarThreshold",
                label: "Dollars (Buy more than Y dollars)",
                type: FieldType.TEXT,
                inputType: "number",
                hidden: true,
                InputProps: {
                    startAdornment: "$",
                },
            },
            {
                name: "percentageDiscount",
                label: "Percentage Off",
                type: FieldType.TEXT,
                inputType: "number",
                hidden: true,
                InputProps: {
                    endAdornment: "%",
                },
            },
            {
                name: "dollarDiscount",
                label: "Dollars Saved",
                type: FieldType.TEXT,
                inputType: "number",
                hidden: true,
                InputProps: {
                    startAdornment: "$",
                },
            },
        ],
    },
    {
        label: "Eligible Products",
        fields: [
            {
                type: FieldType.PRODUCT_LIST,
                label: "Search and Add Related Product",
                name: "products",
            },
        ],
    },
];

function unixTimeStampToDate(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
}

function isPositiveInteger(value) {
    const numValue = Number(value);
    if (typeof numValue !== "number" || isNaN(numValue)) return false;
    if (!Number.isInteger(numValue)) return false;
    if (numValue <= 0) return false;
    return true;
}

function validateFormData(data) {
    if (!data.title) {
        return ["Title must be filled in", { title: "This field cannot be empty" }];
    }
    if (!data.titleEnUs) {
        return ["English title must be filled in", { titleEnUs: "This field cannot be empty" }];
    }
    if (data.startDate > data.endDate) {
        return ["End date must be after start date", { endDate: "Must be after start date" }];
    }
    if (!data.thresholdType) {
        return ["Please specify the discount threshold type", {}];
    }
    if (!data.discountType) {
        return ["Please specify the discount method", {}];
    }
    if (data.thresholdType === "quantity") {
        if (!isPositiveInteger(data.quantityThreshold)) {
            return [
                "Quantity threshold must be positive integer",
                { quantityThreshold: "This field must be positive integer" },
            ];
        }
    }
    if (data.thresholdType === "dollars") {
        if (!isPositiveInteger(data.dollarThreshold)) {
            return [
                "Dollars threshold must be positive integer",
                { dollarThreshold: "This field must be positive integer" },
            ];
        }
    }
    if (data.discountType === "percentage_discount") {
        if (!isPositiveInteger(data.percentageDiscount)) {
            return [
                "Quantity threshold must be positive integer",
                { percentageDiscount: "This field must be positive integer" },
            ];
        }
        if (parseInt(data.percentageDiscount) > 90) {
            return ["Maximum allowed percentage discount is 90%", { percentageDiscount: "Must be 90% or lower" }];
        }
    }
    if (data.discountType === "price_discount") {
        if (!isPositiveInteger(data.dollarDiscount)) {
            return [
                "Quantity threshold must be positive integer",
                { dollarDiscount: "This field must be positive integer" },
            ];
        }
    }
    if (data.products.length === 0) {
        return ["This event must contain at least one product", {}];
    }
    return ["", {}];
}

function getAutoPromoDescription({
    thresholdType,
    discountType,
    quantityThreshold,
    dollarThreshold,
    percentageDiscount,
    dollarDiscount,
    products,
}) {
    if (
        !thresholdType ||
        !products.length ||
        !(
            (discountType === "percentage_discount" && percentageDiscount) ||
            (discountType === "price_discount" && dollarDiscount)
        )
    ) {
        return "";
    }
    const threshold = parseInt(thresholdType === "over_qty" ? quantityThreshold : dollarThreshold);
    const thresholdUnit = thresholdType === "over_qty" ? "items" : "dollars";
    const offer =
        discountType === "percentage_discount"
            ? `enjoy a ${percentageDiscount}% discount on the items.`
            : `save ${dollarDiscount} for the purchase.`;

    return `Buy ${threshold} ${thresholdUnit} or above from (${products.length}) selected products to ${offer}`;
}

export default function OnSalePromotionAddEdit() {
    const { id } = useParams();
    const { pathname } = useLocation();
    const action = pathname.includes("edit") ? "edit" : "add";
    const history = useHistory();
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({
        title: "",
        titleEnUs: "",
        description: "",
        descriptionEnUs: "",
        startDate: new Date(),
        endDate: new Date(),
        thresholdType: null,
        discountType: null,
        quantityThreshold: null,
        dollarThreshold: null,
        percentageDiscount: null,
        dollarDiscount: null,
        isEnableEvent: true,
        products: [],
    });

    const getBulkDiscountInfo = useApiWithErrorHandle(promotionAPI.getDiscountEventInfo);
    const editBulkDiscount = useApiWithErrorHandle(promotionAPI.editDiscountEvent);
    const createBulkDiscount = useApiWithErrorHandle(promotionAPI.createDiscountEvent);

    const changeHandler = (field, value) => {
        const newData = cloneDeep(data);
        newData[field] = value;
        setData(newData);
    };

    const { thresholdType, discountType } = data;

    if (thresholdType === "over_qty") {
        formSections[1].fields[2].hidden = false;
        formSections[1].fields[3].hidden = true;
    }

    if (thresholdType === "over_price") {
        formSections[1].fields[2].hidden = true;
        formSections[1].fields[3].hidden = false;
    }

    if (thresholdType && discountType === "percentage_discount") {
        formSections[1].fields[4].hidden = false;
        formSections[1].fields[5].hidden = true;
    }

    if (thresholdType && discountType === "price_discount") {
        formSections[1].fields[4].hidden = true;
        formSections[1].fields[5].hidden = false;
    }

    formSections[2].fields[0].onItemAdd = (newItems) => {
        const newList = cloneDeep(data.products);
        newList.unshift(...newItems);
        changeHandler("products", newList);
    };

    formSections[2].fields[0].onItemRemove = (removedItems) => {
        const removedIds = removedItems.map(({ id }) => id);
        const newList = data.products.filter(({ id }) => !removedIds.includes(id));
        changeHandler("products", newList);
    };

    const handleSubmit = async () => {
        const [errorMsg, fieldError] = validateFormData(data);
        if (errorMsg) {
            setErrors(fieldError);
            dispatch(
                setSnackbar({
                    open: true,
                    message: errorMsg,
                    severity: "error",
                }),
            );
            return;
        }

        const payload = {
            ...(action === "edit" && { discountEventId: id }),
            promotionType: "bulk_purchase",
            title: data.title,
            titleEnUs: data.titleEnUs,
            description: data.description,
            descriptionEnUs: data.descriptionEnUs,
            startTime: Math.floor(new Date(data.startDate).getTime() / 1000),
            endTime: Math.floor(new Date(data.endDate).getTime() / 1000),
            isEnableEvent: data.isEnableEvent,
            discountThresholdType: data.thresholdType,
            priceCalculationStrategy: data.discountType,
            discountThreshold: data.thresholdType === "over_qty" ? data.quantityThreshold : data.dollarThreshold,
            discountAmount: data.dollarDiscount,
            discountPercentage: data.percentageDiscount,
            goods: data.products.map(({ id, discountPrice }) => ({ goods_id: id, discount_price: discountPrice })),
            sectiondata: {
                title: data.title,
                titleEnUs: data.titleEnUs,
            },
        };

        setSubmitting(true);
        let result;
        if (action === "edit") {
            result = await editBulkDiscount(payload);
        } else {
            result = await createBulkDiscount(payload);
        }

        if (result) {
            history.push("/promotion/bulk-discount");
            dispatch(
                setSnackbar({
                    open: true,
                    message: `Bulk discount event successfully ${action === "edit" ? "edited" : "created"}`,
                    severity: "success",
                }),
            );
        } else {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        const onLoad = async () => {
            const result = await getBulkDiscountInfo(id);
            if (result) {
                const formattedProductList = result.eventGoods.map(({ goods_id, goods_name }) => ({
                    label: goods_name,
                    id: goods_id,
                }));
                setData({
                    title: result.title,
                    titleEnUs: result.title_en_us,
                    description: result.description,
                    descriptionEnUs: result.description_en_us,
                    startDate: unixTimeStampToDate(result.start_time),
                    endDate: unixTimeStampToDate(result.end_time),
                    thresholdType: result.discount_threshold_type,
                    discountType: result.price_calculation_strategy,
                    quantityThreshold: result.discount_threshold_type === "over_qty" ? result.discount_threshold : null,
                    dollarThreshold: result.discount_threshold_type === "over_price" ? result.discount_threshold : null,
                    percentageDiscount: result.discount_percentage,
                    dollarDiscount: result.discount_amount,
                    isEnableEvent: result.is_enable_event,
                    products: formattedProductList,
                });
            } else {
                history.push("/promotion/bulk-discount");
            }
        };
        if (action === "edit") {
            onLoad();
        }
    }, []);

    const autoDescription = getAutoPromoDescription(data);

    return (
        <PageContainer>
            <h1>Bulk Purchase Discount</h1>
            <InfoText>
                Bulk discount events can be created to provide discount for a selected group of products if the customer
                buy a certain amount of them. Take a look at this{" "}
                <a
                    href="https://drive.google.com/file/d/1SPmZaEHXG_P2pjRUICCpG86bNK36byea/view?usp=sharing"
                    target="_blank"
                >
                    video
                </a>{" "}
                to know more.
            </InfoText>
            <Grid>
                <FormSection>
                    <Form sections={formSections} values={data} errors={errors} defaultChangeHandler={changeHandler} />
                </FormSection>
                <PanelSection>
                    <RightPanel>
                        <FormButton cvariant="contained" ccolor="blue" disabled={submitting} onClick={handleSubmit}>
                            {submitting ? "Loading . . . " : "Submit And Finish"}
                        </FormButton>
                        <FormButton
                            cvariant="text"
                            ccolor="blue"
                            onClick={() => history.push("/promotion/bulk-discount")}
                        >
                            Discard
                        </FormButton>
                    </RightPanel>
                    <SwitchContainer>
                        <span style={{ margin: "0px 5px" }}>Enable</span>
                        <Switch
                            checked={data.isEnableEvent}
                            onChange={(e) => changeHandler("isEnableEvent", e.target.checked)}
                            color="primary"
                        />
                    </SwitchContainer>
                    {autoDescription && (
                        <AutoDescContainer>
                            <b>Auto Description:</b>
                            <p style={{ margin: "8px 0px 0px" }}>{autoDescription}</p>
                        </AutoDescContainer>
                    )}
                </PanelSection>
            </Grid>
            <Prompt when={!submitting} message="You have unsaved changes, are you sure you want to leave?" />
        </PageContainer>
    );
}

import { Editor } from "@tinymce/tinymce-react";
import React, { useRef } from "react";
import { useSelector } from "react-redux";

export default ({ name, action, draft }) => {
    const editorRef = useRef(null);
    const delivery_return = useSelector((state) => state.merchant.product?.config?.shipping_management);
    let lang = name.split("_")[name.split("_").length - 1];

    return (
        <Editor
            onInit={(_, editor) => (editorRef.current = editor)}
            apiKey={process?.env.REACT_APP_TINY_MCE}
            onFocusOut={(e) => action({ [name]: e.target.innerHTML })}
            initialValue={draft?.spu?.[name] || delivery_return?.[`delivery_return_policy_${lang}`]}
            init={{
                height: 200,
                menubar: false,
                toolbar_mode: "wrap",
                width: "100%",
                plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                    "image preview media undo redo | formatselect |" +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
        />
    );
};

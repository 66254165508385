import { styled } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        width: ({ width }) => width.width || "100%",
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

const AutocompleteList = styled("div")(({ width }) => ({
    width: width || "100%",
}));

const CustomSelect = ({
    data = [],
    label,
    className,
    name,
    show,
    control,
    action,
    disabled,
    rules,
    error,
    helperText,
}) => {
    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    useEffect(() => {
        setWidth({ width: ref?.current?.offsetWidth });
    }, []);
    const classes = useStyles({ width: width });

    return (
        <Controller
            control={control}
            rules={rules}
            name={name}
            onChange={([, data]) => data}
            render={({ onChange, ref: refRender, value, ...props }) => {
                let currVal =
                    typeof value === "object"
                        ? value
                        : data.find((item) => item[show ? show[0] : "id"] === (value || 3409));
                return (
                    <Autocomplete
                        ref={ref}
                        options={data}
                        className={className}
                        classes={{
                            option: classes.option,
                        }}
                        onChange={(e, val) => {
                            onChange(val);
                            (name === "area_id" || name === "country_id") && action(val);
                        }}
                        autoHighlight
                        disabled={disabled}
                        getOptionLabel={(option) =>
                            option[show[0]]
                                ? show.length > 1
                                    ? `${option[show[0]]} - ${option[show[1]]}`
                                    : option[show[0]]
                                : ""
                        }
                        renderOption={(option) => (
                            <AutocompleteList>
                                {show.length > 1 ? `${option[show[0]]} - ${option[show[1]]}` : option[show[0]]}
                            </AutocompleteList>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label || ""}
                                variant="outlined"
                                fullWidth
                                error={error}
                                helperText={helperText}
                            />
                        )}
                        value={currVal}
                        {...props}
                    />
                );
            }}
        />
    );
};

export default React.memo(CustomSelect);

import { DialogContent, DialogTitle, InputAdornment, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import ComboTextArea from "components/Global/Form/TextArea/Controlled/ComboTextArea";
import ControlledToggle from "components/Global/Form/ControlledToggle";
import Popup from "components/Global/Layout/popup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { unsetPopup } from "service/actions/site";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomDenyDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

const MenuContainer = styled("div")({
    display: "flex",
});

const Menu = styled("h4")(({ active, theme }) => ({
    ...theme.yoho.typography.button,
    color: theme.yoho.md_color.grey[800],
    display: "flex",
    position: "relative",
    "&.active": {
        color: theme.yoho.md_color.blue[500],
        "&:after": {
            content: "''",
            background: theme.yoho.md_color.blue[500],
            width: "100%",
            position: "absolute",
            height: "1px",
            left: 0,
            bottom: "-14px",
        },
    },
    textTransform: "capitalize",
    cursor: "pointer",
    padding: "0 15px",
    margin: "13px 0",
    textAlign: "center",
    "& .bubble": {
        color: theme.yoho.md_color.red[500],
    },
}));

const ServiceFeeWrapper = styled("div")({
    display: "flex",
    gap: 30,
    flexDirection: "column",
    "& .full": {
        display: "flex",
        width: "100%",
        gap: 10,
        "&.fee": {
            justifyContent: "space-between",
        },
    },
    "& .shipping_field": {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        "& > div:nth-child(1)": {
            width: "100px",
        },
    },
    "& .fee_field": {
        height: 56,
        display: "flex",
        alignItems: "center",
        gap: "20px",
        "& > div:nth-child(1)": {
            width: "200px",
        },
    },
});

const Divider = styled("div")(({ theme }) => ({
    borderBottom: `1px solid ${theme.yoho.md_color.grey[300]}`,
    margin: "20px 0 10px 0",
    width: "100% !important",
}));

const DividerTitle = styled("div")(({ theme }) => ({
    width: "100%",
    borderBottom: `1px solid ${theme.yoho.md_color.grey[300]}`,
    marginBottom: "20px",
}));

const store_lang = [
    { id: 1, name: "Traditional Chinese", code: "description_chinese" },
    { id: 2, name: "English", code: "description_english" },
];

const menu = [
    { id: 1, name: "Service Fee", value: "Service Fee" },
    // { id: 2, name: "Description", value: "Description" }
];
export default ({ action, title = "Edit Warranty Terms", editdata = "" }) => {
    const { register, handleSubmit, watch, errors, setValue, control, reset } = useForm();
    const country = useSelector((state) => state.site?.config_countries);
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState({
        free_shipping: editdata?.free_shipping,
        package_fee: editdata?.package_fee,
    });

    const handleToggle = (e) => {
        setToggle({ ...toggle, [e.target.value]: e.target.checked });
    };

    const [ready, setReady] = useState(false);
    const [option, setOption] = useState("Service Fee");

    useEffect(() => {
        reset(editdata);
        setReady(true);
    }, []);

    const onSubmit = (data) => {
        if (editdata) {
            action(data, editdata.id, editdata);
        } else {
            action(data);
        }
        dispatch(unsetPopup());
    };

    return (
        ready && (
            <Popup size="md" fullWidth>
                <CustomDialogTitle>{title}</CustomDialogTitle>
                <CustomDenyDialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="full">
                            <FormText>
                                <p className="subtitle1">Destination</p>
                            </FormText>
                            <FormText>
                                <p className="body1">
                                    {country?.find(({ id }) => id === editdata?.destination_id)?.name}
                                </p>
                            </FormText>
                        </div>
                        <FormCategory justifycontent="center">
                            <MenuContainer className="full">
                                {menu.map((item) => (
                                    <Menu
                                        key={`${JSON.stringify(item.name)}`}
                                        className={option === item.value ? "active" : ""}
                                        onClick={() => setOption(item.value)}
                                    >
                                        {item.name}
                                        {!!item?.count && <a className="bubble">{item?.count}</a>}
                                    </Menu>
                                ))}
                            </MenuContainer>
                            <DividerTitle />
                            {option === "Service Fee" ? (
                                <ServiceFeeWrapper className="full">
                                    <div className="full">
                                        <FormText>
                                            <p className="subtitle1">Calculate shipping fee by</p>
                                        </FormText>
                                        <FormText>
                                            <p className="body1">Total {editdata?.calculated_by}</p>
                                        </FormText>
                                    </div>
                                    <div className="full fee">
                                        <div className="shipping_field">
                                            <FormText>
                                                <p className="subtitle1">Within 1kg</p>
                                            </FormText>
                                            <TextField
                                                variant="outlined"
                                                label="Shipping Fee"
                                                name="within_1"
                                                inputRef={register()}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    // endAdornment: <InputAdornment position="start">per 1kg</InputAdornment>,
                                                }}
                                            />
                                        </div>
                                        <div className="shipping_field">
                                            <FormText>
                                                <p className="subtitle1">Within 5kg</p>
                                            </FormText>
                                            <TextField
                                                variant="outlined"
                                                label="Shipping Fee"
                                                name="within_5"
                                                inputRef={register()}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    // endAdornment: <InputAdornment position="start">per 1kg</InputAdornment>,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="shipping_field">
                                        <FormText>
                                            <p className="subtitle1">Over 5kg</p>
                                        </FormText>
                                        <TextField
                                            variant="outlined"
                                            label="Shipping Fee"
                                            name="over_5"
                                            inputRef={register()}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                // endAdornment: <InputAdornment position="start">per 1kg</InputAdornment>,
                                            }}
                                        />
                                    </div>
                                    <Divider />
                                    {/* <div className="fee_field">
                                    <FormText>
                                        <p className="subtitle1">Package fee</p>
                                    </FormText>
                                   <ControlledToggle mode={1} onChange={handleToggle} defaultValue={editdata?.package_fee} control={control} name="package_fee" value="package_fee" />
                                    {toggle?.package_fee && <TextField
                                        variant="outlined"
                                        label="Package Fee"
                                        name="package_fee_value"
                                        inputRef={register()}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                    />}
                                </div>
                                <Divider /> */}
                                    <div className="fee_field">
                                        <FormText>
                                            <p className="subtitle1">Free shipping</p>
                                        </FormText>
                                        {/* <Toggle mode={1} onChange={handleToggle} defaultValue={editdata?.free_shipping} inputRef={register()} name="free_shipping" value="free_shipping" /> */}
                                        <ControlledToggle
                                            mode={1}
                                            onChange={handleToggle}
                                            defaultValue={editdata?.package_fee}
                                            control={control}
                                            name="free_shipping"
                                            value="free_shipping"
                                        />
                                        {toggle?.free_shipping && (
                                            <>
                                                <FormText>
                                                    <p className="body1">On orders equal or over</p>
                                                </FormText>
                                                <TextField
                                                    variant="outlined"
                                                    label="Order Ammount"
                                                    name="free_shipping_value"
                                                    inputRef={register()}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">$</InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </>
                                        )}
                                    </div>
                                </ServiceFeeWrapper> // <InputText key={e.code} className="full" rows={4} display={option !== e.code ? "none" : "inline-flex"} multiline control={control} name={e.code} className="full" variant="outlined" />
                            ) : (
                                <ComboTextArea
                                    type="subtitle3"
                                    options={store_lang}
                                    control={control}
                                    defaultstate={store_lang[0]?.code}
                                    className="full"
                                />
                            )}
                            <CustomFormButton
                                className="half"
                                ccolor="blue"
                                cvariant="contained"
                                type="submit"
                                fullWidth
                            >
                                Save changes
                            </CustomFormButton>
                        </FormCategory>
                    </form>
                </CustomDenyDialogContent>
            </Popup>
        )
    );
};

import { Editor } from "@tinymce/tinymce-react";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
    TextField,
    Switch,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    styled,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { pickEditorImage } from "helper/imageUpload";
import ProductList from "./Fields/ProductList";
import ImageUpload from "./ImageUpload";
import FormAutocomplete from "./Fields/FormAutocomplete";

const SwitchContainer = styled("div")({
    color: "#333333",
    marginTop: "8px",
    fontSize: 17,
});

const styles = {
    root: {
        "&$checked": {
            color: "#00ACEE",
        },
    },
    checked: {},
};

const CustomRadio = withStyles(styles)(Radio);

export const FieldType = {
    TEXT: 0,
    DATE: 1,
    EDITOR: 2,
    PRODUCT_LIST: 3,
    IMAGE_UPLOAD: 4,
    SWITCH: 5,
    DROPDOWN: 6,
    AUTO_COMPLETE: 7,
    RADIO: 8,
};

export default function FormField({
    type,
    name,
    label,
    readOnly,
    fullWidth,
    options,
    onChange,
    value,
    error,
    ...rest
}) {
    const style = { width: fullWidth ? "100%" : "calc(50% - 6px)" };

    const handleChangeEvent = (event) => {
        const newValue = event.target.value;
        onChange(name, newValue);
    };

    const handleChangeValue = (newValue) => onChange(name, newValue);

    switch (type) {
        case FieldType.TEXT: {
            const { multiline = false, maxRows = 1 } = rest;
            return (
                <TextField
                    style={style}
                    disabled={readOnly}
                    name={name}
                    label={label}
                    value={value}
                    variant="outlined"
                    onChange={handleChangeEvent}
                    helperText={error || ""}
                    error={!!error}
                    multiline={multiline}
                    maxRows={maxRows}
                    type={rest.inputType}
                    {...rest}
                />
            );
        }
        case FieldType.DATE: {
            return (
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                        style={style}
                        name={name}
                        label={label}
                        value={value}
                        format="YYYY-MM-DD"
                        inputVariant="outlined"
                        error={!!error}
                        helperText={error || ""}
                        inputProps={{ readOnly: true }}
                        onChange={(momentObj) => handleChangeValue(momentObj.toDate())}
                        autoOk={true}
                        {...rest}
                    />
                </MuiPickersUtilsProvider>
            );
        }
        case FieldType.EDITOR: {
            return (
                <Editor
                    onEditorChange={handleChangeValue}
                    value={value}
                    init={{
                        resize: "height",
                        min_height: 300,
                        menubar: false,
                        statusbar: true,
                        media_live_embeds: false,
                        plugins:
                            "advlist autolink lists link image media charmap print anchor searchreplace visualblocks insertdatetime table paste wordcount",
                        toolbar:
                            "formatselect | image link | bullist numlist | bold italic backcolor | alignleft aligncenter alignright alignjustify | removeformat",
                        content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
                        file_picker_callback: (callback) => pickEditorImage(callback, ""),
                    }}
                />
            );
        }
        case FieldType.PRODUCT_LIST: {
            return (
                <ProductList
                    value={value}
                    label={label}
                    onItemAdd={rest.onItemAdd}
                    onItemRemove={rest.onItemRemove}
                    getOptions={rest.getOptions}
                    enablePriceInput={rest.enablePriceInput}
                    onEventPriceChange={rest.onEventPriceChange}
                    isAdmin={rest.isAdmin}
                />
            );
        }
        case FieldType.IMAGE_UPLOAD: {
            return (
                <ImageUpload
                    value={value}
                    label={label}
                    style={style}
                    name={name}
                    key={`${name}_image_upload`}
                    onChange={handleChangeValue}
                    {...rest}
                />
            );
        }
        case FieldType.SWITCH:
            return (
                <SwitchContainer style={style}>
                    <span style={{ margin: "0px 5px" }}>{label}</span>
                    <Switch
                        checked={value || false}
                        onChange={(e) => handleChangeValue(e.target.checked)}
                        color="primary"
                        {...rest}
                    />
                </SwitchContainer>
            );
        case FieldType.DROPDOWN:
            return (
                <FormControl style={style} error={!!error}>
                    <InputLabel variant="outlined" style={{ background: "#F8FAFF", padding: "0px 5px" }}>
                        {label}
                    </InputLabel>
                    <Select disabled={readOnly} variant="outlined" value={value} onChange={handleChangeEvent}>
                        {options.map(({ label, value }) => (
                            <MenuItem value={value} key={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            );
        case FieldType.AUTO_COMPLETE:
            return (
                <FormAutocomplete
                    style={style}
                    name={name}
                    value={value}
                    label={label}
                    error={!!error}
                    options={options}
                    onChange={onChange}
                    getOptions={rest.getOptions}
                    optionsFilter={rest.optionsFilter}
                    helperText={error || ""}
                />
            );
        case FieldType.RADIO:
            return (
                <FormControl style={style}>
                    {label && <FormLabel>{label}</FormLabel>}
                    <RadioGroup
                        value={value}
                        onChange={handleChangeEvent}
                        style={{ flexDirection: rest.vertical ? "column" : "row" }}
                    >
                        {options.map(({ value, label }) => (
                            <FormControlLabel value={value} control={<CustomRadio />} label={label} />
                        ))}
                    </RadioGroup>
                </FormControl>
            );
        default: {
            return null;
        }
    }
}

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default () => {
    const isAdmin = useSelector((state) => state.profile.isAdmin);
    const history = useHistory();

    useEffect(() => history.push(isAdmin ? "/admin/application" : "/products/list"), []);
    return <>LOGIN ALREADY </>;
};

if (
    window.location.hostname !== "x.yohohongkong.com" ||
    window.location.hostname === "betax.yohohongkong.com" ||
    window.location.hostname === "localhost"
) {
    global.fetchURL = "https://beta.yohohongkong.com";
} else {
    global.fetchURL = "https://www.yohohongkong.com";
}

// global.fetchNewApiURL = process?.env.REACT_APP_API_URL || "http://localhost:8080/console";
global.icalURL = "https://www.1823.gov.hk/common/ical/tc.json";

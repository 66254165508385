import produce, { current, setAutoFreeze } from "immer";
import { ID_order } from "initialData/merchant";
import moment from "moment";
import * as authActions from "service/actions/authentication";
import * as merchantActions from "service/actions/merchant";
setAutoFreeze(false);

const clearCheckbox = (checkbox) => {
    checkbox.list_goods_ids = [];
    checkbox.add_goods_ids = [];
    checkbox.edit_goods_ids = [];
    checkbox.selected_goods_ids = [];
    return checkbox;
};

let initialState = {
    timestamp: null,
    product: {
        search: {
            query: {
                lang: "en_us",
                product_name: "",
                result: [],
                selected: {},
            },
            data: null,
        },
        loading: false,
        params: {
            size: 30,
            page: 1,
            show: "sku",
            lang: "zh_tw",
            status: "listed",
            search: "",
            field: "goods_id",
            sort: "high_to_low",
        },
        checkbox_ids: {
            list_goods_ids: [],
            add_goods_ids: [],
            edit_goods_ids: [],
            selected_goods_ids: [],
        },
        warranty_terms: {
            add: "",
            edit: "",
            selected: "",
        },
        list: {},
        user: [],
        config: {},
        draft: {},
        cache: [],
        onsale: {},
        onsale_loading: false,
        add: {
            loading: false,
            data: {},
        },
        selected: {
            loading: false,
            data: {},
        },
        edit: {
            loading: false,
            data: {},
        },
    },
    order: {
        ...ID_order,
    },
    setting: {
        account: null,
        store: null,
        shipping: {},
        category: {},
    },
    loading: true,
};

export default produce((draft, action) => {
    const currentDraft = current(draft);
    switch (action.type) {
        case merchantActions.selectStoreWarranty().type:
            draft.product.warranty_terms = produce(draft.product.warranty_terms, (props) => {
                if (action.args !== "all") {
                    props[action.args] = action.data;
                    return props;
                }
                props["add"] = action.data;
                props["edit"] = action.data;
                props["selected"] = action.data;
                return props;
            });
            break;
        case merchantActions.setMerchantLoading().type:
            if (action.key === "default") {
                draft.loading = action.data;
            } else {
                draft[action.key].loading = action.data;
            }
            break;
        case merchantActions.resetMerchantLoading().type:
            draft.product = produce(currentDraft.product, (props) => {
                props.onsale_loading = false;
                props.add.loading = false;
                props.selected.loading = false;
                props.edit.loading = false;
                props.loading = false;
            });
            break;
        case authActions.setInitialForm().type:
            draft.master = action.data;
            break;
        // product
        case merchantActions.getProductList().type:
            draft.loading = true;
            break;
        case merchantActions.setProductList().type:
            draft.timestamp = moment();
            draft.product.list = action.data || {};
            draft.loading = false;
            break;
        case merchantActions.setProductParam().type:
            draft.timestamp = moment();
            draft.product = produce(currentDraft.product, (props) => {
                Object.keys(action.data).forEach((key) => {
                    props.params[key] = action.data[key];
                });
                return props;
            });
            break;
        case merchantActions.setQuery().type:
            draft.timestamp = moment();
            draft.product.search.query = produce(currentDraft.product.search.query, (props) => {
                props.result = action.data;
                return props;
            });
            break;
        case merchantActions.clearQuery().type:
            draft.timestamp = moment();
            draft.product.search = produce(currentDraft.product.search, (props) => {
                props.query = produce(props.query, (query) => {
                    query.lang = action?.data?.lang || "en_us";
                    query.product_name = action?.data?.product_name || "";
                    query.result = action?.data?.result || [];
                    query.selected = action?.data?.selected || {};
                    return query;
                });
                props.data = null;
                return props;
            });
            break;

        case merchantActions.selectResellProduct().type:
            draft.product.search.query = produce(currentDraft.product.search.query, (props) => {
                props.selected = action.data;
                props.product_name = action.data.goods_name;
                return props;
            });
            break;
        case merchantActions.setProductSearch().type:
            draft.timestamp = moment();
            draft.product.search.data = action.data;
            draft.loading = false;
            break;
        case merchantActions.setResellSelection().type:
            draft.product.loading = false;
            draft.product.selected.data = action.data;
            break;
        case merchantActions.setResellSubmitLoading().type:
            draft.product.selected.data.loading = action.isLoading;
            break;
        case merchantActions.setCreateSubmitLoading().type:
            draft.product.add.data.loading = action.isLoading;
            break;
        case merchantActions.getProductInitialConfig().type:
            draft.loading = true;
            break;
        case merchantActions.setProductInitialConfig().type:
            draft.product = produce(currentDraft.product, (props) => {
                Object.keys(action.data).forEach((key) => {
                    props.config[key] = action.data[key];
                });
                return props;
            });
            draft.loading = false;
            break;
        case merchantActions.setProductDraft().type:
            draft.timestamp = moment();
            draft.product = produce(currentDraft.product, (props) => {
                Object.keys(action.data).forEach((key) => {
                    props.draft[key] = action.data[key];
                });
                return props;
            });
            break;
        case merchantActions.setProductEditVariationDraft().type:
            draft.timestamp = moment();
            draft.product.edit.data.spu = produce(currentDraft.product.edit.data.spu, (props) => {
                if (!props.hasOwnProperty("variations_attributes")) {
                    Object.assign(props, { variations_attributes: [] });
                }

                let exist = props.variations_attributes.find(
                    ({ attribute_id }) => attribute_id === action.data.attribute_id,
                );
                if (!exist) {
                    props.variations_attributes.push({
                        ...action.data,
                        editable: true,
                    });
                }
                return props;
            });
            break;
        case merchantActions.orderProductVariationDraft().type:
            draft.timestamp = moment();
            draft.product.draft = produce(currentDraft.product.draft, (props) => {
                props.variation = action.data;
                return props;
            });
            break;
        case merchantActions.setProductVariationTermsDraft().type:
            draft.product.draft = produce(currentDraft.product.draft, (props) => {
                const currentBaseIndex = props.variation.findIndex(
                    ({ attribute_id }) => attribute_id === action.data.attribute_id,
                );
                const currentBaseData = props.variation.find(
                    ({ attribute_id }) => attribute_id === action.data.attribute_id,
                );
                const newData = produce(currentBaseData, (terms) => {
                    if (!terms.hasOwnProperty("items")) {
                        Object.assign(terms, { items: [] });
                    }

                    if (action.data?.clearAll) {
                        terms.items = [];
                        return terms;
                    }

                    let exist = terms.items.findIndex(
                        ({ attribute_item_id }) => attribute_item_id === action.data.attribute_item_id,
                    );
                    if (exist === -1) {
                        terms.items.push(action.data);
                    } else {
                        terms.items.splice(exist, 1);
                    }
                    return terms;
                });

                props.variation[currentBaseIndex] = newData;
                return props;
            });
            break;
        case merchantActions.setProductChecklist().type:
            draft.product.checkbox_ids = produce(currentDraft.product.checkbox_ids, (checked) => {
                const checkedSet = new Set(checked[`${action.type_action}_goods_ids`] || []);
                if (action.status === true) {
                    if (typeof action.id === "number") checkedSet.add(action.id);
                    else action.id.forEach((item) => checkedSet.add(item));
                } else {
                    if (typeof action.id === "number") checkedSet.delete(action.id);
                    else action.id.forEach((item) => checkedSet.delete(item));
                }
                checked[`${action.type_action}_goods_ids`] = Array.from(checkedSet);
                return checked;
            });
            break;
        case merchantActions.postedMerchantProduct().type:
            draft.product = produce(currentDraft.product, (props) => {
                props.checkbox_ids = produce(props.checkbox_ids, clearCheckbox);
                props.onsale = produce(props.onsale, (onsale) => {
                    onsale.ids = [action.data.spu.goods_id];
                    return onsale;
                });
                props.user = action.data;
                props.cache = action.data;
                return props;
            });
            break;
        // setting - global
        case merchantActions.resetSettingLoading().type:
            draft.loading = false;
            break;
        // setting -> account
        case merchantActions.getAccountSetting().type:
            draft.loading = true;
            break;
        case merchantActions.setAccountSetting().type:
            draft.timestamp = moment();
            draft.setting.account = produce(currentDraft.setting.account, (prop) => {
                prop = action.data;
                return prop;
            });
            draft.loading = false;
            break;
        // setting -> store
        case merchantActions.getStoreSetting().type:
            draft.loading = true;
            break;
        case merchantActions.setStoreSetting().type:
            draft.timestamp = moment();
            draft.setting.store = produce(currentDraft.setting.store, (prop) => {
                let newData = action.data;
                ["hotline_international_code", "whatsapp_international_code"].forEach((code) => {
                    if (newData?.contacts[code]) {
                        newData.contacts[code] = "+" + newData?.contacts[code];
                    }
                });
                prop = newData;
                return prop;
            });
            draft.loading = false;
            break;
        // setting -> account
        case merchantActions.getShippingSetting().type:
            draft.loading = true;
            break;
        case merchantActions.setShippingSetting().type:
            draft.timestamp = moment();
            draft.setting.shipping = produce(currentDraft.setting.shipping, (prop) => {
                prop = action.data;
                return prop;
            });
            draft.loading = false;
            break;
        case merchantActions.updatedShippingGeneral().type:
            draft.timestamp = moment();
            draft.setting.shipping = produce(currentDraft.setting.shipping, (props) => {
                if (!props.hasOwnProperty("general")) {
                    Object.assign(props, { general: {} });
                }
                props.general = action.data;
                return props;
            });
            break;
        case merchantActions.updatedShippingShippingMethod().type:
            draft.timestamp = moment();
            draft.setting.shipping = produce(currentDraft.setting.shipping, (props) => {
                if (!props.hasOwnProperty("shipping_methods")) {
                    Object.assign(props, { shipping_methods: {} });
                }
                props.shipping_methods = action.data;
                return props;
            });
            break;
        case merchantActions.updatedShippingTableRates().type:
            draft.timestamp = moment();
            draft.setting.shipping = produce(currentDraft.setting.shipping, (props) => {
                if (!props.hasOwnProperty("table_rates")) {
                    Object.assign(props, { table_rates: {} });
                }
                props.table_rates = action.data;
                return props;
            });
            break;

        case merchantActions.updatedShippingDRPolicy().type:
            draft.timestamp = moment();
            draft.setting.shipping = produce(currentDraft.setting.shipping, (props) => {
                if (!props.hasOwnProperty("delivery_return_policy")) {
                    Object.assign(props, { delivery_return_policy: {} });
                }
                props.delivery_return_policy = action.data;
                return props;
            });
            break;
        case merchantActions.setNewProductName().type:
            draft.product.add.data = {
                spu: {
                    vital_info_product_name_chinese: action.name,
                    vital_info_product_name_english: action.name,
                },
            };
            break;
        // setting -> store category
        case merchantActions.getStoreCategoryDetails().type:
            draft.loading = true;
            break;
        case merchantActions.setStoreCategoryDetails().type:
            draft.setting.category = {
                ...draft.setting.category,
                ...action.data,
            };
            if (draft.setting.category.haveSubCat) {
                draft.setting.category.subCategories = draft.setting.category.subCategories ?? [];
            } else {
                draft.setting.category.goods = draft.setting.category.goods ?? [];
            }
            break;
        case merchantActions.editStoreSubcategory().type: {
            const { catId } = action.data;
            draft.setting.category.subCategories = draft.setting.category.subCategories.map((cat) => {
                if (cat.catId == catId) {
                    return action.data;
                } else {
                    return cat;
                }
            });
            break;
        }
        case merchantActions.addStoreSubcategory().type:
            draft.setting.category.subCategories.push(action.data);
            break;
        case merchantActions.deleteStoreSubcategory().type:
            draft.setting.category.subCategories = draft.setting.category.subCategories.filter((cat) => {
                return cat.catId != action.data;
            });
            break;
        case merchantActions.resetStoreCategoryDetails().type:
            draft.setting.category = {
                loading: false,
                sortOrder: 0,
                chineseName: "",
                englishName: "",
                goods: [],
                subCategories: [],
                haveSubCat: false,
            };
            break;
        case merchantActions.clearStoreCategoryDetails().type:
            draft.setting.category = { loading: true };
            break;
        // product - add
        case merchantActions.setAddProductDraft().type:
            draft.product.add = produce(currentDraft.product.add, (props) => {
                props.data = action.data;
                return props;
            });
            break;
        case merchantActions.setAddProductFieldDraft().type:
            draft.timestamp = moment();
            draft.product.add.data = produce(currentDraft.product.add.data, (props) => {
                props.spu = produce(props.spu, (spu) => {
                    Object.keys(action.data).forEach((key) => {
                        spu[key] = action.data[key];
                    });
                    return spu;
                });
                if (props?.spu?.variations_attributes?.length === 0) {
                    props.sku[0] = produce(props.sku[0], (sku) => {
                        Object.keys(action.data).forEach((key) => {
                            sku[key] = action.data[key];
                        });
                        return sku;
                    });
                }
                return props;
            });
            break;
        case merchantActions.setAddProductDraftField().type: {
            draft.product.add.data.spu = {
                ...draft.product.add.data.spu,
                ...action.data,
            };
            break;
        }
        case merchantActions.syncAddProductSpuToSku().type: {
            if (draft.product.add.data.sku.length === 1) {
                draft.product.add.data.sku[0] = {
                    ...draft.product.add.data.sku[0],
                    ...action.data,
                };
            }
            break;
        }
        case merchantActions.generateVariationSKU().type:
            draft.product.add.loading = true;
            break;
        case merchantActions.deleteProductVariations().type:
            if (action.data.mode === "resell") {
                draft.product.selected = produce(currentDraft.product.selected, (mutableDraft) => {
                    mutableDraft.data.sku = mutableDraft.data.sku.filter((sku) => sku.goods_id !== action.data.goodsId);
                    return mutableDraft;
                });
            } else {
                draft.product.add = produce(currentDraft.product.add, (mutableDraft) => {
                    mutableDraft.data.sku = mutableDraft.data.sku.filter((sku) => sku.goods_id !== action.data.goodsId);
                    return mutableDraft;
                });
            }
            break;
        case merchantActions.updateGeneratedSKU().type:
            if (action.data.mode === "resell") {
                draft.product.selected = produce(currentDraft.product.selected, (mutableDraft) => {
                    mutableDraft.data.sku = action.data.skuList;
                    mutableDraft.data.spu.stale = false;
                    mutableDraft.data.loading = false;
                    return mutableDraft;
                });
            } else {
                draft.product.add = produce(currentDraft.product.add, (mutableDraft) => {
                    mutableDraft.data.sku = action.data.skuList;
                    mutableDraft.data.spu.stale = false;
                    mutableDraft.data.loading = false;
                    return mutableDraft;
                });
            }
            break;
        case merchantActions.setProductCreationPublishStatus().type:
            draft.product.add = produce(currentDraft.product.add, (mutableDraft) => {
                for (const index in mutableDraft.data.sku) {
                    mutableDraft.data.sku[index].status = action.data;
                }
                return mutableDraft;
            });
            break;
        case merchantActions.unsetAddProductVariation().type:
            draft.product.add.data.spu = produce(currentDraft.product.add.data.spu, (mutableDraft) => {
                let cloned = [...mutableDraft.variations_attributes];
                cloned = cloned.filter(({ attribute_id }) => attribute_id !== action.data.attribute_id);
                mutableDraft.variations_attributes = cloned;
                mutableDraft.stale = true;
                return mutableDraft;
            });
            break;
        case merchantActions.setAddProductVariation().type:
            draft.product.add.data.spu = produce(currentDraft.product.add.data.spu, (mutableDraft) => {
                if (!mutableDraft.hasOwnProperty("variations_attributes")) {
                    Object.assign(mutableDraft, { variations_attributes: [] });
                }
                let exist = mutableDraft.variations_attributes.find(
                    ({ attribute_id }) => attribute_id === action.data.attribute_id,
                );
                let maxNum = mutableDraft.variations_attributes.map((item) => item.position) || 0;
                if (!exist) {
                    mutableDraft.variations_attributes.push({
                        ...action.data,
                        position: Number(maxNum) + 1,
                    });
                }
                mutableDraft.stale = true;
                return mutableDraft;
            });
            break;
        case merchantActions.orderAddProductVariation().type:
            draft.timestamp = moment();
            draft.product.add.data.spu = produce(currentDraft.product.add.data.spu, (props) => {
                let clean_order_data = action.data.map((item, index) => ({
                    ...item,
                    position: index + 1,
                }));
                props.variations_attributes = clean_order_data;
                return props;
            });
            break;
        case merchantActions.setAddProductVariationTerms().type:
            draft.product.add.data.spu = produce(currentDraft.product.add.data.spu, (mutableDraft) => {
                const attributeIndex = mutableDraft.variations_attributes.findIndex(
                    ({ attribute_id }) => attribute_id === action.data.attribute_id,
                );
                mutableDraft.variations_attributes[attributeIndex].items.push(action.data);
                mutableDraft.stale = true;
                return mutableDraft;
            });
            break;
        case merchantActions.deleteAddProductVariationTerms().type:
            draft.product.add.data.spu = produce(currentDraft.product.add.data.spu, (mutableDraft) => {
                const attributeIndex = mutableDraft.variations_attributes.findIndex(
                    ({ attribute_id }) => attribute_id === action.data.attribute_id,
                );
                let attributeItems = mutableDraft.variations_attributes[attributeIndex].items;
                attributeItems = attributeItems.filter(
                    ({ attribute_item_id }) => attribute_item_id !== action.data.attribute_item_id,
                );
                mutableDraft.variations_attributes[attributeIndex].items = attributeItems;
                mutableDraft.stale = true;
                return mutableDraft;
            });
            break;
        case merchantActions.updateAddProductDraft().type:
            {
                const targetIndex = draft.product.add.data.sku.findIndex(
                    ({ goods_id }) => goods_id === action.data.goods_id,
                );
                draft.product.add.data.sku[targetIndex] = action.data;
            }
            break;

        // product - resell
        case merchantActions.setProductResellPublishStatus().type:
            draft.product.selected = produce(currentDraft.product.selected, (mutableDraft) => {
                for (const index in mutableDraft.data.sku) {
                    mutableDraft.data.sku[index].status = action.data;
                }
                return mutableDraft;
            });
            break;
        case merchantActions.updateResellProductDraft().type:
            {
                const targetIndex = draft.product.selected.data.sku.findIndex(
                    ({ goods_id }) => goods_id === action.data.goods_id,
                );
                draft.product.selected.data.sku[targetIndex] = action.data;
            }
            break;
        case merchantActions.setResellProductDraftField().type: {
            draft.product.selected.data.spu = {
                ...draft.product.selected.data.spu,
                ...action.data,
            };
            break;
        }
        case merchantActions.setSelectedProductVariation().type:
            draft.timestamp = moment();
            draft.product.selected.data.spu = produce(currentDraft.product.selected.data.spu, (props) => {
                if (!props.hasOwnProperty("variations_attributes")) {
                    Object.assign(props, { variations_attributes: [] });
                }

                let exist = props.variations_attributes.find(
                    ({ attribute_id }) => attribute_id === action.data.attribute_id,
                );
                let maxNum = props.variations_attributes.map((item) => item.position) || 0;
                if (!exist) {
                    props.variations_attributes.push({
                        ...action.data,
                        position: Number(maxNum) + 1,
                    });
                }
                return props;
            });
            break;
        case merchantActions.orderSelectedProductVariation().type:
            draft.timestamp = moment();
            draft.product.selected.data.spu = produce(currentDraft.product.selected.data.spu, (props) => {
                props.variations_attributes = action.data;
                return props;
            });
            break;
        case merchantActions.setSelectedProductVariationTerms().type:
            draft.product.selected.data.spu = produce(currentDraft.product.selected.data.spu, (props) => {
                const currentBaseIndex = props.variations_attributes.findIndex(
                    ({ attribute_id }) => attribute_id === action.attribute_id,
                );
                props.variations_attributes[currentBaseIndex] = produce(
                    props.variations_attributes[currentBaseIndex],
                    (terms) => {
                        let index = action.data.findIndex(
                            (item) => item.attribute_item_id === action?.opt_data?.attribute_item_id,
                        );
                        let clean_data = action.data;
                        clean_data[index] = { ...action.opt_data, editable: true };

                        terms.items = clean_data;
                    },
                );
                return props;
            });
            break;
        case merchantActions.clearSelectedProductVariationTerms().type:
            draft.product.selected.data.spu = produce(currentDraft.product.selected.data.spu, (props) => {
                const currentBaseIndex = props.variations_attributes.findIndex(
                    ({ attribute_id }) => attribute_id === action.attribute_id,
                );
                props.variations_attributes[currentBaseIndex].items = [];
                return props;
            });
            break;
        case merchantActions.deleteSelectedProductVariationTerms().type:
            draft.product.selected.data.spu = produce(currentDraft.product.selected.data.spu, (props) => {
                const currentBaseIndex = props.variations_attributes.findIndex(
                    ({ attribute_id }) => attribute_id === action.attribute_id,
                );
                props.variations_attributes[currentBaseIndex] = produce(
                    props.variations_attributes[currentBaseIndex],
                    (terms) => {
                        terms.items = action.data;
                        return terms;
                    },
                );
                return props;
            });
            break;
        // product - edit
        case merchantActions.updateEditProductDraft().type:
            {
                const targetIndex = draft.product.edit.data.sku.findIndex(
                    ({ goods_id }) => goods_id === action.data.goods_id,
                );
                draft.product.edit.data.sku[targetIndex] = action.data;
            }
            break;
        case merchantActions.bulkUpdateEditProductDraft().type:
            {
                const { checkedIds, updatePayload } = action.data;
                for (let key in updatePayload) {
                    if (!updatePayload[key] && updatePayload[key] !== 0) {
                        delete updatePayload[key];
                    }
                }
                for (const checkedId of checkedIds) {
                    const targetIndex = draft.product.edit.data.sku.findIndex(({ goods_id }) => goods_id === checkedId);
                    const currentData = draft.product.edit.data.sku[targetIndex];
                    draft.product.edit.data.sku[targetIndex] = {
                        ...currentData,
                        ...updatePayload,
                    };
                }
                draft.product.checkbox_ids = produce(draft.product.checkbox_ids, clearCheckbox);
            }
            break;
        case merchantActions.changeStatusEditProductSKU().type:
            {
                const { sku_id, listed } = action.data;
                const targetIndex = draft.product.edit.data.sku.findIndex(({ goods_id }) => goods_id === sku_id);
                draft.product.edit.data.sku[targetIndex].status = listed ? "listed" : "unlist";
            }
            break;
        case merchantActions.setEditSubmitLoading().type:
            draft.product.edit.data.loading = action.isLoading;
            break;
        case merchantActions.setEditProductLoading().type:
            draft.product.edit.loading = action.isLoading;
            break;
        case merchantActions.setEditProductDraft().type:
            draft.product.edit.loading = false;
            draft.product.edit.data = action.data;
            draft.product.checkbox_ids = produce(draft.product.checkbox_ids, clearCheckbox);
            break;
        case merchantActions.setEditProductDraftField().type: {
            draft.product.edit.data.spu = {
                ...draft.product.edit.data.spu,
                ...action.data,
            };
            break;
        }
        case merchantActions.unsetEditProductVariation().type:
            draft.timestamp = moment();
            draft.product.edit.data.spu = produce(currentDraft.product.edit.data.spu, (props) => {
                let cloned = [...props.variations_attributes];
                cloned = cloned.filter(({ attribute_id }) => attribute_id !== action.data.attribute_id);
                props.variations_attributes = cloned;
                return props;
            });
            break;
        case merchantActions.setEditProductVariation().type:
            draft.timestamp = moment();
            draft.product.edit.data.spu = produce(currentDraft.product.edit.data.spu, (props) => {
                if (!props.hasOwnProperty("variations_attributes")) {
                    Object.assign(props, { variations_attributes: [] });
                }

                let exist = props.variations_attributes.find(
                    ({ attribute_id }) => attribute_id === action.data.attribute_id,
                );
                if (!exist) {
                    props.variations_attributes.push(action.data);
                }
                return props;
            });
            break;
        case merchantActions.orderEditProductVariation().type:
            draft.timestamp = moment();
            draft.product.edit.data.spu = produce(currentDraft.product.edit.data.spu, (props) => {
                props.variations_attributes = action.data;
                return props;
            });
            break;
        case merchantActions.setEditProductVariationTerms().type:
            draft.product.edit.data.spu = produce(currentDraft.product.edit.data.spu, (props) => {
                const currentBaseIndex = props.variations_attributes.findIndex(
                    ({ attribute_id }) => attribute_id === action.attribute_id,
                );
                props.variations_attributes[currentBaseIndex] = produce(
                    props.variations_attributes[currentBaseIndex],
                    (terms) => {
                        let index = action.data.findIndex(
                            (item) => item.attribute_item_id === action?.opt_data?.attribute_item_id,
                        );
                        let clean_data = action.data;
                        clean_data[index] = { ...action.opt_data, editable: true };

                        terms.items = clean_data;
                    },
                );
                return props;
            });
            break;
        case merchantActions.clearEditProductVariationTerms().type:
            draft.product.edit.data.spu = produce(currentDraft.product.edit.data.spu, (props) => {
                const currentBaseIndex = props.variations_attributes.findIndex(
                    ({ attribute_id }) => attribute_id === action.attribute_id,
                );
                props.variations_attributes[currentBaseIndex].items = [];
                return props;
            });
            break;
        case merchantActions.deleteEditProductVariationTerms().type:
            draft.product.edit.data.spu = produce(currentDraft.product.edit.data.spu, (props) => {
                const currentBaseIndex = props.variations_attributes.findIndex(
                    ({ attribute_id }) => attribute_id === action.attribute_id,
                );
                props.variations_attributes[currentBaseIndex] = produce(
                    props.variations_attributes[currentBaseIndex],
                    (terms) => {
                        terms.items = action.data;
                        return terms;
                    },
                );
                return props;
            });
            break;
        case merchantActions.renewPLStatus().type:
            draft.timestamp = moment();
            draft.product.list = produce(currentDraft.product.list, (prop) => {
                prop = action.data;
                return prop;
            });
            break;
        case merchantActions.setEditStatus().type:
            draft.timestamp = moment();
            break;
        case merchantActions.renewEditStatus().type:
            draft.timestamp = moment();
            draft.product.list = produce(currentDraft.product.list, (prop) => {
                prop = action.data;
                return prop;
            });
            break;
        case merchantActions.setOrderList().type:
            draft.order.list = produce(currentDraft.order.list, (props) => {
                props = action.data;
                return props;
            });
            break;
        case merchantActions.setOrderDetail().type:
            draft.order.detail = produce(currentDraft.order.detail, (props) => {
                if (!props.hasOwnProperty(action.args)) {
                    Object.assign(props, { [action.args]: {} });
                }
                props[action.args] = produce(props[action.args], (order_detail) => {
                    order_detail = action.data;
                    return order_detail;
                });
                return props;
            });
            break;
        case merchantActions.setOrderLogs().type:
            draft.order.logs = produce(currentDraft.order.logs, (props) => {
                if (!props.hasOwnProperty(action.args)) {
                    Object.assign(props, { [action.args]: [] });
                }
                props[action.args] = produce(props[action.args], (order_logs) => {
                    order_logs = action.data;
                    return order_logs;
                });
                return props;
            });
            break;
        case merchantActions.clearOrderDetail().type:
            draft.order = produce(currentDraft.order, (props) => {
                if (props.detail.hasOwnProperty(action.args)) {
                    // props.detail[action.args] = {};
                    delete props.detail[action.args];
                }
                if (props.logs.hasOwnProperty(action.args)) {
                    // props.logs[action.args] = [];
                    delete props.logs[action.args];
                }
                return props;
            });
            break;
        case merchantActions.setOrderParam().type:
            draft.timestamp = moment();
            draft.order = produce(currentDraft.order, (props) => {
                Object.keys(action.data).forEach((key) => {
                    props.params[key] = action.data[key];
                });
                return props;
            });
            break;
        default:
    }
}, initialState);

import { styled } from "@material-ui/core";
import { FormButton } from "components/Global";
import Table from "components/Global/Table";
import Column from "components/Global/Table/Column";
import { TopRight, Topbar } from "components/Global/Table/Topbar";
import usePopup from "hooks/usePopup";
import { useDispatch, useSelector } from "react-redux";
import { createStoreWarranty } from "service/actions/merchant";
import WarrantyTermPopup from "./popup/WarrantyTermPopup";
import WarrantyTermsActionColumn from "./table/WarrantyTermsActionColumn";

const CustomFormButton = styled(FormButton)({
    height: 36,
    width: "fit-content",
});

export default () => {
    const warrantyTerms = useSelector((state) => state.merchant.setting.store?.warranty_terms);

    const dispatch = useDispatch();
    const popup = usePopup();

    return (
        <div>
            <Table data={warrantyTerms}>
                <Topbar>
                    <TopRight>
                        <CustomFormButton
                            cvariant="outlined"
                            onClick={() =>
                                popup(WarrantyTermPopup, {
                                    mode: "create",
                                    title: "Create Warranty Terms",
                                    action: (data) => {
                                        dispatch(createStoreWarranty(data));
                                    },
                                })
                            }
                        >
                            Create New
                        </CustomFormButton>
                    </TopRight>
                </Topbar>
                <Column field="template_name" label="Name of Term" />
                <Column field="description_chinese" label="Description" ellipsis />
                <Column type="custom" render={(obj) => <WarrantyTermsActionColumn data={obj} />} />
            </Table>
        </div>
    );
};

import { styled } from "@material-ui/core";
import { cloneDeep } from "lodash";

import FormField from "./Field";
import Tab from "./Tab";
import { useState } from "react";

const SectionContainer = styled("div")({
    margin: "15px 0px",
});

const SectionLabel = styled("div")({
    lineHeight: "48px",
    fontSize: 20,
});

const SectionFields = styled("div")({
    flex: 16,
    display: "flex",
    flexWrap: "wrap",
    gap: 12,
});

/**
 * The `formSections` array represents the structure of a form, where each element (a `formSection`)
 * denotes a distinct section of the form containing various fields. These sections are rendered
 * sequentially to create a multi-part form interface.
 *
 * Within this structure, there is a special type of `formSection` that defines a tab grouping.
 * These special sections are represented as arrays of strings, where each string is the title
 * of a sub-tab. The presence of such an array indicates the start of a tabbed interface within
 * the form, and the subsequent form sections are considered to be part of this tab group.
 *
 * The logic for rendering the form interprets these arrays as follows:
 *
 * 1. Each string in the array represents a separate sub-tab within the tab group.
 * 2. The number of strings (N) in the array dictates how many subsequent `formSection` objects
 *    are collapsed under this tab group.
 * 3. Each of the next N `formSection` objects in the `formSections` array corresponds to one
 *    sub-tab, in the same order as the strings in the tab array.
 * 4. The rendering mechanism should ensure that only one `formSection` under a given tab group
 *    is displayed at a time, based on user interaction with the tab titles.
 * 5. The tab group remains in effect until N `formSection` objects have been accounted for,
 *    after which the form reverts back to the normal flow of sections.
 *
 * For example:
 * `formSections` = [
 *   ["Tab 1", "Tab 2"], // Special array indicating a tab group with two sub-tabs
 *   { label: "Section 1 under Tab 1", fields: [...] }, // First `formSection` under "Tab 1"
 *   { label: "Section 1 under Tab 2", fields: [...] }, // Second `formSection` under "Tab 2"
 *   { label: "Regular Form Section", fields: [...] },  // A regular `formSection` after the tab group
 * ];
 *
 * In this example, there is a tab group with two sub-tabs: "Tab 1" and "Tab 2". The first
 * `formSection` object following this array is associated with "Tab 1", and the second with "Tab 2".
 * The third `formSection` represents a regular part of the form, outside of the tabbed interface.
 *
 * This system allows for a dynamic form layout with sections grouped into tabs for better
 * organization and user experience. When implementing this system, care must be taken to ensure
 * that the tab logic correctly interprets the `formSections` array and appropriately manages
 * the display of each section.
 */

function getTabParentMap(formSections) {
    const tabParentMap = {};
    const tabStack = [];

    for (let i = 0; i < formSections.length; i++) {
        const section = formSections[i];

        if (Array.isArray(section)) {
            // If the section is a tab (array of strings)
            tabStack.push({ index: i, remainingItems: section.length });
            tabParentMap[i] = tabStack.length > 1 ? tabStack[tabStack.length - 2].index : null;
        } else {
            // If the section is a regular formSection
            tabParentMap[i] = tabStack.length > 0 ? tabStack[tabStack.length - 1].index : null;
            if (tabStack.length > 0) {
                tabStack[tabStack.length - 1].remainingItems--;
                if (tabStack[tabStack.length - 1].remainingItems === 0) {
                    tabStack.pop();
                }
            }
        }
    }
    return tabParentMap;
}

function getTabInitialState(formSections) {
    const initialState = {};

    formSections.forEach((section, index) => {
        // Check if the section is a tab
        if (Array.isArray(section) && section.every((item) => typeof item === "string")) {
            initialState[index] = section[0];
        }
    });

    return initialState;
}

export function Form({ sections, values, errors, defaultChangeHandler, loading }) {
    const tabParentMap = getTabParentMap(sections);
    const [tabState, setTabState] = useState(() => getTabInitialState(sections));

    if (loading) return null;

    return sections.map((section, index) => {
        // Checking if this section should be shown at all
        // Nested tab not handled
        const parentIndex = tabParentMap[index];
        if (parentIndex !== null) {
            const tabs = sections[parentIndex];
            const currentTabIndex = tabs.indexOf(tabState[parentIndex]);
            if (currentTabIndex + 1 !== index - parentIndex) {
                return null;
            }
        }

        // This section is a tab bar
        if (Array.isArray(section)) {
            return (
                <Tab
                    menu={section}
                    active={tabState[index] || section[0]}
                    setActive={(item) => {
                        const newTabState = cloneDeep(tabState);
                        newTabState[index] = item;
                        setTabState(newTabState);
                    }}
                />
            );
        }

        const { fields, label, hidden } = section;

        if (hidden) return null;

        return (
            <SectionContainer>
                {label && <SectionLabel>{label}</SectionLabel>}
                <SectionFields>
                    {fields.map((props, index) => {
                        if (props.hidden) return null;
                        else
                            return (
                                <FormField
                                    {...props}
                                    key={`${props.name}-${index}`}
                                    value={values[props.name]}
                                    error={errors[props.name]}
                                    onChange={props.onChange || defaultChangeHandler}
                                />
                            );
                    })}
                </SectionFields>
            </SectionContainer>
        );
    });
}

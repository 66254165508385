import XLSX from "xlsx";
import moment from "moment";
import { convertOrderStatus } from "components/MerchantCentre/MerchantConsole/Orders/helper";
import { useState, useEffect } from "react";

import { adminproductexcel } from "service/apis/admin/product";
import { getproducts } from "service/apis/merchant/product";

// export application - admin
const transformApplicationData = (data, category) => {
    return data.map((current) => {
        const categories = Object.keys(current.category_ids);
        const categoryData = categories.reduce((prev, curr) => {
            let name = curr.split("id-");
            let getCategory = category.find((cat) => cat.id === parseInt(name[1]))?.name || "";
            let subCategory = current.category_ids[curr].map(({ name }) => name);

            return [...prev, `[${getCategory}: ${subCategory.join(", ")}]`];
        }, []);

        return {
            "Application Id": current.application_id || "",
            "Submission Date": current.submission_date
                ? moment(current.submission_date).format("YYYY-MM-DD hh:mm:ss")
                : "",
            "Company Name English": current.company_name_english || "",
            "Company Name Chinese": current.company_name_chinese || "",
            "Company Email Address": current.company_email_address || "",
            "Company Description": current.company_description || "",

            "Floor Room": current.floor_room || "",
            "Street Building": current.street_building || "",
            "Area Id": current?.area_id?.name || "",
            "Country Id": current?.country_id?.name || "",
            "Certificate Number": current.certificate_number || "",
            "Document Url": current.document_url || "",
            "Expired Date": current.expired_date || "",

            "Personal Title Id": current.personal_title_id.name || "",
            "Last Name": current.last_name || "",
            "First Name": current.first_name || "",
            "Personal Email": current.personal_email || "",
            "Contact International Code Id": current.contact_international_code_id.dial_code || "",
            "Contact Number": current.contact_number || "",
            "Contact Ext": current.contact_ext || "",
            "Personal Position": current.personal_position || "",
            "Department Ids": current.department_ids?.name || "",
            Categories: categoryData.join(", \r"),

            "Store Name English": current.store_name_english || "",
            "Store Name Chinese": current.store_name_chinese || "",
            "Store Product Sales Id": current.store_product_sales_id.amount_range || "",
            "Store Online Experience": current.store_online_experience ? "Yes" : "No",
            "Terms of Service": current.agreements.some(({ id }) => id === 1) ? "Yes" : "No",
            "Promotion Use": current.agreements.some(({ id }) => id === 2) ? "Yes" : "No",
        };
    });
};

// export product - admin
const transformAdminProductData = (data) => {
    return data.map((row) => ({
        goods_id: row.goods_id || "",
        merchant_id: row.merchant_id || "",
        company_name_english: row.company_name_english || "",
        store_name_english: row.store_name_english || "",
        cat_id: row.cat_id || "",
        cat_name: row.cat_name || "",
        brand_id: row.brand_id || "",
        brand_name_en_us: row.brand_name_en_us || "",
        goods_name: row.goods_name || "",
        goods_name_en_us: row.goods_name_en_us || "",
        is_on_sale: row.is_on_sale || "",
        price_mbr: row.price_mbr || "",
        stock_qty: row.stock_qty ?? "",
        in_stock: row.stock_qty > 0 ? 1 : 0,
    }));
};

// export product - merchant
const transformMerchantProductData = (data) => {
    return data.map((row) => {
        delete row.image;
        delete row.mpn;
        return row;
    });
};

// export order - merchant
export const transformMerchantOrderData = (data) => {
    return data.reduce((acc, current) => {
        const orderStatus = {
            order_status: current.order_status,
            pay_status: current.pay_status,
            shipping_status: current.shipping_status,
            RefundRequests: current.RefundRequests,
        };

        const deliveryOrder = current?.DeliveryOrders?.[0];

        return [
            ...acc,
            ...current.OrderGoods.map((good) => {
                return {
                    "Order Time": current?.add_time ? moment.unix(current?.add_time).format("MMM DD, YYYY HH:mm") : "",
                    "Order #": current?.order_sn || "",
                    "SKU #": good?.goods_id || "",
                    GTIN: good?.goods_sn || "",
                    "Unit Price": good?.goods_price || 0,
                    "Order Item": good?.goods_name || "",
                    "Order Qty": good?.goods_number || "",
                    "Subtotal Amount": good?.goods_price * good?.goods_number || 0,
                    "Payment Time": current?.pay_time
                        ? moment.unix(current?.pay_time).format("MMM DD, YYYY HH:mm")
                        : "",
                    Status: convertOrderStatus(orderStatus) || "",
                    Receiver: current?.consignee || "",
                    "Contact No": "852" + current?.tel || "",
                    Address: current?.address || "",
                    "Customer remarks": current?.serial_numbers || "",
                    Carrier: deliveryOrder?.Logistic?.logistics_name || "",
                    "Tracking No": deliveryOrder?.invoice_no || "",
                    "Ship Time": deliveryOrder?.packed_time
                        ? moment.unix(deliveryOrder?.packed_time).format("MMM DD, YYYY HH:mm")
                        : "",
                };
            }),
        ];
    }, []);
};

const useExportToExcel = (params) => {
    const [isExporting, setIsExporting] = useState(false);
    const [isError, setIsError] = useState(false);
    const { target } = params || {};

    useEffect(() => {
        if (!target) return;

        const fetchDataAndExport = async () => {
            setIsExporting(true);
            setIsError(false);

            let data = null;
            let fileName = null;
            const today = moment().format("YYYYMMDD");
            const currentTime = moment().format("HHmm");

            try {
                if (target === "admin-product") {
                    const response = await adminproductexcel();
                    fileName = `sku_export_${today}_${currentTime}`;
                    data = transformAdminProductData(response.data.result);
                }
                if (target === "admin-application") {
                    fileName = `application_export_${today}_${currentTime}`;
                    data = transformApplicationData(params.data, params.category);
                }
                if (target === "merchant-product") {
                    fileName = `product_export_${today}_${currentTime}`;
                    if (params.data) {
                        data = transformMerchantProductData(params.data);
                    } else {
                        const productParams = {
                            field: "goods_id",
                            show: "sku",
                            lang: "zh_tw",
                            status: "all",
                            size: 10000,
                            page: 1,
                        };
                        const response = await getproducts(productParams);
                        data = transformMerchantProductData(response.data.result.products);
                    }
                }
                if (target === "merchant-order") {
                    fileName = `order_export_${today}_${currentTime}`;
                    data = transformMerchantOrderData(params.data);
                }

                const worksheet = XLSX.utils.json_to_sheet(data);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                XLSX.writeFile(workbook, fileName + ".xlsx");
            } catch (error) {
                console.log(error);
                setIsError(true);
            } finally {
                setIsExporting(false);
            }
        };

        fetchDataAndExport();
    }, [target, params]);

    return { isExporting, isError };
};

export default useExportToExcel;

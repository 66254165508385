import storage from "helper/persist-storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import createTransform from "redux-persist/es/createTransform";
import admin from "./admin";
import authentication from "./authentication";
import merchant from "./merchant";
import profile from "./profile";
import site from "./site";
import { omit } from "lodash";
import produce from "immer";

const _authentication = persistReducer(
    {
        key: "AUTH",
        storage,
        whitelist: [],
    },
    authentication,
);

const _profile = persistReducer(
    {
        key: "PROFILE",
        storage,
        // whitelist: ["privilege", "isAdmin"]
    },
    profile,
);

const merchantBlacklistPaths = ["merchant.product.search"];
const _merchant = persistReducer(
    {
        key: "MERCHANT",
        storage,
        blacklist: merchantBlacklistPaths.filter((a) => !a.includes(".")),
        transforms: [
            createTransform((inboundState, key) => {
                if (key === "product") {
                    let modification = produce(inboundState, (inbound_data) => {
                        inbound_data.search = produce(inbound_data.search, (search_data) => {
                            search_data.query = produce(search_data.query, (props) => {
                                props.result = [];
                                props.selected = {};
                                return props;
                            });
                            search_data.data = [];
                            return search_data;
                        });
                        return inbound_data;
                    });
                    return modification;
                }
                return inboundState;
            }, null),
        ],
    },
    merchant,
);

const _site = persistReducer(
    {
        key: "SITE",
        storage,
        blacklist: ["snackbar", "menu", "popup"],
        // whitelist: [
        //     "config_privilege",
        //     "master",
        //     "config_navbar",
        //     "config_countriesdata",
        //     "config_internationalcodedata",
        //     "config_departmentsdata",
        //     "config_productdata"
        // ]
    },
    site,
);

const _admin = persistReducer(
    {
        key: "ADMIN",
        storage,
        // whitelist: [
        //     "config_privilege",
        //     "master",
        //     "config_navbar",
        //     "config_countriesdata",
        //     "config_internationalcodedata",
        //     "config_departmentsdata",
        //     "config_productdata"
        // ]
    },
    admin,
);

export default combineReducers({
    site: _site,
    authentication: _authentication,
    admin: _admin,
    profile: _profile,
    merchant: _merchant,
});

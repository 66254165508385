import { CircularProgress, styled } from "@material-ui/core";
import RegisterForm from "components/AuthPage/RegisterForm";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInitialForm } from "service/actions/authentication";

const LoadingContainer = styled("div")({
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    zIndex: "500",
    fontSize: 50,
    gap: 20,
});

export default () => {
    const dispatch = useDispatch();
    const FormState = useSelector((state) => state.site.master);
    useEffect(() => {
        dispatch(getInitialForm());
    }, []);

    return FormState ? (
        <RegisterForm />
    ) : (
        <LoadingContainer>
            <CircularProgress size={100} />
            Loading Form Data
        </LoadingContainer>
    );
};

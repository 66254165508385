import { FormText } from "components/Global/Form";

export default (e) => {
    return (
        <div>
            <FormText>
                <p className="subtitle2">
                    {e.first_name} {e.last_name}
                </p>
                <p className="body2">{e?.personal_position || ""}</p>
            </FormText>
        </div>
    );
};

import { styled } from "@material-ui/core";

const AgreementContent = styled("div")({
    display: "inline",
    flexWrap: "wrap",
    fontSize: "16px",
    lineHeight: "24px",
    paddingLeft: "34px",
    "& > a": {
        textDecoration: "none",
    },
});

const AgreementLink = styled("div")(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: "pointer",
    margin: "0 5px",
    whiteSpace: "nowrap",
    display: "inline",
}));

export const defaultOptionsData = {
    areas: [{ id: 1, name: "Hongkong Island" }],
    countries: [{ id: 1, name: "Afghanistan", code: "AF" }],
    personal_titles: [{ id: 1, name: "Mr" }],
    international_codes: [{ id: 1, code: "AF", name: "Afghanistan", dial_code: "+93" }],
    departments: [{ id: 1, name: "Sales" }],
    categories: [
        {
            id: 1,
            name: "Consumer Electronics",
            sub_categories: [{ id: 1, name: "Mobile & Accessories", category_id: 1 }],
        },
        {
            id: 2,
            name: "Beauty & Health",
            sub_categories: [{ id: 6, name: "Face", category_id: 2 }],
        },
    ],
    product_sales: [{ id: 1, amount_range: "1 - 9" }],
};

export const yesNoOption = [
    { id: 1, name: "Yes" },
    { id: 0, name: "No" },
];

export const Agreement = [
    {
        id: 1,
        name: "agreement-1",
        label: (
            <AgreementContent>
                * I agree to the{" "}
                <a href="https://www.yohohongkong.com/post/1064-Yoho-Marketplace-Terms-of-Service" target="_blank">
                    <AgreementLink>YOHO Marketplace Terms of Service</AgreementLink>
                </a>
                on behalf of my company .
            </AgreementContent>
        ),
    },
    {
        id: 2,
        name: "agreement-2",
        label: (
            <AgreementContent>
                {" "}
                I agree to accept the information provided by YOHO for the purpose of business development and
                promotion.
            </AgreementContent>
        ),
    },
];

export const default_subdistrict = [{ id: 1, name: "undefined", district_id: undefined }];

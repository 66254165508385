import { styled } from "@material-ui/core";
import { FormText } from "components/Global/Form";
import { useDispatch } from "react-redux";
import { processEditProduct } from "service/actions/merchant";

const Table = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gridColumn: "1/39",
    "& .row": {
        borderRadius: 8,
        display: "flex",
        gap: 20,
        height: 90,
        "& > div": {
            padding: "10px 15px",
            '& div[class*="col"]:not(.col-1)': {
                minWidth: 100,
                display: "flex",
                justifyContent: "center",
            },
        },
    },
    "& .head-prodtable": {
        "& > div": {
            display: "flex",
            background: theme.yoho.md_color.grey[100],
            borderRadius: "8px 8px 0 0",
            border: "1px solid " + theme.yoho.md_color.grey[200],
            alignItems: "center",
        },
        height: 36,
    },
    "& .body-prodtable": {
        "& > div": {
            border: "1px solid " + theme.yoho.md_color.grey[200],
            borderTop: 0,
        },
    },
    "& .body-prodtable:last-of-type": {
        "& > div": {
            borderRadius: "0 0 8px 8px",
        },
    },
}));

const LeftTable = styled("div")(({ theme }) => ({
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
    "& .col-1": {
        display: "flex",
        gap: 10,
        width: 600,
        "& .info": {
            display: "flex",
            gap: 20,
            "& > div": {
                display: "flex",
            },
        },
        "& img": {
            height: 60,
            width: 60,
            objectFit: "contain",
        },
    },
}));

const RightTable = styled("div")(({ theme }) => ({
    width: 135,
    justifyContent: "center",
}));

export default ({ data }) => {
    const dispatch = useDispatch();

    return (
        <Table>
            <div className="row head-prodtable">
                <LeftTable>
                    <FormText className="col-1">
                        <p className="subtitle3">Product Name</p>
                    </FormText>
                    <FormText className="col-2">
                        <p className="subtitle3">Unite Price</p>
                    </FormText>
                    <FormText className="col-3">
                        <p className="subtitle3">Quantity</p>
                    </FormText>
                    <FormText className="col-4">
                        <p className="subtitle3">Subtotal Amount</p>
                    </FormText>
                </LeftTable>
                <RightTable>
                    <FormText className="col-5">
                        <p className="subtitle3">Inventory</p>
                    </FormText>
                </RightTable>
                {data.isShowYohoQty && (
                    <RightTable>
                        <FormText className="col-5">
                            <p className="subtitle3">YOHO Inventory</p>
                        </FormText>
                    </RightTable>
                )}
            </div>
            {data?.orderGoods?.map((product) => (
                <div key={product.goods_id} className="row body-prodtable">
                    <LeftTable>
                        <div className="col-1">
                            {
                                // Check for real product not discount placeholder
                                product.goods_id !== "N/A" && <img src={product.thumb_full_url} alt="thumb" />
                            }
                            <div>
                                <FormText
                                    onClick={() => {
                                        product.goods_id !== "N/A" && dispatch(processEditProduct(product.goods_id));
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <u className="body2">{product.goods_name}</u>
                                </FormText>
                                {
                                    // Check for real product not discount placeholder
                                    product.goods_id !== "N/A" && (
                                        <div className="info">
                                            <div>
                                                <FormText color="grey" code="500">
                                                    <p className="body3">SKU: </p>
                                                </FormText>
                                                <FormText color="grey" code={800}>
                                                    <p className="body3">{product.goods_id}</p>
                                                </FormText>
                                            </div>
                                            <div>
                                                <FormText color="grey" code="500">
                                                    <p className="body3">GTIN: </p>
                                                </FormText>
                                                <FormText color="grey" code={800}>
                                                    <p className="body3">{product.goods_sn}</p>
                                                </FormText>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-2">
                            <FormText>
                                <p className="body2">HK$ {product.goods_price || 0}</p>
                            </FormText>
                        </div>
                        <div className="col-3">
                            <FormText>
                                <p className="body2">{product.goods_number}</p>
                            </FormText>
                        </div>
                        <div className="col-4">
                            <FormText>
                                <p className="body2">
                                    HK$ {(parseInt(product.goods_price) * product.goods_number).toFixed(2)}
                                </p>
                            </FormText>
                        </div>
                    </LeftTable>
                    <RightTable>
                        <div className="col-5">
                            <FormText>
                                <p className="body2">{product.stockQty}</p>
                            </FormText>
                        </div>
                    </RightTable>
                    {data.isShowYohoQty && (
                        <RightTable>
                            <div className="col-5">
                                <FormText>
                                    <p className="body2">{product.yohoStockQty}</p>
                                </FormText>
                            </div>
                        </RightTable>
                    )}
                </div>
            ))}
        </Table>
    );
};

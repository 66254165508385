import { styled } from "@material-ui/core";
import { useEffect, useState } from "react";

const Container = styled("div")(({ theme, conditionalWidth }) => ({
    padding: "15px 30px",
    display: "flex",
    gap: "30px",
    "&:-webkit-scrollbar": {
        displat: "none",
    },
    "& .main": {
        width: conditionalWidth ? `calc(100% - 440px)` : "100%",
        "&.search-product": {
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh - 40px - 86px)",
        },
    },
    "& > .sub": {
        position: "relative",
        top: `calc(23px * ${theme.screenWidth} / 1440 + 110px)`,
        width: "26.25vw",
        height: "fit-content",
        padding: "20px",
        borderRadius: "20px",
        maxWidth: 378,
        boxSizing: "border-box",
        overflowWrap: "break-word",
        backgroundColor: theme.yoho.md_color.grey[100],
        "& h5": {
            ...theme.yoho.typography.h5,
            margin: 0,
            marginBottom: "10px",
        },
        "& p": {
            ...theme.yoho.typography.body2,
            margin: 0,
            color: theme.yoho.md_color.grey[800],
        },
        "& p.link": {
            color: theme.yoho.md_color.blue[500],
        },
    },
    "& > .sub-product": {
        position: "relative",
        width: "50%",
        maxWidth: 410,
        boxSizing: "border-box",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: 30,
        "& .info": {
            borderRadius: "20px",
            backgroundColor: theme.yoho.md_color.grey[100],
            padding: "18px 22px",
            minWidth: 380,
            boxSizing: "border-box",
            "& h5": {
                ...theme.yoho.typography.h5,
                margin: 0,
                marginBottom: "10px",
                fontWeight: 700,
            },
            "& p": {
                ...theme.yoho.typography.body2,
                margin: "8px 0px",
                color: theme.yoho.md_color.grey[800],
            },
            "& p.link": {
                color: theme.yoho.md_color.blue[500],
            },
        },
    },
}));

export default ({ children }) => {
    const [conditionalWidth, setConditionalWidth] = useState(false);

    useEffect(() => {
        if (Array.isArray(children)) {
            if (children[children.length - 1]?.ref?.current) {
                setConditionalWidth(true);
            } else {
                setConditionalWidth(false);
            }
        }
    }, []);

    return <Container conditionalWidth={conditionalWidth}>{children}</Container>;
};

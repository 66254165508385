import { DEFAULT_COOKIE_PARAMS } from "helper";
import cookie from "js-cookie";
import { all, call, put, takeLatest } from "redux-saga/effects";
import * as merchantAction from "service/actions/merchant";
import * as merchantProductApis from "service/apis/merchant/product";
import add from "./add";
import edit from "./edit";
import list from "./list";

function* getProductInitConfigSAGA() {
    const [
        { data: init, ...restInit },
        { data: categories },
        { data: productattribute },
        { data: productattributeitem },
    ] = yield all([
        call(merchantProductApis.getproductsinit),
        call(merchantProductApis.getproductscategories),
        call(merchantProductApis.getproductattribute),
        call(merchantProductApis.getproductattributeterm),
    ]);
    if (
        init.status === 401 ||
        categories.status === 401 ||
        productattribute.status === 401 ||
        productattributeitem.status === 401
    ) {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
    }

    if (!init.error || !categories.error || !productattribute.error || !productattributeitem.error) {
        yield all([
            put(
                merchantAction.setProductInitialConfig({
                    ...(init.result && init.result),
                    ...(categories.result && { categories: categories.result }),
                    ...(productattribute.result && {
                        variation_attribute: productattribute.result,
                    }),
                    ...(productattributeitem.result && {
                        variation_attribute_item: productattributeitem.result,
                    }),
                }),
            ),
            put(merchantAction.selectStoreWarranty(init.result.warranty_terms[0], "all")),
        ]);
    }
}

function* getProductAttributesSAGA() {
    const [{ data: productattribute }, { data: productattributeitem }] = yield all([
        call(merchantProductApis.getproductattribute),
        call(merchantProductApis.getproductattributeterm),
    ]);

    if (!productattribute.error || !productattributeitem.error) {
        yield put(
            merchantAction.setProductInitialConfig({
                ...(productattribute.result && {
                    variation_attribute: productattribute.result,
                }),
                ...(productattributeitem.result && {
                    variation_attribute_item: productattributeitem.result,
                }),
            }),
        );
    }
}

function* getProductSubCategorySAGA(payload) {
    const { data, ...rest } = yield call(merchantProductApis.getproductssubcategories, payload.id);

    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
    }
    if (!data.error) {
        yield put(merchantAction.setProductInitialConfig({ subcategories: data.result }));
    }
}

function* getProductSubSubCategorySAGA(payload) {
    const { data, ...rest } = yield call(merchantProductApis.getproductssubcategories, payload.id);

    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
    }
    if (!data.error) {
        yield put(merchantAction.setProductInitialConfig({ subsubcategories: data.result }));
    }
}

export default [
    takeLatest(merchantAction.getProductInitialConfig().type, getProductInitConfigSAGA),
    takeLatest(merchantAction.getProductAttributeConfig().type, getProductAttributesSAGA),
    takeLatest(merchantAction.getProductSubCategory().type, getProductSubCategorySAGA),
    takeLatest(merchantAction.getProductSubSubCategory().type, getProductSubSubCategorySAGA),
    ...add,
    ...list,
    ...edit,
];

import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setAdminProductDetail, setAdminProductInit } from "service/actions/admin";
import { setLogout } from "service/actions/authentication";
import { setRedirect, setSnackbar, unsetPopup } from "service/actions/site";
import {
    adminproductcategorylevel,
    adminproductcateogry,
    adminproductdefault,
    adminproductdetail,
    adminproductinit,
    adminproductlist,
    adminproductsubcateogry,
    adminproductsubmit,
    adminproductupdate,
} from "service/apis/admin/product";

export const useGetAdminProductInit = (params) => {
    const dispatch = useDispatch();

    return useQuery(["admin-product-init", params], () => adminproductinit(params.merchant_id), {
        onSuccess: (success) => {
            if (success?.message?.includes("jwt expired")) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Your session has expired. Please sign in again.",
                        severity: "error",
                    }),
                );
                dispatch(setLogout());
                return;
            }
            if (success.error) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: `${success.status} - ${success.message}` || "Unknown Error",
                        severity: "error",
                    }),
                );
                return;
            }
            dispatch(setAdminProductInit(success.result, params));
            return success;
        },
        select: (res) => res?.data,
    });
};

export const useGetAdminProductDetail = (params) => {
    const dispatch = useDispatch();
    const { goods_id, merchant_id } = params;
    const detailData = useSelector((state) => state.admin.product.detail?.[goods_id]?.[merchant_id]);
    const convertGoodsId = goods_id.toString();
    const convertMerchantId = merchant_id.toString();

    return useQuery(
        ["admin-product-detail", { goods_id: convertGoodsId, merchant_id: convertMerchantId }],
        () => adminproductdetail(convertGoodsId, convertMerchantId),
        {
            onSuccess: (success) => {
                if (success?.message?.includes("jwt expired")) {
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: "Your session has expired. Please sign in again.",
                            severity: "error",
                        }),
                    );
                    dispatch(setLogout());
                    return;
                }

                if (success.error) {
                    dispatch(setRedirect("/admin/products"));
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: `${success.status} - ${success.message}` || "Unknown Error",
                            severity: "error",
                        }),
                    );
                    return;
                }
                !detailData && dispatch(setAdminProductDetail(success.result, params));
                return success;
            },
            select: (res) => res?.data,
        },
    );
};

export const useGetAdminProductCategory = (params) => {
    const dispatch = useDispatch();
    const { level, ...rest } = params;

    return useQuery(["admin-product-category", rest], () => adminproductcateogry(rest.merchant_id), {
        onSuccess: (success) => {
            if (success?.message?.includes("jwt expired")) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Your session has expired. Please sign in again.",
                        severity: "error",
                    }),
                );
                dispatch(setLogout());
                return;
            }

            if (success.error) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: `${success.status} - ${success.message}` || "Unknown Error",
                        severity: "error",
                    }),
                );
                return;
            }
            return success;
        },
        select: (res) => res?.data,
    });
};

export const useGetAdminProductSubCategory = (params) => {
    const dispatch = useDispatch();
    const { level, ...rest } = params;
    const queryKey = {
        merchant_id: rest.merchant_id,
        category_id: rest.category_id,
    };

    return useQuery(
        ["admin-product-category", queryKey],
        () => adminproductsubcateogry(rest.merchant_id, rest.category_id),
        {
            onSuccess: (success) => {
                if (success?.message?.includes("jwt expired")) {
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: "Your session has expired. Please sign in again.",
                            severity: "error",
                        }),
                    );
                    dispatch(setLogout());
                    return;
                }

                if (success.error) {
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: `${success.status} - ${success.message}` || "Unknown Error",
                            severity: "error",
                        }),
                    );
                    return;
                }
                return success;
            },
            select: (res) => res?.data,
        },
    );
};

export const useGetAdminProductLastCategory = (params) => {
    const dispatch = useDispatch();
    const { level, ...rest } = params;
    const queryKey = {
        merchant_id: rest.merchant_id,
        category_id: rest.category_id,
        sub_category_id: rest.id,
    };

    return useQuery(["admin-product-category", queryKey], () => adminproductsubcateogry(rest.merchant_id, rest.id), {
        onSuccess: (success) => {
            if (success?.message?.includes("jwt expired")) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Your session has expired. Please sign in again.",
                        severity: "error",
                    }),
                );
                dispatch(setLogout());
                return;
            }

            if (success.error) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: `${success.status} - ${success.message}` || "Unknown Error",
                        severity: "error",
                    }),
                );
                return;
            }
            return success;
        },
        select: (res) => res?.data,
    });
};

export const useGetAdminProductCategoryLevel = (category_id) => {
    const dispatch = useDispatch();

    return useQuery(["admin-product-category-level", category_id], () => adminproductcategorylevel(category_id), {
        onSuccess: (success) => {
            if (success?.message?.includes("jwt expired")) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Your session has expired. Please sign in again.",
                        severity: "error",
                    }),
                );
                dispatch(setLogout());
                return;
            }

            if (success.error) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: `${success.status} - ${success.message}` || "Unknown Error",
                        severity: "error",
                    }),
                );
                return;
            }
            return success;
        },
        select: (res) => res?.data,
    });
};

export const useGetAdminProductList = (params) => {
    const dispatch = useDispatch();

    return useQuery(["admin-product-list", params], () => adminproductlist(params), {
        onSuccess: (success) => {
            if (success?.message?.includes("jwt expired")) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Your session has expired. Please sign in again.",
                        severity: "error",
                    }),
                );
                dispatch(setLogout());
                return;
            }

            if (success.error) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: `${success.status} - ${success.message}` || "Unknown Error",
                        severity: "error",
                    }),
                );
                return;
            }
            return success;
        },
        select: (res) => res?.data,
    });
};

export const useAdminProductActions = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const { mutate: setDefaultProduct, isLoading: onSetDefaultProduct } = useMutation(
        (payload) => adminproductdefault({ goods_id: payload.goods_id }),
        {
            onSuccess: (res, payload) => {
                if (res.data?.message?.includes("jwt expired")) {
                    dispatch(setLogout());
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: "Your session has expired. Please sign in again.",
                            severity: "error",
                        }),
                    );
                    return;
                }
                if (res.ok) {
                    dispatch(setSnackbar({ open: true, message: "Set Main SKU Success", severity: "success" }));
                    queryClient.invalidateQueries(["admin-product-list", { ...payload.params }]);
                    return;
                }
            },
        },
    );

    const { mutate: submitProduct, isLoading: onSubmitProduct } = useMutation(
        (payload) => {
            return adminproductsubmit(payload);
        },
        {
            onSuccess: (res) => {
                if (res?.data?.message?.includes("jwt expired")) {
                    dispatch(setLogout());
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: "Your session has expired. Please sign in again.",
                            severity: "error",
                        }),
                    );
                    return;
                }
                if (res.ok) {
                    dispatch(setSnackbar({ open: true, message: "Submitting Change", severity: "success" }));
                    dispatch(setRedirect({ pathname: "/admin/products" }));
                    return;
                }
            },
        },
    );

    const { mutate: updateProduct, isLoading: onUpdateProduct } = useMutation(
        (payload) => {
            return adminproductupdate(payload.product_id, payload.merchant_id, payload.data);
        },
        {
            onSuccess: (res, payload) => {
                if (res.data?.message?.includes("jwt expired")) {
                    dispatch(setLogout());
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: "Your session has expired. Please sign in again.",
                            severity: "error",
                        }),
                    );
                    return;
                }

                if (res.ok) {
                    dispatch(setSnackbar({ open: true, message: "Update Product Success", severity: "success" }));
                    dispatch(
                        setAdminProductDetail(res.data.result, {
                            goods_id: payload.goods_id.toString(),
                            merchant_id: payload.merchant_id.toString(),
                        }),
                    );
                    dispatch(unsetPopup());
                    return;
                }
            },
        },
    );

    const { mutate: discardProduct, isLoading: onDiscardProduct } = useMutation(
        (payload) => {
            return adminproductsubmit(payload);
        },
        {
            onSuccess: (res, payload) => {
                if (res.data?.message?.includes("jwt expired")) {
                    dispatch(setLogout());
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: "Your session has expired. Please sign in again.",
                            severity: "error",
                        }),
                    );
                    return;
                }

                if (res.ok) {
                    dispatch(setSnackbar({ open: true, message: "Discard Product Success", severity: "success" }));
                    dispatch(setRedirect("/admin/products"));
                    return;
                }
            },
        },
    );

    return {
        setDefaultProduct,
        onSetDefaultProduct,
        submitProduct,
        onSubmitProduct,
        updateProduct,
        onUpdateProduct,
        discardProduct,
        onDiscardProduct,
    };
};

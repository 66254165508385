import { Editor } from "@tinymce/tinymce-react";
import { useMemo } from "react";
import { pickEditorImage } from "helper/imageUpload";

export default ({ name, action, draft }) => {
    const lang = name.split("_").at(-1);
    const initialValue = useMemo(() => draft.spu[name] || "", []);
    const goodName = draft.spu[`vital_info_product_name_${lang}`];

    return (
        <Editor
            onEditorChange={(content) => action({ [name]: content })}
            initialValue={initialValue}
            init={{
                resize: "height",
                min_height: 300,
                menubar: false,
                statusbar: true,
                media_live_embeds: false,
                plugins:
                    "advlist autolink lists link image media charmap print anchor searchreplace visualblocks insertdatetime table paste wordcount",
                toolbar:
                    "formatselect | image link | bullist numlist | bold italic backcolor | alignleft aligncenter alignright alignjustify | removeformat",
                content_style: "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
                file_picker_callback: (callback) => pickEditorImage(callback, `Image of ${goodName}`),
            }}
        />
    );
};

import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    styled,
    TextField,
} from "@material-ui/core";
import { FormButton, FormCategory, YohoSelect } from "components/Global";
import { FormText, PasswordField } from "components/Global/Form";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { changeUserPassword } from "service/actions/merchant";

const Container = styled("div")(({ theme }) => ({}));

const Content = styled("div")(({ theme }) => ({}));

const Heading = styled("h4")(({ theme }) => ({
    ...theme.yoho.typography.h4,
    margin: 0,
    marginTop: "30px",
    color: theme.yoho.md_color.grey[800],
}));

export default () => {
    const { register, handleSubmit, watch, errors, setValue, control } = useForm();
    const profile = useSelector((state) => state.profile);
    const dispatch = useDispatch();
    const old_password = useRef({});
    const new_password = useRef({});
    old_password.current = watch("old_password", "");
    new_password.current = watch("new_password", "");

    const onSubmit = (props) => {
        dispatch(changeUserPassword(props));
    };

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Content>
                    <Heading>Change password for your account</Heading>
                    <FormCategory>
                        <FormText className="w-2">
                            <p className="subtitle1">Username</p>
                        </FormText>
                        <FormText className="w-2">
                            <h6>{profile.personal_email}</h6>
                        </FormText>
                        <PasswordField
                            className="half"
                            name="old_password"
                            label="Current Password"
                            errors={errors}
                            inputRef={register({
                                required: "You must specify a password",
                                minLength: {
                                    value: 2,
                                    message: "Password must have at least 2 characters",
                                },
                            })}
                        />
                        <PasswordField
                            name="new_password"
                            label="New Password"
                            errors={errors}
                            inputRef={register({
                                validate: (value) =>
                                    value !== old_password.current ||
                                    "The New Password can't be match with Old Password",
                            })}
                        />
                        <PasswordField
                            name="repeat_password"
                            label="Repeat Password"
                            errors={errors}
                            inputRef={register({
                                validate: (value) => value === new_password.current || "The passwords do not match",
                            })}
                        />
                        <FormButton ccolor="blue" cvariant="contained" type="submit" fullWidth>
                            Save changes
                        </FormButton>
                    </FormCategory>
                </Content>
            </form>
        </Container>
    );
};

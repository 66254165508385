import Skeleton from "@material-ui/lab/Skeleton";
import Table from "components/Global/Table";
import Column from "components/Global/Table/Column";
import Pagination from "components/Global/Table/Pagination";
import { UncontrolledNormalSelect } from "components/Global/Form/NormalSelect";
import { FormText } from "components/Global/Form";
import { YHPagination } from "components/Global";
import { useSelector } from "react-redux";
import ContactPersonColumn from "./table/ContactPersonColumn";
import CreatedDateColumn from "./table/CreatedDateColumn";
import EmailColumn from "./table/EmailColumn";
import StoreColumn from "./table/StoreColumn";
import { showOptions } from "./constant";
import ActionColumn from "./table/ActionColumn";

export default ({ active, search, setStartPosition, limit, setLimit, isContentLoading, setIsContentLoading }) => {
    const data = useSelector((state) => state.admin.merchant);

    return (
        data && (
            <div>
                <Table
                    data={data?.rows}
                    className="full"
                    showExport={true}
                    showHistory={false}
                    showLayout={false}
                    loading={isContentLoading}
                >
                    <Column field="id" label="User ID" fixed />
                    <Column
                        field={["first_name", "last_name", "personal_position"]}
                        label="Contact Person"
                        fixed
                        render={ContactPersonColumn}
                    />
                    <Column
                        field={["id", "personal_email", "main_user", "active"]}
                        render={(e) => <EmailColumn data={e} active={active} search={search} />}
                        fixed
                        label="Email"
                    />
                    <Column field="contact_number" label="Contact Number" />
                    <Column field="company" label="Store Name" render={StoreColumn} />
                    <Column field="created_at" label="Creation Date" render={CreatedDateColumn} />
                    <Column
                        field={["id", "personal_email", "active"]}
                        render={(e) => <ActionColumn data={e} active={active} search={search} />}
                        width={200}
                        label="Actions"
                    />
                    <Pagination>
                        <div>
                            <YHPagination
                                size={Math.ceil(data?.count / limit)}
                                action={(e) => {
                                    setStartPosition((e - 1) * limit);
                                    setIsContentLoading(true);
                                }}
                            />
                        </div>
                        <div>
                            <UncontrolledNormalSelect
                                variant="standard"
                                data={showOptions}
                                name="size"
                                prefix="Show"
                                onChange={(e) => setLimit(e?.size)}
                                value={limit}
                            />
                            <FormText>
                                <p className="subtitle2">
                                    Applications out of{" "}
                                    {isContentLoading ? (
                                        <Skeleton
                                            style={{
                                                width: 26,
                                                height: 26,
                                                display: "inline-block",
                                                verticalAlign: "bottom",
                                            }}
                                        />
                                    ) : (
                                        data?.count || 0
                                    )}
                                </p>
                            </FormText>
                        </div>
                    </Pagination>
                </Table>
            </div>
        )
    );
};

import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import React from "react";
import { Controller } from "react-hook-form";

export default withStyles((theme) => ({
    root: {
        width: 46,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
        boxSizing: "border-box",
        "& *": {
            boxSizing: "border-box",
        },
    },
    switchBase: {
        padding: 1,
        "& + $track": {
            backgroundColor: theme.yoho.md_color.grey[400],
        },
        "&$checked": {
            transform: "translateX(20px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: theme.yoho.md_color.grey[800],
                opacity: 1,
                border: "none",
            },
            "& $thumb": {
                background: theme.yoho.md_color.grey[500],
            },
        },
        "&$focusVisible $thumb": {
            color: "#52d869",
            border: "6px solid #fff",
        },
    },
    switchBase1: {
        padding: 1,
        "& + $track": {
            backgroundColor: theme.yoho.md_color.grey[400],
        },
        "&$checked": {
            transform: "translateX(20px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: theme.yoho.md_color.blue[900],
                opacity: 1,
                border: "none",
            },
            "& $thumb": {
                background: "#ffffff",
            },
        },
        "&$focusVisible $thumb": {
            color: "#52d869",
            border: "6px solid #fff",
        },
    },
    switchBase2: {
        padding: 1,
        "& + $track": {
            backgroundColor: theme.yoho.md_color.grey[400],
        },
        "&$checked": {
            transform: "translateX(20px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: theme.yoho.md_color.blue[500],
                opacity: 1,
                border: "none",
            },
            "& $thumb": {
                background: "#ffffff",
            },
        },
        "&$focusVisible $thumb": {
            color: "#52d869",
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, mode = "", control, onChange, defaultValue = 0, disabled = false, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            {...(props.name && { name: props.name })}
            {...(disabled && { disabled })}
            classes={{
                root: classes.root,
                switchBase: classes[`switchBase${mode}`],
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            onChange={(e) => onChange(e)}
            defaultChecked={defaultValue}
            {...props}
        />
    );
});

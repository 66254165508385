import { useEffect } from "react";

export default (keyName, dep, action) => {
    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.key === (keyName || "Escape")) {
                e.preventDefault();
                action();
            }
        };
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    }, [dep]);
    return <></>;
};

import { FormText } from "components/Global/Form";
import moment from "moment";

export default (e) => {
    return (
        <div>
            <FormText>
                <p className="subtitle2">{moment(e).fromNow()}</p>
                <p className="body2">{moment(e).format("MMM DD, YYYY hh:mm")}</p>
            </FormText>
        </div>
    );
};

export const yoho = {
    color: {
        white: "#FFFFFF",
        blue: "#00ACEE",
        promotion: "#FF4C27",
        highlight: "#FBB52E",
        warning: "#DA372D",
        error: "#DA372D",
        border: "#BDBDBD",
        icon: "#BDBDBD",
        wordings: "#424242",
        icon_alt: "#424242",
        yellow: "#FFD342",
        greenblue: "#0076A1",
        green: "#72D8B9",
        brown: "#5A4029",
        primary: {
            main: "#00ACEE",
            light: "#E1F5FD",
            dark: "#0076A1",
            text: "#FFFFFF",
        },
        secondary: {
            main: "#FBB52E",
            light: "#FFF9E4",
            dark: "#A16B00",
            text: "#FFFFFF",
        },
        error: {
            main: "#DA372D",
            light: "#FEEAED",
            dark: "#AC1C15",
            text: "#FFFFFF",
        },
    },
    button: {
        contained: {
            blue: {
                background: "#00ACEE",
                borderRadius: "26px",
                cursor: "pointer",
                color: "#FFFFFF",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "24px",
                "&:hover": {
                    background: "#0076A1",
                },
                "&:active": {
                    background: "#FFFFFF",
                    border: "1px solid #00ACEE",
                    color: "#00ACEE",
                },
                "&:disabled": {
                    background: "#E0E0E0",
                    border: "1px solid #9E9E9E",
                    cursor: "context-menu",
                },
            },
            yelloworange: {
                background: "#FBB52E",
                borderRadius: "26px",
                cursor: "pointer",
                color: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "24px",
                "&:hover": {
                    background: "#F99527",
                },
                "&:active": {
                    background: "#FFFFFF",
                    border: "1px solid #FBB52E",
                    color: "#FBB52E",
                },
                "&:disabled": {
                    background: "#E0E0E0",
                    border: "1px solid #9E9E9E",
                    cursor: "context-menu",
                },
            },
            red: {
                background: "#DA372D",
                borderRadius: "26px",
                cursor: "pointer",
                color: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "24px",
                "&:hover": {
                    background: "#AC1C15",
                },
                "&:active": {
                    background: "#FFFFFF",
                    border: "1px solid #DA372D",
                    color: "#DA372D",
                },
                "&:disabled": {
                    background: "#E0E0E0",
                    border: "1px solid #9E9E9E",
                    cursor: "context-menu",
                },
            },
            grey: {
                background: "#757575",
                borderRadius: "26px",
                cursor: "pointer",
                color: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "24px",
                "&:hover": {
                    background: "#424242",
                },
                "&:active": {
                    background: "#FFFFFF",
                    border: "1px solid #757575",
                    color: "#757575",
                },
                "&:disabled": {
                    background: "#E0E0E0",
                    border: "1px solid #9E9E9E",
                    cursor: "context-menu",
                },
            },
        },
        outlined: {
            blue: {
                background: "#FFFFFF",
                borderRadius: "26px",
                cursor: "pointer",
                color: "#00ACEE",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "24px",
                border: "1px solid #00ACEE",
                "&:hover": {
                    background: "#E1F5FD",
                    color: "#00ACEE",
                    border: "1px solid #00ACEE",
                },
                "&:active": {
                    background: "#00ACEE",
                    color: "#FFFFFF",
                },
                "&:disabled": {
                    background: "#E0E0E0",
                    border: "1px solid #9E9E9E",
                    cursor: "context-menu",
                },
            },
            yelloworange: {
                background: "#FFFFFF",
                borderRadius: "26px",
                cursor: "pointer",
                color: "#FBB52E",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "24px",
                border: "1px solid #FBB52E",
                "&:hover": {
                    background: "#FFF9E4",
                    color: "#FBB52E",
                    border: "1px solid #FBB52E",
                },
                "&:active": {
                    background: "#FBB52E",
                    color: "#FFFFFF",
                },
                "&:disabled": {
                    background: "#E0E0E0",
                    border: "1px solid #9E9E9E",
                    cursor: "context-menu",
                },
            },
            red: {
                background: "#FFFFFF",
                borderRadius: "26px",
                cursor: "pointer",
                color: "#DA372D",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "24px",
                border: "1px solid #DA372D",
                "&:hover": {
                    background: "#FEEAED",
                    color: "#DA372D",
                    border: "1px solid #DA372D",
                },
                "&:active": {
                    background: "#DA372D",
                    color: "#FFFFFF",
                },
                "&:disabled": {
                    background: "#E0E0E0",
                    border: "1px solid #9E9E9E",
                    cursor: "context-menu",
                },
            },
            grey: {
                background: "#FFFFFF",
                borderRadius: "26px",
                cursor: "pointer",
                color: "#424242",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "24px",
                border: "1px solid #424242",
                "&:hover": {
                    background: "#E1F5FD",
                    color: "#00ACEE",
                    border: "1px solid #00ACEE",
                },
                "&:active": {
                    background: "#00ACEE",
                    color: "#FFFFFF",
                },
                "&:disabled": {
                    background: "#E0E0E0",
                    border: "1px solid #9E9E9E",
                    cursor: "context-menu",
                },
            },
        },
        text: {
            blue: {
                color: "#00ACEE",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "24px",
                "&:hover": {
                    color: "#0076A1",
                },
                "&:active": {
                    color: "#005996",
                },
            },
            yelloworange: {
                color: "#FEEDB8",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "24px",
                "&:hover": {
                    color: "#FBB52E",
                },
                "&:active": {
                    color: "#F99527",
                },
            },
            red: {
                color: "#FCCBCF",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "24px",
                "&:hover": {
                    color: "#DA372D",
                },
                "&:active": {
                    color: "#AC1C15",
                },
            },
            grey: {
                color: "#424242",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "24px",
                "&:hover": {
                    color: "#00ACEE",
                },
                "&:active": {
                    color: "#0076A1",
                },
            },
        },
    },
    md_color: {
        blue: {
            900: "#005996",
            800: "#0079B8",
            700: "#008ACC",
            600: "#009DDF",
            500: "#00ACEE",
            400: "#2BB7F0",
            300: "#51C4F2",
            200: "#81D5F6",
            100: "#B3E6F9",
            50: "#E1F5FD",
            important: {
                900: "#005996 !important",
                800: "#0079B8 !important",
                700: "#008ACC !important",
                600: "#009DDF !important",
                500: "#00ACEE !important",
                400: "#2BB7F0 !important",
                300: "#51C4F2 !important",
                200: "#81D5F6 !important",
                100: "#B3E6F9 !important",
                50: "#E1F5FD !important",
            },
        },
        yellow: {
            900: "#A16B00",
            800: "#C49007",
            700: "#DAA60B",
            600: "#F0BB11",
            500: "#FFCC13",
            400: "#FFD342",
            300: "#FFDB65",
            200: "#FFE591",
            100: "#FFEFBD",
            50: "#FFF9E4",
            important: {
                900: "#A16B00 !important",
                800: "#C49007 !important",
                700: "#DAA60B !important",
                600: "#F0BB11 !important",
                500: "#FFCC13 !important",
                400: "#FFD342 !important",
                300: "#FFDB65 !important",
                200: "#FFE591 !important",
                100: "#FFEFBD !important",
                50: "#FFF9E4 !important",
            },
        },
        yelloworange: {
            900: "#F77823",
            800: "#F99527",
            700: "#FAA52A",
            600: "#FBB52E",
            500: "#FCC433",
            400: "#FCCD41",
            300: "#FDD75E",
            200: "#FDE18B",
            100: "#FEEDB8",
            50: "#FEF8E3",
            important: {
                900: "#F77823 !important",
                800: "#F99527 !important",
                700: "#FAA52A !important",
                600: "#FBB52E !important",
                500: "#FCC433 !important",
                400: "#FCCD41 !important",
                300: "#FDD75E !important",
                200: "#FDE18B !important",
                100: "#FEEDB8 !important",
                50: "#FEF8E3 !important",
            },
        },
        redorange: {
            900: "#D13109",
            800: "#E13A1A",
            700: "#EE4122",
            600: "#FF4C27",
            500: "#FF5523",
            400: "#FF6046",
            300: "#FF7E6F",
            200: "#FFA298",
            100: "#FFD2D2",
            50: "#FFEDEE",
            important: {
                900: "#D13109 !important",
                800: "#E13A1A !important",
                700: "#EE4122 !important",
                600: "#FF4C27 !important",
                500: "#FF5523 !important",
                400: "#FF6046 !important",
                300: "#FF7E6F !important",
                200: "#FFA298 !important",
                100: "#FFD2D2 !important",
                50: "#FFEDEE !important",
            },
        },
        red: {
            900: "#AC1C15",
            800: "#BB2621",
            700: "#C82D28",
            600: "#DA372D",
            500: "#E8422E",
            400: "#E55049",
            300: "#DD706D",
            200: "#E99795",
            100: "#FCCBCF",
            50: "#FEEAED",
            important: {
                900: "#AC1C15 !important",
                800: "#BB2621 !important",
                700: "#C82D28 !important",
                600: "#DA372D !important",
                500: "#E8422E !important",
                400: "#E55049 !important",
                300: "#DD706D !important",
                200: "#E99795 !important",
                100: "#FCCBCF !important",
                50: "#FEEAED !important",
            },
        },
        green: {
            900: "#005C36",
            800: "#007951",
            700: "#008A5E",
            600: "#009A6E",
            500: "#00A87A",
            400: "#00B78B",
            300: "#33C69E",
            200: "#72D8B9",
            100: "#ACE7D5",
            50: "#DDF6EF",
            important: {
                900: "#005C36 !important",
                800: "#007951 !important",
                700: "#008A5E !important",
                600: "#009A6E !important",
                500: "#00A87A !important",
                400: "#00B78B !important",
                300: "#33C69E !important",
                200: "#72D8B9 !important",
                100: "#ACE7D5 !important",
                50: "#DDF6EF !important",
            },
        },
        greenblue: {
            900: "#005780",
            800: "#0076A1",
            700: "#0087B4",
            600: "#1199C6",
            500: "#1CA7D4",
            400: "#3AB4D8",
            300: "#59C1DC",
            200: "#84D2E5",
            100: "#B3E4EF",
            50: "#E1F5F8",
            important: {
                900: "#005780 !important",
                800: "#0076A1 !important",
                700: "#0087B4 !important",
                600: "#1199C6 !important",
                500: "#1CA7D4 !important",
                400: "#3AB4D8 !important",
                300: "#59C1DC !important",
                200: "#84D2E5 !important",
                100: "#B3E4EF !important",
                50: "#E1F5F8 !important",
            },
        },
        purple: {
            900: "#3A2C6C",
            800: "#49367F",
            700: "#523C89",
            600: "#5D4493",
            500: "#654A99",
            400: "#7A63A7",
            300: "#917EB7",
            200: "#B0A3CC",
            100: "#CFC7E0",
            50: "#ECE8F2",
            important: {
                900: "#3A2C6C !important",
                800: "#49367F !important",
                700: "#523C89 !important",
                600: "#5D4493 !important",
                500: "#654A99 !important",
                400: "#7A63A7 !important",
                300: "#917EB7 !important",
                200: "#B0A3CC !important",
                100: "#CFC7E0 !important",
                50: "#ECE8F2 !important",
            },
        },
        brown: {
            900: "#301B0E",
            800: "#3E2819",
            700: "#4C3521",
            600: "#5A4029",
            500: "#64492F",
            400: "#7B624C",
            300: "#937D6A",
            200: "#B3A193",
            100: "#D3C6BC",
            50: "#EFE8E1",
            important: {
                900: "#301B0E !important",
                800: "#3E2819 !important",
                700: "#4C3521 !important",
                600: "#5A4029 !important",
                500: "#64492F !important",
                400: "#7B624C !important",
                300: "#937D6A !important",
                200: "#B3A193 !important",
                100: "#D3C6BC !important",
                50: "#EFE8E1 !important",
            },
        },
        grey: {
            900: "#212121",
            800: "#424242",
            700: "#616161",
            600: "#757575",
            500: "#9E9E9E",
            400: "#BDBDBD",
            300: "#E0E0E0",
            200: "#EEEEEE",
            100: "#F5F5F5",
            50: "#FAFAFA",
            important: {
                900: "#212121 !important",
                800: "#424242 !important",
                700: "#616161 !important",
                600: "#757575 !important",
                500: "#9E9E9E !important",
                400: "#BDBDBD !important",
                300: "#E0E0E0 !important",
                200: "#EEEEEE !important",
                100: "#F5F5F5 !important",
                50: "#FAFAFA !important",
            },
        },
        white: {
            50: "#ffffff",
            important: {
                50: "#ffffff !important",
            },
        },
    },
    typography: {
        color: "#424242",
        h1: { fontWeight: 700, fontSize: "48px", lineHeight: "52px" },
        h2: { fontWeight: 700, fontSize: "36px", lineHeight: "46px" },
        h3: { fontWeight: 500, fontSize: "28px", lineHeight: "40px" },
        h4: { fontWeight: 500, fontSize: "24px", lineHeight: "34px" },
        h5: { fontWeight: 500, fontSize: "18px", lineHeight: "32px" },
        h6: { fontWeight: 500, fontSize: "16px", lineHeight: "28px" },
        subtitle1: { fontWeight: 700, fontSize: "16px !important", lineHeight: "24px" },
        subtitle2: { fontWeight: 700, fontSize: "14px !important", lineHeight: "24px" },
        subtitle3: { fontWeight: 700, fontSize: "12px !important", lineHeight: "16px" },
        button: { fontWeight: 700, fontSize: "18px", lineHeight: "24px" },
        body1: { fontWeight: 400, fontSize: "16px", lineHeight: "24px" },
        body2: { fontWeight: 400, fontSize: "14px", lineHeight: "20px" },
    },
};

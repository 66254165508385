import { DEFAULT_COOKIE_PARAMS } from "helper";
import { flushStorage } from "helper/persist-storage";
import cookie from "js-cookie";
import storage from "redux-persist/lib/storage";
import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import * as authActions from "service/actions/authentication";
import * as merchantAction from "service/actions/merchant";
import * as siteAction from "service/actions/site";
import * as adminApis from "service/apis/admin/auth";
import * as authApis from "service/apis/authentication";
import api from "service/apis/config";
import * as merchantAuthApis from "service/apis/merchant/auth";
import * as publicApis from "service/apis/public";
import * as impersonationApis from "service/apis/admin/impersonation";
import { getMerhcantPrivilegesSAGA, getMerhcantProfileSAGA } from "../merchant";
import { initAPPSAGA } from "../sites";

function* initialDataSAGA() {
    const [{ data: masterdata }, { data: areadata }, { data: categorydata }] = yield all([
        call(publicApis.getmasterdata),
        call(publicApis.getareas),
        call(publicApis.getcategories),
    ]);

    if (!masterdata.error) yield put(siteAction.setInitialForm(masterdata.result));
    if (!categorydata.error) yield put(siteAction.setCategoryData(categorydata.result));
}

function* getRegisterSAGA(payload) {
    const { data, ...rest } = yield call(authApis.AuthRegister, payload.data);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Register Success, your application will be reviewed",
                severity: "success",
            }),
        );
        yield put(siteAction.setRedirect({ pathname: "/auth/login" }));
        storage.clear("draft-newmerchant");
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* LoginRequestSAGA(payload) {
    const { data, ...rest } = yield call(
        payload.data.type !== "admin-login" ? authApis.AuthLogin : adminApis.authogin,
        payload.data,
    );
    window.localStorage.removeItem("logout");
    if (!data.error) {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Login Success",
                severity: "success",
            }),
        );
        yield call(initAPPSAGA);
        yield api.setHeader("Authorization", `Bearer ${data.result.accessToken}`);
        if (payload.data.type === "admin-login") {
            cookie.set("admin-token", data.result.accessToken, DEFAULT_COOKIE_PARAMS);
            cookie.set("refresh-admin-token", data.result.newRefreshToken, DEFAULT_COOKIE_PARAMS);
        } else {
            cookie.set("merchant-token", data.result.accessToken, DEFAULT_COOKIE_PARAMS);
            cookie.set("refresh-merchant-token", data.result.newRefreshToken, DEFAULT_COOKIE_PARAMS);
        }
        if (payload.data.type !== "admin-login") {
            yield put(
                merchantAction.setProfile({
                    privilege: data?.result?.privilege,
                    isAdmin:
                        payload.data.type === "admin-login" &&
                        cookie.get("admin-token")?.length > 0 &&
                        rest?.config?.url?.includes("admin"),
                }),
            );
            yield all([
                call(getMerhcantPrivilegesSAGA),
                call(getMerhcantProfileSAGA, { privilege: data?.result?.privilege }),
            ]);
        } else {
            yield put(
                merchantAction.setProfile({
                    privilege: [],
                    isAdmin:
                        payload.data.type === "admin-login" &&
                        cookie.get("admin-token")?.length > 0 &&
                        rest?.config?.url?.includes("admin"),
                }),
            );
        }

        yield put(
            siteAction.setRedirect({
                pathname:
                    cookie.get("admin-token")?.length > 0 && rest?.config?.url?.includes("admin")
                        ? "/admin/application"
                        : "/products/list",
            }),
        );
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* StartImpersonateSAGA({ data: mkpUserId }) {
    const { data } = yield call(impersonationApis.adminStartImpersonation, { mkpUserId });
    if (!data.error) {
        flushStorage();
        window.localStorage.clear();
        api.setHeader("Authorization", `Bearer ${data.result.accessToken}`);
        cookie.remove("admin-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-admin-token", DEFAULT_COOKIE_PARAMS);
        cookie.set("merchant-token", data.result.accessToken, DEFAULT_COOKIE_PARAMS);
        cookie.set("refresh-merchant-token", data.result.newRefreshToken, DEFAULT_COOKIE_PARAMS);
        yield call(initAPPSAGA);
        yield put(
            merchantAction.setProfile({
                privilege: data?.result?.privilege,
                isImpersonate: true,
                isAdmin: false,
            }),
        );
        yield all([
            call(getMerhcantPrivilegesSAGA),
            call(getMerhcantProfileSAGA, { privilege: data.result.privilege }),
        ]);

        yield put(siteAction.setRedirect({ pathname: "/products/list" }));
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Impersonation enabled",
                severity: "success",
            }),
        );
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* StopImpersonateSAGA() {
    const { data } = yield call(impersonationApis.adminStopImpersonation);
    if (!data.error) {
        flushStorage();
        window.localStorage.clear();
        api.setHeader("Authorization", `Bearer ${data.result.accessToken}`);
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.set("admin-token", data.result.accessToken, DEFAULT_COOKIE_PARAMS);
        cookie.set("admin-merchant-token", data.result.newRefreshToken, DEFAULT_COOKIE_PARAMS);

        yield call(initAPPSAGA);
        yield put(
            merchantAction.setProfile({
                privilege: [],
                isImpersonate: false,
                isAdmin: true,
            }),
        );

        yield put(siteAction.setRedirect("/admin/application"));
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Impersonation ended",
                severity: "success",
            }),
        );
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* LogoutActionSAGA() {
    try {
        flushStorage();
        window.localStorage.clear();
        if (cookie.get("admin-token")?.length > 0 && window.location.href.indexOf("admin") > -1) {
            cookie.remove("admin-token", DEFAULT_COOKIE_PARAMS);
            cookie.remove("refresh-admin-token", DEFAULT_COOKIE_PARAMS);
        } else {
            cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
            cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        }
        window.localStorage.setItem(
            "logout",
            cookie.get("admin-token")?.length > 0 && window.location.href.indexOf("admin") > -1
                ? "adm-true"
                : "merc-true",
        );
        window.location.reload();
    } catch (error) {
        console.error(error);
    }
}

function* forgotPassSAGA(payload) {
    const { data } = yield call(authApis.AuthResetPassword, payload.data);

    if (!data.error) {
        yield put(
            siteAction.setRedirect({
                pathname: "/auth/forgotpass/redirect",
                state: { type: "email", data: data.result },
            }),
        );
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "An error has occurred. Please try again later.",
                severity: "error",
            }),
        );
    }
}

function* verifyResetPassSAGA(payload) {
    const { data, ...rest } = yield call(authApis.AuthVerify, payload.data);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("admin-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-admin-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield put(authActions.setForgotPasswordVerify(data.result));
    } else if (data.error) {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
        yield delay(1000);
        yield put(siteAction.setRedirect({ pathname: "/auth/forgotpass" }));
    }
}

function* resetPassSAGA(payload) {
    const { data, ...rest } = yield call(authApis.AuthChangePassword, payload.data);
    yield put(siteAction.setLoading({ key: "auth", loading: true }));
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("admin-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-admin-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield delay(500);
        yield put(siteAction.setLoading({ key: "auth", loading: false }));
        yield put(siteAction.setRedirect({ pathname: "/auth/login" }));
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Password reset sucessful.",
                severity: "success",
            }),
        );
    }
}

function* getSubDistrictByAreaSAGA(payload) {
    const { data, ...rest } = yield call(publicApis.getsubdistrictbyarea, payload.data);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("admin-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-admin-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield put(siteAction.setSubDistrictData(data.result));
    }
}

function* getAreaByCountrySAGA(payload) {
    const { data, ...rest } = yield call(publicApis.getareasbycountry, payload.data);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("admin-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-admin-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield put(siteAction.setAreaByCountry(data.result));
    }
}

function* getMerchantRevisedDataSAGA(payload) {
    const { data, ...rest } = yield call(merchantAuthApis.getreviseddata, payload.data);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield put(authActions.setMerchantReviseData(data.result));
    } else if (data.error) {
        yield put(authActions.setMerchantReviseData(false));
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* clearMerchantRevisedDataSAGA(payload) {}

function* mainSagaSignIn() {
    yield takeLatest(authActions.getInitialForm().type, initialDataSAGA);
    yield takeLatest(authActions.getRegisterMerchantData().type, getRegisterSAGA);
    yield takeLatest(authActions.getLoginData().type, LoginRequestSAGA);
    yield takeLatest(authActions.setLogout().type, LogoutActionSAGA);
    yield takeLatest(authActions.forgotPasswordRequest().type, forgotPassSAGA);
    yield takeLatest(authActions.getForgotPasswordVerify().type, verifyResetPassSAGA);
    yield takeLatest(authActions.forgotPasswordChange().type, resetPassSAGA);
    yield takeLatest(authActions.getSubDistrict().type, getSubDistrictByAreaSAGA);
    yield takeLatest(siteAction.getAreaByCountry().type, getAreaByCountrySAGA);
    yield takeLatest(authActions.getMerchantReviseData().type, getMerchantRevisedDataSAGA);
    yield takeLatest(authActions.clearMerchantReviseData().type, clearMerchantRevisedDataSAGA);
    yield takeLatest(authActions.startImpersonation().type, StartImpersonateSAGA);
    yield takeLatest(authActions.stopImpersonation().type, StopImpersonateSAGA);
}

export default mainSagaSignIn;

import { encryptString } from "helper/transformer";
import { extractYouTubeID } from "helper/validate";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as merchantAction from "service/actions/merchant";
import * as siteAction from "service/actions/site";
import * as merchantProductApis from "service/apis/merchant/product";

function* processEditProductSAGA(payload) {
    yield put(merchantAction.setEditProductLoading(true));
    yield put(siteAction.setRedirect(`/products/edit/form/${encryptString(payload.key, "base64")}`));

    const { data } = yield call(merchantProductApis.getEditDraft, payload.key);

    if (!data.error) {
        yield put(merchantAction.setEditProductDraft(data.result));
    } else {
        yield put(siteAction.setRedirect("/products/list"));
        yield put(merchantAction.setEditProductLoading(false));
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* confirmEditSAGA() {
    const productData = yield select((state) => state.merchant.product.edit.data);
    const { spu, sku } = productData;

    const allSkuHaveImg = sku.reduce((prev, curr) => {
        const allImgs = [...curr.existing_images, ...curr.image_slots];
        return prev && !!allImgs.filter(({ occupied }) => occupied).length;
    }, true);

    let errorMessage = "";
    // Invalid YouTube link
    if (spu.youtube_link && extractYouTubeID(spu.youtube_link) === "") {
        errorMessage = "Invalid YouTube video link for product description";
    }
    // Incomplete image data
    if (!allSkuHaveImg) {
        errorMessage = "Some of your product variations does not have an image";
    }

    if (errorMessage) {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: errorMessage,
                severity: "error",
            }),
        );
        return;
    }

    yield put(merchantAction.setEditSubmitLoading(true));
    const { data } = yield call(merchantProductApis.editProduct, productData);

    if (!data.error) {
        yield put(siteAction.setRedirect("/products/list"));
        yield put(merchantAction.setEditSubmitLoading(false));
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Product Edit Sucessful",
                severity: "success",
            }),
        );
    } else {
        yield put(merchantAction.setEditSubmitLoading(false));
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data.message || "Unknown Error",
                severity: "error",
            }),
        );
    }
}

export default [
    takeLatest(merchantAction.processEditProduct().type, processEditProductSAGA),
    takeLatest(merchantAction.confirmEditProduct().type, confirmEditSAGA),
];

import { FormText } from "components/Global/Form";

export default (e) => {
    return (
        <div>
            <FormText>
                <p className="body2">
                    {Array.isArray(e)
                        ? e?.map((store) => store.store_name_english || store.store_name_chinese)?.join(" / ")
                        : e.store_name_english || e.store_name_chinese}
                </p>
            </FormText>
        </div>
    );
};

import {
    MenuItem,
    styled,
    TextField,
    withStyles,
    FormControl,
    InputBase,
    InputLabel,
    NativeSelect,
    Chip,
    makeStyles,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect, useRef, useState } from "react";

const BootstrapInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.yoho.color.white,
        // border: '1px solid #ced4da',
        fontSize: 14,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow", "background-color", "color"]),
        // Use the system font instead of the default Roboto font.
        "&:hover": {
            backgroundColor: theme.yoho.md_color.grey[100],
        },
        "&:active": {
            color: theme.yoho.md_color.blue[500],
            backgroundColor: theme.yoho.md_color.blue[50],
        },
        "&:focus": {
            backgroundColor: theme.yoho.md_color.grey[200],
            color: theme.yoho.md_color.blue[500],
            borderRadius: 4,
        },
    },
}))(InputBase);

const SelectInput = styled(TextField)({
    "& .MuiInput-underline:after": {
        borderBottom: "0px",
    },
    "& .MuiInput-underline:before": {
        borderBottom: "0px",
    },
    "& .MuiInput-underline:hover": {
        borderBottom: "0px",
    },
    "& .MuiInput-underline:hover:before": {
        borderBottom: "0px",
    },
});

const ComboTextField = styled(TextField)(({ mode, theme }) => ({
    "& .MuiOutlinedInput-root": {
        borderRadius: mode === "left" ? "4px 0 0 4px" : mode === "right" ? "0 4px 4px 0" : "0",
        "& input": {
            backgroundColor: theme.yoho.md_color.grey[100],
        },
    },
    "& $input": {
        borderRadius: mode === "left" ? "4px 0 0 4px" : mode === "right" ? "0 4px 4px 0" : "0",
    },
}));

export default ({ name, data, control, label, key = "value" }) => {
    return (
        <Controller
            control={control}
            name={name}
            onChange={([, data]) => data}
            render={({ onChange, ref: refRender, ...props }) => (
                <FormControl style={{ width: "fit-content" }}>
                    {label && <InputLabel htmlFor="demo-customized-select-native">{label}</InputLabel>}
                    <NativeSelect
                        id="demo-customized-select-native"
                        onChange={onChange}
                        input={<BootstrapInput />}
                        {...props}
                    >
                        {data.map((option, key) => (
                            <option key={key} aria-label={option.value} value={JSON.stringify(option)}>
                                {option.name}
                            </option>
                        ))}
                    </NativeSelect>
                </FormControl>
            )}
        />
    );
};

export const TextSelection = ({
    id,
    size = "medium",
    name,
    onChange,
    inputRef,
    className,
    label,
    value,
    defaultValue,
    helperText,
    data,
    keyValue = "value",
    keyDisplay = "name",
    variant = "outlined",
    style = {},
    disabled = false,
    shipment,
}) => {
    const [val, setVal] = useState(defaultValue);

    useEffect(() => {
        onChange && val && onChange({ target: { name, value: val === undefined ? defaultValue : val } });
    }, [val]);

    return (
        <TextField
            size={size}
            disabled={disabled}
            name={name}
            {...(className && { className: className })}
            {...(id && { id: id })}
            style={style}
            value={value || val}
            // {...(defaultValue && { defaultValue: val })}
            {...(label && { label: label })}
            {...(onChange && { onChange: (data, value) => setVal(data.target.value) })}
            {...(inputRef && { inputRef: inputRef })}
            {...(helperText && { helperText: helperText })}
            select
            SelectProps={{
                native: true,
                renderValue: (value) => value,
            }}
            variant={variant}
        >
            {shipment
                ? data?.map((option) => (
                      <option key={option?.value} value={option?.value}>
                          {`${option?.value?.split(",")?.[0]}-${option?.value?.split(",")?.[1]} working days`}
                      </option>
                  ))
                : data?.map((option) => (
                      <option key={option[keyValue]} value={option[keyValue]}>
                          {option[keyDisplay]}
                      </option>
                  ))}
        </TextField>
    );
};

export const FilterSelection = ({
    id,
    className,
    label,
    value,
    onChange,
    helperText,
    data,
    keyValue = "value",
    keyDisplay = "name",
    variant = "outlined",
}) => {
    const handleChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <div>
            <ComboTextField
                {...(className && { className: className })}
                {...(id && { id: id })}
                {...(value && { value: value })}
                {...(label && { label: label })}
                {...(helperText && { helperText: helperText })}
                select
                SelectProps={{
                    native: true,
                }}
                variant={variant}
                mode="left"
            >
                {data?.map((option) => (
                    <option key={option[keyValue]} value={option[keyValue]}>
                        {option[keyDisplay]}
                    </option>
                ))}
            </ComboTextField>
            <ComboTextField variant={variant} mode="right" onChange={handleChange} />
        </div>
    );
};

export const UncontrolledNormalSelect = ({
    data = [],
    label,
    onChange,
    value,
    defaultValue,
    name,
    prefix,
    variant,
    className,
    placeholder = "",
    itemName = "name",
    itemValue = "name",
    size = "medium",
}) => {
    return (
        <FormControl {...(!className && { style: { width: "fit-content" } })} className={className}>
            {label && <InputLabel htmlFor="demo-customized-select-native">{label}</InputLabel>}
            <SelectInput
                size={size}
                placeholder={placeholder}
                select
                value={value}
                name={name}
                variant={variant}
                style={{ border: 0 }}
                defaultValue={defaultValue}
                onChange={({ target }) => {
                    return onChange({ [target.name]: target.value });
                }}
                SelectProps={{
                    renderValue: (option) => (prefix ? `${prefix} ${option}` : option),
                }}
            >
                {data.map((option) => (
                    <MenuItem key={option[itemValue]} value={option[itemValue]}>
                        {option[itemName]}
                    </MenuItem>
                ))}
            </SelectInput>
        </FormControl>
    );
};

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        width: ({ width }) => width.width || "100%",
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

export const MultiSelection = ({
    id,
    className,
    name,
    label,
    onChange,
    data,
    defaultValue = [],
    keyValue,
    variant = "outlined",
}) => {
    const ref = useRef(null);
    const [val, setVal] = useState(defaultValue);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setWidth({ width: ref?.current?.offsetWidth });
    }, []);

    const classes = useStyles({ width: width });

    useEffect(() => {
        onChange({ target: { name, value: val } });
    }, [val]);

    return data ? (
        <Autocomplete
            classes={{
                option: classes.option,
            }}
            ref={ref}
            multiple
            options={data}
            defaultValue={val}
            {...(className && { className: className })}
            {...(id && { id: id })}
            {...(onChange && { onChange: (_, value) => setVal(value) })}
            getOptionLabel={(option) => (keyValue ? option[keyValue] : option)}
            getOptionSelected={(option, value) => (keyValue ? option[keyValue] === value[keyValue] : option === value)}
            filterSelectedOptions
            renderOption={(op) => <div style={{ width: "100%" }}>{op}</div>}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={variant}
                    label={label}
                    // placeholder="Select the Item"
                />
            )}
        />
    ) : (
        <>No Data Found</>
    );
};

import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";

export default ({ name, action, termsData, draft, product_id }) => {
    const editorRef = useRef(null);
    let lang = name.split("_")[name.split("_").length - 1];

    return (
        <>
            <Editor
                disabled
                onInit={(_, editor) => (editorRef.current = editor)}
                apiKey={process?.env.REACT_APP_TINY_MCE}
                onFocusOut={(e) => action({ [name]: e.target.innerHTML })}
                initialValue={draft?.spu?.[name] || termsData?.[`description_${lang}`]}
                init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                        "image preview media undo redo | formatselect |" +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
            />
        </>
    );
};

import { styled } from "@material-ui/core";
import moment from "moment";
import { memo } from "react";

const Column = styled("div")({
    display: "flex",
    flexDirection: "column",
});

export default (submission_date) => {
    return (
        <Column>
            <span>
                <strong>{moment(submission_date).fromNow()}</strong>
            </span>
            <span>{moment(submission_date).format("MMM DD, YYYY")}</span>
            <span>{moment(submission_date).format("h:mm")}</span>
        </Column>
    );
};

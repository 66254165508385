import { Checkbox, styled } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import Table from "components/Global/Table";
import Column from "components/Global/Table/Column";
import { Topbar, Topleft } from "components/Global/Table/Topbar";
import usePopup from "hooks/usePopup";
import { useDispatch, useSelector } from "react-redux";
import { bulkUpdateEditProductDraft, setProductChecklist } from "service/actions/merchant";
import { unsetPopup } from "service/actions/site";
import VariationsBulkAction from "./popup/VariationsBulkAction";
import ActionColumn from "./table/ActionColumn";
import PriceColumn from "./table/Price";
import VariationItem from "./VariationItem";
import SKU from "./table/SKU";
import { onImageError } from "helper";

const VariantCustomColumn = styled("div")({
    display: "flex",
    gap: 20,
});

const SkeletonImage = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    width: "60px",
    height: "60px",
    position: "relative",
    boxSizing: "border-box",
    borderRadius: 8,
    "&:after": {
        content: '""',
        position: "absolute",
        borderBottom: `1px solid ${theme.yoho.md_color.grey[400]}`,
        top: "2.3px",
        right: "-76.853px",
        width: "77.8px",
        transform: "rotate(135deg)",
        transformOrigin: "0% 0%",
    },
}));

const ThumbImg = styled("img")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    width: 60,
    height: 60,
    objectFit: "contain",
    position: "relative",
    boxSizing: "border-box",
    borderRadius: 8,
    "&:after": {
        content: '""',
        position: "absolute",
        borderBottom: `1px solid ${theme.yoho.md_color.grey[400]}`,
        top: "2.3px",
        right: "-76.853px",
        width: "77.8px",
        transform: "rotate(135deg)",
        transformOrigin: "0% 0%",
    },
}));

const Thumbnail = styled("div")({
    width: 60,
    height: 60,
    overflow: "hidden",
    borderRadius: 8,
});

const StatusListing = styled("div")((props) => ({
    padding: 7,
    fontSize: 12,
    fontWeight: "bold",
    borderRadius: 4,
    backgroundColor: props.children.toLowerCase() === "listed" ? "#DDF6EF" : "#ECE8F2",
    color: props.children.toLowerCase() === "listed" ? "#00A87A" : "#654A99",
}));

const StatusColumn = (e) => {
    return <StatusListing>{e === 1 ? "Listed" : "Unlisted"}</StatusListing>;
};

const VariantColumn = (props) => {
    const { current } = props;
    let image = current?.exist_variations_general_image_main?.original_img;

    return (
        <VariantCustomColumn>
            {image ? (
                <Thumbnail>
                    <ThumbImg src={image} onError={onImageError} />
                </Thumbnail>
            ) : (
                <SkeletonImage />
            )}
            <div>
                <FormText>
                    <p className="subtitle1">{current?.vital_info_product_name_chinese}</p>
                </FormText>
                <FormText>
                    <p>SKU: {current?.variations_general_custom_sku || current?.main_goods_id || current?.goods_id}</p>
                </FormText>
                <FormText>
                    <p>GTIN: {current?.variations_general_gtin}</p>
                </FormText>
            </div>
        </VariantCustomColumn>
    );
};

export default ({ draft, product_id, merchant_id }) => {
    const dispatch = useDispatch();
    const popup = usePopup();
    const currentCheckTable = useSelector((state) => state.merchant.product.checkbox_ids.edit_goods_ids);

    const CheckColumn = (e) => {
        return (
            <Checkbox
                color="primary"
                name={e.goods_id?.toString()}
                size="small"
                checked={currentCheckTable?.some((id) => id === e.goods_id)}
                onChange={(_, deleted) => dispatch(setProductChecklist(e.goods_id, deleted, "edit"))}
            />
        );
    };

    return (
        <div>
            <FormCategory>
                <div className="full gap-20 flex col">
                    {draft?.spu?.variations_attributes?.map((item, index) => (
                        <div>
                            <VariationItem data={item} />
                        </div>
                    ))}
                </div>
                <Table
                    data={draft?.sku}
                    className="full"
                    showExport={false}
                    showHistory={false}
                    showLayout={false}
                    disableCheckbox={true}
                >
                    <Topbar>
                        <Topleft>
                            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                                <Checkbox
                                    color="primary"
                                    size="small"
                                    checked={currentCheckTable?.length > 0}
                                    onChange={(data, deleted) =>
                                        dispatch(
                                            setProductChecklist(
                                                draft.sku?.map((item) => item.goods_id),
                                                deleted,
                                                "edit",
                                            ),
                                        )
                                    }
                                />
                                <div>{currentCheckTable?.length || 0} Selected</div>
                                {currentCheckTable?.length > 0 && (
                                    <FormButton
                                        cvariant="contained"
                                        ccolor="blue"
                                        height={36}
                                        width="fit-content"
                                        onClick={(e) =>
                                            popup(VariationsBulkAction, {
                                                mode: "create",
                                                title: "Bulk Actions",
                                                editdata: e,
                                                action: (data, id) => {
                                                    dispatch(bulkUpdateEditProductDraft(data));
                                                },
                                                close: () => {
                                                    dispatch(unsetPopup());
                                                },
                                            })
                                        }
                                    >
                                        Bulk Action
                                    </FormButton>
                                )}
                            </div>
                        </Topleft>
                    </Topbar>
                    <Column type="custom" render={CheckColumn} />
                    <Column type="custom" render={(data) => <VariantColumn current={data} />} label="Variants" />
                    <Column field="listed" render={StatusColumn} label="Listed" width={90} />
                    <Column field="variations_sales_inventory" label="Inventory" width={90} />
                    <Column
                        field={[
                            "variations_sales_vip_price",
                            "variations_sales_member_price",
                            "variations_sales_market_price",
                        ]}
                        render={PriceColumn}
                        label="Selling Price"
                    />
                    <Column field={["is_primary", "goods_id"]} render={SKU} label="Primary SKU" />
                    <Column
                        type="custom"
                        render={(data) => (
                            <ActionColumn
                                draft={draft}
                                current={data}
                                product_id={product_id}
                                merchant_id={merchant_id}
                            />
                        )}
                    />
                </Table>
            </FormCategory>
        </div>
    );
};

import Table from "components/Global/Table";
import Column from "components/Global/Table/Column";
import { useSelector } from "react-redux";
import TableRatesActionColumn from "./table/TableRatesActionColumn";
import TableRatesDesitationColumn from "./table/TableRatesDesitationColumn";
import TableRatesFreeConditionColumn from "./table/TableRatesFreeConditionColumn";
import TableRatesShippingFeeColumn from "./table/TableRatesShippingFeeColumn";

export default () => {
    const table_rates = useSelector((state) => state.merchant.setting.shipping?.table_rates);
    return (
        <div>
            <Table data={table_rates} disableCheckbox={true}>
                <Column
                    field="destination_id"
                    label="Destination"
                    textStyle="subtitle2"
                    render={(obj) => <TableRatesDesitationColumn data={obj} />}
                />
                <Column field="calculated_by" label="Calculated by" textStyle="subtitle2" />
                <Column
                    field="within_1"
                    label="Shipping Fee"
                    textStyle="subtitle2"
                    render={(obj) => <TableRatesFreeConditionColumn data={obj} />}
                />
                <Column
                    field={["free_shipping", "free_shipping_value"]}
                    label="Free Condition"
                    textStyle="subtitle2"
                    render={(obj) => <TableRatesShippingFeeColumn data={obj} />}
                />
                <Column type="custom" render={(obj) => <TableRatesActionColumn data={obj} />} />
            </Table>
        </div>
    );
};

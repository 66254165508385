import { Checkbox, debounce, Icon, InputAdornment, TextField } from "@material-ui/core";
import { YHPagination, YohoButtonIcon } from "components/Global";
import { FormText } from "components/Global/Form";
import { UncontrolledNormalSelect } from "components/Global/Form/NormalSelect";
import Table from "components/Global/Table";
import Column from "components/Global/Table/Column";
import Pagination from "components/Global/Table/Pagination";
import { Topbar, Topleft, TopRight } from "components/Global/Table/Topbar";
import Price from "components/MerchantCentre/AdministrationConsole/Product/List/table/Price";
import Product from "components/MerchantCentre/AdministrationConsole/Product/List/table/Product";
import useExportToExcel from "helper/exportTable.js";
import { useCallback, useEffect, useState } from "react";
import { useGetAdminProductList } from "../hooks.js";
import { showOptions } from "./constant";
import { AdminProductContainer, ListRow } from "./StyledComponent";
import Seller from "./table/Seller";
import SKU from "./table/SKU";
import SPUStatus from "./table/SPUStatus.js";

export default () => {
    const [params, setParams] = useState({
        search: "",
        size: 30,
        lang: "zh_tw",
        page: 1,
    });

    const { data: productData, isLoading } = useGetAdminProductList(params);
    const [checked, setChecked] = useState([]);
    const [exportParam, setExportParam] = useState(null);
    const { isExporting } = useExportToExcel(exportParam);

    const handleCheck = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) newChecked.push(value);
        else newChecked.splice(currentIndex, 1);
        setChecked(newChecked);
    };

    const CheckColumn = (e) => {
        return (
            <Checkbox
                color="primary"
                size="small"
                checked={checked?.indexOf(e.goods_id) !== -1}
                onChange={() => handleCheck(e.goods_id)}
            />
        );
    };

    const handleDebounceFn = (inputValue) => {
        inputValue && setParams((prev) => ({ ...prev, ...inputValue }));
    };

    const debounceFn = useCallback(debounce(handleDebounceFn, 300), []);

    const handleSearch = (e) => {
        debounceFn({ search: e.target.value });
    };

    useEffect(() => setChecked([]), [params]);

    return (
        <AdminProductContainer>
            <ListRow>
                <div className="show">
                    <TextField
                        size="small"
                        variant="outlined"
                        style={{ width: 450 }}
                        placeholder="Search by SKU ID, product name, brand, or store name"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon style={{ textAlign: "center" }}>
                                        <img style={{ height: "100%" }} src="/assets/icon/search-outline.svg" />
                                    </Icon>
                                </InputAdornment>
                            ),
                        }}
                        onKeyUp={handleSearch}
                    />
                </div>
            </ListRow>
            <Table data={productData?.result?.products || []} loading={isLoading}>
                <Topbar>
                    <Topleft>
                        {!isLoading && (
                            <>
                                <Checkbox
                                    color="primary"
                                    size="small"
                                    checked={
                                        checked.length === productData.result.products.length && checked.length !== 0
                                    }
                                    onChange={(_, status) => {
                                        setChecked(
                                            status ? productData.result.products.map((item) => item.goods_id) : [],
                                        );
                                    }}
                                />
                                <div>{checked.length || 0} Selected</div>
                            </>
                        )}
                        {checked.length > 0 && <>No actions available</>}
                    </Topleft>
                    <TopRight className="full">
                        {isExporting ? (
                            <span>Exporting....</span>
                        ) : (
                            <YohoButtonIcon
                                src="/assets/icon/export.svg"
                                width={120}
                                height={35}
                                cursor="pointer"
                                text="Export All"
                                onClick={() => setExportParam({ target: "admin-product" })}
                            />
                        )}
                    </TopRight>
                </Topbar>
                <Column type="custom" width={60} fixed render={CheckColumn} />
                <Column
                    field={["goods_id", "merchant_id", "merchants", "product_name", "image", "sku"]}
                    render={Product}
                    fixed
                    width={370}
                    label="Name"
                />
                <Column field="listed" render={(e) => <SPUStatus data={e} type_data="listed" />} label="Listed" />
                <Column field="unlisted" render={(e) => <SPUStatus data={e} type_data="unlisted" />} label="Unlisted" />
                <Column field={["price_vip", "price_mbr", "price_mp"]} render={Price} label="Price" />
                <Column field="stock_qty" label="Inventory" width={90} />
                <Column field="sold" label="Sold" width={80} />
                <Column
                    field={["is_main", "goods_id"]}
                    label="Key SKU"
                    width={90}
                    render={(e) => <SKU data={e} params={params} />}
                />
                <Column field="merchants" label="Seller store(s)" render={Seller} />
            </Table>
            <Pagination>
                <div>
                    <YHPagination
                        size={productData?.result?.total_pages}
                        action={(e) => setParams({ ...params, page: e })}
                    />
                </div>
                <div>
                    <UncontrolledNormalSelect
                        variant="standard"
                        data={showOptions}
                        name="size"
                        prefix="Show"
                        onChange={(e) => setParams({ ...params, ...e })}
                        value={params.size}
                    />
                    <FormText>
                        <p className="subtitle2">Products out of {productData?.result?.total_products || 0}</p>
                    </FormText>
                </div>
            </Pagination>
        </AdminProductContainer>
    );
};

import ShipmentManagement from "components/MerchantCentre/MerchantConsole/Settings/ShipmentManagement";
import ShipmentSkeleton from "components/Skeleton/merchant-module/Shipping";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShippingSetting } from "service/actions/merchant";

export default () => {
    const dispatch = useDispatch();
    const isloading = useSelector((state) => state.merchant.loading);

    useEffect(() => {
        dispatch(getShippingSetting());
    }, []);

    return isloading ? <div></div> : <ShipmentManagement />;
};

import { all, call, put, takeLatest } from "redux-saga/effects";
import cookie from "js-cookie";
import { DEFAULT_COOKIE_PARAMS } from "helper";
import * as merchantAction from "service/actions/merchant";
import * as siteAction from "service/actions/site";
import * as merchantSettingApis from "service/apis/merchant/setting";

export function* getStoreSettingSAGA() {
    const { data, ...rest } = yield call(merchantSettingApis.getstoresettings);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token");
        cookie.remove("refresh-merchant-token");
    }
    if (!data.error) {
        yield put(merchantAction.setStoreSetting(data.result));
    } else {
        yield all([
            put(siteAction.setSnackbar({ open: true, message: "Failed to get store data", severity: "error" })),
            put(merchantAction.resetSettingLoading()),
            put(siteAction.setRedirect("forceAppBack")),
        ]);
    }
}

function* updateStoreContactSAGA(payload) {
    const { data, ...rest } = yield call(merchantSettingApis.updatestorecontact, payload.data);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token");
        cookie.remove("refresh-merchant-token");
    }
    if (!data.error) {
        yield call(getStoreSettingSAGA);
        yield put(
            siteAction.setSnackbar({ open: true, message: "Store -> Contact has been updated", severity: "success" }),
        );
    } else {
        yield put(siteAction.setSnackbar({ open: true, message: "Failed to update data", severity: "error" }));
    }
}

function* createStoreWarrantySAGA(payload) {
    const { data, ...rest } = yield call(merchantSettingApis.poststorewarranty, payload.data);

    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token");
        cookie.remove("refresh-merchant-token");
    }
    if (!data.error) {
        yield call(getStoreSettingSAGA);
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Store -> Warranty added new warranty",
                severity: "success",
            }),
        );
    } else {
        yield put(siteAction.setSnackbar({ open: true, message: "Failed to update data", severity: "error" }));
    }
}

function* updateStoreWarrantySAGA(payload) {
    const { data, ...rest } = yield call(merchantSettingApis.updatestorewarranty, payload.id, payload.data);

    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token");
        cookie.remove("refresh-merchant-token");
    }
    if (!data.error) {
        yield call(getStoreSettingSAGA);
        yield put(
            siteAction.setSnackbar({ open: true, message: "Store -> Warranty has been updated", severity: "success" }),
        );
    } else {
        yield put(siteAction.setSnackbar({ open: true, message: "Failed to update data", severity: "error" }));
    }
}

export function* getStoreCategoryDetailsSAGA(payload) {
    const { data, ...rest } = yield call(merchantSettingApis.getStorePageCategoryDetails, { catId: payload.data });

    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token");
        cookie.remove("refresh-merchant-token");
    }
    if (!data.error) {
        yield put(merchantAction.setStoreCategoryDetails({ ...data.result, loading: false }));
    } else {
        yield all([
            put(siteAction.setSnackbar({ open: true, message: "Failed to get store data", severity: "error" })),
            put(siteAction.setRedirect("forceAppBack")),
        ]);
    }
}

export default [
    takeLatest(merchantAction.getStoreSetting().type, getStoreSettingSAGA),
    takeLatest(merchantAction.getStoreCategoryDetails().type, getStoreCategoryDetailsSAGA),
    takeLatest(merchantAction.updateStoreContact().type, updateStoreContactSAGA),
    takeLatest(merchantAction.createStoreWarranty().type, createStoreWarrantySAGA),
    takeLatest(merchantAction.updateStoreWarranty().type, updateStoreWarrantySAGA),
];

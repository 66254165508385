import { Checkbox, styled } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import Table from "components/Global/Table";
import Column from "components/Global/Table/Column";
import { Topbar, Topleft } from "components/Global/Table/Topbar";
import usePopup from "hooks/usePopup";
import { useDispatch, useSelector } from "react-redux";
import { orderProductVariationDraft, setProductChecklist } from "service/actions/merchant";
import VariationsBulkAction from "./popup/VariationsBulkAction";
import ActionColumn from "./table/ActionColumn";
import PriceColumn from "./table/Price";
import VariationItem from "./VariationItem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { trimString } from "helper";

const Variant = styled("div")({
    display: "flex",
    width: "100% !important",
    gap: 20,
    alignItems: "center",
});

const VariantCustomColumn = styled("div")({
    display: "flex",
    gap: 20,
});

const SkeletonImage = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    width: "60px",
    height: "60px",
    position: "relative",
    boxSizing: "border-box",
    borderRadius: 8,
    "&:after": {
        content: '""',
        position: "absolute",
        borderBottom: `1px solid ${theme.yoho.md_color.grey[400]}`,
        top: "2.3px",
        right: "-76.853px",
        width: "77.8px",
        transform: "rotate(135deg)",
        transformOrigin: "0% 0%",
    },
}));

const ThumbImg = styled("img")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    width: "60px",
    height: "60px",
    objectFit: "contain",
    position: "relative",
    boxSizing: "border-box",
    borderRadius: 8,
    "&:after": {
        content: '""',
        position: "absolute",
        borderBottom: `1px solid ${theme.yoho.md_color.grey[400]}`,
        top: "2.3px",
        right: "-76.853px",
        width: "77.8px",
        transform: "rotate(135deg)",
        transformOrigin: "0% 0%",
    },
}));

const StatusListing = styled("div")((props) => ({
    padding: 7,
    fontSize: 12,
    fontWeight: "bold",
    borderRadius: 4,
    backgroundColor: props.children.toLowerCase() === "listed" ? "#DDF6EF" : "#ECE8F2",
    color: props.children.toLowerCase() === "listed" ? "#00A87A" : "#654A99",
}));

const ProductAdditionalInfo = styled("div")({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .sku": {
        flexGrow: 1,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2 /* number of lines to show */,
        lineClamp: 2,
        WebkitBoxOrient: "vertical",
    },
});

const Discount = styled("span")({
    borderRadius: 4,
    backgroundColor: "#dbb30f",
    color: "white",
    padding: "6px 0px",
    width: 60,
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
});

const StatusColumn = (status) => {
    return <StatusListing>{status === "listed" ? "Listed" : "Unlisted"}</StatusListing>;
};

const safeGetImageURL = (imageSlot) => {
    try {
        const imageURL = URL.createObjectURL(imageSlot.file);
        return imageURL;
    } catch (error) {
        // Redux persist cannot save non-serializable file object
        if (imageSlot.publicURL) return imageSlot.publicURL;
        return null;
    }
};

const VariantColumn = (rowData) => {
    const mainImageSlot = [...rowData.image_slots, ...rowData.existing_images].find(
        (slot) => slot.isMain && slot.occupied,
    );
    const imageURL = mainImageSlot && safeGetImageURL(mainImageSlot);
    const onDiscountClick = () => {
        window.open(`/promotion/on-sale-promotion/edit/${rowData.discounts[0]?.discount_event_id}`);
    };

    return (
        <VariantCustomColumn>
            {imageURL ? <ThumbImg src={imageURL} /> : <SkeletonImage />}
            <div>
                <FormText
                    style={{
                        width: 250,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    <span className="subtitle1">{trimString(rowData.vital_info_product_name_chinese, 120)}</span>
                </FormText>
                <ProductAdditionalInfo>
                    <div className="sku">
                        <FormText>
                            <p>
                                SKU:{" "}
                                {rowData.variations_general_custom_sku || rowData.goods_id || rowData.main_goods_id}
                            </p>
                        </FormText>
                        <FormText>
                            <p>GTIN: {rowData.variations_general_gtin}</p>
                        </FormText>
                    </div>
                    {rowData.discounts.length > 0 && <Discount onClick={onDiscountClick}>On Sale</Discount>}
                </ProductAdditionalInfo>
            </div>
        </VariantCustomColumn>
    );
};

const DropableContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 20,
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export default () => {
    const dispatch = useDispatch();
    const popup = usePopup();

    const draft = useSelector((state) => state.merchant.product.edit.data);
    const spu = useSelector((state) => state.merchant.product.edit.data.spu);
    const skuList = useSelector((state) => state.merchant.product.edit.data.sku);

    const currentCheckTable = useSelector((state) => state.merchant.product.checkbox_ids.edit_goods_ids);
    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;
        if (destination?.droppableId === source.droppableId && destination.index === source.index) return;

        const newVariation = reorder(spu?.variations_attributes, result.source.index, result.destination.index);

        dispatch(orderProductVariationDraft(newVariation));
    };

    const CheckColumn = (rowData) => {
        return (
            <Checkbox
                color="primary"
                name={rowData.goods_id}
                size="small"
                checked={currentCheckTable?.some((id) => id === rowData.goods_id)}
                defaultChecked={currentCheckTable?.some((id) => id === rowData.goods_id)}
                onChange={(_, deleted) => dispatch(setProductChecklist(rowData.goods_id, deleted, "edit"))}
            />
        );
    };

    return (
        <div>
            <FormCategory>
                <div className="full">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <DropableContainer {...provided.droppableProps} ref={provided.innerRef}>
                                    {spu?.variations_attributes?.map((item, index) => (
                                        <Draggable
                                            key={String(item?.attribute_id)}
                                            draggableId={String(item?.attribute_id)}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps}>
                                                    <VariationItem data={item} provided={provided} />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </DropableContainer>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                <Variant>
                    <FormText>
                        <p className="subtitle1">Variants List</p>
                    </FormText>
                </Variant>
                <Table
                    data={skuList}
                    className="full"
                    showExport={false}
                    showHistory={false}
                    showLayout={false}
                    disableCheckbox={true}
                >
                    <Topbar>
                        <Topleft>
                            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                                <Checkbox
                                    color="primary"
                                    size="small"
                                    defaultChecked={currentCheckTable?.length > 0}
                                    checked={currentCheckTable?.length > 0}
                                    onChange={(data, deleted) =>
                                        dispatch(
                                            setProductChecklist(
                                                draft.sku?.map((item) => item.goods_id),
                                                deleted,
                                                "edit",
                                            ),
                                        )
                                    }
                                />
                                <div>{currentCheckTable?.length || 0} Selected</div>
                                {currentCheckTable?.length > 0 && (
                                    <FormButton
                                        cvariant="contained"
                                        ccolor="blue"
                                        height={36}
                                        width="fit-content"
                                        onClick={() => popup(VariationsBulkAction, {})}
                                    >
                                        Bulk Action
                                    </FormButton>
                                )}
                            </div>
                        </Topleft>
                    </Topbar>
                    <Column type="custom" render={CheckColumn} />
                    <Column
                        field={[
                            "vital_info_product_name_chinese",
                            "variations_general_gtin",
                            "variations_general_custom_sku",
                            "vital_info_product_name_english",
                            "goods_id",
                            "image_slots",
                            "existing_images",
                            "discounts",
                        ]}
                        render={VariantColumn}
                        label="Variants"
                    />
                    <Column field="status" render={StatusColumn} label="Listed" />
                    <Column field="variations_sales_inventory" label="Inventory" />
                    <Column
                        field={[
                            "variations_sales_vip_price",
                            "variations_sales_member_price",
                            "variations_sales_market_price",
                        ]}
                        render={PriceColumn}
                        label="Selling Price"
                    />
                    <Column type="custom" render={(data) => <ActionColumn rowData={data} />} />
                </Table>
            </FormCategory>
        </div>
    );
};

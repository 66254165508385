import { DialogContent, DialogTitle, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import ComboTextArea from "components/Global/Form/TextArea/Controlled/ComboTextArea";
import { UncontrolledNormalSelect } from "components/Global/Form/NormalSelect";
import Popup from "components/Global/Layout/popup";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { unsetPopup } from "service/actions/site";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomDenyDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
    // display: "grid",
    // gridTemplateColumns: "repeat(2, 1fr)",
    // gap: "0 200px",
    // padding: "30px 0 0 0",
    // "& div:nth-child(1)": {
    //     marginBottom: "50px"
    // },
    // "& div:nth-child(3)": {
    //     marginBottom: "12px"
    // },
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

const store_lang = [
    { id: 1, name: "Traditional Chinese", code: "description_chinese" },
    { id: 2, name: "English", code: "description_english" },
];

export default ({ action, title = "Edit Warranty Terms", editdata = "" }) => {
    const { register, handleSubmit, watch, errors, setValue, control, reset } = useForm();
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        reset(editdata);
        setReady(true);
    }, []);

    const onSubmit = (data) => {
        data.name = data.template_name;
        if (editdata) {
            action(data, editdata.template_id, editdata);
        } else {
            action(data);
        }
        dispatch(unsetPopup());
    };

    return (
        ready && (
            <Popup size="md" fullWidth>
                <CustomDialogTitle>{title}</CustomDialogTitle>
                <CustomDenyDialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormCategory justifycontent="center">
                            <TextField
                                inputRef={register()}
                                name="template_name"
                                variant="outlined"
                                label="Name of term"
                                className="full"
                            />
                            <ComboTextArea
                                options={store_lang}
                                control={control}
                                defaultstate="description_chinese"
                                className="full"
                            />
                            <CustomFormButton
                                className="half"
                                ccolor="blue"
                                cvariant="contained"
                                type="submit"
                                fullWidth
                            >
                                Save changes
                            </CustomFormButton>
                            <CustomFormButton
                                className="half"
                                ccolor="blue"
                                cvariant="text"
                                fullWidth
                                onClick={() => dispatch(unsetPopup())}
                            >
                                discard
                            </CustomFormButton>
                        </FormCategory>
                    </form>
                </CustomDenyDialogContent>
            </Popup>
        )
    );
};

import { styled } from "@material-ui/core";

const PriceWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 3,
});

const Price = styled("div")({
    display: "flex",
    gap: 8,
    alignItems: "center",
    "& span": {
        fontSize: 14,
        lineHeight: "20px",
    },
});

const Label = styled("div")(({ idx }) => ({
    padding: 7,
    fontSize: 12,
    fontWeight: "bold",
    borderRadius: 4,
    height: 21,
    boxSizing: "border-box",
    width: 90,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: idx == "0" ? "#424242" : idx == "1" ? "#bdbdbd" : "#eeeeee",
    color: idx == "0" ? "white" : idx == "1" ? "#424242" : "#424242",
}));

export default (e) => {
    return (
        <PriceWrapper>
            <Price>
                <Label idx={0}>VIP Price</Label>
                <span>$ {e.variations_sales_vip_price}</span>
            </Price>
            <Price>
                <Label idx={1}>Member Price</Label>
                <span>$ {e.variations_sales_member_price}</span>
            </Price>
            <Price>
                <Label idx={2}>Market Price</Label>
                <span>$ {e.variations_sales_market_price}</span>
            </Price>
        </PriceWrapper>
    );
};

import { DialogContent, DialogTitle, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import Popup from "components/Global/Layout/popup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { bulkUpdateEditProductDraft } from "service/actions/merchant";
import { unsetPopup } from "service/actions/site";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomDenyDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

export default ({ action, close, title = "Bulk Edit Varaitions" }) => {
    const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();
    const selectedGoodIds = useSelector((state) => state.merchant.product.checkbox_ids.edit_goods_ids);

    console.log(selectedGoodIds);

    const onSubmit = (data) => {
        dispatch(
            bulkUpdateEditProductDraft({
                checkedIds: selectedGoodIds,
                updatePayload: data,
            }),
        );
        dispatch(unsetPopup());
    };

    return (
        <Popup size="md" fullWidth>
            <CustomDialogTitle>{title}</CustomDialogTitle>
            <CustomDenyDialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormCategory justifycontent="center">
                        <p style={{ width: "100%", margin: "0px" }}>Leave blank for fields you are not bulk editing</p>
                        <TextField
                            inputRef={register({ valueAsNumber: true })}
                            type="number"
                            variant="outlined"
                            name="variations_sales_inventory"
                            label="Inventory"
                            className="half"
                        />
                        <TextField
                            inputRef={register({ valueAsNumber: true })}
                            type="number"
                            variant="outlined"
                            name="variations_sales_member_price"
                            label="Member Price"
                            className="half"
                        />
                        <TextField
                            inputRef={register({ valueAsNumber: true })}
                            type="number"
                            variant="outlined"
                            name="variations_sales_vip_price"
                            label="VIP Price"
                            className="half"
                        />
                        <CustomFormButton
                            className="half"
                            ccolor="blue"
                            cvariant="contained"
                            variant="outlined"
                            type="submit"
                            fullWidth
                        >
                            Save changes
                        </CustomFormButton>
                        <CustomFormButton
                            className="half"
                            ccolor="blue"
                            cvariant="text"
                            fullWidth
                            onClick={() => dispatch(unsetPopup())}
                        >
                            Later
                        </CustomFormButton>
                    </FormCategory>
                </form>
            </CustomDenyDialogContent>
        </Popup>
    );
};

import produce, { current } from "immer";
import * as authActions from "service/actions/authentication";

let initialState = {
    merchant: undefined,
    mode: "user",
    password: {},
};

export default produce((draft, action) => {
    const currentDraft = current(draft);
    switch (action.type) {
        case authActions.setInitialForm().type:
            draft.master = action.data;
            break;
        case authActions.setMerchantReviseData().type:
            draft.merchant = action.data;
            break;
        case authActions.clearMerchantReviseData().type:
            draft.merchant = undefined;
            break;
        case authActions.setLoginMode().type:
            draft.mode = action.data;
            break;
        case authActions.setSubDistrict().type:
            draft.subdistrict = produce(currentDraft.subdistrict, (props) => {
                props.subdistrict = action.data;
                return props;
            });
            break;
        case authActions.setForgotPasswordVerify().type:
            draft.password = produce(currentDraft.password, (props) => {
                props.verify = action.data;
            });
            break;
        case authActions.setForgotPasswordEmail().type:
            draft.password = produce(currentDraft.password, (props) => {
                props.data = action.data;
            });
            break;
        default:
    }
}, initialState);

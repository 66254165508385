import { styled } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { encryptString } from "helper/transformer";
import { onImageError } from "helper";

const ProductWrapper = styled("div")({
    display: "flex",
    gap: 10,
    fontSize: 12,
    width: "100%",
    marginRight: 15,
});

const Thumbnail = styled("div")({
    borderRadius: 8,
    border: "1px solid #bdbdbd",
    minWidth: 60,
    width: 60,
    minHeight: 60,
    height: 60,
    overflow: "hidden",
    "& img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
});

const ProductDesc = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
});

const ProductInfo = styled("div")({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& .sku": {
        minWidth: 150,
        maxWidth: 150,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2 /* number of lines to show */,
        lineClamp: 2,
        WebkitBoxOrient: "vertical",
    },
});

const Stock = styled("span")({
    borderRadius: 4,
    backgroundColor: "#e8422e",
    color: "white",
    height: 25,
    width: 90,
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const CustomLink = styled(Link)(({ theme }) => ({
    ...theme.yoho.typography.subtitle3,
    textDecoration: "none",
    color: "black",
    "&:visited": {
        textDecoration: "none",
        color: "black",
    },
    "&:hover": {
        textDecoration: "underline",
    },
}));

export default (e) => {
    const detailUrl = encryptString(`${e.goods_id},${e.merchants[0].id}`, "base64");

    return (
        <ProductWrapper>
            <Thumbnail>
                <img src={e.image} onError={onImageError} />
            </Thumbnail>
            <ProductDesc>
                <CustomLink to={`/admin/products/${detailUrl}`}> {e.product_name}</CustomLink>

                <ProductInfo>
                    <span>SKU #{e.sku}</span>
                    {e["stock.goods_qty"] < 1 && <Stock>Out Of Stock</Stock>}
                </ProductInfo>
            </ProductDesc>
        </ProductWrapper>
    );
};

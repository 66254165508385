import { FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import { useDispatch, useSelector } from "react-redux";
import { setAddProductDraftField } from "service/actions/merchant";
import MPDF_MultiRTE from "./MPDF_MultiRTE";
import MPDI_MultiRTE from "./MPDI_MultiRTE";
import { styled, TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import { extractYouTubeID } from "helper/validate";

const descLangs = [
    { id: 1, name: "Traditional Chinese", code: "description_product_features_chinese" },
    { id: 2, name: "English", code: "description_product_features_english" },
];

const introLangs = [
    { id: 1, name: "Traditional Chinese", code: "description_introduction_chinese" },
    { id: 2, name: "English", code: "description_introduction_english" },
];

const VideoUrlWarning = styled("span")({
    marginLeft: "15px",
    lineHeight: "52px",
    color: "#CC3322",
});

export default () => {
    const dispatch = useDispatch();
    const updateDraft = (obj) => dispatch(setAddProductDraftField(obj));
    const draft = useSelector((state) => state.merchant.product.add.data);
    const [videoError, setVideoError] = useState(false);

    useEffect(() => {
        const vid = extractYouTubeID(draft.spu.youtube_link);
        if (draft.spu.youtube_link && vid === "") {
            setVideoError(true);
        }
    }, []);

    const onVideoLinkBlur = (event) => {
        const vid = extractYouTubeID(event.target.value);
        if (event.target.value && vid === "") {
            setVideoError(true);
        } else {
            setVideoError(false);
        }
    };

    return (
        <div>
            <FormCategory>
                <FormText>
                    <p className="subtitle1">Introduction</p>
                </FormText>
                <FormText color="grey" code={400} style={{ width: "100%" }}>
                    <p className="body2">
                        For better SEO it is recommended to add a short introduction. (max. 255 characters) If you leave
                        this field empty, we will try to fill the introduction based on product description using
                        Generative AI.
                    </p>
                </FormText>
                <div className="full">
                    <MPDI_MultiRTE options={introLangs} defaultstate={introLangs[0].code} action={updateDraft} />
                </div>
                <FormText>
                    <p className="subtitle1">Video Links</p>
                </FormText>
                <div className="full">
                    <TextField
                        variant="outlined"
                        name="youtube_link"
                        label="YouTube Link"
                        style={{ width: "60%" }}
                        value={draft.spu.youtube_link}
                        onChange={(e) => updateDraft({ youtube_link: e.target.value })}
                        onBlur={onVideoLinkBlur}
                    />
                    {videoError && <VideoUrlWarning>Invalid YouTube URL</VideoUrlWarning>}
                </div>
                <FormText>
                    <p className="subtitle1">Product Features</p>
                </FormText>
                <div className="full">
                    <MPDF_MultiRTE options={descLangs} defaultstate={descLangs[0].code} action={updateDraft} />
                </div>
            </FormCategory>
        </div>
    );
};

import { DialogContent, DialogTitle, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import Popup from "components/Global/Layout/popup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { unsetPopup } from "service/actions/site";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomDenyDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

export default ({ action, attributeId }) => {
    const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        data.attribute_id = attributeId;
        data.attribute_item_id = `temp_${Date.now()}`;
        action(data);
        dispatch(unsetPopup());
    };

    return (
        <Popup size="md" fullWidth>
            <CustomDialogTitle>Create Attribute Item</CustomDialogTitle>
            <CustomDenyDialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormCategory justifycontent="center">
                        <TextField
                            inputRef={register()}
                            name="attribute_item_name_chinese"
                            variant="outlined"
                            label="Item Name (Traditional Chinese)"
                        />
                        <TextField
                            inputRef={register()}
                            name="attribute_item_name_english"
                            variant="outlined"
                            label="Item Name (English)"
                        />
                        <CustomFormButton
                            className="half"
                            ccolor="blue"
                            cvariant="text"
                            fullWidth
                            onClick={() => dispatch(unsetPopup())}
                        >
                            Cancel
                        </CustomFormButton>
                        <CustomFormButton className="half" ccolor="blue" cvariant="contained" type="submit" fullWidth>
                            Submit
                        </CustomFormButton>
                    </FormCategory>
                </form>
            </CustomDenyDialogContent>
        </Popup>
    );
};

import api from "../config";

export const healthcheck = () => api.get(`/healthcheck`);
export const getcategories = () => api.get(`/categories`);
export const getmasterdata = () => api.get(`/auth/register/get-master-data`);
export const getsubcategory = (id) => api.get(`/sub-categories/${id}`);
export const getareas = () => api.get(`/areas`);
export const getareasbycountry = (id) => api.get(`/areas/${id}`);
export const getdistrictsbyarea = (id) => api.get(`/districts/${id}`);
export const getsubdistrictbydistrict = (id) => api.get(`/sub-districts/${id}`);
export const getsubdistrictbyarea = (id) => api.get(`/sub-districts/direct/${id}`);
export const getcountries = () => api.get(`/countries`);
export const getinternationalcode = () => api.get(`/international-codes`);
export const getdepartments = () => api.get(`/departments`);
export const getproduct = () => api.get(`/product-sales`);
export const getnavigation = () => api.get(`/menu-navigation`);

import { Checkbox, debounce, Icon, InputAdornment, styled, TextField } from "@material-ui/core";
import { FormButton, YHPagination, YohoButtonIcon } from "components/Global";
import { FormText } from "components/Global/Form";
import DateRangePicker from "components/Global/Form/DateRangePicker";
import { UncontrolledNormalSelect } from "components/Global/Form/NormalSelect";
import Table from "components/Global/Table";
import Column from "components/Global/Table/Column";
import Pagination from "components/Global/Table/Pagination";
import { Topbar, Topleft, TopRight } from "components/Global/Table/Topbar";
import useExportToExcel from "helper/exportTable";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrderParam } from "service/actions/merchant";
import Actions from "./table/Actions";
import OrderId from "./table/OrderId";
import OrderTime from "./table/OrderTime";
import Product from "./table/Product";
import Status from "./table/Status";

const OrderContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 20,
    gap: 10,
});

const Row = styled("div")((props) => ({
    display: "flex",
    justifyContent: "space-between",
    "& .title": {
        display: "flex",
        gap: 50,
        alignItems: "center",
    },
    "& .filter": {
        display: "flex",
        gap: 20,
    },
    "& .show": {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },
}));

const showOptions = [
    { id: 1, name: "30", value: 30 },
    { id: 2, name: "50", value: 50 },
    { id: 3, name: "100", value: 100 },
    { id: 4, name: "200", value: 200 },
];

const CustomButton = styled(FormButton)({
    height: 36,
    width: "fit-content",
});

const filterOptions = [
    { name: "None", value: "None" },
    { name: "Date", value: "Date" },
];

export default ({ data, isLoading, refetch }) => {
    const dispatch = useDispatch();
    const params = useSelector((state) => state.merchant.order.params);
    const [exportParam, setExportParam] = useState(null);
    const [checked, setChecked] = useState([]);
    const [filter, setFilter] = useState({
        currentOption: "None",
        startTime: moment().startOf("month"),
        endTime: moment(),
    });

    useExportToExcel(exportParam);

    const pageCount = Math.ceil((data?.total_count || 0) / parseInt(params.limit, 10));

    const handleCheck = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) newChecked.push(value);
        else newChecked.splice(currentIndex, 1);
        setChecked(newChecked);
    };

    const CheckColumn = (e) => {
        return (
            <Checkbox
                color="primary"
                size="small"
                checked={checked.includes(e.order_id)}
                onChange={() => handleCheck(e.order_id)}
            />
        );
    };

    useEffect(() => {
        setChecked([]);
    }, [data, isLoading]);

    const handleDebounceFn = (inputValue) => {
        dispatch(setOrderParam(inputValue));
    };

    const debounceFn = useCallback(debounce(handleDebounceFn, 300), []);
    const handleSearch = (e) => debounceFn({ search_string: e.target.value });

    const setFilterDate = (date) => setFilter({ ...filter, ...date });

    const setFilterOption = (selected) => setFilter({ ...filter, currentOption: selected["filter-option"] });

    useEffect(() => {
        if (filter.currentOption === "None") {
            dispatch(setOrderParam({ ...filter, start_time: null, end_time: null }));
        } else {
            dispatch(
                setOrderParam({
                    ...filter,
                    start_time: Math.floor(moment(filter.startTime).valueOf() / 1000),
                    end_time: Math.floor(moment(filter.endTime).valueOf() / 1000),
                }),
            );
        }
    }, [filter]);

    return (
        <OrderContainer>
            <Row>
                <div className="filter">
                    <TextField
                        size="small"
                        variant="outlined"
                        style={{ width: 350 }}
                        placeholder="Order No. / Product Name / SKU / GTIN"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon style={{ textAlign: "center" }}>
                                        <img style={{ height: "100%" }} src="/assets/icon/search-outline.svg" />
                                    </Icon>
                                </InputAdornment>
                            ),
                        }}
                        onKeyUp={handleSearch}
                    />
                </div>
                <div className="show">
                    <FormText>
                        <p className="body1">Filter</p>
                    </FormText>
                    <UncontrolledNormalSelect
                        size="small"
                        variant="outlined"
                        data={filterOptions}
                        name="filter-option"
                        onChange={setFilterOption}
                        value={filter.currentOption}
                        itemValue="value"
                    />
                    {filter.currentOption === "Date" && (
                        <DateRangePicker
                            name={["startTime", "endTime"]}
                            size="small"
                            value={filter}
                            onChange={setFilterDate}
                        />
                    )}
                </div>
            </Row>
            <Table data={data?.order_item || []} loading={isLoading}>
                <Topbar>
                    <Topleft>
                        {!isLoading && data.order_item.length > 0 && (
                            <>
                                <Checkbox
                                    color="primary"
                                    size="small"
                                    checked={checked.length === data.order_item.length}
                                    onChange={(_, isChecked) =>
                                        setChecked(isChecked ? data.order_item.map((e) => e.order_id) : [])
                                    }
                                />
                                <div>{checked.length || 0} Selected</div>
                            </>
                        )}
                        {checked.length > 0 && (
                            <CustomButton
                                onClick={() =>
                                    setExportParam({
                                        target: "merchant-order",
                                        data: data.order_item.filter((e) => checked.includes(e.order_id)),
                                    })
                                }
                            >
                                Export selected
                            </CustomButton>
                        )}
                    </Topleft>
                    <TopRight className="full">
                        {!isLoading && (
                            <YohoButtonIcon
                                src="/assets/icon/export.svg"
                                width={80}
                                height={35}
                                cursor="pointer"
                                text="Export"
                                onClick={() =>
                                    setExportParam({
                                        target: "merchant-order",
                                        data: data.order_item,
                                    })
                                }
                            />
                        )}
                    </TopRight>
                </Topbar>
                <Column type="custom" width={60} render={CheckColumn} />
                <Column field="add_time" label="Date" render={OrderTime} />
                <Column field={["order_id", "order_sn"]} label="Order Number" render={OrderId} />
                <Column field={["OrderGoods", "order_id"]} render={Product} width={400} label="Product" />
                <Column field="pay_time" label="Payment Time" render={OrderTime} />
                <Column
                    field={["order_status", "pay_status", "shipping_status", "RefundRequests"]}
                    render={Status}
                    label="Status"
                />
                <Column type="custom" label="Actions" render={(e) => <Actions data={e} refetch={refetch} />} />
            </Table>
            <Pagination>
                <div>
                    <YHPagination
                        size={pageCount}
                        page={params.page}
                        action={(e) => {
                            dispatch(setOrderParam({ start_position: (e - 1) * params.limit, page: e }));
                        }}
                    />
                </div>
                <div>
                    <UncontrolledNormalSelect
                        variant="standard"
                        data={showOptions}
                        name="limit"
                        prefix="Show"
                        onChange={(e) => dispatch(setOrderParam(e))}
                        value={params.limit}
                    />
                    <FormText>
                        <p className="subtitle2">Orders out of {data?.total_count || 0}</p>
                    </FormText>
                </div>
            </Pagination>
        </OrderContainer>
    );
};

import { styled } from "@material-ui/core";
import { useEffect, useState } from "react";
import { FormButton } from "components/Global";
import { Link } from "react-router-dom";

import { Table } from "components/General/Table";
import * as promotionAPI from "service/apis/merchant/promo";
import { useHistory } from "react-router-dom";
import { useApiWithErrorHandle } from "service/apis/merchant/utilHook";

const PageContainer = styled("div")({
    margin: "0px 35px",
    overflow: "auto",
});
const InfoText = styled("p")({
    color: "#888888",
});
const AddButton = styled(FormButton)({ width: 135, height: 38 });
const TableHead = styled("div")({
    display: "flex",
    margin: "16px 0px",
    justifyContent: "flex-end",
});

const columns = [
    {
        label: "Title",
        name: "title",
        size: 2,
    },
    {
        label: "Start Date",
        name: "startDate",
        size: 1,
    },
    {
        label: "End Date",
        name: "endDate",
        size: 1,
    },
    { label: "Status", name: "status", size: 1 },
    {
        label: "Number of Products",
        name: "numProduct",
        size: 1,
    },
];

function unixTimeStampToDate(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
}

const rowPerPage = 10;

export default function OnSalePromotionList() {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [pagination, setPagination] = useState({ count: 1, page: 1 });
    const history = useHistory();

    const deleteOnSalePromotion = useApiWithErrorHandle(promotionAPI.deleteDiscountEvent);
    const getPromotionEventList = useApiWithErrorHandle(promotionAPI.getDiscountEventList);

    const loadOnSalePromotionList = async () => {
        const result = await getPromotionEventList({
            limit: rowPerPage,
            offset: (pagination.page - 1) * rowPerPage,
            type: "on_sale",
        });

        if (result) {
            setPagination({ ...pagination, count: Math.ceil(result.count / rowPerPage) });
            setRows(
                result.data.map((row) => ({
                    id: row.id,
                    title: row.title,
                    startDate: unixTimeStampToDate(row.start_time),
                    endDate: unixTimeStampToDate(row.end_time),
                    status: row.is_enable_event ? "On" : "Off",
                    numProduct: row.product_count,
                })),
            );
            setLoading(false);
        }
    };

    const handlePromoInfoDelete = async (row) => {
        const confirmDelete = window.confirm(`Are you sure to delete "${row.title}"?`);
        if (confirmDelete) {
            setLoading(true);
            await deleteOnSalePromotion({ id: row.id, promotionType: "on_sale" });
            await loadOnSalePromotionList();
        }
    };

    useEffect(() => {
        loadOnSalePromotionList();
    }, [pagination.page]);

    return (
        <PageContainer>
            <h1>On Sale Promotion</h1>
            <InfoText>
                On sale promotion events can be created to provide discount for a selected group of products, over a
                specific period of time. Take a look at this{" "}
                <a
                    href="https://drive.google.com/file/d/1OC-VZMiybkvnE8_lgDds_kAorI-Of7gT/view?usp=drive_link"
                    target="_blank"
                >
                    video
                </a>{" "}
                to know more.
            </InfoText>
            <TableHead>
                <Link to="/promotion/on-sale-promotion/add">
                    <AddButton>Add New</AddButton>
                </Link>
            </TableHead>
            <Table
                loading={loading}
                columns={columns}
                rows={rows}
                emptyMessage="No Promotion Information"
                rowEdit={(row) => history.push(`/promotion/on-sale-promotion/edit/${row.id}`)}
                rowDelete={(row) => handlePromoInfoDelete(row)}
                pagination={{ ...pagination, onChange: (_, value) => setPagination({ ...pagination, page: value }) }}
            />
        </PageContainer>
    );
}

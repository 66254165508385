import React, { useState, useEffect } from "react";
import { Select, MenuItem, InputLabel, FormControl, makeStyles } from "@material-ui/core";
import { FormCategory, FormTitle } from "../index";
import InputText from "./InputText";
import { emailRegex, validateEmail } from "helper/validate";

const bankList = [
    "AIRSTAR BANK LIMITED",
    "ANT BANK (HONG KONG) LIMITED",
    "BANK OF CHINA (HONG KONG) LIMITED",
    "BANK OF COMMUNICATIONS (HONG KONG) LIMITED",
    "BANK OF EAST ASIA, LIMITED (THE)",
    "CHINA CITIC BANK INTERNATIONAL LIMITED",
    "CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED",
    "CHIYU BANKING CORPORATION LIMITED",
    "CHONG HING BANK LIMITED",
    "CITIBANK (HONG KONG) LIMITED",
    "CMB WING LUNG BANK LIMITED",
    "DAH SING BANK, LIMITED",
    "DBS BANK (HONG KONG) LIMITED",
    "FUBON BANK (HONG KONG) LIMITED",
    "FUSION BANK LIMITED",
    "HANG SENG BANK, LIMITED",
    "HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED",
    "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LIMITED",
    "LIVI BANK LIMITED",
    "MOX BANK LIMITED",
    "NANYANG COMMERCIAL BANK, LIMITED",
    "OCBC BANK (HONG KONG) LIMITED",
    "PING AN ONECONNECT BANK (HONG KONG) LIMITED",
    "PUBLIC BANK (HONG KONG) LIMITED",
    "SHANGHAI COMMERCIAL BANK LIMITED",
    "STANDARD CHARTERED BANK (HONG KONG) LIMITED",
    "TAI SANG BANK LIMITED",
    "TAI YAU BANK, LIMITED",
    "WELAB BANK LIMITED",
    "ZA BANK LIMITED",
    "Others",
];

const useStyles = makeStyles((theme) => ({
    selectLabel: {
        transform: "translateY(20px) translateX(12px)",
    },
}));

export const validateBankField = ({ accountInfo, mandatory }) => {
    let message = "";
    const { bank_name, bank_account_number, beneficiary, accounting_email } = accountInfo;

    const haveValue = bank_name || bank_account_number || beneficiary || accounting_email;
    const haveNull = !bank_name || !bank_account_number || !beneficiary || !accounting_email;

    if (mandatory && haveNull) {
        message = "All bank account fields are mandatory";
        return { error: true, message };
    }

    if (haveValue && haveNull) {
        message = "Please complete all bank account fields or leave all of them blank";
        return { error: true, message };
    }

    if (!/^[0-9-]*$/.test(bank_account_number)) {
        message = "Bank account number should only contain digits and hyphen";
        return { error: true, message };
    }

    if (accounting_email && !validateEmail(accounting_email)) {
        message = "Accounting email is not valid";
        return { error: true, message };
    }

    return { error: false, message };
};

const BankField = ({ control, errors = null, register = null, readOnly = false }) => {
    const formLoaded = Object.keys(control.getValues()).length > 0;
    const [selectedBank, setSelectedBank] = useState("");
    const [isOtherBank, setIsOtherBank] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        const currentBank = control.getValues().bank_name;
        if (formLoaded) {
            if (bankList.includes(currentBank)) setSelectedBank(currentBank);
            else if (currentBank !== "") setSelectedBank("Others");
        }
    }, [formLoaded]);

    useEffect(() => {
        if (formLoaded) {
            setIsOtherBank(selectedBank === "Others");
            if (selectedBank !== "Others") control.setValue("bank_name", selectedBank);
        }
    }, [selectedBank]);

    // Enable form error handling
    if (errors)
        return (
            <FormCategory>
                <FormTitle>Bank Account</FormTitle>
                {!readOnly && (
                    <FormControl className="half">
                        {!selectedBank && (
                            <InputLabel id="bank-select-label" className={classes.selectLabel}>
                                Select your bank
                            </InputLabel>
                        )}
                        <Select
                            className="full"
                            labelId="bank-select-label"
                            variant="outlined"
                            id="bank-select"
                            value={selectedBank}
                            onChange={(event) => setSelectedBank(event.target.value)}
                            disabled={readOnly}
                        >
                            {bankList.map((bank) => (
                                <MenuItem key={bank} value={bank}>
                                    {bank}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <InputText
                    className={readOnly ? "full" : "half"}
                    name="bank_name"
                    label="Bank Name"
                    control={control}
                    disabled={!isOtherBank || readOnly}
                    error={!!errors.bank_name}
                    helperText={errors.bank_name?.message}
                />
                <InputText
                    className="w-3"
                    name="bank_account_number"
                    label="Account Number"
                    control={control}
                    disabled={readOnly}
                    error={!!errors.bank_account_number}
                    helperText={errors.bank_account_number?.message}
                    inputRef={register({
                        required: "This field cannot be empty",
                        pattern: {
                            value: /^[0-9-]*$/,
                            message: "Only digits and hyphen are allowed",
                        },
                    })}
                />
                <InputText
                    className="w-3"
                    name="beneficiary"
                    label="Beneficiary"
                    control={control}
                    disabled={readOnly}
                    error={!!errors.beneficiary}
                    helperText={errors.beneficiary?.message}
                    inputRef={register({ required: "This field cannot be empty" })}
                />
                <InputText
                    className="w-3"
                    name="accounting_email"
                    label="Accounting Email"
                    control={control}
                    disabled={readOnly}
                    error={!!errors.accounting_email}
                    helperText={errors.accounting_email?.message}
                    inputRef={register({
                        required: "This field cannot be empty",
                        pattern: {
                            value: emailRegex,
                            message: "Invalid email",
                        },
                    })}
                />
            </FormCategory>
        );
    else
        return (
            <FormCategory>
                <FormTitle>Bank Account</FormTitle>
                {!readOnly && (
                    <FormControl className="half">
                        {!selectedBank && (
                            <InputLabel id="bank-select-label" className={classes.selectLabel}>
                                Select your bank
                            </InputLabel>
                        )}
                        <Select
                            className="full"
                            labelId="bank-select-label"
                            variant="outlined"
                            id="bank-select"
                            value={selectedBank}
                            onChange={(event) => setSelectedBank(event.target.value)}
                            disabled={readOnly}
                        >
                            {bankList.map((bank) => (
                                <MenuItem key={bank} value={bank}>
                                    {bank}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <InputText
                    className={readOnly ? "full" : "half"}
                    name="bank_name"
                    label="Bank Name"
                    control={control}
                    disabled={!isOtherBank || readOnly}
                />
                <InputText
                    className="w-3"
                    name="bank_account_number"
                    label="Account Number"
                    control={control}
                    disabled={readOnly}
                />
                <InputText
                    className="w-3"
                    name="beneficiary"
                    label="Beneficiary"
                    control={control}
                    disabled={readOnly}
                />
                <InputText
                    className="w-3"
                    name="accounting_email"
                    label="Accounting Email"
                    control={control}
                    disabled={readOnly}
                />
            </FormCategory>
        );
};

export default BankField;

import { Checkbox, FormControlLabel, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import { TextSelection } from "components/Global/Form/NormalSelect";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateShippingGeneral } from "service/actions/merchant";
import { DEFAULT_DAYS_TO_SHIP } from "constants/shipment";

const Row = styled("div")({
    display: "flex",
    alignItems: "center",
    margin: "15px 0",
    alignItems: "flex-start",
    "& .operating_days": { padding: "9px 0" },
    "& div:nth-child(1)": { width: 235 },
});

const OperatingDays = styled("div")({
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
});

const operation_days_data = [
    { name: "Monday" },
    { name: "Tuesday" },
    { name: "Wednesday" },
    { name: "Thursday" },
    { name: "Friday" },
    { name: "Saturday" },
    { name: "Sunday" },
];

export default () => {
    const { register, handleSubmit, getValues, control, reset } = useForm();
    const dispatch = useDispatch();
    const general = useSelector((state) => state.merchant.setting.shipping?.general);
    const [selectDaysToShip, setSelectDaysToShip] = useState(
        `${general?.days_to_ship_from},${general?.days_to_ship_to}`,
    );

    useEffect(() => {
        reset(general);
    }, [general]);

    const onSubmit = (data) => {
        dispatch(
            updateShippingGeneral({
                ...data,
                days_to_ship_from: Number(data?.shipment_data?.split(",")?.[0]),
                days_to_ship_to: Number(data?.shipment_data?.split(",")?.[1]),
            }),
        );
    };

    const handleCheck = (value) => {
        const { operating_days } = getValues();
        const operatingdays = operating_days?.includes(value)
            ? operating_days?.filter((id) => id !== value)
            : [...(operating_days ?? []), value];
        return operatingdays;
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <FormCategory>
                        <Row className="full">
                            <FormText className="w-2">
                                <p className="subtitle1">Day to ship</p>
                            </FormText>
                            <TextSelection
                                name="shipment_data"
                                shipment
                                data={DEFAULT_DAYS_TO_SHIP}
                                defaultValue={selectDaysToShip}
                                inputRef={register()}
                                onChange={(e) => setSelectDaysToShip(e?.target?.value)}
                            />
                        </Row>
                        <Row className="full">
                            <FormText className="w-2">
                                <p className="subtitle1">Ships from</p>
                            </FormText>
                            <FormText className="w-2">
                                <h6>{general?.ship_from}</h6>
                            </FormText>
                        </Row>
                        <Row className="full">
                            <FormText className="w-2 operating_days">
                                <p className="subtitle1">Operating Days</p>
                            </FormText>
                            <OperatingDays>
                                <Controller
                                    name="operating_days"
                                    render={(props) =>
                                        operation_days_data.map((item) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={() => props.onChange(handleCheck(item.name))}
                                                        defaultChecked={general?.operating_days?.includes(item.name)}
                                                    />
                                                }
                                                key={item.name}
                                                label={item.name}
                                            />
                                        ))
                                    }
                                    control={control}
                                />
                            </OperatingDays>
                        </Row>
                        <Row className="full">
                            <FormText className="w-2">
                                <p className="subtitle1">Payment due before shipment</p>
                            </FormText>
                            <TextField
                                inputRef={register()}
                                id="start"
                                type="time"
                                name="payment_due"
                                defaultValue="09:00"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 600,
                                }}
                            />
                        </Row>
                        <FormButton ccolor="blue" cvariant="contained" type="submit" fullWidth>
                            Save changes
                        </FormButton>
                    </FormCategory>
                </div>
            </form>
        </div>
    );
};

import { Chip, makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        width: ({ width }) => width.width || "100%",
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    chip: {
        backgroundColor: theme.yoho.md_color.blue[900],
        color: theme.yoho.color.white,
    },
    deleteIcon: {
        color: "unset",
    },
}));

export default ({
    id,
    className,
    name,
    label,
    onChange,
    data,
    keyValue,
    defaultValue = null,
    variant = "outlined",
    disabled = false,
}) => {
    const ref = useRef(null);
    const [val, setVal] = useState(defaultValue);
    const [width, setWidth] = useState(0);
    const classes = useStyles({ width: width });

    useEffect(() => setWidth({ width: ref?.current?.offsetWidth }), []);
    useEffect(() => onChange({ target: { name, value: val } }), [val]);

    return data ? (
        <Autocomplete
            disabled={disabled}
            multiple
            classes={{ option: classes.option }}
            ref={ref}
            options={data}
            {...(val && { value: val })}
            {...(val && { defaultValue: val })}
            {...(className && { className: className })}
            {...(id && { id: id })}
            {...(onChange && { onChange: (_, value) => setVal(value) })}
            // getOptionLabel={option => keyValue ? option[keyValue] : option}
            // getOptionSelected={(option, value) => keyValue ? option[keyValue] === value[keyValue] : option === value}
            filterSelectedOptions
            renderOption={(op) => <div style={{ width: "100%" }}>{op}</div>}
            renderInput={(params) => <TextField {...params} variant={variant} label={label} />}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        classes={{ root: classes.chip, deleteIcon: classes.deleteIcon }}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                    />
                ))
            }
        />
    ) : (
        <>No Data Found</>
    );
};

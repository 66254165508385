import { DialogContent, DialogTitle, Divider, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import Popup from "components/Global/Layout/popup";
import React, { memo, useRef } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import * as siteAction from "service/actions/site";
import { useOrderActions } from "../hooks";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

export default memo(({ orderId, refetch, close }) => {
    const methods = useForm({
        defaultValues: {
            logistics_id: 999,
            shipping_origin: 1,
        },
        mode: "onChange",
    });

    const shippingOrigin = methods.watch("shipping_origin");
    const isShowStyle = parseInt(shippingOrigin, 10) === 2 ? { display: "none" } : { display: "" };

    const popupLoading = useSelector((state) => state.site.popupLoading);
    const shipping = useSelector((state) => state.merchant.setting.shipping);
    const { confirmShipment, shipmentData } = useOrderActions(refetch);
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        if (parseInt(data.shipping_origin) === 1 && !data.tracking_id) {
            dispatch(
                siteAction.setSnackbar({
                    open: true,
                    message: "Please fill-in the order tracking number",
                    severity: "error",
                }),
            );
        } else {
            confirmShipment({ ...data, remark: data.remarks, order_id: orderId });
        }
    };

    return (
        <Popup size="md" fullWidth isLoading={popupLoading.loading}>
            <CustomDialogTitle>Shipment Information</CustomDialogTitle>
            <CustomDialogContent>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <FormCategory justifycontent="center">
                            <Controller
                                name="shipping_origin"
                                control={methods.control}
                                render={({ onChange, value }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        defaultValue={1}
                                        select
                                        label="Ship From"
                                        SelectProps={{ native: true }}
                                        variant="outlined"
                                        className="full"
                                    >
                                        <option value={1}>Own Warehouse</option>
                                        <option value={2}>YOHO Warehouse</option>
                                    </TextField>
                                )}
                            />
                        </FormCategory>
                        <Divider variant="fullWidth" orientation="horizontal" style={isShowStyle} />
                        <FormCategory justifycontent="center" style={isShowStyle}>
                            <Controller
                                name="logistics_id"
                                control={methods.control}
                                render={({ onChange, value }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        defaultValue={shipping?.init_data?.logistics_company[0].logistics_id}
                                        select
                                        label="Shipping Carrier"
                                        SelectProps={{ native: true }}
                                        variant="outlined"
                                    >
                                        {shipping?.init_data?.logistics_company.map((option) => (
                                            <option key={option.logistics_name} value={option.logistics_id}>
                                                {option.logistics_name}
                                            </option>
                                        ))}
                                    </TextField>
                                )}
                            />
                            <Controller
                                name="tracking_id"
                                className="full"
                                control={methods.control}
                                render={({ onChange, value }) => (
                                    <TextField
                                        label="Tracking no."
                                        variant="outlined"
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                        </FormCategory>

                        <Divider variant="fullWidth" orientation="horizontal" />
                        <FormCategory className="full" justifycontent="center" style={{ paddingBottom: 6 }}>
                            <Controller
                                name="remarks"
                                className="full"
                                control={methods.control}
                                render={({ onChange, value }) => (
                                    <TextField
                                        className="full"
                                        label="Remark"
                                        variant="outlined"
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                            {shipmentData?.code === 14028 && (
                                <Alert severity="error" style={{ width: "100%", fontSize: 15 }}>
                                    Insufficient inventroy. Modify product inventory and try again.
                                </Alert>
                            )}
                            <CustomFormButton
                                className="half"
                                ccolor="blue"
                                cvariant="contained"
                                type="submit"
                                disabled={popupLoading.loading}
                                fullWidth
                            >
                                {popupLoading.loading ? popupLoading.message : "Submit"}
                            </CustomFormButton>
                            <CustomFormButton
                                className="half"
                                ccolor="blue"
                                cvariant="text"
                                fullWidth
                                disabled={popupLoading.loading}
                                onClick={close}
                            >
                                Later
                            </CustomFormButton>
                        </FormCategory>
                    </form>
                </FormProvider>
            </CustomDialogContent>
        </Popup>
    );
});

import AccountManagement from "components/MerchantCentre/MerchantConsole/Settings/AccountManagement";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountSetting } from "service/actions/merchant";

export default () => {
    const dispatch = useDispatch();
    const isloading = useSelector((state) => state.merchant.loading);

    useEffect(() => {
        dispatch(getAccountSetting());
    }, []);

    return isloading ? <div></div> : <AccountManagement />;
};

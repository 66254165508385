import { styled } from "@material-ui/core";
import { FormText } from "components/Global/Form";

const BuyerNotes = styled("div")(({ theme }) => ({
    gridColumn: "13/26",
    gridRow: "2/3",
    border: "1px solid" + theme.yoho.md_color.grey[200],
    padding: 15,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    gap: 10,
}));

export default ({ data }) => {
    return (
        <BuyerNotes>
            <FormText>
                <p className="subtitle1">Ship to</p>
            </FormText>
            <FormText>
                <p className="body2">{data?.consignee}</p>
                <p className="body2">+852 {data?.tel}</p>
            </FormText>
            <FormText>
                <p className="body2">{data?.address}</p>
            </FormText>
        </BuyerNotes>
    );
};

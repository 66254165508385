import { styled } from "@material-ui/core";

const PriceWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 3,
});

export default (e) => {
    const newCost =
        typeof e === "string" && e !== ""
            ? `$ ${e
                  .split("-")
                  .map((text) => text.trim() + ".00")
                  .join(" - ")}`
            : `$ ${e || 0}.00`;

    return (
        <PriceWrapper>
            <span>{newCost}</span>
        </PriceWrapper>
    );
};

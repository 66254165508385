import { styled } from "@material-ui/core";
import { FormText } from "components/Global/Form";

const StyledLabel = styled("div")(({ theme, background = ["red", 500], text = ["red", 50] }) => ({
    padding: "0 8px",
    background: theme.yoho.md_color[background[0]][background[1]],
    color: theme.yoho.md_color[text[0]][text[1]],
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
}));

export default ({ text }) => {
    return (
        <>
            {{
                "To Ship": (
                    <StyledLabel background={["green", 50]} text={["green", 500]}>
                        <FormText color="green" code={500}>
                            <p className="subtitle3">Paid</p>
                        </FormText>
                    </StyledLabel>
                ),
                Shipped: (
                    <StyledLabel background={["yelloworange", 50]} text={["yelloworange", 700]}>
                        <FormText color="yelloworange" code={700}>
                            <p className="subtitle3">{text}</p>
                        </FormText>
                    </StyledLabel>
                ),
                "Order refund": (
                    <StyledLabel background={["red", 50]} text={["red", 500]}>
                        <FormText color="red" code={500}>
                            <p className="subtitle3">{text}</p>
                        </FormText>
                    </StyledLabel>
                ),
                "Complete return": (
                    <StyledLabel background={["yelloworange", 50]} text={["yelloworange", 600]}>
                        <FormText color="yelloworange" code={600}>
                            <p className="subtitle3">{text}</p>
                        </FormText>
                    </StyledLabel>
                ),
                "Review refund request": (
                    <StyledLabel background={["yelloworange", 50]} text={["yelloworange", 600]}>
                        <FormText color="yelloworange" code={600}>
                            <p className="subtitle3">{text}</p>
                        </FormText>
                    </StyledLabel>
                ),
                "Release fund": (
                    <StyledLabel background={["green", 50]} text={["green", 500]}>
                        <FormText color="green" code={500}>
                            <p className="subtitle3">{text}</p>
                        </FormText>
                    </StyledLabel>
                ),
            }[text] || <div></div>}
        </>
    );
};

import { ADMIN } from "service/constants/admin";

export const getApplication = (data) => ({
    type: ADMIN.APPLICATION.LIST.GET,
    data,
});

export const setApplication = (data) => ({
    type: ADMIN.APPLICATION.LIST.SET,
    data,
});

export const applicationError = (data) => ({
    type: ADMIN.APPLICATION.LIST.ERROR,
    data,
});

export const setApplicationParam = (data) => ({
    type: ADMIN.APPLICATION.PARAM.SET,
    data,
});

export const selectApplication = (data) => ({
    type: ADMIN.APPLICATION.SELECT.SET,
    data,
});

export const exportApplication = (data) => ({
    type: ADMIN.APPLICATION.EXPORT.SET,
    data,
});

export const getApplicationDetail = (data) => ({
    type: ADMIN.APPLICATION.DETAIL.GET,
    data,
});

export const setApplicationDetail = (data) => ({
    type: ADMIN.APPLICATION.DETAIL.SET,
    data,
});

export const applicationDetailError = (data) => ({
    type: ADMIN.APPLICATION.DETAIL.ERROR,
    data,
});

// application approve deny
export const getApplicationApprove = (data) => ({
    type: ADMIN.APPLICATION.APPROVE.GET,
    data,
});

export const applicationApproveError = (data) => ({
    type: ADMIN.APPLICATION.APPROVE.ERROR,
    data,
});

export const getApplicationDeny = (data) => ({
    type: ADMIN.APPLICATION.DENY.GET,
    data,
});

export const applicationDenyError = (data) => ({
    type: ADMIN.APPLICATION.DENY.ERROR,
    data,
});

export const getMerchantList = (data) => ({
    type: ADMIN.MERCHANT.LIST.GET,
    data,
});

export const setMerchantList = (data) => ({
    type: ADMIN.MERCHANT.LIST.SET,
    data,
});

export const merchantListError = (data) => ({
    type: ADMIN.MERCHANT.LIST.ERROR,
    data,
});

export const resetPasswordMerchant = (data, args) => ({
    type: ADMIN.MERCHANT.RESET.PASSWORD,
    data,
    args,
});

export const resetStatusMerchant = (data, args) => ({
    type: ADMIN.MERCHANT.RESET.STATUS,
    data,
    args,
});

export { setAdminProductDetail, setAdminProductInit, setAdminProductDraft, cleanupAdminProductDetail } from "./product";

import { styled } from "@material-ui/core";
import { encryptString } from "helper/transformer";
import { FormButton } from "components/Global";
import usePopup from "hooks/usePopup";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { changeStatusEditProductSKU, setMerchantLoading } from "service/actions/merchant";
import { unsetPopup } from "service/actions/site";
import { useAdminProductActions } from "../../../hooks";
import VariationsEditPopup from "../popup/EditSKUPopup";

const ActionCustomColumn = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 7,
    alignItems: "center",
});

export default (props) => {
    const { id } = useParams();
    const [goods_id] = encryptString(id, "ascii").split(",");
    const dispatch = useDispatch();
    const popup = usePopup();
    const { updateProduct } = useAdminProductActions();

    const handleEdit = () => {
        popup(VariationsEditPopup, {
            mode: "create",
            title: "Edit Variation",
            draft: props.draft,
            current: props.current,
            action: (data, id) => {
                updateProduct({
                    merchant_id: props.merchant_id,
                    product_id: id,
                    goods_id,
                    data,
                });
            },
            close: () => {
                dispatch(unsetPopup());
            },
        });
    };
    return (
        <ActionCustomColumn>
            <FormButton
                height={25}
                width={75}
                ccolor="blue"
                cvariant="contained"
                onClick={() => {
                    dispatch(setMerchantLoading("product", false));
                    handleEdit();
                }}
            >
                Edit
            </FormButton>
            <FormButton
                height={25}
                width={75}
                ccolor="blue"
                cvariant="outlined"
                onClick={() =>
                    dispatch(
                        changeStatusEditProductSKU({
                            listed: !Boolean(props.current.listed),
                            sku_id: props.current.goods_id,
                        }),
                    )
                }
            >
                {props.current.listed ? "Unlist" : "List"}
            </FormButton>
        </ActionCustomColumn>
    );
};

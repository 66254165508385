import { useState } from "react";
import { styled } from "@material-ui/core/styles";
import { Form } from "components/General/Form";
import { FieldType } from "components/General/Field";
import { FormButton } from "components/Global";
import { useApiWithErrorHandle } from "service/apis/merchant/utilHook";
import { useDispatch, useSelector } from "react-redux";
import * as promotionAPI from "service/apis/merchant/promo";
import { addStoreSubcategory, editStoreSubcategory } from "service/actions/merchant";
import { Prompt } from "react-router-dom";
import { setSnackbar } from "service/actions/site";
import { cloneDeep } from "lodash";

const PageContainer = styled("div")({
    margin: "0px 35px",
    position: "relative",
});
const FormSection = styled("div")({
    flexGrow: 1,
});
const PanelSection = styled("div")({
    width: 380,
    paddingLeft: "25px",
    paddingTop: "13px",
});
const Grid = styled("div")({
    display: "flex",
});
const RightPanel = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    minWidth: "380px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "20px",
    height: "fit-content",
    borderRadius: 16,
    position: "sticky",
    top: "35px",
    "& > *": {
        width: "100% !important",
    },
}));
const TableContainer = styled("div")({
    width: "100%",
});

const initFormSections = [
    {
        fields: [
            {
                label: "Chinese Name",
                name: "chineseName",
                type: FieldType.TEXT,
            },
            {
                label: "English Name",
                name: "englishName",
                type: FieldType.TEXT,
            },
            {
                label: "Sort Order",
                name: "sortOrder",
                type: FieldType.TEXT,
            },
        ],
    },
    {
        fields: [
            {
                label: "Hide Category",
                name: "isHidden",
                type: FieldType.SWITCH,
            },
        ],
    },
];

function validateFormData({ chineseName, englishName, sortOrder, goods }) {
    if (!chineseName) {
        return ["Chinese name must be filled in", { chineseName: "This field cannot be empty" }];
    }
    if (!englishName) {
        return ["English name must be filled in", { englishName: "This field cannot be empty" }];
    }
    if (typeof sortOrder !== "number" && Number.isNaN(Number(sortOrder))) {
        return [
            "sortOrder name must be filled in and should be number only",
            { sortOrder: "This field cannot be empty and should be number only" },
        ];
    }
    if (goods.length === 0) {
        return ["Please add at least one goods to the category.", {}];
    }

    return ["", {}];
}

export default function StorePageCategoryLv2AddEdit({ action, catId, back }) {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const lv2CategoryInfo = useSelector((state) => {
        return state.merchant.setting.category.subCategories.find((item) => item.catId == catId);
    });

    const [data, setData] = useState(
        action === "add"
            ? {
                  catId: `temp_${Math.round(Math.random() * 10000)}`,
                  chineseName: "",
                  englishName: "",
                  isHidden: false,
                  sortOrder: 0,
                  goods: [],
              }
            : lv2CategoryInfo,
    );

    const formValues = {
        ...data,
        goods: data.goods.map(({ goodId, chineseName }) => ({
            label: chineseName,
            id: goodId,
        })),
    };

    const changeHandler = (field, value) => {
        // Handle format conversion
        if (field === "goods") {
            value = value.map(({ id, label }) => ({
                goodId: id,
                chineseName: label,
            }));
        }
        setData({ ...data, [field]: value });
    };

    const handleSubmit = async () => {
        const [errorMsg, fieldError] = validateFormData(data);
        if (errorMsg) {
            setErrors(fieldError);
            dispatch(
                setSnackbar({
                    open: true,
                    message: errorMsg,
                    severity: "error",
                }),
            );
            return;
        }

        if (action === "add") {
            dispatch(addStoreSubcategory(data));
        } else {
            dispatch(editStoreSubcategory(data));
        }

        back();
    };

    return (
        <PageContainer>
            <h1>Store Page Sub-category</h1>
            <Grid>
                <TableContainer>
                    <FormSection>
                        <Form
                            sections={[
                                ...initFormSections,
                                {
                                    fields: [
                                        {
                                            type: FieldType.PRODUCT_LIST,
                                            label: "Add Product",
                                            name: "goods",
                                            onItemAdd: (newItems) => {
                                                const newList = cloneDeep(formValues.goods);
                                                newList.unshift(...newItems);
                                                changeHandler("goods", newList);
                                            },
                                            onItemRemove: (removedItems) => {
                                                const removedIds = removedItems.map(({ id }) => id);
                                                const newList = formValues.goods.filter(
                                                    ({ id }) => !removedIds.includes(id),
                                                );
                                                changeHandler("goods", newList);
                                            },
                                        },
                                    ],
                                },
                            ]}
                            values={formValues}
                            errors={errors}
                            defaultChangeHandler={changeHandler}
                        />
                    </FormSection>
                </TableContainer>
                <PanelSection>
                    <RightPanel>
                        <FormButton cvariant="contained" ccolor="blue" onClick={handleSubmit}>
                            Submit and Back
                        </FormButton>
                        <FormButton cvariant="text" ccolor="blue" onClick={() => back()}>
                            Discard Changes
                        </FormButton>
                    </RightPanel>
                </PanelSection>
            </Grid>
            <Prompt message="You have unsaved changes, are you sure you want to leave?" />
        </PageContainer>
    );
}

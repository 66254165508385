import { styled } from "@material-ui/core";
import { YohoIcon, YohoLabel } from "components/Global";
import { FormText } from "components/Global/Form";
import React from "react";

const EmailColumn = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 50,
    width: "80%",
    justifyContent: "space-between",
    "& .icon": {
        zIndex: 1,
    },
});

const LabelWrapper = styled("div")({
    display: "flex",
    gap: 10,
});

const CustomYohoIcon = styled(YohoIcon)({
    height: 12,
    width: 12,
    padding: 0,
});

const PrimaryWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: 5,
    "& p": {
        fontWeight: 700,
    },
});

const EmailCustomColumn = ({ data, active }) => {
    return (
        <EmailColumn>
            <div>
                <FormText>
                    <p className="body2">{data.personal_email}</p>
                </FormText>
                <LabelWrapper>
                    <YohoLabel
                        backgroundcolor={{ color: active === "active" ? "green" : "red", code: 50 }}
                        textcolor={{ color: active === "active" ? "green" : "red", code: 500 }}
                    >
                        {active === "active" ? "Active" : "Inactive"}
                    </YohoLabel>
                    {data.main_user === true && (
                        <PrimaryWrapper>
                            <CustomYohoIcon src="/assets/icon/star.svg" size={6} />
                            <FormText>
                                <p className="subittle3">Primary</p>
                            </FormText>
                        </PrimaryWrapper>
                    )}
                </LabelWrapper>
            </div>
        </EmailColumn>
    );
};

export default React.memo(EmailCustomColumn);

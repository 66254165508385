import { all, call, put, takeLatest } from "redux-saga/effects";
import cookie from "js-cookie";
import { DEFAULT_COOKIE_PARAMS } from "helper";
import * as merchantAction from "service/actions/merchant";
import * as siteAction from "service/actions/site";
import * as merchantSettingApis from "service/apis/merchant/setting";

export function* getShippingSettingSAGA() {
    const { data, ...rest } = yield call(merchantSettingApis.getshippingsettings);
    // console.log(rest)
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield put(merchantAction.setShippingSetting(data.result));
    } else {
        yield all([
            put(
                siteAction.setSnackbar({
                    open: true,
                    message: "Failed to get shipping data",
                    severity: "error",
                }),
            ),
            put(merchantAction.resetSettingLoading()),
            put(siteAction.setRedirect("forceAppBack")),
        ]);
    }
}

function* updateShippingGeneralSAGA(payload) {
    const { data, ...rest } = yield call(merchantSettingApis.postshippinggeneral, payload.data);

    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield all([
            put(merchantAction.updatedShippingGeneral(data.result)),
            put(
                siteAction.setSnackbar({
                    open: true,
                    message: "Shipping -> General has been updated",
                    severity: "success",
                }),
            ),
        ]);
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Failed to update data",
                severity: "error",
            }),
        );
    }
}

function* updateShippingMethodSAGA(payload) {
    const { data, ...rest } = yield call(merchantSettingApis.postshippingmethod, payload.data);

    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield all([
            put(merchantAction.updatedShippingShippingMethod(data.result)),
            put(
                siteAction.setSnackbar({
                    open: true,
                    message: "Shipping -> Shipping Method has been updated",
                    severity: "success",
                }),
            ),
        ]);
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Failed to update data",
                severity: "error",
            }),
        );
    }
}

function* updateShippingRatesSAGA(payload) {
    const { data, ...rest } = yield call(merchantSettingApis.updateshippingrates, payload.args, payload.data);
    // console.log("UPDATED", data);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield all([
            put(merchantAction.updatedShippingTableRates(data.result)),
            put(
                siteAction.setSnackbar({
                    open: true,
                    message: "Shipping -> Table Rates added new warranty",
                    severity: "success",
                }),
            ),
        ]);
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Failed to update data",
                severity: "error",
            }),
        );
    }
}

function* updateShippingDRPolicySAGA(payload) {
    const { data, ...rest } = yield call(merchantSettingApis.postshippingpolicy, payload.data);

    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token", DEFAULT_COOKIE_PARAMS);
        cookie.remove("refresh-merchant-token", DEFAULT_COOKIE_PARAMS);
        window.location.reload();
    }
    if (!data.error) {
        yield all([
            put(merchantAction.updatedShippingDRPolicy(data.result)),
            put(
                siteAction.setSnackbar({
                    open: true,
                    message: "Shipping -> Return & Delivery Policy has been updated",
                    severity: "success",
                }),
            ),
        ]);
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Failed to update data",
                severity: "error",
            }),
        );
    }
}

export default [
    takeLatest(merchantAction.getShippingSetting().type, getShippingSettingSAGA),
    takeLatest(merchantAction.updateShippingGeneral().type, updateShippingGeneralSAGA),
    takeLatest(merchantAction.updateShippingShippingMethod().type, updateShippingMethodSAGA),
    takeLatest(merchantAction.updateShippingTableRates().type, updateShippingRatesSAGA),
    takeLatest(merchantAction.updateShippingDRPolicy().type, updateShippingDRPolicySAGA),
];

import api from "../config";

// account setting
export const getaccountsettings = () => api.get("/merchant/settings/account-management");
export const updateaccountprivilege = (body) =>
    api.post("/merchant/settings/account-management/change-privilege", body);

// store setting
export const getstoresettings = () => api.get(`/merchant/settings/store-management`);
export const updatestorecontact = (body) => api.put(`/merchant/settings/store-management/contact`, body);
export const poststorewarranty = (body) => api.post(`/merchant/settings/store-management/warranty-term`, body);
export const updatestorewarranty = (id, body) =>
    api.put(`/merchant/settings/store-management/warranty-term/${id}`, body);

// shippingg setting
export const getshippingsettings = () => api.get(`/merchant/settings/shipping-management`);
export const postshippinggeneral = (body) => api.post(`merchant/settings/shipping-management/general`, body);
export const postshippingmethod = (body) => api.post(`merchant/settings/shipping-management/shipping-method`, body);
export const postshippingrates = (body) => api.post(`merchant/settings/shipping-management/table-rate`, body);
export const updateshippingrates = (id, body) =>
    api.put(`merchant/settings/shipping-management/table-rate/${id}`, body);
export const postshippingpolicy = (body) =>
    api.post(`merchant/settings/shipping-management/delivery-return-policy`, body);

// store page
export const getStorePageBasicConfig = () => api.get(`merchant/store/basic-config`);
export const postStorePageBasicConfig = (body) => api.post(`merchant/store/basic-config`, body);
export const getUploadUrl = () => api.get(`merchant/store/upload-url`);
export const postUploadImage = ({ uploadPath, formData }) => api.post(`${uploadPath}`, formData);

// store page category
export const getStorePageCategoryList = (params) => api.get(`merchant/store/store-categories-list`, params);
export const getStorePageCategoryDetails = (params) => api.get(`merchant/store/store-category-details`, params);
export const deleteStorePageCategory = (params) => api.delete(`merchant/store/store-category`, params);
export const editStorePageCategory = (params) => api.put(`merchant/store/store-category`, params);
export const createStorePageCategory = (params) => api.post(`merchant/store/store-category`, params);

// store page banner
export const getStoreBannerList = (params) => api.get(`merchant/store/store-banners-list`, params);
export const getStoreBannerDetails = (id) => api.get(`merchant/store/store-banner-details/${id}`);
export const deleteStoreBanner = (id) => api.delete(`merchant/store/store-banner/${id}`);
export const editStoreBanner = (params) => api.put(`merchant/store/store-banner`, params);
export const createStoreBanner = (params) => api.post(`merchant/store/store-banner`, params);

import { styled } from "@material-ui/core";
import { useEffect, useState } from "react";
import { FormButton } from "components/Global";
import { Link } from "react-router-dom";

import { Table } from "components/General/Table";
import * as settingAPI from "service/apis/merchant/setting";
import { useHistory } from "react-router-dom";
import { useApiWithErrorHandle } from "service/apis/merchant/utilHook";

const PageContainer = styled("div")({
    margin: "0px 35px",
    overflow: "auto",
});

const InfoText = styled("p")({
    color: "#888888",
});

const AddButton = styled(FormButton)({ width: 135, height: 38 });

const TableHead = styled("div")({
    display: "flex",
    margin: "16px 0px",
    justifyContent: "flex-end",
});

const columns = [
    {
        label: "Order",
        name: "sortOrder",
        size: 1,
    },
    {
        label: "Name",
        name: "name",
        size: 2,
    },
    {
        label: "Link To",
        name: "linkUrl",
        size: 3,
    },
];

const rowPerPage = 10;

export default function BannerList() {
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [pagination, setPagination] = useState({ count: 1, page: 1 });
    const history = useHistory();

    const getStoreBannerList = useApiWithErrorHandle(settingAPI.getStoreBannerList);
    const deleteStoreBanner = useApiWithErrorHandle(settingAPI.deleteStoreBanner);

    const loadBannerList = async () => {
        const result = await getStoreBannerList({ limit: rowPerPage, offset: (pagination.page - 1) * rowPerPage });
        if (result) {
            setPagination({ ...pagination, count: Math.ceil(result.count / rowPerPage) });
            setRows(result.rows);
            setLoading(false);
        }
    };

    const handleBannerDelete = async (row) => {
        const confirmDelete = window.confirm(`Are you sure to delete "${row.name}"?`);
        if (confirmDelete) {
            setLoading(true);
            await deleteStoreBanner(row.id);
            await loadBannerList();
        }
    };

    useEffect(() => {
        loadBannerList();
    }, [pagination.page]);

    return (
        <PageContainer>
            <h1>Store Page Banners</h1>
            <InfoText>
                Banners will be inserted between products on your store's homepage, it can be linked to various
                sub-pages within your store and make the homepage more appealing. Take a look at this{" "}
                <a
                    href="https://drive.google.com/file/d/1zqoJ0Ud_yZQ9U9DBCO6WLiz4o-I3QU5e/view?usp=sharing"
                    target="_blank"
                >
                    video
                </a>{" "}
                to know more.
            </InfoText>
            <TableHead>
                <Link to="/settings/store-banner/add">
                    <AddButton>Add New</AddButton>
                </Link>
            </TableHead>
            <Table
                loading={loading}
                columns={columns}
                rows={rows}
                emptyMessage="No Banners Added"
                rowEdit={(row) => history.push(`/settings/store-banner/edit/${row.id}`)}
                rowDelete={(row) => handleBannerDelete(row)}
                pagination={{ ...pagination, onChange: (_, value) => setPagination({ ...pagination, page: value }) }}
            />
        </PageContainer>
    );
}

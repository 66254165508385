import { styled } from "@material-ui/core";

const StatusListing = styled("div")((props) => ({
    padding: 7,
    fontSize: 12,
    fontWeight: "bold",
    borderRadius: 4,
    backgroundColor: props.type.toLowerCase() === "listed" ? "#DDF6EF" : "#ECE8F2",
    color: props.type.toLowerCase() === "listed" ? "#00A87A" : "#654A99",
}));
export default (e) => {
    return <StatusListing type={e.type_data}>{e.data || 0}</StatusListing>;
};

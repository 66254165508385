import { DialogContent, DialogTitle, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import { FormText } from "components/Global/Form";
import Popup from "components/Global/Layout/popup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAdminProductActions } from "../../../hooks";
import EditGeneral from "../tabs/EditGeneral";
import EditImages from "../tabs/EditImages";
import EditSales from "../tabs/EditSales";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomDenyDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
    // display: "grid",
    // gridTemplateColumns: "repeat(2, 1fr)",
    // gap: "0 200px",
    // padding: "30px 0 0 0",
    // "& div:nth-child(1)": {
    //     marginBottom: "50px"
    // },
    // "& div:nth-child(3)": {
    //     marginBottom: "12px"
    // },
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

const MenuContainer = styled("div")({
    display: "flex",
});

const Menu = styled("h4")(({ active, theme, type }) => ({
    ...theme.yoho.typography[type],
    color: theme.yoho.md_color.grey[800],
    display: "flex",
    position: "relative",
    "&.active": {
        color: theme.yoho.md_color.blue[500],
        "&:after": {
            content: "''",
            background: theme.yoho.md_color.blue[500],
            width: "100%",
            position: "absolute",
            height: "1px",
            left: 0,
            bottom: "-14px",
        },
    },
    textTransform: "capitalize",
    cursor: "pointer",
    padding: "0 15px",
    margin: "13px 0",
    textAlign: "center",
    "& .bubble": {
        color: theme.yoho.md_color.red[500],
    },
}));

const Divider = styled("div")(({ theme }) => ({
    width: "100%",
    borderBottom: `1px solid ${theme.yoho.md_color.grey[300]}`,
    marginBottom: "20px",
}));

const edittabs = [
    { id: 1, name: "Sales", code: "sales" },
    { id: 2, name: "General", code: "general" },
    { id: 3, name: "Images", code: "images" },
];

const SkeletonImage = styled("div")({
    width: 60,
    height: 60,
    borderRadius: 12,
    border: "1px dashed black",
});

const ImagePreview = styled("img")({
    width: 60,
    height: 60,
    borderRadius: 12,
    objectFit: "contain",
});

export default ({ action, close, title = "Edit Warranty Terms", draft = "", current }) => {
    const { register, handleSubmit, watch, errors, setValue, control, reset, getValues } = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
    });

    const isloading = useSelector((state) => state.merchant.product.loading);
    const { onUpdateProduct } = useAdminProductActions();
    const [active, setActive] = useState("sales");
    const [ready, setReady] = useState(false);
    let [files, setFiles] = useState({});
    let main_img = files?.exist_variations_general_image_main?.hasOwnProperty("id_img")
        ? files?.exist_variations_general_image_main.thumb_img
        : files?.variations_general_image_main?.hasOwnProperty("preview")
          ? files?.variations_general_image_main.preview
          : "";

    let activeSKU = { ...current };

    useEffect(() => {
        const errorList = Object.keys(errors);

        if (errorList.length > 0) {
            const salesError = errorList.some((item) => item.includes("sales"));
            const generalError = errorList.some((item) => item.includes("general"));
            if (active === "sales") {
                if (salesError) {
                    return;
                } else if (generalError) {
                    setActive("general");
                }
                return;
            } else if (active === "general") {
                if (generalError) {
                    return;
                } else if (salesError) {
                    setActive("sales");
                }
                return;
            }
        }
    }, [errors]);

    useEffect(() => {
        setFiles({
            variations_general_images: activeSKU.variations_general_images || [],
            variations_general_image_main: activeSKU.variations_general_image_main || {},
            exist_variations_general_image_main: activeSKU.exist_variations_general_image_main || {},
            exist_variations_general_images: activeSKU.exist_variations_general_images || [],
        });
    }, []);

    const onSubmit = (data) => {
        Object.keys(data).forEach((key) => (data[key] === undefined ? delete data[key] : {}));
        let newData = data;

        let tabsData = {
            description_introduction_chinese: draft.spu.description_introduction_chinese,
            description_introduction_english: draft.spu.description_introduction_english,
            description_product_features_chinese: draft.spu.description_product_features_chinese,
            description_product_features_english: draft.spu.description_product_features_english,
            days_to_ship_from: draft.spu.days_to_ship_from,
            days_to_ship_to: draft.spu.days_to_ship_to,
            shipment_return_policy_chinese: draft.spu.shipment_return_policy_chinese,
            shipment_return_policy_english: draft.spu.shipment_return_policy_english,
            shipment_ship_to_local_only: draft.spu.shipment_ship_to_local_only,
            warranty_terms_chinese: draft.spu.warranty_terms_chinese,
            warranty_terms_english: draft.spu.warranty_terms_english,
            vital_info_recognition_rewards: draft.spu.vital_info_recognition_rewards || [],
        };

        let deletedImg = activeSKU?.exist_variations_general_images?.reduce((state, reduce) => {
            let current = files?.exist_variations_general_images?.map(({ id_img }) => id_img);
            if (!current?.includes(reduce?.id_img)) {
                state.push(reduce.id_img);
            }
            return state;
        }, []);
        let imageData = {
            image_exist_main: files?.exist_variations_general_image_main?.hasOwnProperty("id_img")
                ? files?.exist_variations_general_image_main?.id_img
                : 0,
            variations_general_image_main: files.variations_general_image_main,
            image_exist_delete: deletedImg || [],
            variations_general_images: files.variations_general_images?.filter(
                ({ preview }) => preview !== files?.variations_general_image_main?.preview,
            ),
        };

        newData = {
            ...activeSKU,
            ...newData,
            ...tabsData,
            ...imageData,
        };

        newData.vital_info_product_name_chinese = draft.spu.vital_info_product_name_chinese;
        newData.vital_info_product_name_english = draft.spu.vital_info_product_name_english;

        const goods_id = activeSKU.goods_id;
        delete newData.goods_id;
        delete newData.variants;
        delete newData.variants_mixed;

        let form_data = new FormData();
        Object.keys(newData).forEach((key) => {
            if (newData[key] instanceof File) {
                form_data.append(key, newData[key]);
            } else if (typeof newData[key] === "object") {
                if (Array.isArray(newData[key])) {
                    if (newData[key][0] instanceof File) {
                        newData[key].forEach((item) => {
                            form_data.append(key, item);
                        });
                    } else {
                        form_data.append(key, JSON.stringify(newData[key]));
                    }
                } else {
                    form_data.append(key, JSON.stringify(newData[key]));
                }
            } else {
                form_data.append(key, newData[key]);
            }
        });
        action(form_data, goods_id);
    };

    useEffect(() => {
        reset(activeSKU);
        setReady(true);
    }, []);

    return (
        ready && (
            <Popup size="md" fullWidth>
                <CustomDialogTitle>{title}</CustomDialogTitle>
                <CustomDenyDialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormCategory
                            justifycontent="center"
                            style={{ display: active === "images" ? "none" : "flex" }}
                        >
                            <div className="full flex align-items-center gap-10">
                                <FormText>
                                    <p className="subtitle1">Variant:</p>
                                </FormText>
                                <FormText>
                                    <p className="body1">{draft?.variants_mixed}</p>
                                </FormText>
                            </div>
                            <div className="full flex gap-10">
                                <div onClick={() => setActive("images")}>
                                    {main_img ? <ImagePreview src={main_img} alt="product image" /> : <SkeletonImage />}
                                </div>
                                {/* <VariationDropzoneInput
                  width={60}
                  height={60}
                  control={control}
                  name="variations_general_image_main"
                  // setValue={setValue}
                  defaultValue={files}
                  action={() => setActive("images")}
                /> */}
                                <div className="w-12 flex gap-10 col">
                                    <TextField
                                        inputRef={register()}
                                        name="vital_info_product_name_chinese"
                                        variant="outlined"
                                        label="Product Name (Traditional Chinese)"
                                        className="w-12"
                                        disabled
                                    />
                                    <TextField
                                        inputRef={register()}
                                        name="vital_info_product_name_english"
                                        variant="outlined"
                                        label="Product Name (English)"
                                        className="w-12"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="full">
                                <MenuContainer>
                                    {edittabs.map((item) => (
                                        <Menu
                                            key={`${JSON.stringify(item.name)}`}
                                            type="button"
                                            className={active === item.code ? " active" : ""}
                                            onClick={() => setActive(item.code)}
                                        >
                                            {item.name}
                                            {!!item?.count && <a className="bubble">{item?.count}</a>}
                                        </Menu>
                                    ))}
                                </MenuContainer>
                                <Divider />
                                <div
                                    // className="full"
                                    style={{
                                        display: active !== "sales" ? "none" : "inline-flex",
                                    }}
                                >
                                    <EditSales
                                        defaultData={activeSKU}
                                        inputRef={register()}
                                        marketRef={register({
                                            valueAsNumber: true,
                                            min: {
                                                value:
                                                    activeSKU?.variations_sales_market_price ||
                                                    activeSKU?.variations_sales_vip_price,
                                                message: "Selling price cannot be larger than market price.",
                                            },
                                        })}
                                        errors={errors}
                                    />
                                </div>
                                <div
                                    // className="full"
                                    style={{
                                        display: active !== "general" ? "none" : "inline-flex",
                                    }}
                                >
                                    <EditGeneral
                                        defaultData={activeSKU}
                                        getValues={getValues}
                                        control={control}
                                        inputRef={register()}
                                        customRef={register({
                                            valueAsNumber: true,
                                            min: {
                                                value: 0.000001,
                                                message: "weight must be more than 0",
                                            },
                                            validate: (val) => val > 0,
                                        })}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            <CustomFormButton
                                className="half"
                                ccolor="blue"
                                cvariant="contained"
                                type="submit"
                                fullWidth
                                disabled={isloading}
                            >
                                {onUpdateProduct ? "Loading . . ." : "Save"}
                            </CustomFormButton>
                        </FormCategory>
                        <FormCategory
                            justifycontent="center"
                            style={{ display: active !== "images" ? "none" : "flex" }}
                        >
                            <div
                                className="full"
                                style={{
                                    display: active !== "images" ? "none" : "inline-flex",
                                }}
                            >
                                <EditImages
                                    defaultData={activeSKU}
                                    generalInputRef={register("variations_general_images")}
                                    mainInputRef={register("variations_general_image_main")}
                                    generalName="variations_general_images"
                                    mainName="variations_general_image_main"
                                    control={control}
                                    setValue={setValue}
                                    files={files}
                                    setFiles={setFiles}
                                />
                            </div>
                            <CustomFormButton
                                className="half"
                                ccolor="blue"
                                cvariant="outlined"
                                fullWidth
                                disabled={
                                    isloading ||
                                    !(
                                        files?.exist_variations_general_image_main?.hasOwnProperty("id_img") ||
                                        files?.variations_general_image_main instanceof File
                                    )
                                }
                                onClick={() => setActive("general")}
                            >
                                Back
                            </CustomFormButton>
                        </FormCategory>
                    </form>
                </CustomDenyDialogContent>
            </Popup>
        )
    );
};

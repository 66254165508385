import Login from "pages/authentication-module/Login";
import NewMerchant from "pages/authentication-module/NewMerchant";
import ForgotPass from "pages/authentication-module/ForgotPass";
import ForgotPassRedirect from "pages/authentication-module/ForgotPassRedirect";
import ResetPass from "pages/authentication-module/ForgotPassVerify";

export default [
    { path: "/auth/login", component: Login, exact: true },
    { path: "/auth/admin-login", component: Login, exact: true },
    { path: "/auth/newmerchant", component: NewMerchant, exact: true },
    { path: "/auth/forgotpass", component: ForgotPass, exact: true },
    { path: "/auth/forgotpass/redirect", component: ForgotPassRedirect, exact: true },
    { path: "/auth/forgotpass/verify", component: ResetPass, exact: true },
];

export const ID_order = {
    list: {
        order_item: [],
        status_summary: {
            await_payment: 0,
            in_progress: 0,
            delivered: 0,
            return_refund: 0,
            invalid: 0,
        },
    },
    detail: {},
    logs: {},
    params: {
        limit: 30,
    },
};

import { styled } from "@material-ui/core";
import { FormText } from "components/Global/Form";

const SalesProceeds = styled("div")(({ theme }) => ({
    gridColumn: "26/39",
    gridRow: "1/3",
    background: theme.yoho.md_color.grey[100],
    padding: 15,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
}));

const SPSection = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: "10px 0",
    "&:not(:nth-last-child(-n+1))": {
        borderBottom: "2px solid" + theme.yoho.md_color.grey[800],
    },
    "& p": {
        display: "flex",
        justifyContent: "space-between",
        margin: 0,
        fontSize: "14px",
        lineHeight: "14px",
    },
    "& .point_used": {
        "& span": {
            color: theme.yoho.md_color.red[500],
        },
    },
    "& .payment_discount": {
        "& span": {
            color: theme.yoho.md_color.red[500],
        },
    },
    "& .coupon_code": {
        color: theme.yoho.md_color.grey[500],
    },
    "& .paid_by_customer": {
        "& *": {
            color: theme.yoho.md_color.green[500],
        },
    },
    "& .refunded": {
        "& *": {
            color: theme.yoho.md_color.red[500],
        },
        "& .desc": {
            color: theme.yoho.md_color.grey[500],
        },
    },
    "& .payment_method": {
        color: theme.yoho.md_color.grey[500],
        "& span": {
            color: theme.yoho.md_color.grey[800],
        },
    },
}));

export default ({ data }) => {
    const couponCodes = data.coupon_codes.join(", ");
    return (
        <SalesProceeds>
            <FormText>
                <p className="subtitle1">Sales Proceeds</p>
            </FormText>
            <SPSection>
                <p>
                    <label>Item(s) Subtotal</label> <span>HK$ {data.order_goods_subtotal}</span>
                </p>
                <p>
                    <label>Shipping</label> <span>HK$ {data.shipping_fee}</span>
                </p>
                {couponCodes && (
                    <p>
                        <label>Coupon Discount [{couponCodes}]</label> <span>HK$ -{data.coupon_amount}</span>
                    </p>
                )}
            </SPSection>
            <SPSection>
                <p>
                    <strong>Grand Total</strong> <strong>HK$ {data.client_grand_total}</strong>
                </p>
            </SPSection>
        </SalesProceeds>
    );
};

export const SITE = {
    APP: { INIT: "SITE_APP_INIT" },
    LOADING: { SET: "SITE_LOADING_SET" },
    FORM: {
        MASTER: {
            GET: "SITE_FORM_MASTER_GET",
            SET: "SITE_FORM_MASTER_SET",
        },
        AREA: {
            GET: "SITE_FORM_AREA_GET",
            SET: "SITE_FORM_AREA_SET",
        },
        SUBDISTRICT: {
            SET: "SITE_FORM_SUBDISTRICT_SET",
        },
    },
    APPLICATION: {
        SELECT: {
            SET: "SITE_APPLICATION_SELECT_SET",
        },
        EXPORT: {
            SET: "SITE_APPLICATION_EXPORT_SET",
        },
    },
    DRAWER: { SET: "SITE_DRAWER_SET" },
    LANGUAGE: { SET: "SITE_LANGUAGE_SET" },
    SNACKBAR: { SET: "SITE_SNACKBAR_SET" },
    POPUP: { SET: "SITE_POPUP_SET", UNSET: "SITE_POPUP_UNSET", LOADING: "SITE_POPUP_LOADING" },
    MENU: { SET: "SITE_MENU_SET", UNSET: "SITE_MENU_UNSET" },
    REDIRECT: { SET: "SITE_REDIRECT_SET" },
    MASTER: {
        CATEGORY: { SET: "SITE_MASTER_CATEGORY_SET" },
        DATA: { SET: "SITE_MASTER_DATA_SET" },
    },
    PERMISSION: {
        ROLE: { SET: "SITE_PERMISSION_ROLE_SET" },
        ROUTE: { SET: "SITE_PERMISSION_ROUTE_SET" },
    },
    ROUTE: {
        PRIVATE: { SET: "SITE_ROUTE_PRIVATE_SET" },
        NAVBAR: { SET: "SITE_ROUTE_NAVBAR_SET" },
    },
};

import { SITE } from "service/constants/site";

export const initAPP = () => ({ type: SITE.APP.INIT });

export const setLanguage = (data) => ({ type: SITE.LANGUAGE.SET, data });

export const setLoading = (data) => ({ type: SITE.LOADING.SET, data });

export const setDrawer = (data) => ({ type: SITE.DRAWER.SET, data });

export const setSnackbar = (data) => ({ type: SITE.SNACKBAR.SET, data });

export const setPopup = (data) => ({ type: SITE.POPUP.SET, data });
export const unsetPopup = () => ({ type: SITE.POPUP.UNSET });
export const setPopupLoading = (payload) => ({ type: SITE.POPUP.LOADING, payload });

export const setMenu = (data) => ({ type: SITE.MENU.SET, data });
export const unsetMenu = () => ({ type: SITE.MENU.UNSET });

export const setRedirect = (data) => ({ type: SITE.REDIRECT.SET, data });

export const getInitialForm = () => ({ type: SITE.FORM.MASTER.GET });
export const setInitialForm = (data) => ({ type: SITE.FORM.MASTER.SET, data });

export const setSubDistrictData = (data) => ({ type: SITE.FORM.SUBDISTRICT.SET, data });
export const setCategoryData = (data) => ({ type: SITE.MASTER.CATEGORY.SET, data });
export const setMasterDataConfig = (data) => ({ type: SITE.MASTER.DATA.SET, data });

export const setPrivateRoute = (data) => ({ type: SITE.ROUTE.PRIVATE.SET, data });
export const setNavbarRoute = (data, args) => ({ type: SITE.ROUTE.NAVBAR.SET, data, args });
export const setPermissionRoleField = (data) => ({ type: SITE.PERMISSION.ROLE.SET, data });
export const setPermission = (data) => ({ type: SITE.PERMISSION.ROUTE.SET, data });

export const selectTableData = (data) => ({ type: SITE.APPLICATION.SELECT.SET, data });
export const exportTableData = (ids = [], data = [], key = "") => ({
    type: SITE.APPLICATION.EXPORT.SET,
    ids,
    data,
    key,
});

export const getAreaByCountry = (data) => ({ type: SITE.FORM.AREA.GET, data });
export const setAreaByCountry = (data) => ({ type: SITE.FORM.AREA.SET, data });

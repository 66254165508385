import { all, call, put, takeLatest, select } from "redux-saga/effects";
import cookie from "js-cookie";
import * as merchantAction from "service/actions/merchant";
import * as siteAction from "service/actions/site";
import * as merchantPrivilegeApis from "service/apis/merchant/privilege";
import * as merchantProfileApis from "service/apis/merchant/profile";
import setting from "./setting";
import product from "./product";
import order from "./order";

export function* getMerhcantPrivilegesSAGA() {
    const { data, ...rest } = yield call(merchantPrivilegeApis.getprivileges);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token");
        cookie.remove("refresh-merchant-token");
    }
    if (!data.error) {
        yield put(siteAction.setPermissionRoleField(data.result));
        return data.result;
    }
}

export function* getMerhcantProfileSAGA(auth) {
    const { data, ...rest } = yield call(merchantProfileApis.getprofile);
    const isAdmin = yield select((state) => state.profile.isAdmin);

    if (!data.error || auth) {
        yield put(merchantAction.setProfile(data.result));
        yield put(
            siteAction.setNavbarRoute(data.result.privilege || auth?.privilege, {
                isAdmin,
            }),
        );
        return data.result;
    }
}

function* changeUserPasswordSAGA(payload) {
    const { data, ...rest } = yield call(merchantProfileApis.changepass, payload.data);

    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token");
        cookie.remove("refresh-merchant-token");
    }
    if (!data.error) {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: "Your password has been changed",
                severity: "info",
            }),
        );
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* updateUserProfileSAGA(payload) {
    const { data, ...rest } = yield call(merchantProfileApis.updateprofile, payload.data);
    yield put(merchantAction.setProfile(payload.data));

    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("merchant-token");
        cookie.remove("refresh-merchant-token");
    }
    if (!data.error) {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data.result,
                severity: "info",
            }),
        );
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* mainSagaSignIn() {
    yield takeLatest(merchantAction.changeUserPassword().type, changeUserPasswordSAGA);
    yield takeLatest(merchantAction.updateMerchantProfile().type, updateUserProfileSAGA);
    yield all([...setting, ...product, ...order]);
}

export default mainSagaSignIn;

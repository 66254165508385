// import { application2excel } from "helper"
import { call, debounce, put, select, takeLatest } from "redux-saga/effects";
import cookie from "js-cookie";
import * as adminAction from "service/actions/admin";
import * as siteAction from "service/actions/site";
import * as adminApplicationApis from "service/apis/admin/application";
import * as adminMerchantApis from "service/apis/admin/merchant";

function* getApplicationSAGA(payload) {
    const { data, ...rest } = yield call(adminApplicationApis.admincompanylist, payload.data);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("admin-token");
        cookie.remove("refresh-admin-token");
    }
    if (!data.error) {
        yield put(adminAction.setApplication(data.result));
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* getApplicationDetailSAGA(payload) {
    const { data, ...rest } = yield call(adminApplicationApis.admincompanydetail, payload.data);

    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("admin-token");
        cookie.remove("refresh-admin-token");
    }
    if (!data.error) {
        yield put(adminAction.setApplicationDetail(data.result));
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* ApproveApplicationSAGA(payload) {
    const { data, ...rest } = yield call(adminApplicationApis.admincompanyaction, payload.data);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("admin-token");
        cookie.remove("refresh-admin-token");
    }
    if (!data.error) {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: `user with email ${data?.result?.personal_email} has been '${data?.result?.status}'`,
                severity: "success",
            }),
        );
        yield put(siteAction.unsetPopup());
        yield put(siteAction.setRedirect({ pathname: "/admin/application", state: payload.data.status.toLowerCase() }));
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* DenyApplicationSAGA(payload) {
    const { data, ...rest } = yield call(adminApplicationApis.admincompanyaction, payload.data);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("admin-token");
        cookie.remove("refresh-admin-token");
    }
    if (!data.error) {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: `user with email ${data?.result?.personal_email} has been '${data?.result?.status}'`,
                severity: "success",
            }),
        );
        yield put(siteAction.unsetPopup());
        yield put(siteAction.setRedirect({ pathname: "/admin/application", state: payload.data.status.toLowerCase() }));
    } else {
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: data?.message || "Your session has expired. Please sign in again",
                severity: "error",
            }),
        );
    }
}

function* getMerchantListSAGA(payload) {
    const { data, ...rest } = yield call(adminMerchantApis.adminmerchantgetstatus, payload.data);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("admin-token");
        cookie.remove("refresh-admin-token");
    }
    if (!data.error) {
        yield put(adminAction.setMerchantList(data.result));
    }
}

function* resetMerchantPasswordSAGA(payload) {
    const { data, ...rest } = yield call(adminMerchantApis.adminmerchantresetpassword, payload.data);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("admin-token");
        cookie.remove("refresh-admin-token");
    }
    if (!data.error) {
        yield put(siteAction.unsetMenu());
        yield put(siteAction.setSnackbar({ open: true, message: `Reset password is success`, severity: "info" }));
        yield call(getMerchantListSAGA, { data: payload.args });
    }
}

function* resetMerchantStatusSAGA(payload) {
    const { data, ...rest } = yield call(adminMerchantApis.adminmerchantupdatestatus, payload.data);
    if (rest.problem === "NETWORK_ERROR") {
        window.localStorage.clear();
        cookie.remove("admin-token");
        cookie.remove("refresh-admin-token");
    }
    if (!data.error) {
        yield put(siteAction.unsetMenu());
        yield put(
            siteAction.setSnackbar({
                open: true,
                message: `Change status to ${payload.data.status} is success`,
                severity: "info",
            }),
        );
        yield call(getMerchantListSAGA, { data: payload.args });
    }
}

function* mainSagaAdmin() {
    yield takeLatest(adminAction.getApplication().type, getApplicationSAGA);
    yield takeLatest(adminAction.getApplicationDetail().type, getApplicationDetailSAGA);
    yield takeLatest(adminAction.getApplicationApprove().type, ApproveApplicationSAGA);
    yield takeLatest(adminAction.getApplicationDeny().type, DenyApplicationSAGA);
    yield debounce(1000, adminAction.getMerchantList().type, getMerchantListSAGA);
    yield takeLatest(adminAction.resetPasswordMerchant().type, resetMerchantPasswordSAGA);
    yield takeLatest(adminAction.resetStatusMerchant().type, resetMerchantStatusSAGA);
}

export default mainSagaAdmin;

import { DialogContent, DialogTitle, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory } from "components/Global";
import Popup from "components/Global/Layout/popup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { unsetPopup } from "service/actions/site";

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    ...theme.yoho.typography.h4,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    "& p": {
        margin: 0,
    },
}));

const CustomDenyDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: `1px solid ${theme.yoho.md_color.grey[300]}`,
}));

const CustomFormButton = styled(FormButton)({
    alignSelf: "center",
    marginBottom: "10px",
});

export default ({ action, close, title = "Edit Warranty Terms", editdata = "" }) => {
    const { register, handleSubmit, reset } = useForm();
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);
    const goods_ids = useSelector((state) => state.merchant.product.checkbox_ids.edit_goods_ids);
    useEffect(() => {
        reset(editdata);
        setReady(true);
    }, []);

    const onSubmit = (data) => {
        if (editdata) {
            action({ ...data, goods_ids });
        } else {
            action({ ...data, goods_ids });
        }
        dispatch(unsetPopup());
    };

    return (
        ready && (
            <Popup size="md" fullWidth>
                <CustomDialogTitle>{title}</CustomDialogTitle>
                <CustomDenyDialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormCategory justifycontent="center">
                            <TextField
                                inputRef={register()}
                                type="number"
                                name="stock"
                                variant="outlined"
                                label="Inventory"
                                className="half"
                            />
                            <TextField
                                inputRef={register()}
                                type="number"
                                name="shop_price"
                                variant="outlined"
                                label="Member Price"
                                className="half"
                            />
                            <TextField
                                inputRef={register()}
                                type="number"
                                name="vip_price"
                                variant="outlined"
                                label="VIP Price"
                                className="half"
                            />
                            <CustomFormButton
                                className="half"
                                ccolor="blue"
                                cvariant="contained"
                                type="submit"
                                fullWidth
                            >
                                Save changes
                            </CustomFormButton>
                            <CustomFormButton className="half" ccolor="blue" cvariant="text" fullWidth onClick={close}>
                                later
                            </CustomFormButton>
                        </FormCategory>
                    </form>
                </CustomDenyDialogContent>
            </Popup>
        )
    );
};

import { setSnackbar } from "service/actions/site";
import XLSX from "xlsx";

export function getCurrentTimeStamp() {
    return Math.floor(new Date().getTime() / 1000);
}

export function string2timeStamp(string) {
    return Math.floor(new Date(string).getTime() / 1000);
}

function debounce(callback, interval, immediate) {
    let timeout;

    return function () {
        let context = this,
            args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) callback.apply(context, args);
        };

        let callNow = immediate && !timeout;

        clearTimeout(timeout);
        timeout = setTimeout(later, interval);

        if (callNow) callback.apply(context, args);
    };
}

export function timeStamp2String(timeStamp) {
    var a = new Date(timeStamp * 1000);
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time =
        date + " " + month + " " + year + " " + (hour >= 10 ? hour : "0" + hour) + ":" + (min >= 10 ? min : "0" + min);
    return time;
}

export function timeStamp2Date(timeStamp) {
    var a = new Date(timeStamp * 1000);
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    var date = a.getDate();
    return `${year}-${month}-${date}`;
}

export function timeStamp2DateMonth(timeStamp) {
    var a = new Date(timeStamp * 1000);
    var month = a.getMonth() + 1;
    var date = a.getDate();

    var datemonth = (month >= 10 ? month : "0" + month) + "-" + (date >= 10 ? date : "0" + date);
    return datemonth;
}

export function timeStamp2Time(timeStamp) {
    var a = new Date(timeStamp * 1000);
    var hour = a.getHours();
    var min = a.getMinutes();

    var time = (hour >= 10 ? hour : "0" + hour) + ":" + (min >= 10 ? min : "0" + min);
    return time;
}

export function timeStamp2TimeFormate(timeStamp, separator = "T", isYMDOnly = false) {
    var a = new Date(timeStamp * 1000);
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var time = year + "-" + (month >= 10 ? month : "0" + month) + "-" + (date >= 10 ? date : "0" + date);
    if (!isYMDOnly) {
        //not only show [Year/ Month/ Day]
        time += separator + (hour >= 10 ? hour : "0" + hour) + ":" + (min >= 10 ? min : "0" + min);
    }
    return time;
}

export function exportJson2Csv(json, name) {
    const ws = XLSX.utils.json_to_sheet(json);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, name + ".csv");
    setSnackbar({ open: true, message: "已下載" + name + ".csv", severity: "success" });
}

export function exportJson2Xlsx(json, name) {
    const ws = XLSX.utils.json_to_sheet(json);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, name + ".xlsx");
    setSnackbar({ open: true, message: "已下載" + name + ".xlsx", severity: "success" });
}

export function parametersToQueryString(parameters) {
    let parameters_strings = [];
    if (parameters) {
        Object.keys(parameters).forEach((key) => {
            if (
                !["object", "undefined", "function", "xml"].includes(typeof parameters[key]) ||
                Array.isArray(parameters[key])
            )
                parameters_strings.push(key + "=" + parameters[key]);
        });
        if (parameters_strings.length > 0) {
            return "?" + parameters_strings.join("&");
        }
    }
    return "";
}

// add Thousands separator
export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function objectForEach(obj, func) {
    for (const [key, value] of Object.entries(obj)) {
        func && func(key, value);
    }
}

export function hexToRgbA(hex) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");

        return {
            r: (c >> 16) & 255,
            g: (c >> 8) & 255,
            b: c & 255,
            a: 1,
        };
    } else {
        return false;
    }
}

export function arrayColumn(arr, column) {
    return arr.map((x) => x[column]);
}

export function keyToCamelCase(obj) {
    if (Array.isArray(obj)) {
        return obj.map((value) => keyToCamelCase(value));
    } else if (typeof obj === "object" && obj !== null) {
        return Object.keys(obj).reduce((result, key) => {
            let newKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
            result[newKey] = keyToCamelCase(obj[key]);
            return result;
        }, {});
    }
    return obj;
}

export function keyToSnakeCase(obj) {
    if (Array.isArray(obj)) {
        return obj.map((value) => keyToSnakeCase(value));
    } else if (typeof obj === "object" && obj !== null) {
        return Object.keys(obj).reduce((result, key) => {
            let newKey = key.replace(/[A-Z]/g, (letter) => "_" + letter.toLowerCase());
            result[newKey] = keyToSnakeCase(obj[key]);
            return result;
        }, {});
    }
    return obj;
}

export function blobToText(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        reader.readAsText(blob);
    });
}

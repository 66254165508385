import { useEffect, useState } from "react";
import { styled, InputAdornment } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import { Prompt, useHistory } from "react-router-dom";
import { FormButton } from "components/Global";
import { FieldType } from "components/General/Field";
import { Form } from "components/General/Form";
import * as promotionAPI from "service/apis/merchant/promo";
import { useDispatch } from "react-redux";
import { setSnackbar } from "service/actions/site";
import { useApiWithErrorHandle } from "service/apis/merchant/utilHook";

const PageContainer = styled("div")({
    margin: "0px 35px",
    position: "relative",
});

const InfoText = styled("p")({
    color: "#888888",
});

const Grid = styled("div")({
    display: "flex",
});

const FormSection = styled("div")({
    flexGrow: 1,
});

const PanelSection = styled("div")({
    width: 380,
    paddingLeft: "25px",
    paddingTop: "13px",
});

const formSections = [
    {
        fields: [
            {
                type: FieldType.TEXT,
                label: "Title",
                name: "title",
                fullWidth: true,
            },
            {
                type: FieldType.DATE,
                label: "Start Date ",
                name: "startDate",
            },
            {
                type: FieldType.DATE,
                label: "End Date",
                name: "endDate",
            },
        ],
    },
    {
        fields: [
            {
                name: "eventType",
                label: "Discount Type",
                type: FieldType.DROPDOWN,
                options: [
                    { label: "Percentage Discount", value: "discountPercentage" },
                    { label: "All Deduct Amount", value: "discountAmount" },
                    { label: "Discount Price", value: "discountPrice" },
                ],
            },
        ],
    },
    {
        fields: [
            {
                label: "Enable On Sale Promotion",
                name: "isEnableEvent",
                type: FieldType.SWITCH,
            },
        ],
    },
    {
        fields: [
            {
                type: FieldType.PRODUCT_LIST,
                label: "Search and Add Related Product",
                name: "products",
            },
        ],
    },
];

function unixTimeStampToDate(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
}

const RightPanel = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    minWidth: "380px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "20px",
    height: "fit-content",
    borderRadius: 16,
    position: "sticky",
    top: "35px",
    "& > *": {
        width: "100% !important",
    },
}));

function validatePromoInfo({ title, startDate, endDate, eventType, discountAmount, discountPercentage, products }) {
    if (!title) {
        return ["Title must be filled in", { title: "This field cannot be empty" }];
    }

    if (startDate > endDate) {
        return ["End date must be after start date", { endDate: "Must be after start date" }];
    }

    if (!eventType) {
        return ["Please choose a type of discount", { eventType: "Please choose a type of discount" }];
    }

    if (eventType === "discountPercentage" && discountPercentage <= 0) {
        return [
            "Please fill the valid discount percentage",
            { discountPercentage: "Please fill the valid discount percentage" },
        ];
    }

    if (eventType === "discountAmount" && discountAmount <= 0) {
        return ["Please fill the valid discount amount", { discountAmount: "Please fill the valid discount amount" }];
    }

    const isProductsPriceValid = products.every(({ discountPrice }) => discountPrice > 0);
    if (eventType === "discountPrice" && !isProductsPriceValid) {
        return [
            "The product price must larger than 0, please check again",
            { products: "The product price must larger than 0, please check again" },
        ];
    }

    return ["", {}];
}

export default function OnSalePromotionAddEdit() {
    const { id } = useParams();
    const { pathname } = useLocation();
    const action = pathname.includes("edit") ? "edit" : "add";
    const history = useHistory();
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({
        title: "",
        startDate: new Date(),
        endDate: new Date(),
        eventType: "",
        isEnableEvent: true,
        discountAmount: 0,
        discountPercentage: 0,
        products: [],
    });

    const getOnSalePromotionInfo = useApiWithErrorHandle(promotionAPI.getDiscountEventInfo);
    const editOnSalePromotionInfo = useApiWithErrorHandle(promotionAPI.editDiscountEvent);
    const createOnSalePromotionInfo = useApiWithErrorHandle(promotionAPI.createDiscountEvent);

    const eventTypeMapper = {
        percentage_discount: "discountPercentage",
        amount_discount: "discountAmount",
        price_discount: "discountPrice",
    };

    const changeHandler = (field, value) => {
        const newData = cloneDeep(data);
        newData[field] = value;
        setData(newData);
    };

    formSections[1].fields[1] = ["discountPercentage", "discountAmount"].includes(data.eventType)
        ? {
              type: FieldType.TEXT,
              label: data.eventType === "discountPercentage" ? "Discount Percentage Off" : "All Deduct Amount",
              name: data.eventType === "discountPercentage" ? "discountPercentage" : "discountAmount",
              helperText:
                  data.eventType === "discountPercentage" ? "e.g. 10% means 10% off" : "e.g. 20 means deduct 20 HKD",
              InputProps: {
                  ...(data.eventType === "discountPercentage" && {
                      endAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
                  }),
                  ...(data.eventType === "deductAmount" && {
                      startAdornment: <InputAdornment position="end">{"$"}</InputAdornment>,
                  }),
              },
          }
        : {};

    formSections[3].fields[0].onItemAdd = (newItems) => {
        const newList = cloneDeep(data.products);
        newList.unshift(...newItems);
        changeHandler("products", newList);
    };

    formSections[3].fields[0].onItemRemove = (removedItems) => {
        const removedIds = removedItems.map(({ id }) => id);
        const newList = data.products.filter(({ id }) => !removedIds.includes(id));
        changeHandler("products", newList);
    };

    formSections[3].fields[0].enablePriceInput = data.eventType === "discountPrice";

    formSections[3].fields[0].onEventPriceChange = (id, newPrice) => {
        const regex = /^[0-9\b]+$/;
        if (newPrice === "" || regex.test(newPrice)) {
            const newList = data.products.map((item) =>
                item.id === Number(id) ? { ...item, discountPrice: Number(newPrice) } : item,
            );
            changeHandler("products", newList);
        }
    };

    const handleSubmit = async () => {
        const [errorMsg, fieldError] = validatePromoInfo(data);
        if (errorMsg) {
            setErrors(fieldError);
            dispatch(
                setSnackbar({
                    open: true,
                    message: errorMsg,
                    severity: "error",
                }),
            );
            return;
        }

        const payload = {
            ...(action === "edit" && { discount_event_id: id }),
            promotionType: "on_sale",
            title: data.title,
            start_time: Math.floor(new Date(data.startDate).getTime() / 1000),
            end_time: Math.floor(new Date(data.endDate).getTime() / 1000),
            type: {
                discountAmount: "amount_discount",
                discountPercentage: "percentage_discount",
                discountPrice: "price_discount",
            }[data.eventType],
            is_enable_event: data.isEnableEvent,
            discount_percentage: data.discountPercentage,
            discount_amount: data.discountAmount,
            goods: data.products.map(({ id, discountPrice }) => ({ goods_id: id, discount_price: discountPrice })),
        };

        setSubmitting(true);
        let result;
        if (action === "edit") {
            result = await editOnSalePromotionInfo(payload);
        } else {
            result = await createOnSalePromotionInfo(payload);
        }

        if (result) {
            history.push("/promotion/on-sale-promotion");
            dispatch(
                setSnackbar({
                    open: true,
                    message: `On Sale Promotion successfully ${action === "edit" ? "edited" : "created"}`,
                    severity: "success",
                }),
            );
        } else {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        const onLoad = async () => {
            const result = await getOnSalePromotionInfo(id);
            if (result) {
                const formattedProductList = result.goods.map(({ goods_id, goods_name, promote_price }) => ({
                    label: goods_name,
                    id: goods_id,
                    discountPrice: Number(promote_price) ?? 0,
                }));
                setData({
                    title: result.title,
                    startDate: unixTimeStampToDate(result.start_time),
                    endDate: unixTimeStampToDate(result.end_time),
                    eventType: eventTypeMapper[result.type],
                    isEnableEvent: result.is_enable_event,
                    discountPercentage: result.discount_percentage,
                    discountAmount: result.discount_amount,
                    products: formattedProductList,
                });
            } else {
                history.push("/promotion/on-sale-promotion");
            }
        };
        if (action === "edit") {
            onLoad();
        }
    }, []);

    return (
        <PageContainer>
            <h1>Add On Sale Promotion</h1>
            <InfoText>
                On sale promotion events can be created to provide discount for a selected group of products, over a
                specific period of time. Take a look at this{" "}
                <a
                    href="https://drive.google.com/file/d/1OC-VZMiybkvnE8_lgDds_kAorI-Of7gT/view?usp=drive_link"
                    target="_blank"
                >
                    video
                </a>{" "}
                to know more.
            </InfoText>
            <Grid>
                <FormSection>
                    <Form sections={formSections} values={data} errors={errors} defaultChangeHandler={changeHandler} />
                </FormSection>
                <PanelSection>
                    <RightPanel>
                        <FormButton cvariant="contained" ccolor="blue" disabled={submitting} onClick={handleSubmit}>
                            {submitting ? "Loading . . . " : "Submit And Finish"}
                        </FormButton>
                        <FormButton
                            cvariant="text"
                            ccolor="blue"
                            onClick={() => history.push("/promotion/on-sale-promotion")}
                        >
                            Discard
                        </FormButton>
                    </RightPanel>
                </PanelSection>
            </Grid>
            <Prompt when={!submitting} message="You have unsaved changes, are you sure you want to leave?" />
        </PageContainer>
    );
}

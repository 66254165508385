import Table from "components/Global/Table";
import Column from "components/Global/Table/Column";
import { useSelector } from "react-redux";
import ApprovedColumn from "./table/ApprovedColumn";
import ListingColumn from "./table/ListingColumn";
import MainColumn from "./table/MainColumn";

const data = [
    { main: "Consumer Electronics", sub: "-", list: 0 },
    {
        main: "Beauty & Health",
        sub: "Face / Hair Care / Skin Care / Oral Care / Body / Health Care / Cosmetic / Service",
        list: 30,
    },
    { main: "Home Appliances", sub: "-", list: 0 },
    { main: "Computer", sub: "-", list: 0 },
    { main: "Lifestyle", sub: "Pet", list: 5 },
];

export default () => {
    const category = useSelector((state) => state.merchant.setting.store?.categories);
    return (
        <div>
            <Table data={category?.category_ids} disableCheckbox={true}>
                <Column field="category" label="Main Category" render={MainColumn} />
                <Column
                    field="sub_categories"
                    label={`Approved Sub Category (${category?.approved_sub_category || 0})`}
                    render={ApprovedColumn}
                    disableWhiteSpace
                />
                <Column field="sub_categories" label="Listings" render={ListingColumn} />
            </Table>
        </div>
    );
};

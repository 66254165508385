import MomentUtils from "@date-io/moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { Fragment } from "react";
import { Controller } from "react-hook-form";

export default ({ label, className, name, control, defaultValue, disabled, shouldDisableDate }) => {
    const dateFormatter = (str) => {
        return str;
    };

    return (
        <Fragment>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <Controller
                    control={control}
                    name={name}
                    render={({ value, onChange }) => (
                        <KeyboardDatePicker
                            shouldDisableDate={shouldDisableDate}
                            name={name}
                            variant="inline"
                            disabled={disabled}
                            inputVariant="outlined"
                            className={className}
                            autoOk={true}
                            value={moment(value).format("YYYY-MM-DD") || defaultValue}
                            format="YYYY-MM-DD"
                            label={label}
                            inputValue={moment(value).format("YYYY-MM-DD") || defaultValue}
                            onChange={onChange}
                            rifmFormatter={dateFormatter}
                        />
                    )}
                />
            </MuiPickersUtilsProvider>
        </Fragment>
    );
};

import { Box, makeStyles, Popper, styled, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { FormCategory } from "components/Global";
import { FormText, FormButton } from "components/Global/Form";
import MultiSelection from "components/Global/Form/AutoComplete/Uncontrolled/MultiSelection";
import MultipleTag from "components/Global/Form/MultipleTag";
import { TextSelection } from "components/Global/Form/NormalSelect";
import { stringSize, trimString } from "helper";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setAddProductDraftField, syncAddProductSpuToSku } from "service/actions/merchant";
import { setRedirect } from "service/actions/site";

const Category = styled("div")({
    display: "flex",
    width: "100% !important",
    gap: 20,
});

const CustomFormButton = styled(FormButton)({
    width: 397,
    height: 36,
});

const useStyles = makeStyles((theme) => ({
    input: {
        padding: "18.5px 38px 18.5px 14px",
    },
}));

const PNBox = styled("div")(({ theme, ...rest }) => {
    const counter = rest?.children?.find((item) => item.props.className === "counter");

    return {
        position: "relative",
        "& .counter": {
            position: "absolute",
            display: "flex",
            alignItems: "center",
            right: 14,
            fontSize: 13,
            top: "calc(50% - 6px)",
            fontWeight: counter.props.children < 120 ? "normal" : "bold",
            color: theme.yoho.md_color[counter.props.children < 120 ? "grey" : "red"][400],
        },
    };
});

const PreviewBox = styled("div")(({ theme }) => ({
    display: "flex",
    width: "100% !important",
    padding: "10px",
    gap: "2px",
    backgroundColor: theme.yoho.md_color.grey[100],
    borderRadius: "8px",
    fontSize: "14px",
}));

const PreviewTag = styled("span")(({ theme }) => ({
    padding: "2px 5px",
    gap: "10px",
    width: "100%",
    backgroundColor: theme.yoho.md_color.grey[400],
    borderRadius: "4px",
    fontSize: "12px",
    color: theme.palette.common.white,
    marginRight: 5,
}));

const CharText = styled("span")(({ theme }) => ({
    color: theme.yoho.md_color.grey[500],
}));

const CharError = styled("span")(({ theme }) => ({
    color: theme.yoho.md_color.red[500],
}));

const ProductName = styled("div")(({ theme }) => ({
    color: theme.yoho.md_color.grey[800],
    lineHeight: "20px",
    marginTop: 2,
}));

export default () => {
    const dispatch = useDispatch();
    const vitaldata = useSelector((state) => state.merchant.product.config);
    const draft = useSelector((state) => state.merchant.product.add);
    const classes = useStyles();

    const debounceFn = debounce((inputValue) => {
        dispatch(setAddProductDraftField(inputValue));
        dispatch(syncAddProductSpuToSku(inputValue));
    }, 200);

    const handleInput = (e) => {
        debounceFn({ [e.target.name]: e.target.value });
    };

    const handleFieldChange = (e) => {
        dispatch(setAddProductDraftField({ [e.target.name]: e.target.value }));
    };

    const handleKeywordChange = (value) => {
        dispatch(setAddProductDraftField(value));
    };

    const handleAutoComplete = (param, selection, action) => {
        if (action === "clear") {
            dispatch(setAddProductDraftField({ [param.name]: null }));
            return;
        }
        dispatch(setAddProductDraftField({ [param.name]: selection[param.key] }));
    };

    const handleChangeCategory = () => {
        dispatch(setRedirect({ pathname: "/products/add/selected" }));
        dispatch(setAddProductDraftField({ category: {} }));
    };

    const filterOptions = createFilterOptions({
        matchFrom: "any",
        stringify: (option) => option?.brand_name,
    });

    return (
        <div>
            <FormCategory>
                <Autocomplete
                    filterOptions={filterOptions}
                    className="full"
                    name="vital_info_brand"
                    options={vitaldata?.brands}
                    defaultValue={vitaldata?.brands?.find(
                        (item) => item?.brand_id == draft?.data?.spu?.vital_info_brand,
                    )}
                    getOptionLabel={(option) => option?.brand_name}
                    onChange={(_, e, event) =>
                        handleAutoComplete({ name: "vital_info_brand", key: "brand_id" }, e, event)
                    }
                    renderInput={(params) => <TextField variant="outlined" {...params} label="Brand" />}
                    PopperComponent={(props) => (
                        <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />
                    )}
                />
                <PNBox className="full">
                    <TextField
                        name="vital_info_product_name_chinese"
                        variant="outlined"
                        fullWidth
                        label="Product Name (Traditional Chinese)"
                        defaultValue={draft.data.spu.vital_info_product_name_chinese}
                        inputProps={{ className: classes.input }}
                        onChange={handleInput}
                    />
                    <div className="counter">{stringSize(draft.data.spu.vital_info_product_name_chinese)}</div>
                </PNBox>
                <PreviewBox>
                    <div>
                        <PreviewTag>Preview</PreviewTag>
                        <CharText>{`${stringSize(draft.data.spu.vital_info_product_name_chinese) >= 120 ? 120 : stringSize(draft?.data?.spu?.vital_info_product_name_chinese)}/120 char `}</CharText>
                        {stringSize(draft.data.spu.vital_info_product_name_chinese) >= 120 ? (
                            <CharError>Product title has been shorten to fit the maximum character limit.</CharError>
                        ) : (
                            <></>
                        )}
                        <ProductName>{trimString(draft.data.spu.vital_info_product_name_chinese, 120)}</ProductName>
                    </div>
                </PreviewBox>
                <PNBox className="full">
                    <TextField
                        name="vital_info_product_name_english"
                        variant="outlined"
                        fullWidth
                        label="Product Name (English)"
                        defaultValue={draft.data.spu.vital_info_product_name_english}
                        inputProps={{
                            className: classes.input,
                        }}
                        onChange={handleInput}
                    />
                    <div className="counter">{stringSize(draft.data.spu.vital_info_product_name_english)}</div>
                </PNBox>
                <PreviewBox>
                    <div>
                        <PreviewTag>Preview</PreviewTag>
                        <CharText>{`${stringSize(draft.data.spu.vital_info_product_name_english) >= 120 ? 120 : stringSize(draft?.data?.spu?.vital_info_product_name_english)}/120 char `}</CharText>
                        {stringSize(draft.data.spu.vital_info_product_name_english) >= 120 ? (
                            <CharError>Product title has been shorten to fit the maximum character limit.</CharError>
                        ) : (
                            <></>
                        )}
                        <ProductName>{trimString(draft.data.spu.vital_info_product_name_english, 120)}</ProductName>
                    </div>
                </PreviewBox>
                <Category>
                    <FormText>
                        <h5>Category:</h5>
                    </FormText>
                    <FormText>
                        <h5>{draft.data.spu.category_levels}</h5>
                    </FormText>
                    <CustomFormButton cvariant="outlined" ccolor="blue" onClick={handleChangeCategory}>
                        Change Category
                    </CustomFormButton>
                </Category>

                <TextSelection
                    disabled
                    label="Type of Item"
                    name="vital_info_type_of_item"
                    data={vitaldata.type_of_item}
                    keyValue="id"
                    keyDisplay="name"
                    defaultValue={
                        vitaldata.type_of_item?.find((item) => item.id == draft?.data?.spu?.vital_info_type_of_item)
                            ?.id || vitaldata.type_of_item[0].id
                    }
                    onChange={handleFieldChange}
                />
                <Autocomplete
                    fullWidth
                    name="vital_info_origin_of_goods"
                    PopperComponent={(props) => (
                        <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />
                    )}
                    options={vitaldata.country_of_origin_id}
                    defaultValue={vitaldata.country_of_origin_id?.find(
                        (item) => item.id == draft?.data?.spu?.vital_info_origin_of_goods,
                    )}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, props) => {
                        return (
                            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                {option.name}
                            </Box>
                        );
                    }}
                    onChange={(_, e, event) =>
                        handleAutoComplete({ name: "vital_info_origin_of_goods", key: "id" }, e, event)
                    }
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} label="Country of manufacture (made in)" />
                    )}
                />
                <TextSelection
                    label="Source of product"
                    name="vital_info_source_of_product"
                    data={vitaldata.source_of_product}
                    keyValue="id"
                    keyDisplay="name"
                    defaultValue={
                        vitaldata.source_of_product?.find(
                            (item) => item.id == draft?.data?.spu?.vital_info_source_of_product,
                        )?.id || vitaldata.source_of_product[0].id
                    }
                    onChange={handleFieldChange}
                />
                <MultiSelection
                    label="Recognition Rewards"
                    name="vital_info_recognition_rewards"
                    data={vitaldata.recognition_reward}
                    defaultValue={draft?.data?.spu?.vital_info_recognition_rewards}
                    onChange={handleFieldChange}
                />
                <MultipleTag
                    label="Chinese Keywords (Press enter to confirm)"
                    name="vital_info_keyword_chinese"
                    tagList={draft?.data?.spu?.vital_info_keyword_chinese || []}
                    onChange={handleKeywordChange}
                    tagLenLimit={8}
                />
                <MultipleTag
                    label="English Keywords (Press enter to confirm)"
                    name="vital_info_keyword_english"
                    tagList={draft?.data?.spu?.vital_info_keyword_english || []}
                    onChange={handleKeywordChange}
                    tagLenLimit={25}
                />
            </FormCategory>
        </div>
    );
};

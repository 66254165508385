import { Checkbox, FormControlLabel, styled } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

const ErrorText = styled("div")({
    color: "#f44336",
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 1.66,
    width: "100%",
});

export default ({
    data,
    name,
    control,
    setValue,
    detail = undefined,
    disabled,
    rules,
    inputRef,
    setError,
    submitClicked,
}) => {
    const key = name.split(".");
    let defaultValue =
        (Object.keys(control.defaultValuesRef.current).length !== 0 &&
            ["", ...key].reduce((a, b, c) => {
                return a[b] || control.defaultValuesRef.current[b];
            })) ||
        [];
    if (detail)
        defaultValue = defaultValue
            ?.filter((selected) => selected.selected)
            .map(({ id, name, category_id }) => ({ id, name, category_id }));
    const [selectedItems, setSelectedItems] = useState(defaultValue);
    const handleSelect = (value) => {
        const isPresent =
            selectedItems.filter((item) => item.category_id === value.category_id && item.id === value.id).length > 0;
        if (isPresent) {
            const remaining = selectedItems.filter((item) => item.id !== value.id);
            setSelectedItems(remaining);
        } else {
            setSelectedItems((prevItems) => [...prevItems, value]);
        }

        return true;
    };

    useEffect(() => {
        setValue(name, selectedItems);
    }, [selectedItems]);

    useEffect(() => {
        if (submitClicked && selectedItems?.length === 0 && name === "department_ids")
            setError("department_ids", {
                type: "required",
                message: "This field cannot be empty",
            });
    }, [selectedItems]);

    return (
        <>
            {selectedItems &&
                data.map((item) => (
                    <FormControlLabel
                        control={
                            <Controller
                                inputRef={inputRef}
                                name={name}
                                render={({ onChange }) => {
                                    return (
                                        <Checkbox
                                            color="primary"
                                            disabled={disabled}
                                            defaultChecked={!!defaultValue?.find((val) => val.id === item.id)}
                                            onChange={() => onChange(handleSelect(item))}
                                        />
                                    );
                                }}
                                control={control}
                                rules={rules}
                            />
                        }
                        label={item.label || item.name}
                        key={`${item.category_id}${item.id}`}
                    />
                ))}

            {submitClicked && selectedItems?.length === 0 ? <ErrorText>This field cannot be empty</ErrorText> : <></>}
        </>
    );
};

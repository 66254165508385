import Container from "components/MerchantCentre/Container";
import Content from "components/MerchantCentre/Content";
import List from "./List";

export default () => {
    return (
        <Container>
            <div className="main">
                <Content>
                    <List />
                </Content>
            </div>
        </Container>
    );
};

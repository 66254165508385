import { FormText } from "components/Global/Form";

export default (e) => {
    return (
        <div>
            <FormText>
                <p className="subtitle2">{e.map((item) => item.name).join(" / ")}</p>
            </FormText>
        </div>
    );
};

import axios from "axios";
import cookie from "js-cookie";
import { styled } from "@material-ui/core";

const UploadButtonContainer = styled("div")({});

const UploadButton = styled("div")({
    display: "flex",
    gap: "10px",
});

const Button = styled("label")({
    width: "147px",
    height: "52px",
    borderRadius: "26px",
    border: "1px solid #00ACEE",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    justifyContent: "center",
    backgroundColor: "#00ACEE",
    color: "#FFFFFF",
    cursor: "pointer",
    transition: "all 0.3s ease",
    "&:hover": {
        backgroundColor: "#0076A1",
    },
    "&:active": {
        backgroundColor: "#00ACEE",
        color: "white",
    },
    "& > input": {
        display: "none",
    },
});

const UploadText = styled("p")({
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "24px",
});

export default ({ className, name, setIsUploadLoading, setUploadState, setErrorMessage }) => {
    const handleSubmit = async (event) => {
        try {
            setIsUploadLoading(true);
            event.preventDefault();
            const formData = new FormData();
            formData.append("product_file", event.target.files[0]);
            const resp = await axios.post(
                `${process.env.REACT_APP_API_URL}/merchant/products/upload-product`,
                formData,
                {
                    headers: {
                        "content-type": "multipart/form-data",
                        Authorization: `Bearer ${cookie.get("merchant-token")}`,
                    },
                },
            );
            if (resp) {
                setIsUploadLoading(false);
                setUploadState("success");
            }
        } catch (error) {
            setIsUploadLoading(false);
            setUploadState("error");
            setErrorMessage(error.response.data.message);
        }
    };

    return (
        <UploadButtonContainer className={className}>
            <UploadButton>
                <Button htmlFor="productFileUpload" onChange={handleSubmit}>
                    <UploadText>Choose file</UploadText>
                    <input type="file" id="productFileUpload" name={name} accept=".xlsx,.xls" />
                </Button>
            </UploadButton>
        </UploadButtonContainer>
    );
};

import { styled } from "@material-ui/core";
import { YohoButtonIcon, YohoIcon } from "components/Global";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductSubCategory, getProductSubSubCategory, setProductDraft } from "service/actions/merchant";

const SearchWrapper = styled("div")(({ theme, lock, height }) => ({
    width: "100%",
    boxSizing: "border-box",
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    borderRadius: "8px 8px 0 0",
    padding: "7px 20px",
    height: height,
    display: "flex",
    alignItems: "center",
    backgroundColor: lock == "pointer" ? "rgb(245, 245, 245)" : "rgb(225, 245, 253)",
    "& input": {
        backgroundColor: lock === "default" && "rgb(225, 245, 253)",
    },
    cursor: lock,
}));

const SearchBar = styled("input")(({ theme }) => ({
    width: "100%",
    height: "calc(100% - 2px)",
    border: "none",
    display: "flex",
    alignItems: "center",
    padding: 0,
    fontFamily: `"Noto Sans", sans-serif`,
    ...theme.yoho.typography.h5,
    "&:focus": {
        outline: "none",
    },
    "&::placeholder": {
        alignSelf: "center",
        ...theme.yoho.typography.h5,
        padding: 0,
        fontFamily: `"Noto Sans", sans-serif`,
    },
}));

const Block = styled("div")(({ theme }) => ({
    ...theme.yoho.typography.h5,
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: "calc(100% - 2px)",
}));

const SearchResult = styled("div")({
    border: "1px solid #BDBDBD",
    borderRadius: "0px 0px 8px 8px",
    display: "flex",
    flexDirection: "column",
    maxHeight: "calc(100vh - 490px)",
    overflowY: "auto",
});

const SearchItem = styled("div")(({ theme, mode, active }) => {
    return {
        ...theme.yoho.typography.h6,
        padding: "5px 20px",
        ...(mode > 1 && { "&:not(:first-child)": { padding: "5px 30px" } }),
        ...(active === "true" && { backgroundColor: theme.yoho.md_color.blue[50] }),
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.yoho.md_color.grey[300],
        },
        "&:last-child": {
            "&:hover": {
                backgroundColor: theme.yoho.md_color.grey[300],
                borderRadius: "0px 0px 8px 8px",
            },
        },
    };
});

const Wrapper = styled("div")({
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-start",
});

export default ({ placeholder = "Product Name", action, height = 62, data = [], mode, setMode }) => {
    const [lock, setLock] = useState(true);
    const dispatch = useDispatch();
    const category = useSelector((state) => state.merchant.product.draft?.category);

    // Search function disabled but some related code may still be there

    const handleMode = (mode_id, r) => {
        if (!lock) {
            setLock(!lock);
            action("");
        }
        if (mode_id !== 4) {
            setMode(mode_id);
        }
        if (r) {
            if (mode_id === 2) {
                dispatch(getProductSubCategory(r.id));
            } else if (mode_id === 3) {
                dispatch(getProductSubSubCategory(r.id));
            }
            dispatch(setProductDraft({ category: { ...category, [mode_id - 1]: r } }));
        } else {
            let cloneInput = category;
            delete cloneInput[mode_id + 1];
            dispatch(setProductDraft({ category: cloneInput }));
        }
    };

    const reset = () => {
        setMode(1);
        dispatch(setProductDraft({ category: [] }));
    };

    return (
        <Wrapper>
            <SearchWrapper height={height} lock={lock ? "pointer" : "default"}>
                <YohoIcon src="/assets/icon/search-outline.svg" size={12} />
                <Block>
                    {mode === 1
                        ? placeholder
                        : Object.values(category)
                              .map(({ name }) => name)
                              .join(" / ")}
                </Block>
                {mode > 1 && (
                    <YohoButtonIcon
                        id="close"
                        src="/assets/icon/x.svg"
                        width={28}
                        onClick={(e) => {
                            e.stopPropagation();
                            reset();
                        }}
                        cursor={"pointer"}
                    />
                )}
            </SearchWrapper>
            <SearchResult>
                {mode > 1 && (
                    <SearchItem mode={mode} onClick={() => handleMode(mode - 1)}>
                        Back
                    </SearchItem>
                )}
                {data?.map((item) => (
                    <SearchItem
                        {...(mode === 3 && { active: (item?.name === category?.[mode]?.name).toString() })}
                        mode={mode}
                        key={item.id}
                        onClick={() => handleMode(mode + 1, item)}
                    >
                        {item.name} {mode !== 3 && ">"}
                    </SearchItem>
                ))}
            </SearchResult>
        </Wrapper>
    );
};

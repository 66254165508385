import api from "../config";

export const getproductsinit = () => api.get(`/merchant/products/get-init`);
export const getproducts = (params) => api.get(`/merchant/products`, params);
export const searchproducts = (name) => api.get(`/merchant/products/search?lang=en_us&product_name=${name}`);
export const selectProduct = (id) => api.get(`/merchant/products/select/${id}`);
export const getCategoryLevel = (id) => api.get(`/merchant/products/category-level/${id}`);
export const getproductscategories = () => api.get(`/merchant/products/categories`);
export const getproductssubcategories = (id) => api.get(`/merchant/products/sub-categories/${id}`);
export const setproducts = (body) => api.post(`/merchant/products`, body);

export const getproductattribute = () => api.get(`/merchant/products/attributes`);
export const getproductattributeterm = () => api.get(`/merchant/products/attribute-items`);

export const publishproduct = (body) => api.put(`/merchant/products/on-sale`, body);

// Product system revamp
// Backend variation generation due to need for getting image upload slots
export const generateNewProductDraft = (body) => api.post(`/merchant/products/new-product-draft`, body);
export const generateProductVariation = (body) => api.post(`/merchant/products/generate-variation`, body);
export const confirmCreateProduct = (body) => api.post(`/merchant/products/confirm-create`, body);

export const getResellProductDraft = (id) => api.get(`/merchant/products/resell-draft/${id}`);
export const confirmResellProduct = (body) => api.post(`/merchant/products/confirm-resell`, body);

export const getEditDraft = (id) => api.get(`/merchant/products/edit-draft/${id}`);
export const editProduct = (body) => api.post(`/merchant/products/edit-product`, body);

export const getImageUploadLinks = (id) => api.get(`/merchant/products/image-upload-links/${id}`);
export const uploadProductImages = (body) => api.post(`/merchant/products/update-product-images`, body);

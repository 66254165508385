import { applyMiddleware, createStore } from "redux";
import persistStore from "redux-persist/es/persistStore";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import rootReducer from "./reducers";

const Composing = (middleware) => {
    if (process?.env.NODE_ENV !== "proudction") {
        const { composeWithDevTools } = require("redux-devtools-extension");

        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(rootReducer, Composing([sagaMiddleware]));
export const persistor = persistStore(store, null, () => {
    sagaMiddleware.run(rootSaga);
});
